export default {
  id: 'educoVolunteers',
    title: "Teach, Watch and Learn.",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Dr. Joseph Michael Levermore",
      image: '',
      bio: "I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-05-10",
      readTime: "5 minutes",
      category: "charity"
    },
    content: [
      {
        type: "heading",
        content: "Introduction"
      },
      {
        type: "paragraph",
        content: "Firstly, welcome to Off the Curriculum's (OTC) in-house developed website. Two of our team members have worked tirelessly to give you a seamless and engaging experience, and we hope you stick around to see what's in store."
      },
      {
        type: "heading",
        content: "About the Charity"
      },
      {
        type: "paragraph",
        content: `Off the Curriculum is a London-based charity aiming to provide young students - ages 15 to 18 - with access to leading experts from diverse fields. We have created six-week seminar blocks (two or one? hour per week for five weeks, and one final assessment session in the sixth week), where students will get to engage with specialists from different backgrounds and find out more about the topic at hand. They will also learn about the individual's work and/or educational journey, the dos and don'ts of the sector, and everything in between.`
      },
      {
        type: "paragraph",
        content: `We want to bridge the gap between school and adult life, as we feel that choosing what to do once you finish school can be tricky. Most of us don't know what we'll want or where we'll be in five years' time so, we would like to help students make the right choices now, to open up as many doors as possible for their future. Giving them the opportunity to delve into different subjects and develop a well-rounded skill-set.`
      },
      {
        type: "heading",
        content: "EDUCO"
      },
      {
        type: "paragraph",
        content: "Yet, we are not just providing students with our seminar series, as we also want to support them outside of seminar hours in their overall educational journey. Therefore, we have designed an online learning portal called Educo. Educo, as the name suggests, will be everything education and available to the general public. Searching for more information on something you learned in class? Want to know more about the content in one of our seminars? You name it; we have the info to share and spare."
      },
      {
        type: "paragraph",
        content: "We are looking for Educo Volunteers to help with video and written content for the online learning portal. We aim to recruit students from an array of courses enrolled at a London-based university. Which leads me to my next point: why should you volunteer for an educational charity?"
      },
      {
        type: "paragraph",
        content: "We fully understand that studying at university for the first time can be challenging, especially if you have other commitments also. However, two of the key things to take away from your time as a bachelor's student are a shining CV with lots of experience to show and contacts, contacts, contacts!"
      },
      {
        type: "heading",
        content: "Volunteering"
      },
      {
        type: "paragraph",
        content: "This is where we hope to lend a helping hand to both our participants as well as yourselves.By helping create video content for us, you will be given a platform to share your knowledge, take ownership of what you have learned and add the experience to your resume. But, the experience doesn't have to stop there. If you enjoy this process, we will invite you to join us and help out during the seminar block relating to your field."
      },
      {
        type: "paragraph",
        content: "Albeit the seminars are designed for students in tertiary education, this experience will also allow you to engage with professionals from your own field. You will be able to learn more about the job market in your area of interest and build connections with the guest speakers (which you never know where it could lead!). Not to mention volunteering is considered a bonus to an employer."
      },
      {
        type: "paragraph",
        content: "There is a lot to take in, but we want this to be as fun and helpful an experience as possible. We want to create a community focused on bringing about change through educational empowerment and equal opportunity. If you wish to contribute to this charitable cause and feel passionate about education, why not contact us via email or through our multiple social media channels."
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };