export const formStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '10px',
    width: '600px',
    height: '80vh',
    overflow: 'hidden',
  },
  content: {
    overflowY: 'auto',
    padding: '20px',
  },
  label: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
    marginTop: '15px',

  },
  input: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    transition: 'border-color 0.3s ease',
    resize: 'none',
  },
  textarea: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
    resize: 'none',
    height: '60px',
    transition: 'border-color 0.3s ease',
  },
  charCount: {
    fontSize: '12px',
    color: '#999',
    textAlign: 'right',
    marginTop: '-20px',
  },
  fileInput: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
    outline: 'none',
  },
  imagePreview: {
    marginTop: '10px',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '10px',
  },
  saveButton: {
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#52aeb8',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    marginTop: '10px',
    alignSelf: 'center',
  },
  cropperContainer: {
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 1000,
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
  cropper: {
    width: '100%',
    height: '300px',
  },
  cropButton: {
    marginTop: '10px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: 'none',
    backgroundColor: '#007bff',
    color: 'white',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
    alignSelf: 'center',
  },
};