import React from 'react';

const StudioDashboard = () => {
  const containerStyle = {
    display: 'flex',
    height: '100vh',
    backgroundColor: '#181818',
    color: '#fff',
  };

  const sidebarStyle = {
    width: '250px',
    backgroundColor: '#202020',
    padding: '20px',
  };

  const mainContentStyle = {
    flex: '1',
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  };

  const logoStyle = {
    width: '40px',
    height: '40px',
    marginBottom: '20px',
  };

  const sidebarItemStyle = {
    marginBottom: '20px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  };

  const sidebarIconStyle = {
    marginRight: '10px',
  };

  const widgetStyle = {
    backgroundColor: '#292929',
    padding: '20px',
    borderRadius: '10px',
    flex: '1',
  };

  const uploadWidgetStyle = {
    ...widgetStyle,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  };

  const uploadButtonStyle = {
    marginTop: '20px',
    padding: '10px 20px',
    backgroundColor: '#1a73e8',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  };

  return (
    <div style={containerStyle}>
      <div style={sidebarStyle}>
        <img src="https://via.placeholder.com/40" alt="Logo" style={logoStyle} />
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-home"></i>
          <span>Dashboard</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-play"></i>
          <span>Content</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-chart-bar"></i>
          <span>Analytics</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-comments"></i>
          <span>Comments</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-closed-captioning"></i>
          <span>Subtitles</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-copyright"></i>
          <span>Copyright</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-wallet"></i>
          <span>Earn</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-wrench"></i>
          <span>Customization</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-music"></i>
          <span>Audio library</span>
        </div>
        <div style={sidebarItemStyle}>
          <i style={sidebarIconStyle} className="fas fa-cog"></i>
          <span>Settings</span>
        </div>
      </div>
      <div style={mainContentStyle}>
        <div style={uploadWidgetStyle}>
          <div>
            <i className="fas fa-video" style={{ fontSize: '50px', color: '#ff5722' }}></i>
          </div>
          <h3>Want to see metrics on your recent video?</h3>
          <p>Upload and publish a video to get started.</p>
          <button style={uploadButtonStyle}>Upload Videos</button>
        </div>
        <div style={{ display: 'flex', gap: '20px' }}>
          <div style={widgetStyle}>
            <h3>Channel analytics</h3>
            <p>Current subscribers: 6</p>
            <p>Summary (Last 28 days):</p>
            <p>Views: 3</p>
            <p>Watch time (hours): 0.0</p>
            <p>Top videos (Last 48 hours):</p>
            <a href="#">Go to channel analytics</a>
          </div>
          <div style={widgetStyle}>
            <h3>Creator Insider</h3>
            <img src="https://via.placeholder.com/300x150" alt="Creator Insider" />
            <p>This Week at YouTube: We're talking about the ability to remix official music videos for Shorts and how to enable auto-captions to watch videos in other languages.</p>
            <a href="#">Watch on YouTube</a>
          </div>
        </div>
        <div style={widgetStyle}>
          <h3>Latest comments</h3>
          <p>Channel comments that I haven't responded to:</p>
          <div>
            <img src="https://via.placeholder.com/50" alt="Commenter" style={{ marginRight: '10px' }} />
            <span>No Big Bang actually God made the world in 7 days and that's why you're...</span>
          </div>
          <a href="#">View more</a>
        </div>
      </div>
    </div>
  );
};

export default StudioDashboard;
