import React from 'react';
import { Link } from 'react-router-dom';

const EPQMenteeResourceLibrary = () => {
  const teamInfo = [
    {
      title: 'EdExcel EPQ Example Dissertation',
      description: 'The first highlight resource is to an EPQ example dissertation hosted on the EdExcel website. This sample showcases the structure, formatting, and content expected in an EPQ dissertation.',
      image: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-3/2010/Coursework-materials/exemplar-dissertation.pdf',
      link: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-3/2010/Coursework-materials/exemplar-dissertation.pdf'
    },
    {
      title: 'EdExcel EPQ Example Project Proposal ',
      description: 'Find here a model project proposal. Reviewing this example will provide guidance on crafting a compelling and well-organized EPQ project proposal.',
      image: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-1-and-Level-2/2010/Coursework-materials/Project-Proposal-Form-completed-example.pdf',
      link: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-1-and-Level-2/2010/Coursework-materials/Project-Proposal-Form-completed-example.pdf',
    },
    {
      title: 'Mentoring done well!',
      description: `In this short video Simon Sinek describes what it means to develop a true mentorship, he states: 'True mentorship, like true friendship, is not a one way street. It's not about one person only giving advice to the other. Both people are showing up to give and both people are showing up to learn. But you can't ask someone to be your mentor, especially someone who's a total stranger,`,
      image: 'https://i.ytimg.com/vi/lFUXtURI-Dk/hqdefault.jpg?sqp=-oaymwEjCOADEI4CSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLAiD_RSEpVTAEjUBMW_ybMv9LE1pQ',
      link: 'https://www.youtube.com/watch?v=lFUXtURI-Dk'
    },
    {
      title: 'EdExcel EPQ and Employability',
      description: `Participating in the EPQ mentoring program offers a valuable opportunity to develop key employability skills. Remember this mentoring opportunity can enhance a students communication, leadership, problem-solving, and time management abilities, all of which are highly sought after by employers.`,
      image: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-3/2010/Coursework-materials/introduction-to-future-skills-concept.pdf',
      link: 'https://qualifications.pearson.com/content/dam/pdf/Project-Qualification/Level-3/2010/Coursework-materials/introduction-to-future-skills-concept.pdf'
    },
    {
      title: 'How to write an EPQ dissertation!',
      description: `As an EPQ mentor, it's essential to understand that students may feel overwhelmed by the challenge of academic essay writing, even if they are talented writers in other contexts. Remember to reassure your mentees that academic writing is a skill anyone can learn with the right guidance and support, and encourage them to seek help from their supervisor and refer to this helpful booklet whenever they need assistance`,
      image: 'https://thelakesschool.com/the-lakes-school-cumbria/wp-content/uploads/2019/11/EPQ-Guidance-Booklet-compressed.pdf',
      link: 'https://thelakesschool.com/the-lakes-school-cumbria/wp-content/uploads/2019/11/EPQ-Guidance-Booklet-compressed.pdf'

    },
      {
        title: 'Mentor styles: The importance of adapting!',
        description: `Executive coach David Carmen provides advice on how to adapt your mentoring style to bring out the best in your student mentee. He notes, "There is a very big range of confidence and experience levels you're likely to encounter with students." As a mentor, it's important to recognize where your mentee falls on this spectrum and provide support accordingly to help them thrive.`,
        image: 'https://i.ytimg.com/vi/bSyhGKIzzwk/hq720.jpg?sqp=-oaymwEXCNAFEJQDSFryq4qpAwkIARUAAIhCGAE=&rs=AOn4CLAZUPgGZWhXdtOZDaOQDAZ3D_e3jQ',
        link: 'https://www.youtube.com/watch?v=bSyhGKIzzwk',

      },
      {
        title: 'Support your mentor to put forward the best aims and objectives possible',
        description: `In this blog post Pat Thomson's explains the difference between aims and objectives in research projects. In the post, she clarifies that aims are broad statements of desired outcomes, while objectives are the specific steps or actions you will take to achieve those aims.`,
        image: 'https://patthomson.net/wp-content/uploads/2015/11/cropped-i5lxvew5alxdgrwiq2ixtbysiqlw0zdo1ckr4iudto01.jpeg',
        link: 'https://patthomson.net/2014/06/09/aims-and-objectives-whats-the-difference/'

      },
      {
        title: 'The influence of environment on young people educational attainment.',
        description: 'In this journal article Dr. Hartas explores the influence of psychosocial factors, background characteristics, and home environment on the educational aspirations of young people. Concluding that self-efficacy, well-being, emotional closeness to parents, and cultural capital were good predictors of educational aspirations.',
        image: 'https://www.tandfonline.com/doi/cover-img/10.1080/cjys20.v022.i01',
        link: 'https://www.tandfonline.com/doi/full/10.1080/13676261.2016.1145634?scroll=top&needAccess=true#abstract'
      },
      {
        title: `Further safeguarding support: Responding to a Child's Disclosure of Abuse`,
        description: `It is crucial that adults who work with children are able to respond supportively in the moment a child chooses to disclose, and to interact and communicate in a way that helps children feel listened to. The NSPCC’s Let Children Know You’re Listening animation outlines the key interpersonal skills that adults should use when a child discloses abuse and/or neglect.`,
        image: 'https://i.ytimg.com/vi/bvJ5uBlGYgE/hqdefault.jpg?sqp=-oaymwEjCOADEI4CSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLDkGBFJYqmEcjy043S680pOU4_9rg',
        link: 'https://www.youtube.com/watch?v=bvJ5uBlGYgE'

      },
      {
        title: 'A situation you will experience soon - How to close your mentor/mentee relationship successfully!',
        description: `Dr. Emilie DeYoung gives some great tips to closing out your mentoring. She reminds us to write a note or card to tell them a little bit about what you've seen in terms of growth over the past year, maybe if there were some challenges reflect on how they faced them - here remember to focus on character traits rather than academic.`,
        image: 'https://i.ytimg.com/vi/NLzRHcei6pA/hqdefault.jpg?sqp=-oaymwEjCOADEI4CSFryq4qpAxUIARUAAAAAGAElAADIQj0AgKJDeAE=&rs=AOn4CLDCHNfKiDLHlv7Bf7qqi_khBqqoGQ',
        link: 'https://www.youtube.com/watch?v=NLzRHcei6pA'
      },
  ];

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    leftSection: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
    },
    rightSection: {
      flex: '2',
    },
    heading: {
      fontSize: '2.5em',
      color: '#333',
      marginBottom: '20px',
    },
    description: {
      fontSize: '1.1em',
      color: '#666',
      marginBottom: '30px',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
    },
    image: {
      width: '100%',
      height: '200px',
      objectFit: 'cover',
      borderRadius: '8px',
      marginBottom: '10px',
    },
    itemTitle: {
      fontSize: '1.2em',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    itemDescription: {
      fontSize: '0.9em',
      color: '#666',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <h1>Resource Library</h1>
        <p >
        Welcome to Off the Curriculum's Academic Resource Library. We understand you may need extra-support from external sources, therefore, we have curated a list of trusted external resources - found below: 
        </p>
        <br />
      </div>
      <div style={styles.rightSection}>
        <div style={styles.grid}>
          {teamInfo.map((item, index) => (
            <div key={index} style={styles.gridItem}>
              <Link to ={item.link} target='_'>
                <img src={item.image} alt={item.title} style={styles.image} />
              </Link>
                <h2 style={styles.itemTitle}>{item.title}</h2>
                <p style={styles.itemDescription}>{item.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default EPQMenteeResourceLibrary;