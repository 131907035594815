import { ref, child, get, update, query, orderByChild, equalTo, onValue, orderByKey, limitToFirst, getDatabase } from 'firebase/database';
import { db } from './firebaseConfig';

export function emailsHasher(s) {
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0);
    return a & a;
  }, 0);
}

export function publicUserHasher(input) {
  const salt = "publicProfile_";
  const saltedInput = salt + input;
  const hash = saltedInput.split('').reduce((acc, char, index) => {
    const charCode = char.charCodeAt(0);
    acc = ((acc << 7) - acc + (charCode * (index + 1)));
    return acc & acc;
  }, 0);
  const positiveHash = Math.abs(hash);
  const hashString = positiveHash.toString(36);
  const prefix = 'prf_';
  const paddedHash = hashString.padStart(8, '0');
  return prefix + paddedHash;
}

export const fetchVideoData = async (name) => {
  if (!name) {
    //console.error('Error: name is undefined or null');
    return { videoData: null, updatedCount: null };
  }

  const videoRef = child(ref(db), `video/name/${name}`);
  //console.log('videoRef', videoRef);

  try {
    const snapshot = await get(videoRef);
    if (snapshot.exists()) {
      //console.log('Data exists');
      const videoData = snapshot.val();
      const updatedCount = videoData.count + 1;

      // Update the count value in the database
      await update(videoRef, { count: updatedCount });
      //console.log('Updated videoData', videoData);

      return { videoData, updatedCount }; // Return the fetched data
    } else {
      //console.log('No data available');
      return { videoData: null, updatedCount: null }; // Return an object with null values
    }
  } catch (error) {
    //console.error('Error fetching data:', error);
    return { videoData: null, updatedCount: null }; // Return an object with null values in case of error
  }
};

//Similar videos from the same course
export const fetchNextVideos = (courseTag) => {
  return new Promise((resolve, reject) => {
    const videoRef = query(
      ref(db, 'video/name/'),
      orderByChild('courseTag'),
      equalTo(courseTag)
    );

    onValue(videoRef, (snapshot) => {
      const videoData = snapshot.val();
      if (videoData) {
        const arr = Object.entries(videoData);
        //console.log('array', arr);

        // Shuffle the array using Fisher-Yates shuffle algorithm
        for (let i = arr.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1));
          [arr[i], arr[j]] = [arr[j], arr[i]];
        }

        // Define the start and end indices for the slice
        const start = 1;
        const end = 6;
        // Extract the slice from the shuffled array
        const slicedArray = arr.slice(start, end);
        // Convert the sliced array back into an array of video details
        const slicedObjectArray = slicedArray.map(([key, value]) => value);
        //console.log(slicedObjectArray);
        resolve(slicedObjectArray);
      } else {
        resolve([]);
      }
    }, reject);
  });
};

export const handleUpdate = async ({currentUser, data}) => {
    if (currentUser && currentUser.email) {
      const email = currentUser.email;
      ////console.log(email, data);
      const emailHash = emailsHasher(email);
      ////console.log(emailHash)
      const userRef = child(db, `person/${emailHash}`);
      const currentDate = new Date();
      const currentTime = currentDate.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      });

      // Get the current date in a short date format (MM/DD/YYYY)
      const currentShortDate = currentDate.toLocaleDateString([], {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
      const dateTime = currentShortDate + ' ' + currentTime;

      await update(userRef, {
        lastViewTime: dateTime,
        lastViewed: data.key,
        seminarTagOfLastViewed: data.courseTag,
      });
    }
  };

  //fetch user recommended video data
  export const fetchUserRecommendedVideoData = (email, videoLimit = 6) => {
    const emailHash = emailsHasher(email);
    //console.log(emailHash);
    const dbRef = ref(db);
    const videoRef = query(ref(db, 'video/name/'));
  
    return new Promise((resolve, reject) => {
      get(child(dbRef, `person/${emailHash}`))
        .then(snapshot => {
          if (!snapshot.exists()) {
            reject(new Error('User does not exist'));
            return;
          }
          const tagData = snapshot.val();
          const seminarTag = tagData.seminarTagOfLastViewed;
          const recommendedSeminarTag = calculateVideoTagSimilarity(seminarTag);
          let videos = []; // Declare the array to store videos
  
          onValue(videoRef, (snapshot) => {
            const matchingVideos = [];
            const allVideos = [];
            snapshot.forEach((childSnapshot) => {
              const videoData = childSnapshot.val();
              allVideos.push(videoData);
              if (videoData.courseTag === recommendedSeminarTag) {
                matchingVideos.push(videoData);
              }
            });
  
            let selectedVideos;
            if (matchingVideos.length > 0) {
              // Create a subset of the matching videos
              selectedVideos = matchingVideos.slice(0, videoLimit);
            } else {
              // Shuffle all videos and select a random subset if no matching videos
              const shuffledVideos = [...allVideos].sort(() => 0.5 - Math.random());
              selectedVideos = shuffledVideos.slice(0, videoLimit);
            }
  
            resolve({ emailHash, seminarTag, videos: selectedVideos });
          });
        })
        .catch(reject);
    });
  };
  
  
  //similarity video suggestions
  const calculateVideoTagSimilarity = (videoTag) => {
    const tagsToCompare = [
      'Astronomy',
      'ArtificialIntelligence',
      'History',
      'ClimateChange',
      'Business',
      'Immunology',
      'NeuroscienceAndPsychology',
      'Politics'
    ];
    if (!videoTag || videoTag.length === 0) {
      return 0;
    }
    let matchFound = false;

    for (let i = 0; i < tagsToCompare.length; i++) {
      const tag = tagsToCompare[i];
      if (tag === videoTag) {
        matchFound = tag;
        break;
      }
    }
    if (!matchFound) {
      // Match not found handling logic
    }
    return matchFound;
  };

  //fetch additional videos for explore page
  //Similar videos from the same course
  //fetch user recommended video data
  export const fetchMoreVideoData = (videoLimit = 6, lastViewedVideos) => {
    const fetchLimit = videoLimit + lastViewedVideos.length; // Fetch more to account for filtering
    const videoQuery = query(
      ref(db, 'video/name/'),
      orderByKey(),
      limitToFirst(fetchLimit)
    );
  
    ////console.log('last viewed', lastViewedVideos);
  
    return new Promise((resolve, reject) => {
      onValue(videoQuery, (snapshot) => {
        const newVideos = [];
        snapshot.forEach((childSnapshot) => {
          const videoData = childSnapshot.val();
          //console.log('loading more', videoData)
          if (!lastViewedVideos.includes(videoData.key)) {
            newVideos.push(videoData);
            ////console.log('hello - what are you', videoData)
          }
          if (newVideos.length === videoLimit) return true; // Early exit
        });
  
        resolve(newVideos.slice(0, videoLimit)); // Ensure we only return the desired number of new videos
      }, reject);
    });
  };

  // fetch shorts video data
  export const fetchUserRecommendedShortsVideoData = (email, videoLimit = 6) => {
    const emailHash = emailsHasher(email);
    //console.log(emailHash);
    const dbRef = ref(db);
    const videoRef = query(ref(db, 'video/shorts/'));

    return new Promise((resolve, reject) => {
      get(child(dbRef, `person/${emailHash}`))
        .then(snapshot => {
          if (!snapshot.exists()) {
            reject(new Error('User does not exist'));
            return;
          }
          const tagData = snapshot.val();
          const seminarTag = tagData.seminarTagOfLastViewed;
          const recommendedSeminarTag = calculateVideoTagSimilarity(seminarTag);
          let videos = []; // Declare the array to store videos
  
          onValue(videoRef, (snapshot) => {
            const matchingVideos = [];
            const allVideos = [];
            snapshot.forEach((childSnapshot) => {
              const videoData = childSnapshot.val();
              allVideos.push(videoData);
              if (videoData.courseTag === recommendedSeminarTag) {
                matchingVideos.push(videoData);
              }
            });
  
            let selectedVideos;
            if (matchingVideos.length > 0) {
              // Create a subset of the matching videos
              selectedVideos = matchingVideos.slice(0, videoLimit);
            } else {
              // Shuffle all videos and select a random subset if no matching videos
              const shuffledVideos = [...allVideos].sort(() => 0.5 - Math.random());
              selectedVideos = shuffledVideos.slice(0, videoLimit);
            }
  
            resolve({ emailHash, seminarTag, videos: selectedVideos });
          });
        })
        .catch(reject);
    });
  };
  

  // update short video view count data
  export const updateVideoViewCount = async (videoId) => {
    //console.log(videoId)
    const videoRef = ref(db, `videos/shorts/${videoId}/views`);
  
    try {
      // Fetch the current view count
      const snapshot = await get(videoRef);
      const currentViews = snapshot.val();
      // Increment the view count
      const updatedViews = currentViews + 1;
  
      // Update the view count in the database
      await update(videoRef, updatedViews);
  
      //console.log(`View count updated for video ${videoId}. New count: ${updatedViews}`);
    } catch (error) {
      throw new Error(`Error updating view count for video ${videoId}: ${error.message}`);
    }
  };

  