// useOfficerDocuments.js
import { useEffect, useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../Firebase/firebaseConfig';
import useAuth from '../../../../Firebase/Auth';

const useOfficerDocuments = (currentUser, documentName) => {
  const [documentUrl, setDocumentUrl] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDocuments = async () => {
      //console.log('useOfficer Documentation', currentUser)
      if (currentUser?.email) {
        try {
          //console.log('running')
          setLoading(true);
          setError(null);
          const fetchOfficerPdfs = httpsCallable(functions, 'fetch_officer_pdfs');
          const result = await fetchOfficerPdfs({
              documentName: `${documentName}.pdf`,
          });
            //console.log('Document URL received:', result);
          if (result.data && result.data.success) {
            //console.log('Document URL received:', result.data.documentUrl);
            setDocumentUrl(result.data.documentUrl);
          } else {
            throw new Error('Document not found');
          }
        } catch (error) {
          //console.error('Error fetching document:', error);
          setError('Failed to load document. Please try again later.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchDocuments();
  }, [currentUser, documentName]);

  return { documentUrl, loading, error };
};

export default useOfficerDocuments;