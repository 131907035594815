import React, { useState, useEffect } from 'react';

const AttendingOfficersComponent = ({ officers }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(typeof window !== 'undefined' ? window.innerWidth : 0);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  const isMobile = windowWidth < 768;

  const styles = {
    container: {
      borderRadius: '5px',
      padding: windowWidth < 768 ? '' : '10px',
      width: '100%',
      maxWidth: '1200px',
      margin: '20px auto',
    },
    header: {
      backgroundColor: '#1F484C',
      padding: '10px',
      cursor: 'pointer',
      color: '#fff',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      borderBottomLeftRadius: isOpen ? '0px' : '10px',
      borderBottomRightRadius: isOpen ? '0px' : '10px',
      transition: 'border-radius 0.3s ease',
    },
    content: {
      padding: '20px',
      display: isOpen ? 'block' : 'none',
      border: '1px solid #ccc',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      backgroundColor: '#fff',
    },
    officersList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    officerCard: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: isMobile ? '10px' : '20px',
      padding: '15px',
      backgroundColor: '#f8f9fc',
      borderRadius: '8px',
      alignItems: isMobile ? 'center' : 'flex-start',
      transition: 'all 0.3s ease',
    },
    officerImage: {
      width: isMobile ? '100px' : '120px',
      height: isMobile ? '100px' : '120px',
      borderRadius: '50%',
      objectFit: 'cover',
    },
    officerInfo: {
      flex: 1,
      textAlign: isMobile ? 'center' : 'left',
      width: isMobile ? '100%' : 'auto',
    },
    officerName: {
      fontSize: isMobile ? '1rem' : '1.1rem',
      fontWeight: '600',
      marginBottom: '4px',
    },
    officerRole: {
      fontSize: isMobile ? '0.9rem' : '1rem',
      color: 'rgb(102, 102, 102)',
      marginBottom: '0.25rem',
    },
    officerDescription: {
      fontSize: isMobile ? '0.85rem' : '0.9rem',
      color: '#000',
      lineHeight: '1.4',
      maxWidth: isMobile ? '100%' : '800px',
    },
    toggleButton: {
      background: 'none',
      border: 'none',
      color: '#fff',
      fontSize: '20px',
      cursor: 'pointer',
    },
    noOfficers: {
      textAlign: 'center',
      color: '#000',
      padding: '20px',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={toggleCollapse}>
        <span>Attending Officers</span>
        <button style={styles.toggleButton}>
          {isOpen ? '-' : '+'}
        </button>
      </div>
      <div style={styles.content}>
        {officers && officers.length > 0 ? (
          <div style={styles.officersList}>
            {officers.map((officer, index) => (
              <div key={index} style={styles.officerCard}>
                <img
                  src={officer.image}
                  alt={officer.name}
                  style={styles.officerImage}
                />
                <div style={styles.officerInfo}>
                  <h3 style={styles.officerName}>{officer.name}</h3>
                  <p style={styles.officerRole}>{officer.role}</p>
                  {officer.description && (
                    <p style={styles.officerDescription}>
                      {officer.description}
                    </p>
                  )}
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div style={styles.noOfficers}>
            No officers assigned yet.
          </div>
        )}
      </div>
    </div>
  );
};

export default AttendingOfficersComponent;