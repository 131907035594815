import React, { useState, useEffect } from 'react';

const supportData = {
  title: 'Support Us',
  description: 'There are several ways to support us. Donations are the primary source of revenue for the charity.',
  usages: [
    'Learning materials',
    'Making education accessible to everyone',
    'Providing certifications',
    'Running the organisation',
    'Other miscellaneous work',
  ],
  donateUrl: 'https://www.justgiving.com/offthecurriculum',
  image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Foffthecurriculum_long_logo.png?alt=media&token=d1824229-8237-473f-947c-4008f6fd3b80'
};

const SupportUs = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isButtonHovered, setIsButtonHovered] = useState(false);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Responsive styles based on window width
  const getResponsiveStyles = () => {
    const isLaptop = windowWidth > 1024;
    const isTablet = windowWidth <= 1024 && windowWidth > 768;
    const isMobile = windowWidth <= 768;

    return {
      container: {
        margin: '0 auto',
        marginTop: isLaptop ? '40px' : '20px',
        marginBottom: isLaptop ? '40px' : '20px',
        padding: isMobile ? '' : '',
        maxWidth: isLaptop ? '' : '100%',
      },
      
      card: {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        gap: isLaptop ? '40px' : '20px',
        backgroundColor: '#fff',
        borderRadius: isMobile ? '12px' : '15px',
        boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        marginBottom: '20px',
      },

      imageColumn: {
        flex: isMobile ? 'none' : '1',
        padding: isMobile ? '15px' : '20px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: isMobile ? '200px' : 'auto',
      },

      imageContainer: {
        width: '100%',
        maxHeight: isMobile ? '200px' : isTablet ? '250px' : '300px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '10px',
        overflow: 'hidden',
      },

      image: {
        width: isMobile ? '90%' : '80%',
        height: 'auto',
        objectFit: 'contain',
        transition: 'transform 0.3s ease',
      },

      content: {
        flex: isMobile ? 'none' : '1',
        padding: isMobile ? '20px' : isTablet ? '25px' : '30px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },

      title: {
        fontSize: isMobile ? '24px' : isTablet ? '30px' : '36px',
        fontWeight: 'bold',
        color: '#1F484C',
        marginBottom: isMobile ? '15px' : '20px',
      },

      description: {
        fontSize: isMobile ? '16px' : isTablet ? '17px' : '18px',
        lineHeight: '1.6',
        color: '#444',
        marginBottom: isMobile ? '20px' : '30px',
      },

      usagesContainer: {
        backgroundColor: '#f8f9fa',
        padding: isMobile ? '20px' : '25px',
        borderRadius: '12px',
        marginBottom: isMobile ? '20px' : '30px',
      },

      usagesTitle: {
        fontSize: isMobile ? '18px' : '20px',
        fontWeight: 'bold',
        color: '#1F484C',
        marginBottom: isMobile ? '12px' : '15px',
      },

      usagesList: {
        display: 'flex',
        flexDirection: 'column',
        gap: isMobile ? '12px' : '15px',
        listStyle: 'none',
        padding: 0,
      },

      usageItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        fontSize: isMobile ? '14px' : '16px',
        color: '#666',
      },

      checkmark: {
        color: '#1F484C',
        fontSize: isMobile ? '16px' : '20px',
      },

      button: {
        backgroundColor: '#1F484C',
        color: '#fff',
        border: 'none',
        padding: isMobile ? '12px 30px' : '15px 40px',
        borderRadius: '30px',
        fontSize: isMobile ? '16px' : '18px',
        fontWeight: 'bold',
        cursor: 'pointer',
        transition: 'transform 0.2s, box-shadow 0.2s',
        boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)',
        alignSelf: isMobile ? 'center' : 'flex-start',
        width: isMobile ? '100%' : 'auto',
      },

      buttonHover: {
        transform: 'translateY(-2px)',
        boxShadow: '0 4px 15px rgba(0, 0, 0, 0.2)',
      },
    };
  };

  const styles = getResponsiveStyles();

  const handleDonateClick = () => {
    window.open(supportData.donateUrl, '_blank');
  };

  return (
    <div style={styles.container}>
      <div style={styles.card}>
        <div style={styles.imageColumn}>
          <div style={styles.imageContainer}>
            <img 
              src={supportData.image} 
              alt="Support Us" 
              style={styles.image}
            />
          </div>
        </div>
        
        <div style={styles.content}>
          <h2 style={styles.title}>{supportData.title}</h2>
          <p style={styles.description}>{supportData.description}</p>
          
          <div style={styles.usagesContainer}>
            <h3 style={styles.usagesTitle}>Your donation helps with:</h3>
            <ul style={styles.usagesList}>
              {supportData.usages.map((usage, index) => (
                <li key={index} style={styles.usageItem}>
                  <span style={styles.checkmark}>✓</span>
                  {usage}
                </li>
              ))}
            </ul>
          </div>
          
          <button 
            style={{
              ...styles.button,
              ...(isButtonHovered ? styles.buttonHover : {})
            }}
            onMouseEnter={() => setIsButtonHovered(true)}
            onMouseLeave={() => setIsButtonHovered(false)}
            onClick={handleDonateClick}
          >
            Donate Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default SupportUs;