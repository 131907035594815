import React, { useState, useEffect } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
// styles
import { formStyles } from '../styles';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import MembershipPlansComponent from '../../../../Components/Common/MembershipPlansComponent/MembershipPlansComponent';

// Buttons
import ForwardFormButton from '../../../../Components/Common/Buttons/BlackButton/ForwardFormButton';
import BackFormButton from '../../../../Components/Common/Buttons/BlackButton/BackFormButton';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { motion } from 'framer-motion';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

// custom hooks
import useAuth from '../../../../Firebase/Auth';
// Functions
import { handleFormChange, createHandleBlur, createHandleFocus, handleEmailAndPasswordSignIn, createHandleCrop } from '../../../../Firebase/signInFunctions';

// icons
import { IoPersonOutline, IoImageOutline, IoDocumentTextOutline, IoKeyOutline, IoHappyOutline } from "react-icons/io5";


const steps = [
  { 
    label: 'Details', 
    icon: <IoPersonOutline size={24} /> 
  },
  { 
    label: 'Avatar', 
    icon: <IoImageOutline size={24} />
  },
  { 
    label: 'About', 
    icon: <IoDocumentTextOutline size={24} />
  },
  { 
    label: 'Enrolment', 
    icon: <IoKeyOutline size={24} />
  },
  { 
    label: 'Welcome', 
    icon: <IoHappyOutline size={24} />
  },
];


export default function ProfileForm({ currentStep, setCurrentStep }) {
  const [focusedField, setFocusedField] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [cropper, setCropper] = useState(null);
  const [imageToCrop, setImageToCrop] = useState('');
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [cropType, setCropType] = useState('');
  const [standardOption, setStandardOption] = useState('basic');
  const [isUploading, setIsUploading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [accessOption, setAccessOption] = useState('standard');
  const [formData, setFormData] = useState({
    token: true, 
    user_id: '',
    displayPhoto: '',
    coverPhoto: '',
    name: '',
    userName: '',
    age: '',
    bio: '',
    interests: '',
    career: '',
    preferredField: '',
    bursary: '',
    keyCode: '',
    userRole: '',
    Likes: '', 
    user_interactions: {
      video_interactions:{},
    },
    courses: {
      enrolledCourses: {
      }
    },
    views: {
      lastViewed: {
        videoName: '', 
        seminarTagOfLastViewed:'',
        time:'',
      },
    },
    posts: {
      Liked:'',
      Viewed: '',
      Posted: '',
    },
    communities: {
      Created: '',
      Subscribed: ''
    },
    enrollmentQuestions: {
      futureGoals: '',
      whyQuestion: '',
      questions: ''
    }
  });
  const navigate = useNavigate()

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const defaultImages = {
    cover: [
      // Abstract gradient cover 1
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="1628" height="143" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad1" x1="0%" y1="0%" x2="100%" y2="0%">
              <stop offset="0%" style="stop-color:#1F484C;stop-opacity:1" />
              <stop offset="100%" style="stop-color:#FEFBF6;stop-opacity:1" />
            </linearGradient>
          </defs>
          <rect width="100%" height="100%" fill="url(#grad1)"/>
        </svg>
      `)}`,
      
      // Abstract pattern cover 2
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="1628" height="143" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <pattern id="pattern1" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
              <rect width="10" height="10" fill="#1F484C"/>
              <rect x="10" y="10" width="10" height="10" fill="#1F484C"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="#FEFBF6"/>
          <rect width="100%" height="100%" fill="url(#pattern1)" opacity="0.3"/>
        </svg>
      `)}`,
      
      // Abstract waves cover 3
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="1628" height="143" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient id="grad3" x1="0%" y1="0%" x2="0%" y2="100%">
              <stop offset="0%" style="stop-color:#1F484C;stop-opacity:1" />
              <stop offset="100%" style="stop-color:#FEFBF6;stop-opacity:1" />
            </linearGradient>
          </defs>
          <path d="M0 0 Q407 71.5 814 0 Q1221 71.5 1628 0 V143 H0" fill="url(#grad3)"/>
        </svg>
      `)}`,
    ],
    display: [
      // Profile Avatar 1 - Professional
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
          <circle cx="100" cy="100" r="100" fill="#FEFBF6"/>
          <circle cx="100" cy="70" r="40" fill="#1F484C"/>
          <path d="M100 120 Q100 180 160 180 A80 80 0 0 1 40 180 Q100 180 100 120" fill="#1F484C"/>
        </svg>
      `)}`,
      
      // Profile Avatar 2 - Minimalist
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
          <circle cx="100" cy="100" r="100" fill="#1F484C"/>
          <circle cx="100" cy="85" r="35" fill="#FEFBF6"/>
          <path d="M50 140 Q100 190 150 140" stroke="#FEFBF6" stroke-width="12" fill="none"/>
        </svg>
      `)}`,
      
      // Profile Avatar 3 - Modern
      `data:image/svg+xml,${encodeURIComponent(`
        <svg width="200" height="200" xmlns="http://www.w3.org/2000/svg">
          <circle cx="100" cy="100" r="100" fill="#FEFBF6"/>
          <rect x="60" y="60" width="80" height="80" fill="#1F484C" rx="20"/>
          <circle cx="100" cy="85" r="20" fill="#FEFBF6"/>
          <path d="M70 115 Q100 145 130 115" fill="#FEFBF6"/>
        </svg>
      `)}`,
    ]
  };
  const iconStyle = (isActive, isCompleted) => ({
    color: isActive ? 'white' : isCompleted ? 'white' : '#1F484C',
    fontSize: windowWidth <= 527 ? '16px' : '24px',
  });
  //user hash
  const currentUser = useAuth();

  const uploadImageToFirebase = async (imageData, hashedEmail, imageType) => {
    if (!imageData) return null;
    
    try {
      const storage = getStorage();
      const imageRef = ref(storage, `person/${hashedEmail}/${imageType}`);
      
      await uploadString(imageRef, imageData, 'data_url');
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (currentStep === steps.length - 1) {
      setIsUploading(true);
      try {
        // Get hash from Firebase function
        const functions = getFunctions();
        const hashEmail = httpsCallable(functions, 'hashEmail');
        const { data: { hash: hashedEmail } } = await hashEmail({ email: currentUser.email });

        // Upload images and get URLs
        const [displayPhotoUrl, coverPhotoUrl] = await Promise.all([
          uploadImageToFirebase(formData.displayPhoto, hashedEmail, 'displayPhoto'),
          uploadImageToFirebase(formData.coverPhoto, hashedEmail, 'coverPhoto')
        ]);

        // Prepare the final user data object
        const userDataToStore = {
          ...formData,
          displayPhotoUrl,
          coverPhotoUrl,
          email: currentUser.email,
          createdAt: new Date().toISOString(),
          user_id: hashedEmail,
          // Remove base64 image data to reduce payload
          displayPhoto: undefined,
          coverPhoto: undefined
        };

        // Upload to Firebase using Python function
        const uploadPersonData = httpsCallable(functions, 'store_user_data');
        const result = await uploadPersonData({
          userData: userDataToStore
        });

        if (result.data.success) {
          navigate('/explore');
        } else {
          throw new Error(result.data.error || 'Failed to store user data');
        }
      } catch (error) {
        alert('An error occurred while uploading data. Please try again.');
        navigate('/error');
      } finally {
        setIsUploading(false);
      }
    } else {
      // Proceed to next step
      nextStep();
    }
  };

  if (isUploading) {
    return (
      <div style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '100vh',
        padding: '20px',
        backgroundColor: '#f5f5f5'
      }}>
        <div style={{
          backgroundColor: 'white',
          padding: '2rem',
          borderRadius: '8px',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
          maxWidth: '400px',
          width: '100%'
        }}>
          <h2 style={{
            color: '#1F484C',
            marginBottom: '1rem',
            textAlign: 'center'
          }}>Creating Your Profile</h2>
          
          <div style={{
            display: 'flex',
            justifyContent: 'center',
            marginBottom: '1rem'
          }}>
            <div style={{
              width: '40px',
              height: '40px',
              border: '3px solid #1F484C',
              borderTopColor: 'transparent',
              borderRadius: '50%',
              animation: 'spin 1s linear infinite'
            }} />
          </div>
          
          <p style={{
            color: '#666',
            textAlign: 'center',
            marginBottom: '0.5rem'
          }}>
            Please wait while we process your information...
          </p>
        </div>

        <style>
          {`
            @keyframes spin {
              0% { transform: rotate(0deg); }
              100% { transform: rotate(360deg); }
            }
          `}
        </style>
      </div>
    );
  }


  const requiredFieldsMap = {
    0: ['name', 'userName', 'age'],
    1: ['coverPhoto', 'displayPhoto'],
    2: ['interests', 'career', 'preferredField'],
    //3: ['userType'],
  };

  const validateFields = () => {
    if (currentStep === 3) {
      if (accessOption === 'standard') {
        if (standardOption === 'enroll') {
          // Check required enrollment questions
          if (!formData.enrollmentQuestions?.futureGoals || 
              !formData.enrollmentQuestions?.questions) {
            alert('Please complete all required enrollment questions');
            return false;
          }
        }
      } else {
        // Existing validation for key access
        if ((formData.userRole === 'studentHarris' || formData.userRole === 'studentElm') && 
            !formData.EPQ?.topicChosen) {
          alert('Please enter your EPQ project title');
          return false;
        }
        if (formData.userRole === 'teacher' && !formData.schoolCode) {
          alert('Please select your school before proceeding.');
          return false;
        }
      }
      return true;
    }
    
    const requiredFields = requiredFieldsMap[currentStep] || [];
    for (let field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  

  const nextStep = () => {
    if (validateFields()) {
      setCurrentStep((prev) => Math.min(prev + 1, steps.length - 1));
    } else {
      alert('Please complete all fields before proceeding.');
    }
  };

  const prevStep = () => setCurrentStep((prev) => Math.max(prev - 1, 0));

  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
    setFormData(prevFormData => ({ ...prevFormData, userType: plan }));
  };

  const handleFileChange = (e, cropType) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageToCrop(reader.result);
        setCropType(cropType);
        setShowCropper(true);
        // Clear previous cropped image data
        setFormData(prevFormData => ({
          ...prevFormData,
          [cropType === 'cover' ? 'coverPhoto' : 'displayPhoto']: '',
        }));
      };
      reader.readAsDataURL(file);
    }
  };
  

  const stepperStyle = {
    display: 'flex',
    justifyContent: 'space-around',
    marginBottom: '20px',
    position: 'relative',
    marginTop: '40px',
    gap: windowWidth <= 527 ? '10px' : '30px',
    padding: windowWidth <= 527 ? '0 10px' : '0',
  };


  const stepStyle = (isActive, isCompleted) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
    color: isActive ? 'orange' : isCompleted ? '#1F484C' : '#1F484C',
    width: windowWidth <= 527 ? '60px' : 'auto',
  });

  const circleStyle = (isActive, isCompleted) => ({
    width: windowWidth <= 527 ? '30px' : '40px',
    height: windowWidth <= 527 ? '30px' : '40px',
    borderRadius: '50%',
    backgroundColor: isActive ? 'orange' : isCompleted ? '#1F484C' : '#FEFBF6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    marginBottom: windowWidth <= 527 ? '4px' : '8px',
    fontSize: windowWidth <= 527 ? '18px' : '24px',
    zIndex: '10'
  });

  const labelStyle = {
    fontSize: windowWidth <= 527 ? '10px' : '14px',
    textAlign: 'center',
    whiteSpace: 'nowrap',
  };


  const lineStyle = (isActive, isCompleted) => ({
    position: 'absolute',
    top: windowWidth <= 527 ? '15px' : '20px',
    left: '50%',
    width: windowWidth <= 527 ? '100%' : '250px',
    height: '2px',
    backgroundColor: isActive ? 'orange' : isCompleted ? '#1F484C' : '#FEFBF6',
    zIndex: 0,
  });

  const formStyle = {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    gap: windowWidth <= 527 ? '10px' : '20px',
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: windowWidth <= 527 ? '10px' : '0px',
  };

  const finalContainerStyle = {
    display: 'flex',
    width: windowWidth <= 750 ? '100%' : '80%',
    margin: windowWidth <= 750 ? '5px auto' : '10px auto',
    border: '1px solid #ddd',
    padding: windowWidth <= 750 ? '5px' : '20px',
    borderRadius: windowWidth <= 750 ? '4px' : '8px',
    boxShadow: '2px 2px 5px rgba(0, 0, 0, 0.1)',
  }

  const finalCoverPhotoStyle = {
    position: 'relative',
    width: '100%',
    height: windowWidth <= 750 ? '100px' : '142.857px',
    backgroundColor: 'rgb(204, 204, 204)',
    borderRadius: windowWidth <= 750 ? '15px' : '25px',
    marginBottom: windowWidth <= 750 ? '40px' : '0',
  }

  const profileImageContainer = {
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    left: windowWidth <= 750 ? '50%' : '30px',
    transform: windowWidth <= 750 ? 'translateX(-50%)' : 'none',
    top: windowWidth <= 750 ? '50px' : '40px',
    bottom: windowWidth <= 750 ? '-40px' : '-100px',
    width: windowWidth <= 750 ? '80px' : '200px',
    height: windowWidth <= 750 ? '80px' : '200px',
    borderRadius: '50%',
    overflow: 'hidden',
    border: windowWidth <= 750 ? '2px solid white' : 'none',
    boxShadow: windowWidth <= 750 ? '0 2px 4px rgba(0,0,0,0.1)' : 'none',
  }

  const profileImage = {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  }

  const finalCoverImageStyle = {
    width: '100%',
    height: '100%',
    borderRadius: windowWidth <= 750 ? '15px' : '25px',
    objectFit: 'cover',
  }

  const finalProfileInfoStyle = {
    flex: windowWidth <= 750 ? 1 : 2,
    textAlign: windowWidth <= 750 ? 'left' : 'center',
    width: '100%',
  }

  const finalProfileHeader = {
    display: 'flex',
    flexDirection: windowWidth <= 750 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: windowWidth <= 750 ? '10px 5px' : '20px',
  }

  const finalDetailsStyle ={
    textAlign: 'left',
    marginLeft: windowWidth <= 750 ? '10px' : '250px',
    marginTop: windowWidth <= 750 ? '45px' : '0',
    padding: windowWidth <= 750 ? '0 10px' : '0',
  }

 const finalDetailItemStyle = {
    marginBottom: windowWidth <= 750 ? '8px' : '10px',
    fontSize: windowWidth <= 750 ? '14px' : '16px',
    display: windowWidth <= 750 ? 'flex' : 'block',
    flexDirection: windowWidth <= 750 ? 'column' : 'row',
    gap: windowWidth <= 750 ? '2px' : '0',
  }

  const strongStyle =  {
    color: '#333',
    fontSize: windowWidth <= 750 ? '12px' : '16px',
    fontWeight: windowWidth <= 750 ? '600' : 'bold',
  }

  const inputContainerStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 527 ? 'column' : 'row',
    gap: windowWidth <= 527 ? '5px' : '10px',
    marginBottom: windowWidth <= 527 ? '8px' : '20px',
    width: windowWidth <= 527 ? '100%' : 'auto',
  }

  const defaultImageGridStyle = {
    display: 'grid',
    gridTemplateColumns: windowWidth <= 527 ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
    gap: '10px',
    marginTop: '15px',
    padding: '10px',
    backgroundColor: '#f8fafc',
    borderRadius: '8px',
    border: '1px solid #e2e8f0',
  };
  
  const defaultImageItemStyle = {
    position: 'relative',
    cursor: 'pointer',
    border: '2px solid transparent',
    borderRadius: '8px',
    overflow: 'hidden',
    transition: 'all 0.2s ease',
    backgroundColor: 'white',
    padding: '5px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    '&:hover': {
      transform: 'scale(1.02)',
      boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    }
  };
  
  const selectedImageStyle = {
    border: '2px solid #54AFB8',
    boxShadow: '0 0 0 2px rgba(84, 175, 184, 0.2)',
  };
  
  const defaultImageLabelStyle = {
    marginTop: '20px',
    color: '#1F484C',
    fontWeight: '500',
    fontSize: windowWidth <= 527 ? '14px' : '16px',
    padding: '10px 0',
    borderTop: '1px solid #e2e8f0',
  };
  const defaultImageStyle = {
    width: '100%',
    height: 'auto',
    objectFit: 'cover',
  };

  const formEnrolQuestions = {
    padding: windowWidth <= 527 ? '0rem' : '1.5rem',
    borderRadius: '0.75rem',
    marginBottom: '1.5rem',
  };

  const enrolQuestionDiv ={
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
  }

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      key={currentStep}
      transition={{ duration: 0.5 }}
    >
      <div style={stepperStyle}>
        {steps.map((step, index) => {
          const isActive = currentStep === index;
          const isCompleted = currentStep > index;
          return (
            <div key={index} style={stepStyle(isActive, isCompleted)}>
              {index < steps.length - 1 && (
                <div style={lineStyle(isActive, isCompleted)}></div>
              )}
              <div style={circleStyle(isActive, isCompleted)}>
                {React.cloneElement(step.icon, {
                  style: iconStyle(isActive, isCompleted),
                  size: windowWidth <= 527 ? 16 : 24
                })}
              </div>
              <span style={labelStyle}>{step.label}</span>
            </div>
          );
        })}
      </div>
      <form onSubmit={handleSubmit} style={containerStyle}>
        <div style={formStyle}>
          {currentStep === 0 && (
            <div style={{ padding: windowWidth <= 527 ? '10px' : '20px' }}>
              <h2 style={{ fontSize: windowWidth <= 527 ? '20px' : '24px', marginBottom: windowWidth <= 527 ? '15px' : '20px' }}>
                Personal Details
              </h2>
              <label style={formStyles.label}>
                Name:
                <input 
                  type="text" 
                  name="name"
                  placeholder="i.e. Joe Blogs"
                  value={formData.name}
                  onChange={handleFormChange(setFormData)}
                  style={{
                    ...formStyles.input,
                    borderColor: focusedField === 'name' ? '#1F484C' : '#ccc',
                  }}
                  onFocus={createHandleFocus(setFocusedField)}
                  onBlur={createHandleBlur(setFocusedField)}
                />
              </label>
              <label style={formStyles.label}>
                Username:
                <input 
                  type="text" 
                  placeholder="i.e. JoeBlogs"
                  name="userName"
                  value={formData.userName}
                  onChange={handleFormChange(setFormData)}
                  style={{
                    ...formStyles.input,
                    borderColor: focusedField === 'userName' ? '#1F484C' : '#ccc',
                  }}
                  onFocus={createHandleFocus(setFocusedField)}
                  onBlur={createHandleBlur(setFocusedField)}
                />
              </label>
              <label style={formStyles.label}>
                Age:
                <input 
                  type="number" 
                  name="age"
                  placeholder="e.g. 17"
                  value={formData.age}
                  onChange={handleFormChange(setFormData)}
                  style={{
                    ...formStyles.input,
                    borderColor: focusedField === 'age' ? '#1F484C' : '#ccc',
                  }}
                  onFocus={createHandleFocus(setFocusedField)}
                  onBlur={createHandleBlur(setFocusedField)}
                />
              </label>
            </div>
          )}
          {currentStep === 1 && (
            <div>
              <h2>Upload Photo or Create Avatar</h2>
              {/* Cover Photo Section */}
              <div style={{ marginBottom: '30px' }}>
                <label style={formStyles.label}>
                  Cover Photo:
                  <input
                    type="file"
                    name="coverPhoto"
                    onChange={(e) => handleFileChange(e, 'cover')}
                    accept=".jpg, .jpeg" 
                    style={formStyles.fileInput}
                  />
                </label>
                
                <p style={defaultImageLabelStyle}>Or choose from default cover photos:</p>
                <div style={defaultImageGridStyle}>
                  {defaultImages.cover.map((image, index) => (
                    <div
                      key={`cover-${index}`}
                      style={{
                        ...defaultImageItemStyle,
                        ...(formData.coverPhoto === image ? selectedImageStyle : {}),
                      }}
                      onClick={() => setFormData(prev => ({
                        ...prev,
                        coverPhoto: image
                      }))}
                    >
                      <img
                        src={image}
                        alt={`Default cover ${index + 1}`}
                        style={{
                          ...defaultImageStyle,
                          height: '60px',
                        }}
                      />
                    </div>
                  ))}
                </div>
                
                {formData.coverPhoto && (
                  <div style={{ marginTop: '15px' }}>
                    <p style={{ marginBottom: '10px', color: '#1F484C' }}>Selected cover photo:</p>
                    <img
                      src={formData.coverPhoto}
                      alt="Selected cover"
                      style={formStyles.imagePreview}
                    />
                  </div>
                )}
              </div>
              {/* Display Photo Section */}
            <div style={{ marginBottom: '30px' }}>
              <label style={formStyles.label}>
                Profile Picture:
                <input
                  type="file"
                  name="displayPhoto"
                  onChange={(e) => handleFileChange(e, 'display')}
                  accept=".jpg, .jpeg" 
                  style={formStyles.fileInput}
                />
              </label>

              <p style={defaultImageLabelStyle}>Or choose from default profile pictures:</p>
              <div style={defaultImageGridStyle}>
                {defaultImages.display.map((image, index) => (
                  <div
                    key={`display-${index}`}
                    style={{
                      ...defaultImageItemStyle,
                      ...(formData.displayPhoto === image ? selectedImageStyle : {}),
                    }}
                    onClick={() => setFormData(prev => ({
                      ...prev,
                      displayPhoto: image
                    }))}
                  >
                    <img
                      src={image}
                      alt={`Default avatar ${index + 1}`}
                      style={{
                        ...defaultImageStyle,
                        height: '80px',
                        borderRadius: '50%',
                      }}
                    />
                  </div>
                ))}
              </div>

              {formData.displayPhoto && (
                <div style={{ marginTop: '15px' }}>
                  <p style={{ marginBottom: '10px', color: '#1F484C' }}>Selected profile picture:</p>
                  <img
                    src={formData.displayPhoto}
                    alt="Selected display"
                    style={{
                      ...formStyles.imagePreview,
                      borderRadius: '50%',
                      width: '190px',
                      height: '190px'
                    }}
                  />
                </div>
              )}
            </div>

            {showCropper && (
              <div style={formStyles.cropperContainer}>
                <Cropper
                  src={imageToCrop}
                  style={formStyles.cropper}
                  aspectRatio={cropType === 'cover' ? 1628 / 142.85 : 1}
                  guides={false}
                  viewMode={1}
                  dragMode="move"
                  autoCropArea={1}
                  background={false}
                  responsive={true}
                  onInitialized={(instance) => {
                    setCropper(instance);
                  }}
                />
                <button 
                  onClick={createHandleCrop(setFormData, cropper, cropType, setShowCropper)} 
                  style={formStyles.cropButton}
                >
                  Crop Image
                </button>
              </div>
            )}
          </div>
        )}
          {currentStep === 2 && (
          <div>
            <h2>About You</h2>
            <label style={formStyles.label}>
              Write a short bio about yourself? 
              <textarea
                type="text"
                placeholder="i.e. Joseph Levermore | Capturing Life in Monochrome | Born in Birmingham, UK based in Paris, FR | Embracing the Power of Black & White Photography"
                name="bio"
                value={formData.bio}
                onChange={handleFormChange(setFormData)}
                style={{
                  ...formStyles.input,
                  borderColor: focusedField === 'bio' ? '#1F484C' : '#ccc',
                }}
                onFocus={createHandleFocus(setFocusedField)}
                onBlur={createHandleBlur(setFocusedField)}
              />
            </label>

            {/* Preferred Field Selector */}
            <label style={formStyles.label}>
              Preferred Field
              <select
                name="preferredField"
                value={formData.preferredField}
                onChange={handleFormChange(setFormData)}
                style={{
                  ...formStyles.input,
                  height: '42px',
                  cursor: 'pointer',
                  backgroundColor: 'white',
                  borderColor: focusedField === 'preferredField' ? '#1F484C' : '#ccc',
                  appearance: 'none', // Removes default browser styling
                  paddingRight: '2rem', // Space for custom arrow
                  backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke='%23666666'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M19 9l-7 7-7-7'%3E%3C/path%3E%3C/svg%3E")`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'right 0.5rem center',
                  backgroundSize: '1.5em 1.5em',
                }}
                onFocus={createHandleFocus(setFocusedField)}
                onBlur={createHandleBlur(setFocusedField)}
              >
                <option value="">Select your preferred field</option>
                <option value="humanities">Humanities</option>
                <option value="stem">Science Technology or Mathematics</option>
                <option value="orboth ">Or both (?)</option>
              </select>
            </label>

            <label style={formStyles.label}>
              What topics most interest you?
              <textarea
                type="text"
                placeholder="i.e. Physics"
                name="interests"
                value={formData.interests}
                onChange={handleFormChange(setFormData)}
                style={{
                  ...formStyles.input,
                  borderColor: focusedField === 'interests' ? '#1F484C' : '#ccc',
                }}
                onFocus={createHandleFocus(setFocusedField)}
                onBlur={createHandleBlur(setFocusedField)}
              />
            </label>

            <label style={formStyles.label}>
              What would like to be in the future?
              <textarea
                type="text"
                placeholder="i.e. Artists"
                name="career"
                value={formData.career}
                onChange={handleFormChange(setFormData)}
                style={{
                  ...formStyles.input,
                  borderColor: focusedField === 'career' ? '#1F484C' : '#ccc',
                }}
                onFocus={createHandleFocus(setFocusedField)}
                onBlur={createHandleBlur(setFocusedField)}
              />
            </label>
          </div>
        )}
        {currentStep === 3 && (
        <div style={{
          backgroundColor: 'white',
        }}>
          <div style={{
            flexDirection: 'column',
            gap: '1rem',
          }}>
            {/* Option Selection Tabs */}
            <div style={{
                display: 'flex',
                gap: '1rem',
                marginBottom: '1rem',
              }}>
                <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setAccessOption('standard');
                  setFormData(prev => ({
                    ...prev,
                    userRole: 'standard',
                    userType: 'standard',
                    keyCode: ''
                  }));
                }}
                style={{
                  flex: 1,
                  padding: '0.75rem',
                  width: '100%',
                  borderRadius: '0.5rem',
                  border: `1px solid ${accessOption === 'standard' ? '#1F484C' : '#1F484C'}`,
                  backgroundColor: accessOption === 'standard' ? '#1F484C' : '#FEFBF6',
                  color: accessOption === 'standard' ? '#FEFBF6' : '#1F484C',
                  fontWeight: accessOption === 'standard' ? '600' : '500',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: accessOption === 'standard' 
                    ? '0 4px 6px rgba(31, 72, 76, 0.2)' 
                    : '0 2px 4px rgba(31, 72, 76, 0.1)',
                  '&:hover': {
                    backgroundColor: accessOption === 'standard' ? '#1F484C' : '#F0F7FF',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 6px 12px rgba(31, 72, 76, 0.15)',
                  },
                  '&:active': {
                    transform: 'translateY(1px)',
                  },
                  '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 3px rgba(31, 72, 76, 0.2)`,
                  },
                }}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}>
                  {accessOption === 'standard' && (
                    <span style={{
                      display: 'inline-block',
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: 'orange',
                      marginRight: '8px',
                    }} />
                  )}
                  Account Type
                </div>
              </button>
              <button
                type="button"
                onClick={(e) => {
                  e.preventDefault();
                  setAccessOption('key');
                }}
                style={{
                  flex: 1,
                  padding: '0.75rem',
                  width: '100%',
                  borderRadius: '0.5rem',
                  border: `1px solid ${accessOption === 'key' ? '#1F484C' : '#1F484C'}`,
                  backgroundColor: accessOption === 'key' ? '#1F484C' : '#FEFBF6',
                  color: accessOption === 'key' ? '#FEFBF6' : '#1F484C',
                  fontWeight: accessOption === 'key' ? '600' : '500',
                  transition: 'all 0.3s ease',
                  cursor: 'pointer',
                  position: 'relative',
                  overflow: 'hidden',
                  boxShadow: accessOption === 'key' 
                    ? '0 4px 6px rgba(31, 72, 76, 0.2)' 
                    : '0 2px 4px rgba(31, 72, 76, 0.1)',
                  '&:hover': {
                    backgroundColor: accessOption === 'key' ? '#1F484C' : '#F0F7FF',
                    transform: 'translateY(-1px)',
                    boxShadow: '0 6px 12px rgba(31, 72, 76, 0.15)',
                  },
                  '&:active': {
                    transform: 'translateY(1px)',
                  },
                  '&:focus': {
                    outline: 'none',
                    boxShadow: `0 0 0 3px rgba(31, 72, 76, 0.2)`,
                  },
                }}
              >
                <div style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '0.5rem',
                }}>
                  {accessOption === 'key' && (
                    <span style={{
                      display: 'inline-block',
                      width: '6px',
                      height: '6px',
                      borderRadius: '50%',
                      backgroundColor: 'orange',
                      marginRight: '8px',
                    }} />
                  )}
                  <span>I Have an Access Key</span>
                </div>
              </button>
              </div>

            {accessOption === 'key' ? (
              <>
                <div style={{
                  padding: '1.5rem',
                  backgroundColor: '#FFE8CC',
                  borderRadius: '0.75rem',
                  border: '1px solid orange',
                  marginBottom: '1.5rem',
                  boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)'
                }}>
                  <p style={{
                    fontSize: '1rem',
                    lineHeight: '1.6',
                    color: '#1F484C',
                    marginBottom: selectedPlan ? '0.75rem' : '0'
                  }}>
                    If you're a registered member of our community (student, officer, teacher, or mentor),
                    please enter your access code below to verify your role.
                  </p>
                </div>

                {/* Key Code Input Section */}
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '0.75rem',
                }}>
                  <label style={{
                    display: 'block',
                  }}>
                    <input
                      type="text"
                      name="keyCode"
                      placeholder="Enter your access key code"
                      value={formData.keyCode || ''}
                      onChange={async (e) => {
                        const code = e.target.value.toUpperCase();
                        setFormData(prev => ({
                          ...prev,
                          keyCode: code,
                          userRole: '',
                          validating: true,
                          ...(prev.schoolCode && { schoolCode: undefined }),
                        }));
                        try {
                          const functions = getFunctions();
                          const validateKeyCode = httpsCallable(functions, 'validate_key_code');
                          const result = await validateKeyCode({ keyCode: code });
                          if (result.data.success) {
                            const role = result.data.role.role;
                            let userType = 'standard';
                            
                            // Set userType based on role
                            if (role.includes('student')) {
                              userType = 'student';
                            } else if (role === 'teacher') {
                              userType = 'teacher';
                            } else if (role === 'mentor') {
                              userType = 'mentor';
                            }
                            setFormData(prev => ({
                              ...prev,
                              userRole: role,
                              userType: userType,
                              validating: false,
                              ...(role === 'teacher' ? { schoolCode: '' } : {}),
                              ...(role === 'studentHarris' || role === 'studentElm' ? { EPQ: { topicChosen: '' } } : {})
                            }));
                            setSelectedPlan(result.data.role.plan);
                          } else {
                            setFormData(prev => ({
                              ...prev,
                              validating: false
                            }));
                          }
                        } catch (error) {
                          setFormData(prev => ({
                            ...prev,
                            validating: false
                          }));
                        }
                      }}
                      style={{
                        width: '100%',
                        padding: '0.5rem',
                        borderRadius: '0.375rem',
                        border: '1px solid #d1d5db',
                        boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)',
                        outline: 'none',
                        transition: 'border-color 0.15s ease-in-out',
                      }}
                    />
                  </label>

                  {/* Loading State */}
                  {formData.validating && (
                    <div style={{
                      padding: '0.75rem',
                      backgroundColor: '#f3f4f6',
                      borderRadius: '0.375rem',
                      border: '1px solid #e5e7eb',
                    }}>
                      <p style={{
                        color: '#4b5563',
                        fontSize: '0.875rem',
                      }}>
                        Validating key code...
                      </p>
                    </div>
                  )}

                  {/* Success State with School Selector for Teachers */}
                  {(formData.userRole && formData.userRole !== 'standard' && formData.userRole !== 'studentEnrolled') && (
                    <>
                      <div style={{
                        padding: '0.75rem',
                        backgroundColor: '#f0fdf4',
                        borderRadius: '0.375rem',
                        border: '1px solid #bbf7d0',
                      }}>
                        <p style={{
                          color: '#15803d',
                          fontSize: '0.875rem',
                        }}>
                          ✓ Valid key code - You will be assigned the role of:{' '}
                          <span style={{
                            fontWeight: '600',
                            textTransform: 'capitalize',
                          }}>
                            {formData.userRole}
                          </span>
                        </p>
                      </div>

                      {/* EPQ Section for Students */}
                      {(formData.userRole === 'studentHarris' || formData.userRole === 'studentElm') && (
                        <div style={{
                          marginTop: '1rem',
                          padding: '1rem',
                          backgroundColor: '#F0F7FF',
                          borderRadius: '0.5rem',
                          border: '1px solid #A8CBF7',
                        }}>
                          <label style={{
                            display: 'block',
                            marginBottom: '0.5rem',
                            color: '#1F484C',
                            fontWeight: '500',
                          }}>
                            Enter Your EPQ Project Title:
                          </label>
                          <input
                            type="text"
                            value={formData.EPQ?.topicChosen || ''}
                            onChange={(e) => {
                              setFormData(prev => ({
                                ...prev,
                                EPQ: {
                                  ...prev.EPQ,
                                  topicChosen: e.target.value
                                }
                              }));
                            }}
                            placeholder="Enter your EPQ project title"
                            style={{
                              width: '100%',
                              padding: '0.5rem',
                              borderRadius: '0.375rem',
                              border: '1px solid #d1d5db',
                              backgroundColor: 'white',
                              outline: 'none',
                              color: '#1F484C',
                            }}
                          />
                          {formData.EPQ?.topicChosen && (
                            <p style={{
                              marginTop: '0.5rem',
                              color: '#15803d',
                              fontSize: '0.875rem',
                            }}>
                              ✓ EPQ topic entered
                            </p>
                          )}
                        </div>
                      )}

                      {/* School Selector for Teachers */}
                      {formData.userRole === 'teacher' && (
                        <div style={{
                          marginTop: '1rem',
                          padding: '1rem',
                          backgroundColor: '#F0F7FF',
                          borderRadius: '0.5rem',
                          border: '1px solid #A8CBF7',
                        }}>
                          <label style={{
                            display: 'block',
                            marginBottom: '0.5rem',
                            color: '#1F484C',
                            fontWeight: '500',
                          }}>
                            Select Your School:
                          </label>
                          <select
                            value={formData.schoolCode || ''}
                            onChange={(e) => {
                              setFormData(prev => ({
                                ...prev,
                                schoolCode: e.target.value
                              }));
                            }}
                            style={{
                              width: windowWidth <= 400 ? '93.5%' : '100%',
                              padding: '0.5rem',
                              borderRadius: '0.375rem',
                              border: '1px solid #d1d5db',
                              backgroundColor: 'white',
                              outline: 'none',
                              color: '#1F484C',
                            }}
                          >
                            <option value="">Select a school...</option>
                            <option value="school_001">Elm Academy</option>
                            <option value="school_002">Harris Purley</option>
                          </select>
                          
                          {formData.schoolCode && (
                            <p style={{
                              marginTop: '0.5rem',
                              color: '#15803d',
                              fontSize: '0.875rem',
                            }}>
                              ✓ School selected
                            </p>
                          )}
                        </div>
                      )}
                    </>
                  )}

                  {/* Help text */}
                  <p style={{
                    fontSize: '0.875rem',
                    color: '#6b7280',
                    marginTop: '0.5rem',
                  }}>
                    If you have been given an access key code, please enter it above. If not, continue to register account via the 'Create Account' tab.
                  </p>
                </div>
              </>
            ) : (
              <div style={formEnrolQuestions}>
                <div style={{
                  marginBottom: '1.5rem',
                }}>
                  <h3 style={{
                    fontWeight: '600',
                    color: '#1F484C',
                    marginBottom: '1rem',
                  }}>
                    Choose Your Account Type
                  </h3>
              
                  {/* Account Type Options */}
                  <div style={enrolQuestionDiv}>
                    {/* Standard Account Option */}
                    <button
                      type="button"
                      onClick={() => {
                        setStandardOption('basic');
                        setSelectedPlan('standard user')
                        setFormData(prev => ({
                          ...prev,
                          userRole: 'standard',
                          userType: 'standard',
                          keyCode: ''
                        }));
                      }}
                      style={{
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        border: '1px solid #1F484C',
                        backgroundColor: standardOption === 'basic' ? '#FFE8CC' : 'white',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                    >
                      <h2 style={{
                        fontWeight: '600',
                        color: '#1F484C',
                        marginBottom: '0.5rem',
                      }}>
                        Standard User (available to all ages)
                      </h2>
                      <ul style={{
                        listStyle: 'none',
                        padding: '0',
                        margin: '0',
                      }}>
                        <p style={{color: 'red'}}>This option is only for members interested in our digital educational material. If you are volunteer (Officer or Mentor) at OTC or a teacher at one of our partnered schools - please head to the <b>'I have an Access Key'</b> tab and enter your code there. </p>
                        {[
                          'Explore our educational video content features',
                          'Join community discussions',
                        ].map((feature, index) => (
                          <li key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            marginBottom: '0.3rem',
                            color: '#1F484C',
                          }}>
                            <span style={{ color: '#15803d' }}>✓</span>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </button>
              
                    {/* Enrollment Option */}
                    <button
                      type="button"
                      onClick={() => {
                        setStandardOption('enroll');
                        setSelectedPlan('student')
                        setFormData(prev => ({
                          ...prev,
                          userRole: 'studentEnrolled',
                          userType: 'student', // Set to student when they choose to enroll
                          keyCode: ''
                        }));
                      }}
                      style={{
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        border: '1px solid #1F484C',
                        backgroundColor: standardOption === 'enroll' ? '#FFE8CC' : 'white',
                        cursor: 'pointer',
                        textAlign: 'left',
                      }}
                    >
                      <h2 style={{
                        fontWeight: '600',
                        color: '#1F484C',
                        marginBottom: '0.5rem',
                      }}>
                        Join Our Excellence Programme (only available for 15 to 18 year olds)
                      </h2>
                      <ul style={{
                        listStyle: 'none',
                        padding: '0',
                        margin: '0',
                      }}>
                        <p style={{color: 'red'}}>Are you a young person interested in joining Off the Curriculum's <b>Excellence Programme</b>? Enroll Today by selecting this option. FYI, our <b>Excellence programme</b> offers:</p>
                        {[
                          'One-to-one academic mentoring',
                          'Learn more with our online Masterclasses',
                          'Attend our live events',
                          'Engage in our monthly well-being seminars',
                          `Access our exclusive AI tutor, 'Ask ASTRO'`
                        ].map((feature, index) => (
                          <li key={index} style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: '0.5rem',
                            marginBottom: '0.3rem',
                            color: '#1F484C',
                          }}>
                            <span style={{ color: '#15803d' }}>✓</span>
                            {feature}
                          </li>
                        ))}
                      </ul>
                    </button>
                  </div>
                </div>
              
                {/* Enrollment Questions Section */}
                {standardOption === 'enroll' && (
                  <div style={{
                    marginTop: '1.5rem',
                    marginTop: '1rem',
                    padding: windowWidth <= 768 ? '1rem' : '1.5rem',
                    backgroundColor: 'white',
                    borderRadius: '0.5rem',
                    border: '1px solid #1F484C',
                    width: windowWidth <= 768 ? 'auto' : 'auto',
                  }}>
              
                    {/* Future Goals Question */}
                    <div style={{
                      marginBottom: '1.5rem',
                    }}>
                      <label style={{
                        display: 'block',
                        marginBottom: '0.5rem',
                        color: '#1F484C',
                        fontWeight: '500',
                        fontSize: windowWidth <= 768 ? '15px' : '16px',
                      }}>
                        Where do you see yourself in 5 years? *
                      </label>
                      <textarea
                        value={formData.enrollmentQuestions?.futureGoals || ''}
                        name="futureGoals"
                        onChange={(e) => {
                          if (e.target.value.length <= 200) {
                            setFormData(prev => ({
                              ...prev,
                              enrollmentQuestions: {
                                ...prev.enrollmentQuestions,
                                futureGoals: e.target.value
                              }
                            }));
                          }
                        }}
                        placeholder="Share your aspirations and goals..."
                        style={{
                          width: windowWidth <= 400 ? '' : '97.5%',
                          fontSize: windowWidth <= 768 ? '14px' : '15px',
                          padding: windowWidth <= 768 ? '0.5rem' : '0.75rem',
                          borderRadius: '0.375rem',
                          border: `1px solid ${focusedField === 'futureGoals' ? '#1F484C' : '#e5e7eb'}`,
                          minHeight: windowWidth <= 768 ? '80px' : '100px',
                          resize: 'none',
                          outline: 'none',
                          boxShadow: focusedField === 'futureGoals' ? '0 0 0 1px #1F484C' : 'none',
                          transition: 'all 0.2s ease',
                        }}
                        onFocus={() => setFocusedField('futureGoals')}
                        onBlur={() => setFocusedField(null)}
                      />
                      <p style={{
                        fontSize: '0.875rem',
                        color: '#6b7280',
                        marginTop: '0.25rem',
                      }}>
                        {`${formData.enrollmentQuestions?.futureGoals?.length || 0}/200 characters`}
                      </p>
                    </div>
              
                    {/* Why Question */}
                    <div style={{
                      marginBottom: '1.5rem',
                    }}>
                      <label style={{
                        display: 'block',
                        fontSize: '14px',
                        marginBottom: '0.5rem',
                        color: '#1F484C',
                        fontWeight: '500',
                      }}>
                        If you could have the answer to any question beginning with "why", what would it be?
                      </label>
                      <textarea
                        value={formData.enrollmentQuestions?.whyQuestion || ''}
                        name="whyQuestion"
                        onChange={(e) => {
                          if (e.target.value.length <= 100) {
                            setFormData(prev => ({
                              ...prev,
                              enrollmentQuestions: {
                                ...prev.enrollmentQuestions,
                                whyQuestion: e.target.value
                              }
                            }));
                          }
                        }}
                        placeholder="Share your curiosity..."
                        style={{
                          width: windowWidth <= 400 ? '' : '97.5%',
                          fontSize: windowWidth <= 768 ? '14px' : '15px',
                          padding: windowWidth <= 768 ? '0.5rem' : '0.75rem',
                          borderRadius: '0.375rem',
                          border: `1px solid ${focusedField === 'whyQuestion' ? '#1F484C' : '#e5e7eb'}`,
                          minHeight: windowWidth <= 768 ? '80px' : '100px',
                          resize: 'none',
                          outline: 'none',
                          boxShadow: focusedField === 'whyQuestion' ? '0 0 0 1px #1F484C' : 'none',
                          transition: 'all 0.2s ease',
                        }}
                        onFocus={() => setFocusedField('whyQuestion')}
                        onBlur={() => setFocusedField(null)}
                      />
                      <p style={{
                        fontSize: '0.875rem',
                        color: '#6b7280',
                        marginTop: '0.25rem',
                      }}>
                        {`${formData.enrollmentQuestions?.whyQuestion?.length || 0}/100 characters`}
                      </p>
                    </div>
              
                    {/* Questions For Us */}
                    <div style={{
                      marginBottom: '1rem',
                    }}>
                      <label style={{
                        display: 'block',
                        marginBottom: '0.5rem',
                        color: '#1F484C',
                        fontWeight: '500',
                      }}>
                        Do you have any questions for us? *
                      </label>
                      <textarea
                        value={formData.enrollmentQuestions?.questions || ''}
                        name="questions"
                        onChange={(e) => {
                          setFormData(prev => ({
                            ...prev,
                            enrollmentQuestions: {
                              ...prev.enrollmentQuestions,
                              questions: e.target.value
                            }
                          }));
                        }}
                        placeholder="Ask us anything..."
                        style={{
                          width: windowWidth <= 400 ? '' : '97.5%',
                          fontSize: windowWidth <= 768 ? '14px' : '15px',
                          padding: windowWidth <= 768 ? '0.5rem' : '0.75rem',
                          borderRadius: '0.375rem',
                          border: `1px solid ${focusedField === 'questions' ? '#1F484C' : '#e5e7eb'}`,
                          minHeight: windowWidth <= 768 ? '80px' : '100px',
                          resize: 'none',
                          outline: 'none',
                          boxShadow: focusedField === 'questions' ? '0 0 0 1px #1F484C' : 'none',
                          transition: 'all 0.2s ease',
                        }}
                        onFocus={() => setFocusedField('questions')}
                        onBlur={() => setFocusedField(null)}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
          {currentStep === 4 && (
            <div>
              <section style={finalContainerStyle}>
              <div style={finalProfileInfoStyle}>
                <div style={finalProfileHeader}>
                  <div style={finalCoverPhotoStyle}>
                    <>
                      <img
                        src={formData.coverPhoto}
                        alt="Cover Photo"
                        style={finalCoverImageStyle}
                      />
                    </>
                      <div style={profileImageContainer}>
                        <img
                          src={formData.displayPhoto}
                          alt="Display Photo"
                          style={profileImage}
                        />
                    </div>
                  </div>
                </div>
                  <div style={finalDetailsStyle}>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Name:</strong>{' '} 
                        {formData.name}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Username:</strong> {' '} 
                        @{formData.userName}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Age:</strong> {' '} 
                        {formData.age}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Bio:</strong> {formData.bio}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Interests:</strong> {formData.interests}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Career Aspirations:</strong> {formData.career}
                    </p>
                    <p style={finalDetailItemStyle}>
                      <strong style={strongStyle}>Selected Plan:</strong> {selectedPlan}
                    </p>
                    {formData.userRole === 'studentEnrolled' && (
                      <>
                        <p style={finalDetailItemStyle}>
                          <strong style={strongStyle}>In 5 Years:</strong> {formData.enrollmentQuestions?.futureGoals}
                        </p>
                        <p style={finalDetailItemStyle}>
                          <strong style={strongStyle}>Why Question:</strong> {formData.enrollmentQuestions?.whyQuestion}
                        </p>
                        <p style={finalDetailItemStyle}>
                          <strong style={strongStyle}>Questions for Us:</strong> {formData.enrollmentQuestions?.questions}
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '25px' }}>
          {currentStep > 0 && 
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonOne
            type="button"
            onClick={prevStep}
            >
            Back
            </StandardButtonContainer.ButtonOne>
          </StandardButtonContainer>
          }
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonTwo type="submit">
              {currentStep === steps.length - 1 ? 'Submit' : 'Next'}
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
        </div>
      </form>
    </motion.div>
  );
};