import React, { useState, useEffect } from 'react';
import { educationPageContent } from './educationPageContent';
import CollapsibleStage from './Components/CollapsibleDiv';

import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
const Education = () => {
  const meta = pageMetaTags.Education;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [listHover, setListHover] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Responsive styles object
  const styles = {
    container: {
      width: windowWidth > 1100 ? '100%' : '80%',
      maxWidth: '1400px',
      margin: '0 auto',
      padding: '20px'
    },
    columnLayout: {
      display: 'flex',
      flexDirection: windowWidth > 1100 ? 'row' : 'column',
      gap: '2rem'
    },
    columnOne: {
      width: windowWidth > 1100 ? '30%' : '100%',
      paddingBottom: windowWidth <= 1100 ? '5%' : '0',
      order: windowWidth > 1100 ? 1 : 2 // This will put the sidebar on the right on desktop
    },
    columnTwo: {
      width: windowWidth > 1100 ? '75%' : '100%',
      paddingBottom: windowWidth <= 1100 ? '5%' : '0',
      order: windowWidth > 1100 ? 2 : 1 // This will put the main content on the left on desktop
    },
    responsiveColumnOne: {
      marginBottom: '2rem',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    responsiveColumnTwo: {
      flexDirection: 'column',
      padding: '20px',
      borderRadius: '10px',
    },
    pageList: {
      listStyle: 'none',
      transition: 'all 0.3s ease'
    },
    pageListLink: {
      textDecoration: 'none',
      color: '#333',
      display: 'block',
      borderRadius: '5px',
      transition: 'all 0.3s ease',
      ':hover': {
        color: '#f5f5f5',
        transform: 'translateX(5px)'
      }
    },
    ulPageList:{
      padding: 0,
      margin: 0,
    },
    clearFix: {
      clear: 'both'
    },
    heading: {
      fontSize: windowWidth > 1100 ? '2.5rem' : '2rem',
      marginBottom: '1.5rem',
      color: '#2E5C4F'
    },
    subHeading: {
      fontSize: windowWidth > 1100 ? '1.8rem' : '1.5rem',
      marginTop: '2rem',
      marginBottom: '1rem',
      color: '#2E5C4F'
    }
  };


  const checkpoints = [
    {
      title: 'Off the Curriculum Profresh Launch',
      description: 'Launch of academic mentoring, new website, programme, and mobile application!',
      date: 'October 11th 2024, 18:30PM - 19:00PM'
    },
    {
      title: 'Neuroscience and Psychology Launch',
      description: 'Launch of our first short courses covering Neuroscience and Psychology. Discover the wonders of our own minds via our EDUCO portal.',
      date: 'October 11th 2024, 19:00PM - 19:15PM'
    },
    {
      title: 'Mighty minds: Resilience and wellbeing session',
      description: 'Mental health and wellbeing session with Sinead Reed-Forrester',
      date: 'November 16th 2024, 11:30-12:30'
    }
  ];

  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.columnLayout}>
        <div style={styles.columnTwo}>
          <h1 id="education">{educationPageContent.pageTitle}</h1>
          <p>{educationPageContent.pageIntroduction}</p>
          
          <h2 id="mentoring">{educationPageContent.headerOne}</h2>
          <p>{educationPageContent.paraOne}</p>
          
          <h2 id="seminarseries">{educationPageContent.headerTwo}</h2>
          <p>{educationPageContent.paraTwo}</p>
          
          <CollapsibleStage
            stageNumber="October 2024 to January 2025"
            title="Autumn/Winter Term"
            description="Get ready for an epic kick-off to our Autumn/Winter extravaganza! We're about to blast off with not one, but THREE mind-blowing events that'll rock your world from October to January 2024/25. Check out this sneak peek of what's coming your way:"
            checkpoints={checkpoints}
          />
          
          <h2 id="educo">{educationPageContent.headerThree}</h2>
          <p>{educationPageContent.paraThree}</p>
          
          <h2 id="educo">{educationPageContent.headerFour}</h2>
          <p>{educationPageContent.paraFour}</p>
          
          <h2 id="mightyminds">{educationPageContent.headerFive}</h2>
          <p>{educationPageContent.paraFive}</p>
        </div>

        <div style={styles.columnOne}>
          <div style={styles.responsiveColumnOne}>
            <h2>About the Education team:</h2>
            <p>
              The Education team is in charge of developing Off the Curriculum's educational philosophy. 
              We look after the Academic mentoring programme, the Seminar Series, the EDUCO platform, 
              and our Mighty Minds programme.
            </p>
          </div>
          
          <div style={styles.responsiveColumnTwo}>
            <h4>Article quick links:</h4>
            <ul style={styles.ulPageList}>
              {[
                { id: `${educationPageContent.pageTitle}`, title: educationPageContent.pageTitle },
                { id: `${educationPageContent.headerOne}`, title: educationPageContent.headerOne },
                { id: `${educationPageContent.headerTwo}`, title: educationPageContent.headerTwo },
                { id: `${educationPageContent.headerThree}`, title: educationPageContent.headerThree },
                { id:  `${educationPageContent.headerFour}`, title: educationPageContent.headerFour },
                { id:  `${educationPageContent.headerFive}`, title: educationPageContent.headerFive }
              ].map(link => (
                <li key={link.id} style={styles.pageList}>
                  <a 
                    href={`#${link.id}`}
                    style={styles.pageListLink}
                  >
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};



export default Education;