import React from 'react';
import { motion } from 'framer-motion';

const BottomLoader = ({
  isLoading,
  message = "Loading more videos...",
  spinnerColor = "#54AFB8",
  textColor = "#333333",
}) => {
  if (!isLoading) return null;

  const styles = {
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      padding: '20px',
    },
    loaderCard: {
      backgroundColor: '#FFFFFF',
      borderRadius: '15px',
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      boxShadow: `
        0px 1.8px 2.2px rgba(0, 0, 0, 0.028),
        0px 4.3px 5.3px rgba(0, 0, 0, 0.04),
        0px 8.1px 10px rgba(0, 0, 0, 0.05),
        0px 14.5px 17.9px rgba(0, 0, 0, 0.06),
        0px 65px 80px rgba(0, 0, 0, 0.01)
      `,
      border: '1px solid rgba(0, 0, 0, 0.05)',
      maxWidth: '300px',
      gap: '20px',
    },
    spinnerContainer: {
      position: 'relative',
    },
    spinner: {
      width: '30px',
      height: '30px',
      border: '3px solid rgba(84, 175, 184, 0.1)',
      borderTop: `3px solid ${spinnerColor}`,
      borderRadius: '50%',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    text: {
      color: textColor,
      fontSize: '14px',
      fontWeight: 500,
      margin: 0,
    },
    subText: {
      color: '#666666',
      fontSize: '12px',
      margin: '4px 0 0 0',
    },
  };

  return (
    <motion.div
      style={styles.container}
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 20 }}
      transition={{ duration: 0.3 }}
    >
      <div style={styles.loaderCard}>
        <div style={styles.spinnerContainer}>
          <motion.div
            style={styles.spinner}
            animate={{ rotate: 360 }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "linear",
            }}
          />
        </div>
        <div style={styles.textContainer}>
          <p style={styles.text}>{message}</p>
          <p style={styles.subText}>Please wait while we fetch more content</p>
        </div>
      </div>
    </motion.div>
  );
};

export default BottomLoader;