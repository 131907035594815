import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

const NavActionButton = ({ children, to }) => {
  const [isHovered, setIsHovered] = useState(false);
  const navigate = useNavigate()

  const NavActionButtonStyle = {
    color: '#000',
    backgroundColor: '#fff',
    border: 'none',
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '15px',
    cursor: 'pointer',
    marginBottom: '15px',
    width: '100%',
    whiteSpace: 'nowrap',
    position: 'relative',
    transition: 'all 0.3s ease',
    fontSize: '18px',
    fontWeight: isHovered ? 'bold' : 'normal'
  };

  const underlineStyle = {
    content: '""',
    position: 'absolute',
    left: '0',
    bottom: '-2px',
    width: '100%',
    height: '2px',
    backgroundColor: '#000',
    transform: isHovered ? 'scaleY(1)' : 'scaleY(0)',
    transformOrigin: 'bottom',
    transition: 'transform 0.3s ease'
  };
  const handleClick = () => {
    const path = to.startsWith('/') ? to : `/${to}`;
    navigate(path);
  }
  return (
      <button
        style={NavActionButtonStyle}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={handleClick}
      >
        <span>{children}</span>
        <span style={underlineStyle}></span>
      </button>
  );
};

export default NavActionButton;