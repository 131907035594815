const styles = {    
    container:{
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingTop: '2.5%',
      marginBottom: '50px'
    }, 
    centerscheme2: {
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: '75%',
      cursor: 'pointer',
        }, 
    columnOne: {
      width: '20%',
      padding: '0 0px 0 px',
    },
    columnTwo: {
      width: '75%',
      padding: '0 0px 0 0',
      float: 'right',
      minHeight: '500px'
    },  
    imgPartneredSchool: {
      width: '40%',
      maxHeight: '120px',
      display: 'block',
      marginLeft: 'auto', 
      marginRight: 'auto',
    },
    imgAuthor:{
      width: '80%',
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    seminarTableDates: {
      textAlign: 'center',
      marginLeft: 'auto',
      marginRight: 'auto', 
      paddingBottom: '2.5%',
      overflowX: 'auto',
    },
    seminarTopicTable: {
      width: '90%', 
      textAlign: 'center', 
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingBottom: '2.5%',
      display: 'block',
      overflowX: 'auto'
    }, 
    th: {
      border: '1px solid #101518',
      borderStyle: 'solid',
      borderWidth: '0.5px',
    },
    td: {
      border: '1px solid #101518',
      borderStyle: 'solid',
      borderWidth: '0.5px',
    }, 
    tr:{
      border: '1px solid #101518',
      borderStyle: 'solid',
      borderWidth: '0.5px',
    },
    /*calendar*/
    collapsible: {
        backgroundColor: '#ffbaab',
        color: 'white',
        cursor: 'pointer',
        padding: '18px',
        width: '80%',
        border: 'none',
        textAlign: 'left',
        outline: 'none',
        fontSize: '15px',
        display: 'block',
        marginLeft: 'auto',
        marginRight: 'auto',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)', 
        marginBottom: '2.5%',
        borderRadius: '12px',
      },
    collapsibleHover: {
      backgroundColor: '#ef0000', 
    },
    content: {
      display: 'none',
      overflow: 'hidden',
      backgroundColor: 'white',
      width: '80%',
      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: '2%', 
      boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2)' 
    }, 
  pageList: {
    color: '#98989c',
    listStyleType: 'none', 
    lineHeight: '1.5',
    transition: '2ms',
    fontWeight: 'normal',
  },
  pageListLink: {
    color: '#98989c',
    textDecoration: 'none',
    fontWeight: '500',
  }, 
  pageListLinkHover: {
    opacity: '0.5',
  },
  clearFix: {
    clear: 'both' // This ensures the footer clears both floated columns
  },
  }

  export {
    styles
  }