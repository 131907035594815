import React, { useState, useEffect } from 'react';
import PolicyViewer from './PolicyTemplatePage';
import { useNavigate, useParams } from 'react-router-dom';
// meta data
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';

const Policies = ({}) => {
  const meta = pageMetaTags.Policies;

  const navigate = useNavigate();
  const { policyId } = useParams(); // Get policy ID from URL if present
  //console.log('polciies', policyId)
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handlePolicyClick = (policy) => {
    //console.log(policy)
    navigate(`/policies/${policy.id}`, { 
      state: { 
        policyDetails: policy 
      }
    });
  };

  const handleClose = () => {
    navigate('/policies');
  };
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > 1200;
  const isTablet = windowWidth <= 1200 && windowWidth >= 768;
  const isMobile = windowWidth < 768;


  const resources = [
    {
      id: 'education-memorandum',
      title: 'Education Memorandum of Off the Curriculum’s Programme',
      description: 'The purpose of this document is to outline the memorandum on education at Off the Curriculum. Culminating in the future of our educational programme.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2023-10-15',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'educational-aims-memorandum',
      title: 'Memorandum on the educational aims of Off the Curriculum’s programme',
      description: 'The purpose of demonstrating the shift in educational aims of our programme to the needs and wants of our young people.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2023-10-20',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'new-member-onboarding-checklist',
      title: 'New Member Onboarding Checklist',
      description: 'The purpose of this document is to act as a process for onboarding new members of the Off the Curriculum educational programme.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-01-15',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'educational-evaluation-policy',
      title: 'Educational Evaluation Policy',
      description: 'The rationale behind this policy is to ensure that the educational services provided by Off the Curriculum address the needs and expectations of our young people.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-01-15',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'target_audience',
      title: 'Charity Target Audience',
      description: 'Our focus lies squarely on addressing the needs of wards across London boroughs with the highest deprivation scores, recognising the urgency of support in these communities.',
      image: '/api/placeholder/400/320',
      category: 'Programme Evaluation',
      dateUpdated: '2024-01-15',
      approvedBy: 'Tim Siu'
    },
    {
      id: 'off_the_curriculum_project_plan_2024_25',
      title: 'Off the Curriculum: Project Plan 2024 to 2025',
      description: 'We are embarking on an ambitious educational program for the 2024-2025 academic year, designed to provide a comprehensive and engaging learning experience for young people aged 15-18.',
      image: '/api/placeholder/400/320',
      category: 'Programme Evaluation',
      dateUpdated: '2024-01-15',
      approvedBy: 'Tim Siu'
    },
    {
      id: 'programme_rationale',
      title: 'Off the Curriculum: Project Rationale',
      description: 'The 2010 Index of Multiple Deprivation (IMD) placed Lambeth as the 9th most deprived borough in London and 29th most deprived council in England (Demie and Mclean, 2016). ',
      image: '/api/placeholder/400/320',
      category: 'Programme Evaluation',
      dateUpdated: '2024-01-15',
      approvedBy: 'Tim Siu'
    },
    {
      id: 'lambeth-academy-demonstration-of-need',
      title: 'Lambeth Academy Demonstration of need',
      description: 'Demonstration of support by one of our partnered schools Elm Academy, formerly Lambeth Academy.',
      image: '/api/placeholder/400/320',
      category: 'Demonstration of need',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'off_the_curriculum_mission_statement',
      title: 'Off the Curriculum Mission Statement',
      description: 'Off the Curriculum conducts its work for the public benefit, to encourage young people to forge new paths by providing an educational service that incorporates "in-person" and "online" learning experiences.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'off_the_curriculum_organisation_profile',
      title: 'Off the Curriculum Organisation Profile',
      description: `The purpose of this document is to outline Off the Curriculum's organisational profile, our mission statement, about us and our origin, our team, and much more. `,
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'outreach_plan_2024_25',
      title: 'Off the Curriculum Outreach Plan',
      description: 'Our zero-tolerance approach to bullying, including cyber-bullying, with clear procedures for reporting and addressing incidents.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'organisational_chart_2024_2025',
      title: 'Off the Curriculum Organisational Chart 2024 to 2025',
      description: 'Our zero-tolerance approach to bullying, including cyber-bullying, with clear procedures for reporting and addressing incidents.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'off_the_curriculum_volunteer_welcome pack_24_25',
      title: 'Off the Curriculum Volunteer Welcome Pack',
      description: 'Our zero-tolerance approach to bullying, including cyber-bullying, with clear procedures for reporting and addressing incidents.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'off_the_curriculum_appraisal_form',
      title: 'Off the Curriculum Appraisal Form',
      description: 'Our zero-tolerance approach to bullying, including cyber-bullying, with clear procedures for reporting and addressing incidents.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'template_volunteer_officer_contract',
      title: 'Off the Curriculum Template Officer Contract',
      description: 'Our zero-tolerance approach to bullying, including cyber-bullying, with clear procedures for reporting and addressing incidents.',
      image: '/api/placeholder/400/320',
      category: 'Organisational Structure',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'v2_4_OTC_Safeguarding_Policy_10_Oct',
      title: 'Off the Curriculum Safeguarding Policy',
      description: 'This policy is informed by current legislation to protect children and young people as part of safeguarding and promoting welfare in Off the Curriculum.',
      image: '/api/placeholder/400/320',
      category: 'Safeguarding and Protection',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'staff_meeting_discussion_about_safeguarding_policy',
      title: 'Staff discussion regarding our Safeguarding Policy',
      description: 'Joseph Levermore and Jawaria Suleman discussed improving the safeguarding policy upon receiving feedback from London Youth.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-01-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'staff_recruitment_policy',
      title: 'Staff Recruitment Policy',
      description: 'The purpose of this policy is to make sure that the staff and volunteers we recruit are suitable to work with children and/or adults at risk.',
      image: '/api/placeholder/400/320',
      category: 'Administrative and Operational',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'safe_recruitment_checklist',
      title: 'Staff Recruitment Checklist Policy',
      description: 'Off the Curriculum is dedicated to creating a safe and supportive environment for all participants, particularly children and adults at risk.',
      image: '/api/placeholder/400/320',
      category: 'Administrative and Operational',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'bullying_incident_report_form',
      title: 'Staff discussion regarding our Safeguarding Policy',
      description: 'At Off the Curriculum, we are dedicated to fostering an inclusive and welcoming environment for all young people, volunteers, trustees, and senior officers.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'bullying_awareness_and_training_for_staff_and_young_people',
      title: 'Staff discussion regarding our Safeguarding Policy',
      description: 'At Off the Curriculum, we are committed to fostering an inclusive, safe, and respectful environment for all staff, students, and stakeholders.',
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'bullyingandharassment',
      title: `Bullying and Harassment Policy`,
      description: `Staff discussion regarding our Safeguarding Policy', description: 'Everyone will be treated with dignity and respect at Off the Curriculum. Bullying and harassment of any kind are in no-one's interest and will not be tolerated at Off the Curriculum`,
      image: '/api/placeholder/400/320',
      category: 'Safeguarding and Protection',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'code_of_behaviour_for_young_people_policy',
      title: `Code of Behaviour for Young People`,
      description: `The purpose of this policy is in order to provide children and young people with advice on the behaviour that is expected of them when attending and using the facilities of Off the Curriculum.`,
      image: '/api/placeholder/400/320',
      category: 'Safeguarding and Protection',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'confidentiality_and_information_sharing',
      title: `Confidentiality and Information Sharing`,
      description: `This policy applies to all staff, volunteers, and stakeholders involved in the organisation, including those working with children, young people, and vulnerable adults...`,
      image: '/api/placeholder/400/320',
      category: 'Safeguarding and Protection',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'confidentiality_agreement',
      title: `Confidentiality Agreement`,
      description: `This policy aims to ensure that confidential information collected in the course of providing services is handled securely and shared appropriately....`,
      image: '/api/placeholder/400/320',
      category: 'Data and Information Management',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'policy_for_obtaining_parental_carer_consent',
      title: `Policy for Obtaining Parental or Carer Consent`,
      description: `This policy outlines the procedures for obtaining informed parental/carer consent for young people participating in Off the Curriculum activities and for the use of their details or images in publicity or media.`,
      image: '/api/placeholder/400/320',
      category: 'Consent and Documentation',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'parent_or_carer_media_consent_form',
      title: `Parent or Carer Media Consent Form`,
      description: `In accordance with our Young Person Protection Policy, we will not permit photographs, video footage or other content of young people to be taken without consent.`,
      image: '/api/placeholder/400/320',
      category: 'Consent and Documentation',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'parent_or_carer_consent_form',
      title: `Parent or Carer Consent Form`,
      description: `In accordance with our Young Person Protection Policy, the aim of this consent form is to acquire parental content.`,
      image: '/api/placeholder/400/320',
      category: 'Consent and Documentation',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'grievance_policy_and_procedure',
      title: `Grievance Policy and Procedures`,
      description: `We recognise that disagreements or concerns may arise from time to time, and it is important to address these issues promptly and fairly.`,
      image: '/api/placeholder/400/320',
      category: 'Administrative and Operational',
      dateUpdated: '2023-12-05',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'designated_safeguarding_officer_job_description_volunteer',
      title: `Designated Safeguarding Officer Job Description Volunteer`,
      description: `We recognise that disagreements or concerns may arise from time to time, and it is important to address these issues promptly and fairly.`,
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2023-12-05',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'enrolment_form',
      title: `Young Person Enrolment Form`,
      description: `A UK charity with a global perspective, Off the Curriculum provides educational services with a common goal - inspiring the next generation to succeed. See our enrolment form here...`,
      image: '/api/placeholder/400/320',
      category: 'Consent and Documentation',
      dateUpdated: '2024-05-05',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'young_person_checklist',
      title: `Young Person Enrolment Checklist`,
      description: `To ensure you have a smooth start and make the most of our programs, please complete the following induction checklist...`,
      image: '/api/placeholder/400/320',
      category: 'Consent and Documentation',
      dateUpdated: '2024-05-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'young_people_code_of_conduct',
      title: `Young People Code of Conduct`,
      description: `The purpose of this policy is in order to provide children and young people with advice on the behaviour that is expected of them when attending and using the facilities of Off the Curriculum.`,
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-09-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'e_safety_policy',
      title: `E-Safety Policy`,
      description: `Off the Curriculum is committed to ensuring the safety and wellbeing of young people while they engage with our online services. This e-safety policy outlines our approach to protecting young people from potential online dangers...`,
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'equality_diversity_and_inclusion',
      title: `Equality, Diversity and Inclusion`,
      description: `Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. We want everyone to feel like they belong. We value each person as an individual.`, 
      image: '/api/placeholder/400/320',
      category: 'Equality and Inclusion',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'equality_diversity_and_inclusion_incident_form',
      title: `Off the Curriculum Equality, Diversity and Inclusion Report Incident Form`,
      description: `Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. The purpose of this form is to record any incidents of discrimination during your time with Off the Curriculum. `, 
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'equality_diversity_and_inclusion_incident_form',
      title: `Off the Curriculum Equality, Diversity and Inclusion Report Incident Form`,
      description: `Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. The purpose of this form is to record any incidents of discrimination during your time with Off the Curriculum. `, 
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'promoting_anti_discriminatory_practice',
      title: `Promoting Anti-discriminatory Practice at Off the Curriculum`,
      description: `Our commitment to anti-discriminatory practice is central to our mission and operations. Here's how we actively promote equality and respect for diversity:`, 
      image: '/api/placeholder/400/320',
      category: 'Equality and Inclusion',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'statement_of_adjustment_2024_25',
      title: `Statement of Adjustment`,
      description: `At Off the Curriculum (OTC), we are committed to continual improvement and adaptation of our educational offerings to best serve our diverse learners. Our journey from a project-based seminar series...`, 
      image: '/api/placeholder/400/320',
      category: 'Equality and Inclusion',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'contextual_note_interview_assessment_form',
      title: `Contextual Note: Interview Assessment Form`,
      description: `The Interview Assessment Form found here: OTC Interview Assessment Form - Mentor Document is a crucial tool in Off the Curriculum's commitment to fair and consistent recruitment practices for mentor positions. Its purpose and implementation are as follows:`, 
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'job_description_health_and_safety_advisor',
      title: `Job description health and safety advisor`,
      description: `Off the Curriculum (OTC), an educational charity, is looking for a Health and Safety Advisor to oversee and manage all aspects of health and safety within the organisation. `, 
      image: '/api/placeholder/400/320',
      category: 'Education',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'health_and_safety_policy_at_off_the_curriculum',
      title: `Health and safety policy at Off the Curriculum`,
      description: `This Health and Safety manual contains our Health and Safety Policy as required by the Health and Safety at Work etc. Act 1974 and it defines the way Off the Curriculum manages the health and safety hazards and risks associated with our business, premises and activities.`, 
      image: '/api/placeholder/400/320',
      category: 'Health and Safety',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'fire_safety_policy',
      title: `Fire Safety Policy`,
      description: `This policy applies to all members, trustees and volunteers. It is Off the Curriculum’s policy to protect the members, trustees, volunteers, visitors and contractors against the incidence of fire by complying with Fire Safety Legislation.`, 
      image: '/api/placeholder/400/320',
      category: 'Health and Safety',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },   
    {
      id: 'first_aid_policy',
      title: `First Aid Policy`,
      description: `Off the Curriculum is committed to safeguarding and promoting the welfare of young people.This First Aid Policy is designed with the primary goal of ensuring the safety and well-being of all young people participating in our programs.`, 
      image: '/api/placeholder/400/320',
      category: 'Safeguarding and Protection',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },   
    {
      id: 'accident_investigation_and_reporting',
      title: `Accident Investigation and Reporting with Off the Curriculum`,
      description: `The purpose of this accident investigation document is to find out the root causes of accidents, incidents, or near misses involving young people or staff during Off the Curriculum (OTC) activities, and to prevent reoccurrence.`, 
      image: '/api/placeholder/400/320',
      category: 'Health and Safety',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'event_safety_opening_script',
      title: `Off the Curriculum Event Safety Opening Script`,
      description: `The event was opened by Dr. Joseph Levermore with Jawaria Suleman in attendance. The event occurred on 23rd September 2023. The transcript for the opening ceremony of the event pertaining...`, 
      image: '/api/placeholder/400/320',
      category: 'Health and Safety',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'policy_bee_insurance',
      title: `Policy Bee Insurance Coverage`,
      description: `Please see here Off the Curriculum's Policy bee insurance coverage...`, 
      image: '/api/placeholder/400/320',
      category: 'Insurance and Legal',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'off_the_curriculum_ico_registration',
      title: `ICO Registration Coverage`,
      description: `Please see here Off the Curriculum's ICO registration coverage...`, 
      image: '/api/placeholder/400/320',
      category: 'Data and Information Management',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'data_protection_policy',
      title: `Off the Curriculum Data Protection Policy`,
      description: `Off the Curriculum needs to collect, store and process personal data in order to carry out its functions and activities. The Charity is a Controller for all of the personal data it processes and is committed...`, 
      image: '/api/placeholder/400/320',
      category: 'Administrative and Operational',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'data_access_permissions_and_methods_policy',
      title: `Off the Curriculum Data Access Permissions and Methods`,
      description: `This document outlines the individuals who have the need and permissions to access information within Off the Curriculum, along with the details of how they access data:...`, 
      image: '/api/placeholder/400/320',
      category: 'Data and Information Management',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'whistleblowing_policy',
      title: `Whistleblowing Policy`,
      description: `This policy aims to help the Trustees and employees/volunteers to raise any serious concerns they may have about colleagues or their employer with confidence and without having to worry about being victimised...`, 
      image: '/api/placeholder/400/320',
      category: 'Administrative and Operational',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'achievement_of_aims_and_use_of_innovation',
      title: `Off the Curriculum: Innovative Achievement of Aims`,
      description: `This statement outlines the ways in which we have innovatively met our goals and continuously improved our service delivery...`, 
      image: '/api/placeholder/400/320',
      category: 'Insurance and Legal',
      dateUpdated: '2024-10-10',
      approvedBy: 'Joseph Levermore'
    },
  ];

  const categories = ['all', ...new Set(resources.map(resource => resource.category))];

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      gap: '40px',
      flexDirection: isDesktop ? 'row' : 'column',
    },
    leftSection: {
      flex: '1',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      flex: isDesktop ? '0 0 300px' : 'auto',
      marginBottom: isDesktop ? '0' : '24px',  // Simplified
      width: isDesktop ? 'auto' : '100%',
    },
    rightSection: {
      flex: '2',
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : '2em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '30px',
    },
    filterContainer: {
      marginTop: '20px',
      flexDirection: isDesktop ? 'column' : 'row',  // Simplified
      display: 'flex',
      gap: '8px',
      overflowX: isDesktop ? 'visible' : 'auto',  // Simplified
      paddingBottom: isDesktop ? '0' : '16px',  // Simplified
      WebkitOverflowScrolling: 'touch',
    },
    filterButton: {
      padding: '12px 16px',
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'left',
      transition: 'all 0.2s ease',
      fontSize: '0.95em',
      fontWeight: '500',
      whiteSpace: isDesktop ? 'normal' : 'nowrap',  // Simplified
      minWidth: isDesktop ? '100%' : 'auto',  // Simplified
    },
    activeFilter: {
      backgroundColor: '#1F484C',
      color: 'white',
      border: '1px solid #1F484C',
    },
    inactiveFilter: {
      backgroundColor: 'white',
      color: '#374151',
      '&:hover': {
        backgroundColor: '#f3f4f6',
      },
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: isDesktop ? 'repeat(2, 1fr)' : '1fr',
      gap: '24px',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      borderRadius: '12px',
      overflow: 'hidden',
      border: '1px solid #e5e7eb',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
      },
    },
    contentContainer: {
      padding: '24px',
    },
    itemTitle: {
      fontSize: '1.4em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '12px',
      lineHeight: '1.3',
    },
    itemDescription: {
      fontSize: '1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '16px',
    },
    categoryTag: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#f3f4f6',
      color: '#111827',
      borderRadius: '6px',
      fontSize: '0.875em',
      fontWeight: '500',
      marginBottom: '16px',
    },
    metadataContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      paddingTop: '16px',
      borderTop: '1px solid #e5e7eb',
    },
    metadataItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#6b7280',
      fontSize: '0.875em',
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
    },
    dateLabel: {
      fontWeight: '500',
      color: '#374151',
    },
    approvedLabel: {
      fontWeight: '500',
      color: '#374151',
    }
  };

  const filteredResources = selectedCategory === 'all'
    ? resources
    : resources.filter(resource => resource.category === selectedCategory);

    const formatDate = (dateString) => {
      const options = { year: 'numeric', month: 'short', day: 'numeric' };
      return new Date(dateString).toLocaleDateString('en-GB', options);
    };

  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.leftSection}>
        <h1 style={styles.pageTitle}>Policies at OTC</h1>
        <p style={styles.pageDescription}>
          Welcome to Off the Curriculum's Policy Center. Here you'll find all our organisational policies that guide our operations, ensure safety, and maintain high standards in all our activities.
        </p>
        
        <div style={styles.filterContainer}>
          <h2 style={{ fontSize: '1.2em', fontWeight: '600', marginBottom: '12px', color: '#111827' }}>
            Filter by Category
          </h2>
          {categories.map(category => (
            <button
              key={category}
              style={{
                ...styles.filterButton,
                ...(selectedCategory === category ? styles.activeFilter : styles.inactiveFilter)
              }}
              onClick={() => setSelectedCategory(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div style={styles.rightSection}>
      {!policyId ? (
        <div style={styles.grid}>
          {filteredResources.map((item, index) => (
            <div key={index} 
            style={styles.gridItem}               
            onClick={() => handlePolicyClick(item)}>
              <div style={styles.contentContainer}>
                <h2 style={styles.itemTitle}>{item.title}</h2>
                <div style={styles.categoryTag}>
                  {item.category}
                </div>
                <p style={styles.itemDescription}>{item.description}</p>
                <div style={styles.metadataContainer}>
                  <div style={styles.metadataItem}>
                    <span role="img" aria-label="calendar" style={styles.icon}>📅</span>
                    <span style={styles.dateLabel}>Updated:</span> {formatDate(item.dateUpdated)}
                  </div>
                  <div style={styles.metadataItem}>
                    <span role="img" aria-label="approved" style={styles.icon}>✓</span>
                    <span style={styles.approvedLabel}>Approved by:</span> {item.approvedBy}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
          ) : (
            <PolicyViewer 
              policyId={policyId}
              onClose={handleClose}
              resources={resources}
            />
          )}
        </div>
  </div>
  );
};

export default Policies;