import React, {useContext, useEffect} from 'react';
import MetaTags from "../../../../Components/Common/MetaTags/metaTagsComponent";
import { pageMetaTags } from "../../../../Components/Common/MetaTags/metaData";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { motion } from 'framer-motion';

import { LoadingViews, LoadingLikes } from "../Components/LikeViewsComponent/LikeViewsComponent";
import { LikeButton } from "../../../../Components/Common/Buttons/LikeButton/LikeButton";
import { styles, lightTheme, darkTheme } from "../styles";
import { LoadingContext } from '../../../../Roots/LoadingContent';
import { LoadingGuestSpeakerComponent } from '../Components/GuestSpeakerComponent/GuestSpeakerComponent';
import CommentSection from '../../../../Components/Common/Comments/Comments';


const UnLoadedPageContent = ({ name }) => {
  const { setIsContentLoaded } = useContext(LoadingContext);

  useEffect(() => {
    // Simulate content loading with a timeout
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 0.1); // Adjust the delay as needed

    // Cleanup the timer if the component unmounts
    return () => clearTimeout(timer);
  }, [setIsContentLoaded]);

  const meta = pageMetaTags.Loading;

  //console.log('unloaded content component', name);
  return (
    <>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.educoContainer}>
      <div style={styles.secondEduco}>
        <video
          id="videoPlayer"
          style={styles.videoEduoPlayer}
          controls
          autoPlay
          controlsList="nodownload"
          poster=""
          alt=""
          onContextMenu={(e) => e.preventDefault()}
        >
          <source src="" type="video/mp4" />
          <track default kind="captions" srcLang="English" src="" />
        </video>
        <div style={styles.videoInfoContainer}>
          <div style={styles.videoAnalytics}>
            <LikeButton />
            <LoadingLikes />
            <LoadingViews />
          <div>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <p style={{ marginRight: '10px' }}>
                Share via:
              </p>
              <FacebookShareButton>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
              <WhatsappShareButton>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
              <TwitterShareButton>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
              <LinkedinShareButton>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
              </div>
            </div>
          </div>
          <LoadingGuestSpeakerComponent />
        <CommentSection />
        <div className="card-course">
          <div className="card-horizontal">
            <div className="cardBody">
              <div className="card-educo-title">
                <img 
                style={styles.courseInfographic} src="" alt="Transcript" />
                <span className="card-educo-course-title">
                  Transcript
                </span>
              </div>
              <hr 
              style={styles.educoSubjectCourse}
               />
              <div 
                style={styles.scrollableBox}>
                <div>
                  <h3></h3>
                  <p></p>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div style={styles.firstEduco}>
        <div style={styles.recommendOverview}>
          <h3 style={styles.educoTitle}>
            For you:
          </h3>
            <div style={styles.carouselContainer}>
              <div style={styles.carousel}>
                {[1, 2, 3, 4, 5].map((data) => (
                    <motion.div
                      style={styles.videoItemStyle}
                      whileHover={{
                        scale: 1.1,
                      }}
                      initial={{
                        scale: 1.0,
                      }}
                      key={data}
                      >
                    <div style={{width: '100%', height: '100%' }}>
                      <div style={styles.thumbnailStyle}></div>
                        <div style={styles.videoInfoStyle}>
                          <h3 style={styles.videoTitle}>Loading</h3>
                          <div style={styles.videoDetailsStyle}>
                          <span style={styles.videoSpan}>Loading</span>
                          <span style={styles.videoSpan}>
                            Loading{'👀'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </motion.div>
              ))}
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
};

export default UnLoadedPageContent;

