import React, { useState } from 'react';
import { CharityUpdates } from '../CharityUpdates/CharityUpdates';// Assuming this component exists
import RecentUpdates from '../RecentUpdates/RecentUpdates';

// Placeholder components - you'll need to create these
import ProgrammeDocumentation from './ProgrammeDocumentation/ProgrammeDocumentation';
import EPQResourceLibrary from './EPQResourceLibrary/EPQResourceLibrary';
import IncidentForm from './SafeguardIncidentForm/SafeguardingIncidentForm';
import EPQTrainingVideo from './Training/Training';
import CreateMentorProfile from './UploadInformation/UploadInformation';
import WelcomeProfresh from './WelcomeDiv/WelcomeDiv';
import EditMentorProfile from './EditProfile/EditProfile';

const MentorProgrammeAdminSection = ({epqData}) => {
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  //console.log('epq data', epqData)
  const [menteeName, setMenteeName] = useState(epqData?.MenteeName)
  const [projectTitle, setprojectTitle] = useState(epqData?.topicChosen)
  const [startDate, setstartDate] = useState(epqData?.startDate)
  const [endDate, setendDate] = useState(epqData?.endDate)
  const [editProfileUserId, setEditProfileUserId] = useState(epqData?.MentorInfo)


  const mentorProgrammeUpdates = [
    { title: 'Welcome to the Pro-fresh academic mentoring programme', status: 'New', image: '📝' },
    { title: 'Programme Documentation', status: 'Available', image: '📄' },
    { title: 'Resources Portal', status: 'Updated', image: '🗃️' },
    { title: 'Safeguarding Incident Form', status: 'Important', image: '🛡️' },
    { title: 'Training', status: 'In progress', image: '🎓' },
    { title: 'Upload Information', status: 'Action needed', image: '📤' },
    { title: 'Edit Mentor Profile Information', status: 'Action needed', image: '📤' },

  ];

  const mentorUpdatesList = [
    {
      title: 'Welcome to the Pro-fresh academic mentoring programme',
      assignedBy: 'Mentor Support Team',
      dueDate: 'As needed',
      ongoingProjects: 'Mentor Safety Initiative',
      urgencyLevel: 'High',
    },
    {
      title: 'Programme Documentation',
      assignedBy: 'Programme Management',
      dueDate: 'Always available',
      ongoingProjects: 'Mentor Programme Guidelines',
      urgencyLevel: 'Low',
    },
    {
      title: 'Resources Portal',
      assignedBy: 'Content Team',
      dueDate: 'Regularly updated',
      ongoingProjects: 'Mentor Resource Enhancement',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Safeguarding Incident Form',
      assignedBy: 'Safeguarding Team',
      dueDate: 'As needed',
      ongoingProjects: 'Student Protection Protocol',
      urgencyLevel: 'High',
    },
    {
      title: 'Training',
      assignedBy: 'Training Department',
      dueDate: 'Next Month',
      ongoingProjects: 'Mentor Skill Development',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Upload Information',
      assignedBy: 'Data Management Team',
      dueDate: 'End of week',
      ongoingProjects: 'Mentor Profile Update',
      urgencyLevel: 'Low',
    },
    {
      title: 'Edit Mentor Profile Information',
      assignedBy: 'Data Management Team',
      dueDate: 'End of week',
      ongoingProjects: 'Mentor Profile Update',
      urgencyLevel: 'Low',
    },
  ];

  const handleUpdateClick = (index) => {
    setSelectedUpdate(mentorUpdatesList[index]);
  };

  return (
    <div style={styles.columns}>
      <div style={styles.column}>
        <RecentUpdates
          title="Mentor Resource Center"
          updates={mentorProgrammeUpdates}
          onUpdateClick={handleUpdateClick}
        />
      </div>
      <div style={styles.column}>
        {selectedUpdate && selectedUpdate.title === 'Welcome to the Pro-fresh academic mentoring programme' ? (
          <WelcomeProfresh 
          menteeName={menteeName}  
          projectTitle={projectTitle}
          startDate={startDate}
          endDate={endDate}
          />
        ) : selectedUpdate && selectedUpdate.title === 'Programme Documentation' ? (
          <ProgrammeDocumentation />
        ) : selectedUpdate && selectedUpdate.title === 'Resources Portal' ? (
          <EPQResourceLibrary />
        ) : selectedUpdate && selectedUpdate.title === 'Safeguarding Incident Form' ? (
          <IncidentForm />
        ) : selectedUpdate && selectedUpdate.title === 'Training' ? (
          <EPQTrainingVideo />
        ) : selectedUpdate && selectedUpdate.title === 'Upload Information' ? (
          <CreateMentorProfile />
        ) : selectedUpdate && selectedUpdate.title === 'Edit Mentor Profile Information' ? (
          <EditMentorProfile  
            currentUser={editProfileUserId}/>
        ) : (
          <>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  columns: {
    display: 'flex',
    gap: '20px',
  },
  column: {
    flex: 1,
  },
};

export default MentorProgrammeAdminSection;