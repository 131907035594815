import React, { useState, useEffect } from 'react'; 
import { volunteerPageContent } from './volunteerPageContent'; 
import { useNavigate } from 'react-router-dom';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';

const Volunteer = () => {
  const meta = pageMetaTags.Volunteer;
  const navigate = useNavigate()
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [listHover, setListHover] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


const handleClick = () =>{
  navigate('/volunteer/becomeanofficer')
}

  // Responsive styles object
  const styles = {
    container: {
      width: windowWidth > 1100 ? '100%' : '80%',
      maxWidth: '1400px',
      margin: '0 auto',
      padding: '20px'
    },
    columnLayout: {
      display: 'flex',
      flexDirection: windowWidth > 1100 ? 'row' : 'column',
      gap: '2rem'
    },
    columnOne: {
      width: windowWidth > 1100 ? '30%' : '100%',
      paddingBottom: windowWidth <= 1100 ? '5%' : '0',
      order: windowWidth > 1100 ? 1 : 2 // This will put the sidebar on the right on desktop
    },
    columnTwo: {
      width: windowWidth > 1100 ? '75%' : '100%',
      paddingBottom: windowWidth <= 1100 ? '5%' : '0',
      order: windowWidth > 1100 ? 2 : 1 // This will put the main content on the left on desktop
    },
    responsiveColumnOne: {
      marginBottom: '2rem',
      backgroundColor: '#fff',
      padding: '20px',
      borderRadius: '10px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    },
    responsiveColumnTwo: {
      flexDirection: 'column',
      padding: '20px',
      borderRadius: '10px',
    },
    pageList: {
      listStyle: 'none',
      transition: 'all 0.3s ease'
    },
    pageListLink: {
      textDecoration: 'none',
      color: '#333',
      display: 'block',
      borderRadius: '5px',
      transition: 'all 0.3s ease',
      ':hover': {
        color: '#f5f5f5',
        transform: 'translateX(5px)'
      }
    },
    ulPageList:{
      padding: 0,
      margin: 0,
    },
    clearFix: {
      clear: 'both'
    },
    heading: {
      fontSize: windowWidth > 1100 ? '2.5rem' : '2rem',
      marginBottom: '1.5rem',
      color: '#2E5C4F'
    },
    subHeading: {
      fontSize: windowWidth > 1100 ? '1.8rem' : '1.5rem',
      marginTop: '2rem',
      marginBottom: '1rem',
      color: '#2E5C4F'
    }
  };


  const checkpoints = [
    {
      title: 'Off the Curriculum Profresh Launch',
      description: 'Launch of academic mentoring, new website, programme, and mobile application!',
      date: 'October 11th 2024, 18:30PM - 19:00PM'
    },
    {
      title: 'Neuroscience and Psychology Launch',
      description: 'Launch of our first short courses covering Neuroscience and Psychology. Discover the wonders of our own minds via our EDUCO portal.',
      date: 'October 11th 2024, 19:00PM - 19:15PM'
    },
    {
      title: 'Mighty minds: Resilience and wellbeing session',
      description: 'Mental health and wellbeing session with Sinead Reed-Forrester',
      date: 'November 16th 2024, 11:30-12:30'
    }
  ];

  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.columnLayout}>
        <div style={styles.columnTwo}>
          <h1 id="education">{volunteerPageContent.headerOne}</h1>
          <p>{volunteerPageContent.pageIntroduction}</p>
          
          <h2 id="mentoring">{volunteerPageContent.headerTwo}</h2>
          <p>{volunteerPageContent.paraOne}</p>
          
          <h2 id="seminarseries">{volunteerPageContent.headerThree}</h2>
          <p>{volunteerPageContent.paraTwo}</p>
          
          <h2 id="educo">{volunteerPageContent.headerFour}</h2>
          <p>{volunteerPageContent.paraThree}</p>
          
          <h2 id="mightyminds">{volunteerPageContent.headerFive}</h2>
          <p>{volunteerPageContent.paraFour}</p>
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonTwo onClick={handleClick}>
              Volunteer with us1
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
        </div>

        <div style={styles.columnOne}>
          <div style={styles.responsiveColumnOne}>
            <h2>About the Volunteer team:</h2>
            <p>
            The Volunteering team is in charge of recruiting and managing short and long term volunteers for the Charity, whilst establishing partnerships with Universities across the UK. From their recruitment onwards, the team ensures that all volunteers enjoy their time with us and use their volunteering experience to grow personally and professionally.
            </p>
          </div>
          
          <div style={styles.responsiveColumnTwo}>
            <h4>Article quick links:</h4>
            <ul style={styles.ulPageList}>
              {[
                { id: `${volunteerPageContent.pageTitle}`, title: volunteerPageContent.pageTitle },
                { id: `${volunteerPageContent.headerOne}`, title: volunteerPageContent.headerOne },
                { id: `${volunteerPageContent.headerTwo}`, title: volunteerPageContent.headerTwo },
                { id: `${volunteerPageContent.headerThree}`, title: volunteerPageContent.headerThree },
                { id: `${volunteerPageContent.headerFour}`, title: volunteerPageContent.headerFour },
                { id: `${volunteerPageContent.headerFive}`, title: volunteerPageContent.headerFive }
              ].map(link => (
                <li key={link.id} style={styles.pageList}>
                  <a 
                    href={`#${link.id}`}
                    style={styles.pageListLink}
                  >
                    {link.title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};


export default Volunteer;