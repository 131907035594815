export default {
  id: 'guidedmeditation',
    title: "A guided meditation session before entering an exam",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Nicole Curtis",
      image: '',
      bio: "I take care of all Marketing and Communications at Off the Curriculum. I was born in London but moved to Spain as a child, growing up there until university. Currently, I am an MSc student reading Psychology and Neuroscience of Mental Health at King’s College London. I work in Child and Adolescent Mental Health and Addiction Rehabilitation. With a passion for education, I jumped at the opportunity to be a part of OTC. Curious by nature, I am passionate about mental health and wellbeing, the creative and performing arts, food, travel, and culture.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-02-06",
      readTime: "5 minutes",
      category: "wellness"
    },
    content: [
      {
        type: "paragraph",
        content: `Welcome! Before you step into the battleground of knowledge and conquer that exam, let's take a moment to find your inner calm and boost your focus with a guided meditation session. Don't worry, this isn't your typical "close your eyes and float on a cloud" kind of meditation. It's a scientifically backed technique that can help you unleash your full potential. Ready? Let's dive in!`
      },
      {
        type: "paragraph",
        content: `Find a comfortable spot where you won't be disturbed. Sit with your feet grounded on the floor and your back straight, like a superhero ready to save the day. Take a deep breath in through your nose, and exhale through your mouth with a satisfying "haaa." Feel the tension leaving your body as you settle into the present moment.`
      },
      {
        type: "paragraph",
        content: `Now, let's activate your exam superpowers! Bring your attention to your breath. Notice the cool air entering your nostrils as you inhale, and the warm air leaving your body as you exhale. With each breath, imagine your mind becoming sharper and more focused, like a laser beam of knowledge.`
      },
      {
        type: "paragraph",
        content: "Now, we're going to try a simple breath work technique called the 4-7-8 breath. Inhale deeply through your nose for 4 seconds, hold your breath for 7 seconds, and then exhale slowly through your mouth for 8 seconds. Repeat this cycle for a few minutes, focusing solely on your breath."
      },
      {
        type: "heading",
        content: `As you continue to breathe, let's engage your senses. Visualize yourself in your favorite study spot, surrounded by books, notes, and your trusted study buddies. See yourself flipping through the pages with ease, absorbing information effortlessly. You are the master of your subject, and the exam is simply a chance to showcase your brilliance.`
      },
      {
        type: "paragraph",
        content: `Now, let's add some auditory magic to your meditation. Imagine hearing the sounds of success. Close your eyes and listen to the applause of your peers as you confidently answer questions. Hear the words of praise from your professors, acknowledging your hard work and dedication. Let these sounds of triumph boost your confidence and motivate you to do your best.`
      },
      {
        type: "paragraph",
        content: `As we progress through your meditation, let's engage your emotions. Feel the excitement and eagerness bubbling within you. Embrace the thrill of the challenge, knowing that you are fully equipped to overcome any obstacle. Visualize a golden shield of confidence surrounding you, protecting you from doubt and anxiety.`
      },
      {
        type: "paragraph",
        content: `Now, it's time to tap into your inner wisdom. Imagine a wise mentor or role model standing before you. It could be a historical figure, a fictional character, or even your future self. Seek their guidance and let their wisdom flow through you. Feel their presence supporting you, whispering words of encouragement and reminding you of your incredible potential.`
      },
      {
        type: "paragraph",
        content: `Finally, take a moment to express gratitude. Acknowledge your hard work, the lessons you've learned, and the growth you've achieved. Appreciate the opportunity to demonstrate your knowledge and the privilege of education.`
      },      
      {
        type: "paragraph",
        content: `As you open your eyes and prepare to face the exam, carry this sense of calm, focus, and confidence with you. Remember, you have the power within you to conquer any challenge that comes your way. Believe in yourself, take a deep breath, and let your inner brilliance shine!`
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };