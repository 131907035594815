import React, {useState} from 'react';

const styles = {
  newsSection: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gridGap: '20px',
    marginBottom: '10px',
    marginTop: '10px',
  },
  newsCard: {
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease'
  },
  imageContainer: {
    width: '100%',
    height: '200px',
    marginBottom: '15px',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  categories: {
    display: 'flex',
    gap: '10px',
    marginBottom: '10px',
    flexWrap: 'wrap',
  },
  category: {
    backgroundColor: '#f2f2f2',
    padding: '5px 10px',
    borderRadius: '20px',
    fontSize: '14px',
    textAlign: 'center',
  },
  title: {
    marginBottom: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
  },
  date: {
    color: '#888',
    fontSize: '14px',
    marginBottom: '10px',
  },
  readMore: {
    color: '#1F484C',
    textDecoration: 'none',
    fontSize: '14px',
    alignSelf: 'flex-end',
  },
  newsCardHovered: {
    transform: 'translateY(-8px)',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
};

const NewsCard = ({ title, category1, category2, category3, date, readMoreLink, image }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div 
      style={{
        ...styles.newsCard,
        ...(isHovered ? styles.newsCardHovered : {})
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div style={styles.imageContainer}>
        <img
          src={image}
          alt={title}
          style={styles.image}
        />
      </div>
      <div style={styles.categories}>
        <span style={styles.category}>{category1}</span>
        <span style={styles.category}>{category2}</span>
        <span style={styles.category}>{category3}</span>
      </div>
      <h3 style={styles.title}>{title}</h3>
      <p style={styles.date}>{date}</p>
      <a 
        href={readMoreLink} 
        style={{
          ...styles.readMore,
          ...(isHovered ? styles.readMoreHovered : {})
        }}
      >
        read more &gt;
      </a>
    </div>
  );
};


const NewsComponent = () => {
  const newsItems = [
    {
      title: 'Advice for young people: Unleashing the Power of Self-Gratitude',
      category1: 'Social News',
      category2: 'Social News',
      category3: 'Environment',
      date: 'Joseph Levermore | 17/05/2023',
      readMoreLink: '/news/adviceforyoungpeoplepowerofselfgratitude',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Funleashing_the_power_of_self_gratitude%20.webp?alt=media&token=58e48af3-66eb-4ffa-aa3f-dca606d11e3e'
    },
    {
      title: 'Young. Black. And Neurodiverse.',
      category1: 'Technology',
      category2: 'Environment',
      category3: 'Innovation',
      date: 'Joseph Levermore | 06/02/2023',
      readMoreLink: '/news/youngblackandneurodiverse',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fyoung_black_neurodiversity.webp?alt=media&token=0f386307-ca11-4788-a46b-5b4b714229fa'
    },
    {
      title: 'Advice for young people: Best revision techniques',
      category1: 'Science',
      category2: 'Space',
      category3: 'Exploration',
      date: 'Joseph Levermore | 17/05/2023',
      readMoreLink: '/news/adviceforyoungpeoplebestrevisiontechniques',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fbest_revision_techniques.webp?alt=media&token=d8bf63ef-e282-46ef-b139-543dfc7d0af0'
    },
  ];

  return (
    <div style={styles.newsSection}>
      {newsItems.map((newsItem, index) => (
        <NewsCard
          key={index}
          title={newsItem.title}
          category1={newsItem.category1}
          category2={newsItem.category2}
          category3={newsItem.category3}
          date={newsItem.date}
          readMoreLink={newsItem.readMoreLink}
          image={newsItem.image}
        />
      ))}
    </div>
  );
};

export default NewsComponent;