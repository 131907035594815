import { color } from 'framer-motion';
import React, { useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import useAuth from '../../../Firebase/Auth';
import usePolicyDocument from './function/fetchPolicy';
import { useLocation } from 'react-router-dom';
if (typeof window !== 'undefined' && !pdfjs.GlobalWorkerOptions.workerSrc) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
}

const PolicyViewer = ({policyId, resources, title}) => {
  const location = useLocation();
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [name, setName] = useState('');
  const currentUser = useAuth();
  const policyDetails = location.state?.policyDetails || 
  resources.find(resource => resource.id === policyId);


  const { documentUrl, loading, error } = usePolicyDocument(currentUser, policyDetails.id);

  const styles = {
    container: {
      maxWidth: '1000px',
      margin: '40px auto',
      padding: '32px',
      backgroundColor: '#ffffff',
      borderRadius: '16px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
    },
    header: {
      marginBottom: '32px',
      textAlign: 'center',
    },
    title: {
      fontSize: '2.5rem',
      fontWeight: '700',
      color: '#1F484C',
      marginBottom: '16px',
      letterSpacing: '-0.025em',
    },
    subtitle: {
      fontSize: '1.1rem',
      color: '#4B5563',
      lineHeight: '1.6',
      maxWidth: '700px',
      margin: '0 auto',
    },
    pdfContainer: {
      backgroundColor: '#F3F4F6',
      padding: '24px',
      borderRadius: '12px',
      marginBottom: '32px',
    },
    pdfViewer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '24px',
    },
    loadingContainer: {
      padding: '40px',
      textAlign: 'center',
      color: '#6B7280',
      fontSize: '1.1rem',
    },
    errorContainer: {
      padding: '20px',
      backgroundColor: '#FEE2E2',
      color: '#DC2626',
      borderRadius: '8px',
      marginBottom: '20px',
      textAlign: 'center',
    },
    navigationControls: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '16px',
      marginTop: '20px',
      padding: '16px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
    },
    pageInfo: {
      fontSize: '0.95rem',
      color: '#4B5563',
      fontWeight: '500',
    },
    signatureSection: {
      marginTop: '40px',
      padding: '32px',
      backgroundColor: '#F8FAFC',
      borderRadius: '12px',
      border: '1px solid #E2E8F0',
    },
    signatureTitle: {
      fontSize: '1.25rem',
      fontWeight: '600',
      color: '#1F484C',
      marginBottom: '16px',
    },
    inputContainer: {
      marginBottom: '24px',
    },
    input: {
      width: '100%',
      padding: '12px 16px',
      fontSize: '1rem',
      borderRadius: '8px',
      border: '2px solid #E2E8F0',
      transition: 'border-color 0.2s ease',
      '&:focus': {
        outline: 'none',
        borderColor: '#1F484C',
      },
    },
    submitSection: {
      marginTop: '24px',
      textAlign: 'center',
    }

  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePreviousPage = () => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    if (!name) {
      alert('Please enter your name before submitting.');
      return;
    }
    //console.log('Submitting:', { name });
    alert('Welcome pack submitted successfully!');
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>{title}</h1>
        <p style={styles.subtitle}>
          Please review the following job description carefully. Your digital signature will be required at the end.
        </p>
      </header>

      <div style={styles.pdfContainer}>
        {loading && (
          <div style={styles.loadingContainer}>
            <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-gray-900 mx-auto mb-4"></div>
            <p>Loading your document...</p>
          </div>
        )}
        
        {error && (
          <div style={styles.errorContainer}>
            <p>{error}</p>
          </div>
        )}

        {documentUrl && (
          <div style={styles.pdfViewer}>
            <Document
              file={documentUrl}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <div style={styles.loadingContainer}>
                  <p>Preparing document...</p>
                </div>
              }
            >
              <Page 
                pageNumber={pageNumber}
                renderTextLayer={false}
                renderAnnotationLayer={false}
                width={Math.min(700, window.innerWidth - 64)}
                className="shadow-lg"
              />
            </Document>

            <div style={styles.navigationControls}>
              <StandardButtonContainer>
                <StandardButtonContainer.ButtonOne
                  onClick={handlePreviousPage} 
                  disabled={pageNumber <= 1}
                >
                  Previous Page
                </StandardButtonContainer.ButtonOne>
                <span style={styles.pageInfo}>Page {pageNumber} of {numPages}</span>
                <StandardButtonContainer.ButtonOne
                  onClick={handleNextPage} 
                  disabled={pageNumber >= numPages}
                >
                  Next Page
                </StandardButtonContainer.ButtonOne>
              </StandardButtonContainer>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PolicyViewer;