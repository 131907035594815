import { Link } from "react-router-dom";
import { styles } from "../../styles";
import { motion } from "framer-motion";
import { IoEyeOutline } from "react-icons/io5";
import { useState, useEffect } from "react";

const RecommendedComponent = ({ nextVideos, onVideoClick }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 1441);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 1441);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: isSmallScreen ? 'row' : 'column',
    gap: '50px',
    ...(isSmallScreen && {
      overflowX: 'auto',
      scrollBehavior: 'smooth',
      WebkitOverflowScrolling: 'touch',
      padding: '10px 0',
      scrollbarWidth: 'none',
      msOverflowStyle: 'none',
      '&::-webkit-scrollbar': {
        display: 'none'
      }
    })
  };

  const cardStyle = {
    ...(styles.videoItemStyle || {}), // Prevent null/undefined error
    ...(isSmallScreen && {
      flex: '0 0 280px',
      minWidth: '280px',
    })
  };

  const loadingContainerStyle = {
    display: 'flex',
    flexDirection: isSmallScreen ? 'row' : 'column',
    gap: '20px',
    overflowX: isSmallScreen ? 'auto' : 'visible',
    scrollBehavior: 'smooth',
    WebkitOverflowScrolling: 'touch',
    padding: '10px 0',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const videoLink = {
    textDecoration: 'none',
    ...(isSmallScreen && {
      flex: '0 0 auto'
    })
  };

  const handleLinkClick = (item) => {
    onVideoClick(item);
  };

  return (
    <div style={containerStyle}>
      {nextVideos === null ? (
        <div style={styles.recommendOverview || {}}>
          <h3 style={styles.educoTitle || {}}>For you:</h3>
          <div style={loadingContainerStyle}>
            {[1, 2, 3, 4, 5].map((data) => (
              <motion.div
                style={cardStyle}
                whileHover={{ scale: 1.02, y: -5 }}
                initial={{ scale: 1.0 }}
                transition={{ type: "spring", stiffness: 300 }}
                key={data}
              >
                <div style={{ width: '100%', height: '100%' }}>
                  <div style={styles.thumbnailStyle || {}}></div>
                  <div style={styles.videoInfoStyle || {}}>
                    <h3 style={styles.videoTitle || {}}>Loading</h3>
                    <div style={styles.videoDetailsStyle || {}}>
                      <span style={styles.videoSpan || {}}>Loading</span>
                      <span style={styles.videoSpan || {}}>
                        Loading <IoEyeOutline style={{ color: '#000' }} size={15} />
                      </span>
                    </div>
                  </div>
                </div>
              </motion.div>
            ))}
          </div>
        </div>
      ) : (
        nextVideos.map((item, index) => (
          <Link
            to={`/${item.videohref}`}
            style={videoLink}
            onClick={() => handleLinkClick(item)}
            key={index}
          >
            <motion.div
              style={cardStyle}
              whileHover={{ scale: 1.02, y: -5 }}
              initial={{ scale: 1.0 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <div style={{ width: '100%', height: '100%' }}>
                <img
                  src={item.videoImage}
                  alt=""
                  style={styles.thumbnailLoadedStyle || {}}
                />
                <div style={styles.videoInfoStyle || {}}>
                  <h3 style={styles.videoTitle || {}}>{item.key || 'Loading'}</h3>
                  <div style={styles.videoDetailsStyle || {}}>
                    <span style={styles.videoSpan || {}}>{item.author || 'Loading'}</span>
                    <span style={styles.videoSpan || {}}>
                      {item.count ? (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <span>{item.count}</span>
                          <IoEyeOutline style={{ color: '#000' }} size={15} />
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                          <span>Loading</span>
                          <IoEyeOutline style={{ color: '#000' }} size={15} />
                        </div>
                      )}
                    </span>
                  </div>
                </div>
              </div>
            </motion.div>
          </Link>
        ))
      )}
    </div>
  );
};

export { RecommendedComponent };