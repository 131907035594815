import React, { useState, useEffect, useRef } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../Firebase/firebaseConfig';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import BottomLoader from '../../../../Components/Common/Loading/BottomLoaderComponent';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

const EditMentorProfile = ({ currentUser }) => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [profile, setProfile] = useState({
    name: '',
    specialties: [],
    languages: [],
    bio: '',
    availabilityStatus: 'available',
    weeklyAvailability: {
    Monday: { from: 'none', to: '18:00' },
    Tuesday: { from: 'none', to: '18:00' },
    Wednesday: { from: 'none', to: '18:00' },
    Thursday: { from: 'none', to: '18:00' },
    Friday: { from: 'none', to: '18:00' },
    Saturday: { from: 'none', to: '18:00' },
    Sunday: { from: 'none', to: '18:00' }
  },
    availabilityNotes: ''
  });
  const [newSpecialty, setNewSpecialty] = useState('');
  const [status, setStatus] = useState('');

  const [newLanguage, setNewLanguage] = useState({ name: '', level: '' });
  const [userRoles, setUserRoles] = useState({
    isMentor: false,
    isTeacher: false,
    isOfficer: false,
    hasEPQ: false
  });

  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    card: {
      backgroundColor: '#ffffff',
      borderRadius: '12px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1)',
      padding: '32px',
    },
    header: {
      marginBottom: '32px',
      borderBottom: '2px solid #f3f4f6',
      paddingBottom: '16px',
    },
    title: {
      fontSize: '28px',
      fontWeight: '700',
      color: '#1F484C', // Charity blue color
      marginBottom: '8px',
    },
    subtitle: {
      fontSize: '16px',
      color: '#666',
      fontWeight: '400',
    },
    section: {
      marginBottom: '15px',
      padding: '10px',
    },
    sectionTitle: {
      fontSize: '20px',
      fontWeight: '600',
      color: '#1F484C',
      marginBottom: '16px',
    },
    label: {
      display: 'block',
      marginBottom: '8px',
      fontWeight: '500',
      color: '#4a5568',
      fontSize: '14px',
    },
    input: {
      width: '95%',
      padding: '12px 16px',
      borderRadius: '8px',
      border: '1px solid #e2e8f0',
      marginBottom: '16px',
      fontSize: '16px',
      transition: 'border-color 0.2s ease',
      backgroundColor: '#ffffff',
      '&:focus': {
        outline: 'none',
        borderColor: '#4299e1',
        boxShadow: '0 0 0 3px rgba(66, 153, 225, 0.1)',
      }
    },
    textarea: {
      width: '95%',
      padding: '12px 16px',
      borderRadius: '8px',
      border: '1px solid #e2e8f0',
      marginBottom: '16px',
      fontSize: '16px',
      minHeight: '120px',
      resize: 'vertical',
      backgroundColor: '#ffffff',
      resize: 'none'
    },
    tagContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '8px',
      marginBottom: '16px',
    },
    tag: {
      backgroundColor: '#FDF5E8',
      borderRadius: '20px',
      padding: '6px 12px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      color: '#E79B18',
      border: '1px solid #E79B18',
    },
    removeButton: {
      backgroundColor: 'transparent',
      border: 'none',
      color: '#4a5568',
      cursor: 'pointer',
      padding: '4px 8px',
      fontSize: '16px',
      borderRadius: '50%',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#f7fafc',
      }
    },
    addContainer: {
      display: 'flex',
      gap: '12px',
      alignItems: 'flex-start',
    },
    button: {
      backgroundColor: '#4299e1',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      padding: '12px 24px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '500',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#3182ce',
      }
    },
    submitButton: {
      backgroundColor: '#2c5282',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      padding: '16px 32px',
      cursor: 'pointer',
      width: '100%',
      fontSize: '16px',
      fontWeight: '600',
      transition: 'background-color 0.2s ease',
      '&:hover': {
        backgroundColor: '#2a4365',
      }
    },
    gridContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '10px',
      marginBottom: '24px',
    },
    fileInputContainer: {
      border: '2px dashed #e2e8f0',
      borderRadius: '8px',
      padding: '32px',
      textAlign: 'center',
      cursor: 'pointer',
      transition: 'border-color 0.2s ease',
      '&:hover': {
        borderColor: '#4299e1',
      }
    },
    availabilityGrid: {
      display: 'grid',
      gridTemplateColumns: '120px 1fr 1fr',
      gap: '12px',
      alignItems: 'center',
      padding: '12px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      marginBottom: '8px',
    },
    dayLabel: {
      fontWeight: '500',
      color: '#4a5568',
    },
    select: {
      padding: '8px 12px',
      borderRadius: '6px',
      border: '1px solid #e2e8f0',
      backgroundColor: '#ffffff',
      width: '100%',
    },
    profileImagePreview: {
      width: '150px',
      height: '150px',
      objectFit: 'cover',
      borderRadius: '50%',
      border: '4px solid #ffffff',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    cropperContainer: {
      marginTop: '24px',
      padding: '24px',
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.05)',
    }
  };
  


  useEffect(() => {
    const fetchMentorProfile = async () => {
      if (currentUser) {
        setIsLoading(true)
        try {
            const getMentorProfile = httpsCallable(functions, 'get_mentor_profile');
            const profileResult = await getMentorProfile({ userId: currentUser });
            //console.log(profileResult)
            setStatus(profileResult.data.success)

            if (profileResult.data.profile) {
              setProfile({
                availableDates: profileResult.data.profile.availableDates || '',
                category: profileResult.data.profile.category || '',
                email: profileResult.data.profile.email || '',
                languages: profileResult.data.profile.languages || [],
                linkedin: profileResult.data.profile.linkedin || '',
                name: profileResult.data.profile.name || '',
                profileImage: profileResult.data.profile.profileImage || '',
                specialties: profileResult.data.profile.specialties || [],
                teachingMethod: profileResult.data.profile.teachingMethod || '',
                bio: profileResult.data.profile.bio || '',
                isAvailable: profileResult.data.profile.isAvailable || '',
                availabilityStatus: profileResult.data.profile.availabilityStatus || 'available',
                weeklyAvailability: profileResult.data.profile.weeklyAvailability || {
                  Monday: { from: 'none', to: '18:00' },
                  Tuesday: { from: 'none', to: '18:00' },
                  Wednesday: { from: 'none', to: '18:00' },
                  Thursday: { from: 'none', to: '18:00' },
                  Friday: { from: 'none', to: '18:00' },
                  Saturday: { from: 'none', to: '18:00' },
                  Sunday: { from: 'none', to: '18:00' }
                },
                availabilityNotes: profileResult.data.profile.availableDates || ''
              });
        }} 
        catch (error) {
        //console.error("Error fetching mentor profile:", error);
      }
    };
    setIsLoading(false)
  }

    fetchMentorProfile();
  }, [currentUser]);


  const handleSpecialtyRemove = (specialtyToRemove) => {
    const currentSpecialties = profile.specialties ? profile.specialties.split(';').map(s => s.trim()) : [];
    const updatedSpecialties = currentSpecialties
      .filter(specialty => specialty !== specialtyToRemove)
      .join(';');
    setProfile(prev => ({
      ...prev,
      specialties: updatedSpecialties
    }));
  };

  const getCropData = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    if (typeof cropperRef.current?.cropper !== "undefined") {
      setCroppedImage(cropperRef.current?.cropper.getCroppedCanvas().toDataURL());
    }
  };
  const triggerFileInput = (e) => {
    if (e) e.preventDefault();
    e.stopPropagation();  // Stop event bubbling
    fileInputRef.current.click();
  };

  const handleLanguageAdd = (e) => {
    if (e) e.preventDefault();
    if (newLanguage.name && newLanguage.level) {
      const combinedLanguage = `${newLanguage.name} (${newLanguage.level})`;
      const currentLanguages = profile.languages ? profile.languages.split(';').map(l => l.trim()) : [];
      if (!currentLanguages.includes(combinedLanguage)) {
        const updatedLanguages = [...currentLanguages, combinedLanguage].join(';');
        setProfile(prev => ({
          ...prev,
          languages: updatedLanguages
        }));
        setNewLanguage({ name: '', level: '' });
      }
    }
  };
  const handleSpecialtyAdd = (e) => {
    if (e) e.preventDefault();
    if (newSpecialty) {
      const currentSpecialties = profile.specialties ? profile.specialties.split(';').map(s => s.trim()) : [];
      if (!currentSpecialties.includes(newSpecialty)) {
        const updatedSpecialties = [...currentSpecialties, newSpecialty].join(';');
        setProfile(prev => ({
          ...prev,
          specialties: updatedSpecialties
        }));
        setNewSpecialty('');
      }
    }
  };  

  const handleLanguageRemove = (languageToRemove) => {
    const currentLanguages = profile.languages ? profile.languages.split(';').map(l => l.trim()) : [];
    const updatedLanguages = currentLanguages
      .filter(language => language !== languageToRemove)
      .join(';');
    setProfile(prev => ({
      ...prev,
      languages: updatedLanguages
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (currentUser && profile.category) {
      if (isSaving) return; // Prevent multiple submissions
      
      setIsSaving(true); // Start loading state
      try {
        const updatedProfile = {
          name: profile.name,
          email: profile.email,
          category: profile.category,
          linkedin: profile.linkedin,
          profileImage: croppedImage || profile.profileImage,
          teachingMethod: profile.teachingMethod,
          specialties: Array.isArray(profile.specialties) ? profile.specialties.join(';') : profile.specialties,
          languages: Array.isArray(profile.languages) ? profile.languages.join(';') : profile.languages,
          bio: profile.bio,
          availabilityStatus: profile.availabilityStatus,
          weeklyAvailability: profile.weeklyAvailability,
          availabilityNotes: profile.availabilityNotes,
          lastUpdated: new Date().toISOString()
        };

        const updateMentorProfile = httpsCallable(functions, 'update_mentor_profile');
        const result = await updateMentorProfile({ 
          userId: currentUser,
          profileData: updatedProfile,
          category: profile.category
        });

        if (result.data.success) {
          alert('Profile updated successfully!');
        } else {
          throw new Error(result.data.message || 'Failed to update profile');
        }
      } catch (error) {
        alert(`Error updating profile: ${error.message}`);
      } finally {
        setIsSaving(false); // Reset loading state whether successful or not
      }
    } else {
      alert('Please select a category before saving');
    }
};

  if (isLoading) {
    return (
      <>
      <BottomLoader 
        isLoading={true}
        message="Loading your mentor profile..."
        backgroundColor={"rgba(255, 255, 255, 0.95)"}
        textColor={"#FFFFFF"}
      />
    </>
    )
  }
  // Only render if user is a mentor
  if (!status) {
    return <div style={styles.container}>You have yet to upload a profile. Please do so in the 'Upload Information' section. </div>;
  }

  const formatSpecialty = (specialty) => {
    const matches = specialty.match(/(.*?)\s*\((.*?)\)/);
    if (matches) {
      const [, spec, level] = matches;
      return (
        <span className="languageStyle languageTag">
          <strong>{spec.trim()}</strong>
          <span className="languageTag">({spec.trim()})</span>
        </span>
      );
    }
    return <span className="languageStyle languageTag">{specialty}</span>;
  };

  const formatLanguage = (language) => {
    const matches = language.match(/(.*?)\s*\((.*?)\)/);
    if (matches) {
      const [, lang, level] = matches;
      return (
        <span className="languageStyle languageTag">
          <strong>{lang.trim()}</strong>
          <span className="languageTag">({level.trim()})</span>
        </span>
      );
    }
    return <span className="languageStyle languageTag">{language}</span>;
  };
  const cropperOptions = {
    viewMode: 1,
    aspectRatio: 1,
    minCropBoxWidth: 250,
    minCropBoxHeight: 250,
    cropBoxResizable: false,
    data: { 
      width: 250,
      height: 250
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    const file = e.target.files?.[0];
    //console.log('File selected:', file);
    
    if (file) {
      if (file.type.startsWith('image/')) {
        const reader = new FileReader();
        reader.onload = () => {
          //console.log('FileReader onload triggered');
          setImage(reader.result);
          //console.log('Image state set to:', reader.result.substring(0, 100) + '...');
          // Reset cropped image when new image is selected
          setCroppedImage(null);
        };
        reader.onerror = (error) => {
          //console.error('FileReader error:', error);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Please select an image file');
      }
    }
  };

  return (
    <div style={styles.container}>
      <form style={styles.form} onSubmit={handleSubmit}>
          <div style={styles.header}>
            <h2 style={styles.title}>Edit Profile</h2>
          </div>

          <div style={styles.section}>
            <label style={styles.label}>Email</label>
            <input
              type="email"
              style={styles.input}
              value={profile.email}
              onChange={(e) => setProfile(prev => ({ ...prev, email: e.target.value }))}
            />
          </div>

          <div style={styles.section}>
            <label style={styles.label}>Name</label>
            <input
              type="text"
              style={styles.input}
              value={profile.name}
              onChange={(e) => setProfile(prev => ({ ...prev, name: e.target.value }))}
            />
          </div>

          <div style={styles.section}>
            <label style={styles.label}>Category</label>
            <select
              style={styles.input}
              value={profile.category}
              onChange={(e) => setProfile(prev => ({ ...prev, category: e.target.value }))}
            >
              <option value="">Select Category</option>
              <option value="stem">STEM</option>
              <option value="humanities">Humanities</option>
            </select>
          </div>

          <div style={styles.section}>
            <label style={styles.label}>LinkedIn Profile</label>
            <input
              type="url"
              style={styles.input}
              value={profile.linkedin}
              onChange={(e) => setProfile(prev => ({ ...prev, linkedin: e.target.value }))}
              placeholder="https://linkedin.com/in/your-profile"
            />
          </div>


          <div style={styles.section}>
            <h2>Profile Photo</h2>
            <div style={styles.fileInputContainer}>
              <div 
                onClick={(e) => triggerFileInput(e)} 
                style={{ cursor: 'pointer' }}
              >
                <img 
                  src={profile.profileImage || '/placeholder-avatar.png'} 
                  style={styles.profileImagePreview}
                  alt="Profile"
                />
                <span style={styles.fileInputText}>
                  {image ? '1 file selected' : 'Click to update image'}
                </span>
              </div>
              <input 
                type="file" 
                ref={fileInputRef}
                onChange={handleImageChange} 
                accept="image/*" 
              />
            </div>
            {console.log('Image state:', image)}
            {image && (
              <>
                {console.log('Attempting to render Cropper')}
                <Cropper
                  ref={cropperRef}
                  style={{ height: 400, width: '100%', marginTop: '10px' }}
                  zoomTo={0.5}
                  src={image}
                  viewMode={1}
                  aspectRatio={1}
                  minCropBoxWidth={250}
                  minCropBoxHeight={250}
                  cropBoxResizable={false}
                  guides={false}
                />
                <StandardButtonContainer.ButtonOne
                  onClick={(e) => getCropData(e)}
                  type="button"
                  style={{marginTop: '10px'}}
                >
                  Crop Image (250x250)
                </StandardButtonContainer.ButtonOne>
              </>
            )}
            {croppedImage && (
              <div style={{marginTop: '20px'}}>
                <h3>Cropped Image Preview</h3>
                <img 
                  src={croppedImage} 
                  alt="Cropped" 
                  style={{ width: '250px', height: '250px', objectFit: 'cover' }} 
                />
              </div>
            )}
          </div>

          <div style={styles.section}>
            <label style={styles.label}>Teaching Method</label>
            <select
              style={styles.input}
              value={profile.teachingMethod}
              onChange={(e) => setProfile(prev => ({ ...prev, teachingMethod: e.target.value }))}
            >
              <option value="">Select Teaching Method</option>
              <option value="online">Online</option>
              <option value="inPerson">In Person</option>
              <option value="both">Both</option>
            </select>
          </div>

          <div style={styles.section}>
          <label style={styles.label}>Availability Status</label>
          <select
            style={styles.input}
            value={profile.availabilityStatus || 'available'}
            onChange={(e) => setProfile(prev => ({ 
              ...prev, 
              availabilityStatus: e.target.value 
            }))}
          >
            <option value="available">Available for Meetings</option>
            <option value="unavailable">Currently Unavailable</option>
          </select>

          {profile.availabilityStatus !== 'unavailable' && (
            <>
              <label style={{...styles.label, marginTop: '16px'}}>Weekly Availability</label>
              <div style={{display: 'grid', gap: '12px'}}>
                {['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'].map((day) => (
                  <div key={day} style={{
                    display: 'grid',
                    gridTemplateColumns: '120px 1fr 1fr',
                    gap: '8px',
                    alignItems: 'center'
                  }}>
                    <label style={{fontWeight: '500'}}>{day}</label>
                    <select
                      style={{...styles.input, marginBottom: 0}}
                      value={profile.weeklyAvailability?.[day]?.from || 'none'}
                      onChange={(e) => setProfile(prev => ({
                        ...prev,
                        weeklyAvailability: {
                          ...prev.weeklyAvailability,
                          [day]: {
                            ...prev.weeklyAvailability?.[day],
                            from: e.target.value
                          }
                        }
                      }))}
                    >
                      <option value="none">Not Available</option>
                      {Array.from({length: 24}, (_, i) => {
                        const hour = i.toString().padStart(2, '0');
                        return (
                          <option key={hour} value={`${hour}:00`}>
                            {`${hour}:00`}
                          </option>
                        );
                      })}
                    </select>
                    {profile.weeklyAvailability?.[day]?.from !== 'none' && (
                      <select
                        style={{...styles.input, marginBottom: 0}}
                        value={profile.weeklyAvailability?.[day]?.to}
                        onChange={(e) => setProfile(prev => ({
                          ...prev,
                          weeklyAvailability: {
                            ...prev.weeklyAvailability,
                            [day]: {
                              ...prev.weeklyAvailability?.[day],
                              to: e.target.value
                            }
                          }
                        }))}
                      >
                        {Array.from({length: 24}, (_, i) => {
                          const hour = i.toString().padStart(2, '0');
                          return (
                            <option 
                              key={hour} 
                              value={`${hour}:00`}
                              disabled={parseInt(hour) <= parseInt(profile.weeklyAvailability?.[day]?.from)}
                            >
                              {`${hour}:00`}
                            </option>
                          );
                        })}
                      </select>
                    )}
                  </div>
                ))}
              </div>

              <label style={{...styles.label, marginTop: '16px'}}>Additional Availability Notes</label>
              <textarea
                style={styles.textarea}
                value={profile.availabilityNotes}
                onChange={(e) => setProfile(prev => ({ 
                  ...prev, 
                  availabilityNotes: e.target.value 
                }))}
                placeholder="Add any additional notes about your availability (e.g., preferred meeting duration, break times, etc.)"
                rows={3}
              />
            </>
          )}
        </div>
          <div style={styles.section}>
            <label style={styles.label}>Specialties</label>
            <div style={styles.tagContainer}>
            {profile.specialties?.split(';')
                .map(specialty => specialty.trim())
                .map((specialty, index) => (
                  <>
                  <div style={styles.tag} key={index}>
                    {formatSpecialty(specialty)}
                  </div>
                  <button
                    type="button"
                    style={styles.removeButton}
                    onClick={() => handleSpecialtyRemove(specialty)}
                  >
                    ×
                  </button>
                  </>
                ))}
            </div>
            <div style={styles.addContainer}>
              <input
                type="text"
                style={{ ...styles.input, marginBottom: 0 }}
                value={newSpecialty}
                onChange={(e) => setNewSpecialty(e.target.value)}
                placeholder="Add new specialty"
              />
              <StandardButtonContainer.ButtonOne
                type="button"
                onClick={handleSpecialtyAdd}
              >
                Add
              </StandardButtonContainer.ButtonOne>
            </div>
          </div>

          <div style={styles.section}>
            <label style={styles.label}>Languages</label>
            <div style={styles.tagContainer}>
              {profile.languages?.split(';')
                .filter(language => language.trim()) // Filter out empty strings
                .map((language, index) => (
                  <React.Fragment key={index}>
                    <div style={styles.tag}>
                      {formatLanguage(language.trim())}
                    </div>
                    <button
                      type="button"
                      style={styles.removeButton}
                      onClick={() => handleLanguageRemove(language.trim())}
                    >
                      ×
                    </button>
                  </React.Fragment>
              ))}
            </div>
            <div style={styles.addContainer}>
              <input
                type="text"
                style={{ ...styles.input, marginBottom: 0 }}
                value={newLanguage.name}
                onChange={(e) => setNewLanguage(prev => ({ ...prev, name: e.target.value }))}
                placeholder="Language name"
              />
              <input
                type="text"
                style={{ ...styles.input, marginBottom: 0 }}
                value={newLanguage.level}
                onChange={(e) => setNewLanguage(prev => ({ ...prev, level: e.target.value }))}
                placeholder="Proficiency level"
              />
              <StandardButtonContainer.ButtonOne
                type="button"
                onClick={handleLanguageAdd}
              >
                Add
              </StandardButtonContainer.ButtonOne>
            </div>
          </div>

          <div style={styles.section}>
            <label style={styles.label}>About</label>
            <textarea
              style={styles.textarea}
              value={profile.bio}
              onChange={(e) => setProfile(prev => ({ ...prev, bio: e.target.value }))}
              rows={6}
            />
          </div>

          <StandardButtonContainer>
            <StandardButtonContainer.ButtonTwo  
              type="submit"
              disabled={isSaving}
              style={{
                opacity: isSaving ? 0.5 : 1,
                cursor: isSaving ? 'not-allowed' : 'pointer',
                pointerEvents: isSaving ? 'none' : 'auto',
                backgroundColor: isSaving ? '#6B7280' : ''
              }}
            >
              {isSaving ? 'Updating Profile...' : 'Save Changes'}
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
      </form>
    </div>
  );
};

export default EditMentorProfile;