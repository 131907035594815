import { color } from 'framer-motion';
import React, { useState } from 'react';
import { Document, Page } from 'react-pdf';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import useAuth from '../../../../Firebase/Auth';
import useOfficerDocuments from '../functions/functions';
import { pdfjs } from 'react-pdf';


if (typeof window !== 'undefined' && !pdfjs.GlobalWorkerOptions.workerSrc) {
  pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;
}


const JobDescription = ({ menteeName, projectTitle, startDate, endDate }) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [name, setName] = useState('');

  const currentUser = useAuth();
  const { documentUrl, loading, error } = useOfficerDocuments(currentUser, 'general_officer_job_description');
  //console.log('document', documentUrl)

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const handlePreviousPage = () => {
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  };

  const handleNextPage = () => {
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const handleSubmit = () => {
    if (!name) {
      alert('Please enter your name before submitting.');
      return;
    }
    // Here you would typically send the data to your backend
    //console.log('Submitting:', { name });
    alert('Welcome pack submitted successfully!');
  };
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    header: {
      textAlign: 'center',
      borderBottom: '2px solid #0C111D',
      paddingBottom: '10px',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    title: {
      color: '#1F484C',
      borderBottom: '1px solid #0C111D',
      paddingBottom: '5px',
    },
    info: {
      backgroundColor: '#1F484C',
      color: '#fff',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    contactGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '15px',
    },
    contactCard: {
      backgroundColor: 'white',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    email: {
      color: '#1F484C',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.container}>
        <h1>Your job description</h1>
      <section style={styles.section}>
        <p>
          Please see below your job description. 
        </p>
      </section>
      <section>
        <div style={styles.pdfContainer}>
            {loading && <div>Loading document...</div>}
            {error && <div style={{ color: 'red' }}>{error}</div>}
            {documentUrl && (
              <>
                <Document
                  file={documentUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                  loading="Loading PDF..."
                >
                  <Page 
                    pageNumber={pageNumber}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    width={700}
                  />
                </Document>
                
                <div style={styles.pdfControls}>
                  <StandardButtonContainer>
                    <StandardButtonContainer.ButtonOne
                      onClick={handlePreviousPage} 
                      disabled={pageNumber <= 1}
                    >
                      Previous
                    </StandardButtonContainer.ButtonOne>
                    <span>Page {pageNumber} of {numPages}</span>
                    <StandardButtonContainer.ButtonOne
                      onClick={handleNextPage} 
                      disabled={pageNumber >= numPages}
                    >
                      Next
                    </StandardButtonContainer.ButtonOne>
                  </StandardButtonContainer>
                </div>
              </>
            )}
          </div>

      <div className="name-section">
        <h2>To sign please enter your name:</h2>
        <input
          type="text"
          value={name}
          onChange={handleNameChange}
          placeholder="Your full name"
        />
      </div>
      <StandardButtonContainer>
        <StandardButtonContainer.ButtonTwo
         onClick={handleSubmit}
        >
        Submit 
        </StandardButtonContainer.ButtonTwo>
      </StandardButtonContainer>
      </section>
    </div>
  );
};

export default JobDescription;