import React, { useState } from 'react';
import { styles } from '../PortalHomepage/AdminHomePage';
export const CharityUpdates = ({ updates }) => (
    <div style={styles.companyUpdates}>
      <div style={styles.sectionHeader}>
        <h2 style={styles.sectionTitle}>{updates.title}</h2>
        <button style={styles.closeButton}>✕</button>
      </div>
      <div style={styles.updateDetails}>
        <div style={styles.detailItem}>
          <span style={styles.detailLabel}>Assigned by</span>
          <span style={styles.detailValue}>{updates.assignedBy}</span>
        </div>
        <div style={styles.detailItem}>
          <span style={styles.detailLabel}>Due date</span>
          <span style={styles.detailValue}>{updates.dueDate}</span>
        </div>
        <div style={styles.detailItem}>
          <span style={styles.detailLabel}>Ongoing projects</span>
          <span style={styles.detailValue}>{updates.ongoingProjects}</span>
        </div>
        <div style={styles.detailItem}>
          <span style={styles.detailLabel}>Urgency level</span>
          <span style={styles.urgencyBadge}>{updates.urgencyLevel}</span>
        </div>
      </div>
      {/* Files and Links sections remain the same */}
    </div>
  );
  