import React, { useState, useEffect } from 'react';
// Component
import { images } from '../../General/Sign-In-Registration/Components/SignInWith/images';
// Loading metaTag information
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import ProfileForm from './Components/ProfileForm';

const carouselStyleHeight = 750;

const CreateAUserProfileComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [currentStep, setCurrentStep] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const stepTitles = [
    { title: 'Getting Started!', description: 'Fill in your details to get started' },
    { title: 'Create Your Profile Picture', description: 'Upload your display and cover photos' },
    { title: 'About You', description: 'Tell us about yourself' },
    { title: 'Membership Plans', description: 'Choose the plan that suits you best' },
    { title: 'Review and Submit', description: 'Review your profile details before submitting' },
  ];

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  const meta = pageMetaTags.createAUserProfile;

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '2.5%',
    marginBottom: '2.5%',
  };

  const carouselStyle = {
    width: '80%',
    height: `${carouselStyleHeight}px`,
    top: '50px',
    padding: windowWidth <= 527 ? '20px' : '50px',
    border: '2px solid #ccc',
    borderRadius: '10px',
    backgroundColor: '#fff',
    marginRight: windowWidth <= 527 ? '0px' : '50px',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'auto',
  };

  return (
    <div>
      <MetaTags
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={containerStyle}>
        <div style={carouselStyle}>
          <h1>{stepTitles[currentStep].title}</h1>
          <p>{stepTitles[currentStep].description}</p>
          <ProfileForm currentStep={currentStep} setCurrentStep={setCurrentStep}/>
        </div>
      </div>
    </div>
  );
};

export default CreateAUserProfileComponent;