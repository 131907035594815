import React, { useState } from 'react';
import { MdExpandLess, MdExpandMore, MdCalendarToday, MdVideoCall } from 'react-icons/md';

const CollapsibleStage = ({ stageNumber, title, description, checkpoints = [] }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const toggleExpand = () => setIsExpanded(!isExpanded);

  const styles = {
    container: {
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      margin: '20px 0',
      overflow: 'hidden',
      boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 8px',
    },
    header: {
      backgroundColor: '#fff',
      padding: '20px',
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    headerText: {
      flex: 1,
    },
    stageNumber: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '5px',
    },
    title: {
      fontSize: '24px',
      fontWeight: 'bold',
      margin: '0 0 10px 0',
    },
    description: {
      fontSize: '16px',
      color: '#333',
    },
    content: {
      backgroundColor: '#f9f9f9',
      padding: '20px',
      display: isExpanded ? 'block' : 'none',
    },
    checkpointsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '10px',
      marginBottom: '25px',
    },
    checkpointCard: {
      backgroundColor: '#fff',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '95%',
    },
    checkpointTitle: {
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
      flex: '0 0 auto',
    },
    checkpointDescription: {
      fontSize: '14px',
      color: '#666',
      marginBottom: '15px',
      flex: '1 0 auto',
    },
    dateBox: {
      display: 'flex',
      alignItems: 'center',
      backgroundColor: '#CCE1FF',
      color: '#004099',
      padding: '8px 12px',
      borderRadius: '8px',
      fontSize: '14px',
      marginBottom: '15px',
      flex: '0 0 auto',
    },
    joinButton: {
      backgroundColor: '#1F484C',
      color: '#fff',
      border: 'none',
      borderRadius: '48px',
      padding: '10px 15px',
      fontSize: '14px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      gap: '5px',
      justifyContent: 'center',
      flex: '0 0 auto',
    },
    noCheckpoints: {
      textAlign: 'center',
      padding: '20px',
      color: '#666',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={toggleExpand}>
        <div style={styles.headerText}>
          <div style={styles.stageNumber}>{stageNumber}</div>
          <h2 style={styles.title}>{title}</h2>
          <p style={styles.description}>{description}</p>
        </div>
        {isExpanded ? <MdExpandLess size={24} /> : <MdExpandMore size={24} />}
      </div>
      <div style={styles.content}>
        {Array.isArray(checkpoints) && checkpoints.length > 0 ? (
          <div style={styles.checkpointsGrid}>
            {checkpoints.map((checkpoint, index) => (
              <div key={index} style={styles.checkpointCard}>
                <h3 style={styles.checkpointTitle}>{checkpoint.title}</h3>
                <p style={styles.checkpointDescription}>{checkpoint.description}</p>
                <div style={styles.dateBox}>
                  <MdCalendarToday size={16} style={{ marginRight: '5px' }} />
                  {checkpoint.date}
                </div>
                <button style={styles.joinButton}>
                  <MdVideoCall size={16} />
                  Join Session
                </button>
              </div>
            ))}
          </div>
        ) : (
          <div style={styles.noCheckpoints}>No checkpoints available for this stage.</div>
        )}
      </div>
    </div>
  );
};

export default CollapsibleStage;