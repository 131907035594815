import React from 'react';

const EPQTrainingVideo = () => {
  // Static placeholder data
  const videoData = {
    videoLink: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/mentor_programme%2Ftraining_video%2FInductionSeminarVideo.mp4?alt=media&token=4e02c4cc-8d63-4406-ba3a-2083b9d51eeb",
    videoImage: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/mentor_programme%2Ftraining_video%2FinductionEPQthumbnail.webp?alt=media&token=e31a1451-7d3a-4374-8e3a-6e530dbe9f6d",
    videoImageAlt: "Video Thumbnail",
    count: 1234,
    likes: 567,
    videoDesc: "Please see below an induction video explaining in detail the EPQ programme. In this video, Dr. Joseph Levermore - will explain the programme journey for you and your mentee and offer training and advice on interacting with your student. Upon watching this, if you have any questions, please do email Joseph directly at 'josephlevermore@offthecurriculum.co.uk'.",
    videohref: "watch-video"
  };

  // Section data
  return (
    <div style={styles.container}>
        <h1>
            Training Video
        </h1>
      <div style={styles.videoContainer}>
      <video
        id="videoPlayer"
        controls
        poster={videoData.videoImage}
        alt={videoData.videoImageAlt}
        style={styles.videoThumbnail}
      >
        <source
          src={videoData.videoLink}
          type="video/mp4"
        />
      </video>
      </div>
      <div style={styles.videoInfo}>
        <div style={styles.videoStats}>
          <span>Views: {videoData.count} | </span>
          <span>Likes: {videoData.likes}</span>
        </div>
        <p style={styles.videoDescription}>{videoData.videoDesc}</p>
      </div>
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  videoContainer: {
    position: 'relative',
    width: '100%',
    cursor: 'pointer',
  },
  videoThumbnail: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  playButton: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: '48px',
    color: 'white',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    width: '80px',
    height: '80px',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  videoInfo: {
    marginTop: '20px',
  },
  videoStats: {
    fontSize: '14px',
    color: '#666',
    marginBottom: '10px',
  },
  videoDescription: {
    fontSize: '16px',
    lineHeight: 1.5,
    marginBottom: '20px',
  },
  watchLink: {
    textDecoration: 'none',
  },
  watchButton: {
    backgroundColor: '#0077be',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
  sections: {
    marginTop: '30px',
  },
  section: {
    marginBottom: '20px',
  },
  sectionTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  sectionDescription: {
    fontSize: '14px',
    lineHeight: 1.4,
  },
};

export default EPQTrainingVideo;