import React, { useState, useEffect } from 'react';
import { FaCheck, FaClock, FaLock, FaUnlock } from 'react-icons/fa';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import Tooltip from '../../../../Components/Common/ToolTips/ToolTips';

const StageProgressionControls = ({ 
  currentStage,
  userType,
  onRequestProgression,
  onApproveProgression,
  hasCompletedFeedback,
  progressionStatus
}) => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('info');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // Window resize handler
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Breakpoints
  const isMobile = windowWidth <= 640;
  const isTablet = windowWidth <= 1024 && windowWidth > 640;

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      marginTop: '1rem'
    },
    alert: {
      padding: '1rem',
      borderRadius: '0.5rem',
      marginBottom: '1rem',
      backgroundColor: alertType === 'warning' ? '#FEF3C7' : 
                      alertType === 'success' ? '#D1FAE5' : 
                      '#E0F2FE',
      color: '#1F2937'
    },
    controlsContainer: {
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      alignItems: isMobile ? 'stretch' : 'center',
      gap: isMobile ? '1rem' : '0.5rem',
      backgroundColor: '#F9FAFB',
      borderRadius: '0.5rem',
      padding: isMobile ? '1rem' : '1.5rem'
    },
    stageInfo: {
      flex: 1,
      marginBottom: isMobile ? '1rem' : 0
    },
    stageLabel: {
      fontSize: isMobile ? '1rem' : '0.875rem',
      fontWeight: '500',
      color: '#6B7280',
      marginBottom: isMobile ? '0.5rem' : '0.25rem'
    },
    stageName: {
      color: '#1F2937',
      fontSize: isMobile ? '0.875rem' : 'inherit',
      lineHeight: isMobile ? '1.4' : 'inherit'
    },
    buttonContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: isMobile ? 'stretch' : 'flex-end',
      gap: '0.5rem',
      width: isMobile ? '100%' : 'auto'
    },
    button: {
      width: isMobile ? '100%' : 'auto',
      padding: isMobile ? '0.75rem 1rem' : '0.5rem 1rem',
      fontSize: isMobile ? '1rem' : '0.875rem'
    },
    feedbackMessage: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      fontSize: isMobile ? '0.875rem' : '0.75rem',
      color: '#6B7280',
      marginTop: '0.5rem',
      flexWrap: 'wrap'
    },
    icon: {
      fontSize: isMobile ? '1.25rem' : '1rem',
      marginRight: '0.5rem'
    },
    tooltipContainer: {
      width: isMobile ? '100%' : 'auto'
    }
  };

  const handleProgressionRequest = () => {
    onRequestProgression();
    if (!hasCompletedFeedback) {
      setAlertMessage('Please complete the feedback form before requesting progression');
      setAlertType('warning');
      setShowAlert(true);
      return;
    }
    setAlertMessage('Progression request sent to mentor');
    setAlertType('info');
    setShowAlert(true);
  };

  const handleProgressionApproval = () => {
    onApproveProgression();
    setAlertMessage('Stage progression approved');
    setAlertType('success');
    setShowAlert(true);
  };

  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => setShowAlert(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [showAlert]);

  const getButtonBackgroundColor = (progressionStatus) => {
    switch(progressionStatus) {
      case 'pending':
      case 'approved':
        return '#E5E7EB';
      case 'none':
      default:
        return '#E79B18';
    }
  };

  const renderMenteeControls = () => (
    <>
      <div style={styles.stageInfo}>
        <div style={styles.stageLabel}>
          Ready to move forward?
        </div>
        <div style={styles.stageName}>
          You can request to progress from {currentStage} to the next stage of your EPQ journey. Once you've completed your feedback form, click 'Request Progression' to continue. 
          <b>Before doing so please ensure you and mentor have completed a feedback form for your most recent meeting!</b>
        </div>
      </div>
      
      <div style={styles.buttonContainer}>
        <StandardButtonContainer style={{ width: isMobile ? '100%' : 'auto' }}>
          <StandardButtonContainer.ButtonThree 
            disabled={progressionStatus === 'pending' || progressionStatus === 'approved'}
            onClick={progressionStatus === 'pending' ? undefined : handleProgressionRequest}
            backgroundColor={getButtonBackgroundColor(progressionStatus)}
            style={{ 
              cursor: progressionStatus === 'pending' ? 'not-allowed' : 'pointer',
              width: isMobile ? '100%' : 'auto'
            }}
          >
            {progressionStatus === 'pending' ? (
              <>
                <FaClock style={styles.icon} />
                Pending Approval
              </>
            ) : progressionStatus === 'approved' ? (
              <>
                <FaCheck style={styles.icon} />
                Approved
              </>
            ) : (
              <>
                <FaUnlock style={styles.icon} />
                Request Progression
              </>
            )}
          </StandardButtonContainer.ButtonThree>
        </StandardButtonContainer>
      </div>
    </>
  );

  const renderMentorControls = () => (
    <>
      <div style={styles.stageInfo}>
        <div style={styles.stageLabel}>Mentee Progress Request</div>
        <div style={styles.stageName}>
          {progressionStatus === 'pending' ? (
            <>
              Your mentee has requested to progress from {currentStage} to the next stage. 
              <b>Before approving, please ensure both you and your mentee have completed the feedback forms for your most recent session.</b>
            </>
          ) : (
            <>
              When your mentee requests to progress from {currentStage}, you'll be able to review and approve their progression here.
            </>
          )}
        </div>
      </div>
      
      {progressionStatus === 'pending' ? (
        <div style={styles.buttonContainer}>
          <StandardButtonContainer style={{ width: isMobile ? '100%' : 'auto' }}>
            <StandardButtonContainer.ButtonThree
              onClick={handleProgressionApproval}
              backgroundColor="#1F484C"
              style={{ width: isMobile ? '100%' : 'auto' }}
            >
              <FaCheck style={styles.icon} />
              Approve Progression
            </StandardButtonContainer.ButtonThree>
          </StandardButtonContainer>
        </div>
      ) : (
        <div style={styles.tooltipContainer}>
          <Tooltip text="Ask your mentee to request an upgrade">
            <StandardButtonContainer style={{ width: isMobile ? '100%' : 'auto' }}>
              <StandardButtonContainer.ButtonThree
                backgroundColor="#E5E7EB"
                style={{ width: isMobile ? '100%' : 'auto' }}
              >
                <FaCheck style={styles.icon} />
                Approve Progression
              </StandardButtonContainer.ButtonThree>
            </StandardButtonContainer>
          </Tooltip>
        </div>
      )}
    </>
  );

  return (
    <div style={styles.container}>
      {showAlert && (
        <div style={styles.alert}>
          {alertMessage}
        </div>
      )}
      
      <div style={styles.controlsContainer}>
        {userType === 'mentee' ? renderMenteeControls() : renderMentorControls()}
      </div>
    </div>
  );
};

export default StageProgressionControls;