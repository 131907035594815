import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import BlackButton from '../../../Components/Common/Buttons/BlackButton/ButtonButton';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { useNavigate } from 'react-router-dom';

const BentoBoxComponent = () => {
  const [events, setEvents] = useState([
    {
      title: 'Unleash Your Potential with OTC',
      content: 'Unleash your potential in STEM or Humanities with expert guidance and immersive learning. Choose your path, start exploring!',
      color: '#76BFC6',
      size: 'largeItem',
    },
    {
      title: 'Level up with "Off-the-Track"',
      content: 'Build your soft skills, explore emerging topics, and even create your own learning content!',
      color: '#FFAD33',
      size: 'mediumItem',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FmediumBoxOne%2Fbento-box-medium-1.webp?alt=media&token=4cd77622-2e6b-4513-be8e-50c72e5e530b'
    },
    {
      title: 'Mighty Minds',
      content: 'Breathe in. Breathe out. Join our monthly mental health and well-being sessions.',
      color: '#FF5C5C',
      size: 'mediumItem',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FmediumBoxTwo%2Fbento-content-mighty-minds.webp?alt=media&token=d575d919-85bf-4d65-afa0-c584b3ec6f8a'
    },
    {
      quote: '"... unique academic experience... engaging in high-level discussions with global experts"',
      author: 'Raghad - OTC Diploma Graduate -',
      color: '#FFE5E5',
      size: 'smallItem',
    },
    {
      title: 'We do more...',
      items: [
        {
          title: 'Accreditation',
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FsmallBoxOne%2FCertificateOfExcellence.png?alt=media&token=a68dd1a3-59fb-4931-ab04-a8600c6ac535'
        },
        {
          title: 'Partner Sch...',
          image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FsmallBoxOne%2FpartneredSchools.png?alt=media&token=d31445ea-156c-45d3-95c9-251c9a7825c7'
        }
      ],
      color: '#EDF7F8',
      size: 'smallItem',
    },
  ]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [screenSize, setScreenSize] = useState('desktop');
  const [armsAnimated, setArmsAnimated] = useState(false);
  const [bookRotation, setBookRotation] = useState(0);
  const [scrollPosition, setScrollPosition] = useState(0);
  const navigate = useNavigate();

  const handleEnrolClick = () => {
    navigate('/education/enrol');
  };
  
const handleResize = () => {
  if (window.innerWidth <= 768) {
    setScreenSize('mobile');
  } else if (window.innerWidth <= 1091) {
    setScreenSize('tablet');
  } else if (window.innerWidth <= 1404) {
    setScreenSize('smallDesktop');
  } else {
    setScreenSize('desktop');
  }
};
useEffect(() => {
  handleResize();
  window.addEventListener('resize', handleResize);
  return () => window.removeEventListener('resize', handleResize);
}, []);

const getRandomPosition = () => {
  const randomTop = Math.random() * 100;
  const randomLeft = Math.random() * 100;
  return {
    top: `${randomTop}%`,
    left: `${randomLeft}%`,
  };
};
useEffect(() => {
  const handleScroll = () => {
    const bentoBox = document.querySelector('.bento-box');
    if (bentoBox) {
      const rect = bentoBox.getBoundingClientRect();
      if (rect.top < window.innerHeight && rect.bottom >= 0 && !armsAnimated) {
        setArmsAnimated(true);
        //console.log('Arms animated set to true');
      }
    }
  };

  window.addEventListener('scroll', handleScroll);
  return () => window.removeEventListener('scroll', handleScroll);
}, [armsAnimated]);
const renderEventContent = (event, index) => (
    <>
    {index === 2 ? ( // For Mighty Minds box
      <div style={styles.mightyMindsContainer}>
        <div style={styles.mightyMindsText}>
          <h2 style={styles.mightyMindsHeader}>{event.title}</h2>
          <p style={styles.mightyMindsContent}>{event.content}</p>
        </div>
        <div style={styles.mightyMindsIllustration}>
          <img 
            src={event.image} 
            alt="Mighty Minds Illustration" 
            style={styles.mightyMindsImage}
          />
        </div>
      </div>
    ) : (
      <>
    {index === 1 ? ( // For Off-the-Track box
      <div style={styles.offTrackContainer}>
        <div style={styles.textContent}>
          <h2 style={styles.offTrackHeader}>{event.title}</h2>
          <p style={styles.offTrackContent}>{event.content}</p>
        </div>
        <div style={styles.illustrationContainer}>
          <img 
            src={event.image} 
            alt="Off the Track Illustration" 
            style={styles.illustration}
          />
        </div>
      </div>
      
    ) : (
      <>
        {event.title && <h2 style={styles.header}>{event.title}</h2>}
        {event.content && <p style={styles.content}>{event.content}</p>}
        {event.quote && (
            <div style={styles.quoteContainer}>
              <div style={styles.quoteText}>
                <span style={styles.quoteMark}>"</span>
                <span>... </span>
                <span style={styles.highlightText}>unique</span>
                <span> academic </span>
                <br />
                <span>experience... </span>
                <br />
                <span>engaging in high-level</span>
                <br />
                <span>discussions with</span>
                <br />
                <span style={styles.expertsText}>global experts</span>
                <span style={styles.quoteMark}>"</span>
              </div>
              {event.author && (
                <div style={styles.authorSection}>
                  <span style={styles.authorName}>Raghad</span>
                  <span style={styles.authorTitle}>- OTC Diploma Graduate -</span>
                </div>
              )}
              {/* Add decorative stars */}
              <div style={styles.star1}>✦</div>
              <div style={styles.star2}>✦</div>
              <div style={styles.star3}>✦</div>
            </div>
          )}
        {index === 0 && ( // Only render icons for the large bento box
              <div style={styles.iconsContainer}>
                {/* Render arms first */}
                {/* Render hats and scrolls */}
                {/* Render stars */}
                <div style={styles.circlesContainer}>
                  {[...Array(7)].map((_, index) => (
                      <div key={index} style={{ ...styles.starIcon, ...getRandomPosition() }}>
                          {/* Add your star element here */}
                          <img src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FcircleTwo.svg?alt=media&token=abf7879e-e1cf-47be-96b8-f9997992fed0'} alt="Circle 2" style={styles.starIcon} />
                      </div>
                    ))}
                </div>
                <div style={styles.scrollContainer}>
                <motion.img
                  src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FscrollOne.svg?alt=media&token=e6a1d592-1ff3-420c-82be-87de85b1f39b'}
                  alt="Scroll 1"
                  style={styles.scrollIcon}
                  initial={{ y: 70, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  whileHover={{ rotate: 15, y: -10 }}
                  transition={{ duration: 0.5, delay: 2 * 0.2 }}
                />
                <motion.img
                  src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FscrollTwo.svg?alt=media&token=04c4cbed-348b-4ad7-b2c9-4f2f955f9a86'}
                  alt="Scroll 2"
                  style={styles.scrollIcon}
                  initial={{ y: 70, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  whileHover={{ rotate: -15, y: -10 }}
                  transition={{ duration: 0.5, delay: 2 * 0.2 }}
                />
                </div>
                <div style={styles.starsContainer}>
                {[...Array(10)].map((_, index) => (
                    <div key={index} style={{ ...styles.starIcon, ...getRandomPosition() }}>
                        {/* Add your star element here */}
                        <img src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FstarOne.svg?alt=media&token=495de0aa-b712-49a5-aba8-f6ae1000104c'} alt="Star 1" style={styles.starIcon} />    
                    </div>
                  ))}
                </div>
                <div style={styles.bookContainer}>
                <motion.img 
                  src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FbookOne.svg?alt=media&token=23de4c71-9b13-45d3-94e1-15b14cd1385a'} 
                  alt="Book" 
                  style={styles.bookIcon} 
                  animate={{ rotate: bookRotation }}
                  transition={{ type: "spring", stiffness: 100 }}
                />
              </div>
                <div style={styles.hatsScrollsContainer}>
                <motion.img
                  src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FhatTwo.svg?alt=media&token=02737f4b-b475-485a-8ea0-b82bc88ff32c'}
                  alt="Hat/Scroll 2"
                  style={styles.hatScrollIcon}
                  animate={{ y: [0, -10, 0] }}
                  transition={{ duration: 2, repeat: Infinity, repeatType: 'loop' }}
                />
                <motion.img
                  src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FhatOne.svg?alt=media&token=65171291-cd39-4b11-96a0-c60bb1f9e9a0'}
                  alt="Hat/Scroll 1"
                  style={styles.hatScrollIcon}
                  animate={{ y: [0, -10, 0] }}
                  transition={{ duration: 2, repeat: Infinity, repeatType: 'loop', delay: 1 }}
                />
                </div>
              
                <div style={styles.armsContainer}>
                  <motion.img
                  src={`https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FarmOne.svg?alt=media&token=41d570d5-1b99-491c-be01-ce9bcfddbe07`}
                  alt={`Arm One`}
                  style={styles.armIcon}
                  initial={{ y: 50, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  transition={{ duration: 0.5, delay: 1 * 0.2 }}
                />
                  <motion.img
                  src={`https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FarmTwo.svg?alt=media&token=4ad1791d-46bd-4aba-9a01-126cc98b4d80`}
                  alt={`Arm two`}
                  style={styles.armIcon}
                  initial={{ y: 50, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  transition={{ duration: 0.5, delay: 2 * 0.2 }}
                />
                <motion.img
                  src={`https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FarmThree.svg?alt=media&token=c8363813-ca54-496e-aa33-c5f28b9461fc`}
                  alt={`Arm three`}
                  style={styles.armIcon}
                  initial={{ y: 50, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  transition={{ duration: 0.5, delay: 3 * 0.2 }}
                />
                <motion.img
                  src={`https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FBentobox%2FLargeBox%2FarmFour.svg?alt=media&token=dc321ff2-2956-4ee8-937a-f166de101227`}
                  alt={`Arm four`}
                  style={styles.armIcon}
                  initial={{ y: 50, opacity: 0 }}
                  animate={armsAnimated ? { y: 0, opacity: 1 } : {}}
                  transition={{ duration: 0.5, delay: 4 * 0.2 }}
                />
                </div>
            </div>
        )}
      {(index === 4) && (
        <div style={styles.weDoMoreContainer}>
          <div style={styles.cardContainer}>
            {event.items.map((item, i) => (
              <div key={i} style={styles.card}>
                <img src={item.image} alt={item.title} style={styles.cardImage} />
                <div style={styles.cardOverlay}>
                  <h3 style={styles.cardTitle}>{item.title}</h3>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </>
       )}
  </>
  )}
</>
);

const styles = screenSize === 'mobile' ? mobileStyles : 
screenSize === 'tablet' ? tabletStyles :
screenSize === 'smallDesktop' ? smallDesktopStyles :
desktopStyles;

return (
    <div style={styles.container}>
        <div style={styles.columnOne}>
            <h1>The World of Learning Outside the Classroom</h1>
        </div>
        <div style={styles.columnTwo}>
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonOne
            onClick={handleEnrolClick}
            >
            Get Started
            </StandardButtonContainer.ButtonOne>
          </StandardButtonContainer>
        </div>
        <div className="bento-box" style={styles.bentoBox}>
        {events.map((event, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          whileHover={{
            scale: 1.05,
            zIndex: 2, // Ensures the hovered item appears above others
            transition: { duration: 0.2 },
          }}
          onHoverStart={() => {
            setBookRotation(15);
            setScrollPosition(-20);
          }}
          onHoverEnd={() => {
            setBookRotation(0);
            setScrollPosition(0);
          }}
          transition={{ duration: 0.5, delay: index * 0.1 }}
          style={{
            ...styles.bentoItem,
            ...styles[event.size],
            backgroundColor: event.color,
          }}
        >
          {renderEventContent(event, index)}
        </motion.div>
      ))}
        </div>
    </div>
);
};

const iconContainerBaseStyles = {
  iconsContainer: {
    position: 'absolute',  // Changed from relative to absolute
    bottom: '0',          // Position from bottom instead of using negative calc
    right: '0',          // Align to right
    width: '100%',       // Take full width of parent
    height: '100%',      // Take full height of parent
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    overflow: 'hidden',  // Contain overflow
    pointerEvents: 'none', // Allow clicks to pass through to parent
  },
  circlesContainer: {
    position: 'absolute',
    width: '40%',
    height: '80%',
    bottom: '90px',
    right: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  scrollContainer: {
    position: 'absolute',
    bottom: '70px',
    right: '10px',
    width: '40%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 2,
  },
  bookContainer: {
    position: 'absolute',
    bottom: '130px',
    right: '10px',
    width: '30.5%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 4,
  },
  starsContainer: {
    position: 'absolute',
    width: '45%',
    height: '80%',
    bottom: '90px',
    right: '10px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  hatsScrollsContainer: {
    position: 'absolute',
    bottom: '150px',
    right: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 2,
    width: '40%',
  },
  armsContainer: {
    position: 'absolute',
    bottom: '0',
    right: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    zIndex: 3,
    width: '40%',
  },
};

const desktopStyles = {
  ...iconContainerBaseStyles,
  title :{
    fontSize: 40,
    fontWeight: 'bold',
  }, 
  container: {
    display: 'grid',
    gridTemplateColumns: '3fr 1fr', // This creates a 75% / 25% split
    gridGap: '20px',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    marginTop: '15px',
    marginBottom: '15px',
    alignItems: 'center',
  },
  columnOne: {
    gridColumn: '1 / span 1',
  },
  columnTwo: {
    gridColumn: '2 / span 1',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  bentoBox: {
    gridColumn: '1 / span 2',
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'auto auto',
    gridGap: '1rem',
    padding: '1rem',
    borderRadius: '15px',

  },
  largeItem: {
    gridColumn: 'span 2',
    gridRow: 'span 1',
    height: '400px',
    borderRadius: '15px',
  },
  mediumItem: {
    gridColumn: 'span 1',
    gridRow: 'span 1',
    height: '400px',
    borderRadius: '15px',
  },
  smallItem: {
    gridColumn: 'span 1',
    gridRow: 'span 1',
    height: '400px',
    borderRadius: '15px',
  },
  smallItemContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: '20px',
    paddingRight: '20px',

  },
  smallItemTitle: {
    fontSize: '24px',
    marginBottom: '0.5rem',
  },
  smallItemText: {
    fontSize: '16px',
  },
  header:{
    fontSize: '38px', 
    marginBottom: '0px',
    marginLeft: '15px',
  },
  content:{
    fontSize: '20px', 
    width: '60%',
    marginLeft: '15px',
  }, 
  expandedItem: {
    position: 'absolute',
    left: '10%',
    marginTop: '10%',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    height: '60%',
    zIndex: 3,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    padding: '1rem',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  iconsContainer: {
    position: 'relative',
    bottom: 'calc(100% * 0.32)',
    right: '10px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  circlesContainer:{
    position: 'absolute',
    width: '40%',
    bottom: '90px', 
    marginRight: '10px',
    marginBottom: '15px',
    height: '80%',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  }, 
  armsContainer: {
    position: 'initial',
    bottom: 0,
    left: 0,
    right: 0,
    justifyContent: 'end',
    zIndex: 1,
  },
  hatsScrollsContainer: {
    position: 'initial',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'end',
    zIndex: 2,
  },
  scrollContainer: {
    position: 'absolute',
    bottom: '70px', // Move the scrolls down
    width: '40%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
  },
  bookContainer:{
    position: 'absolute',
    bottom: '130px', // Move the scrolls down
    width: '30.5%',
    height: 'auto',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: '4', 
  },
  starsContainer: {
    position: 'absolute',
    width: '45%',
    height: '80%',
    bottom: '90px', 
    marginRight: '10px',
    marginBottom: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: 1,
  },
  bookIcon:{
    width: '140px'
  },
  starIcon: {
    position: 'absolute',
    width: '20px',
    height: 'auto',
  },
  armIcon: {
    width: '100px',
    height: 'auto',
    margin: '0 -10px',
  },
  hatScrollIcon: {
    width: '100px',
    margin: '0 50px',
    height: 'auto',
  },
  scrollIcon:{
    width: '70px',
    margin: '0 20px',
    height: 'auto',
  }, 
  offTrackContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
  },
  textContent: {
    zIndex: 2,
    padding: '15px',
    height: '45%',  // Fixed height for text area
    position: 'relative',
  },
  offTrackHeader: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#000000',
  },
  offTrackContent: {
    fontSize: '18px',
    lineHeight: '1.5',
    color: '#000000',
    maxWidth: '70%',
  },
  illustrationContainer: {
    position: 'absolute',
    right: '-40px',
    bottom: '40px',
    width: '60%',
    height: '60%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1,
  },
  illustration: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  mightyMindsContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '20px',
    position: 'relative',
    overflow: 'hidden',
  },
  mightyMindsText: {
    zIndex: 2,
    position: 'relative',
    marginBottom: '20px',
  },
  mightyMindsHeader: {
    fontSize: '48px',
    fontWeight: 'bold',
    marginBottom: '15px',
    color: '#000000',
    lineHeight: '1.1',
  },
  mightyMindsContent: {
    fontSize: '24px',
    lineHeight: '1.4',
    color: '#000000',
    maxWidth: '80%',
  },
  mightyMindsIllustration: {
    position: 'absolute',
    right: '-20px',
    bottom: '40px',
    width: '65%',
    height: '65%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    zIndex: 1,
  },
  mightyMindsImage: {
    width: '80%',
    height: 'auto',
    objectFit: 'contain',
  },
  quoteContainer: {
    position: 'relative',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  quoteText: {
    fontSize: '32px',
    lineHeight: '1.2',
    fontWeight: '700',
    marginBottom: '30px',
  },
  quoteMark: {
    fontSize: '48px',
    fontWeight: 'bold',
  },
  highlightText: {
    color: '#EF0000', // Orange-red color
    fontWeight: '800',
  },
  expertsText: {
    color: '#EF0000', // Teal color
    fontWeight: '800',
  },
  authorSection: {
    display: 'flex',
    flexDirection: 'column',
    gap: '5px',
  },
  authorName: {
    fontSize: '24px',
    fontWeight: '700',
  },
  authorTitle: {
    fontSize: '18px',
    color: '#4A4A4A',
  },
  star1: {
    position: 'absolute',
    top: '20%',
    right: '15%',
    fontSize: '24px',
    color: '#FFF', // Purple color
  },
  star2: {
    position: 'absolute',
    bottom: '10%',
    left: '10%',
    fontSize: '24px',
    color: '#FFF',
  },
  star3: {
    position: 'absolute',
    top: '40%',
    right: '20%',
    fontSize: '24px',
    color: '#FFF',
  },
  weDoMoreContainer: {
    paddingRight: '20px',
    paddingLeft: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
  weDoMoreTitle: {
    fontSize: '48px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#000000',
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
    flex: 1,
  },
  card: {
    position: 'relative',
    borderRadius: '15px',
    overflow: 'hidden',
    height: '140px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
    border: '2px solid #F4A261',
  },
  cardImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  cardOverlay: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    padding: '15px',
    background: 'white',
    borderTopLeftRadius: '15px',
    borderTopRightRadius: '15px',
  },
  cardTitle: {
    margin: 0,
    fontSize: '24px',
    fontWeight: 'bold',
    color: '#000000',
    textAlign: 'center',
  },
};
const smallDesktopStyles = {
  ...desktopStyles,
  container: {
    ...desktopStyles.container,
    gridTemplateColumns: '2.5fr 1fr',
    gridGap: '18px',
    padding: '18px',
  },
  bentoBox: {
    ...desktopStyles.bentoBox,
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridGap: '0.85rem',
    padding: '0.85rem',
  },
  largeItem: {
    ...desktopStyles.largeItem,
    height: '380px',
  },
  mediumItem: {
    ...desktopStyles.mediumItem,
    height: '380px',
  },
  smallItem: {
    ...desktopStyles.smallItem,
    height: '380px',
  },
  header: {
    ...desktopStyles.header,
    fontSize: '34px',
  },
  content: {
    ...desktopStyles.content,
    fontSize: '19px',
    width: '65%',
  },
  // Icons and decorative elements adjustments
  iconsContainer: {
    ...desktopStyles.iconsContainer,
    bottom: 'calc(100% * 0.37)',
  },
  circlesContainer: {
    ...desktopStyles.circlesContainer,
    width: '50%',
    bottom: '85px',
  },
  scrollContainer: {
    ...desktopStyles.scrollContainer,
    bottom: '100px',
    width: '45%',
  },
  bookContainer: {
    ...desktopStyles.bookContainer,
    width: '35%',
    bottom: '125px',
  },
  starsContainer: {
    ...desktopStyles.starsContainer,
    width: '50%',
    bottom: '85px',
  },
  bookIcon: {
    ...desktopStyles.bookIcon,
    width: '130px',
  },
  armIcon: {
    ...desktopStyles.armIcon,
    width: '95px',
    margin: '0 -9px',
  },
  hatScrollIcon: {
    ...desktopStyles.hatScrollIcon,
    width: '95px',
    margin: '0 45px',
  },
  scrollIcon: {
    ...desktopStyles.scrollIcon,
    width: '65px',
    margin: '0 18px',
  },
  // Off-track box adjustments
  offTrackContainer: {
    ...desktopStyles.offTrackContainer,
    padding: '19px',
  },
  textContent: {
    ...desktopStyles.textContent,
    width: '75%',
  },
  offTrackHeader: {
    ...desktopStyles.offTrackHeader,
    fontSize: '30px',
  },
  offTrackContent: {
    ...desktopStyles.offTrackContent,
    fontSize: '17px',
    maxWidth: '95%',
  },
  illustrationContainer: {
    ...desktopStyles.illustrationContainer,
    right: '-50px',
    bottom: '38px',
    width: '63%',
    height: '63%',
  },
  // Mighty Minds box adjustments
  mightyMindsHeader: {
    ...desktopStyles.mightyMindsHeader,
    fontSize: '44px',
  },
  mightyMindsContent: {
    ...desktopStyles.mightyMindsContent,
    fontSize: '22px',
    maxWidth: '85%',
  },
  mightyMindsIllustration: {
    ...desktopStyles.mightyMindsIllustration,
    width: '63%',
    height: '63%',
  },
  // Quote box adjustments
  quoteContainer: {
    ...desktopStyles.quoteContainer,
    padding: '20px',
    height: '',
  },
  quoteText: {
    ...desktopStyles.quoteText,
    fontSize: '27px',
  },
  quoteMark: {
    ...desktopStyles.quoteMark,
    fontSize: '44px',
  },
  authorName: {
    ...desktopStyles.authorName,
    fontSize: '23px',
  },
  authorTitle: {
    ...desktopStyles.authorTitle,
    fontSize: '17px',
  },
  // We Do More section adjustments
  weDoMoreContainer: {
    ...desktopStyles.weDoMoreContainer,
    paddingRight: '18px',
    paddingLeft: '18px',
  },
  weDoMoreTitle: {
    ...desktopStyles.weDoMoreTitle,
    fontSize: '44px',
  },
  cardContainer: {
    ...desktopStyles.cardContainer,
    gap: '13px',
  },
  card: {
    ...desktopStyles.card,
    height: '135px',
  },
  cardTitle: {
    ...desktopStyles.cardTitle,
    fontSize: '23px',
  },
};

const tabletStyles = {
  ...desktopStyles,
  container: {
    ...desktopStyles.container,
    display: 'grid',
    gridTemplateColumns: '2fr 1fr',
    gridGap: '15px',
    padding: '15px',
    backgroundColor: '#fff',
    borderRadius: '15px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    marginTop: '15px',
    marginBottom: '15px',
    alignItems: 'center',
  },
  bentoBox: {
    ...desktopStyles.bentoBox,
    gridColumn: '1 / span 2',
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridGap: '0.75rem',
    padding: '0.75rem',
    borderRadius: '15px',
  },
  largeItem: {
    ...desktopStyles.largeItem,
    gridColumn: 'span 2',
    height: '100%',
    borderRadius: '15px',
  },
  mediumItem: {
    ...desktopStyles.mediumItem,
    gridColumn: 'span 1',
    height: '350px',
    borderRadius: '15px',
  },
  smallItem: {
    ...desktopStyles.smallItem,
    gridColumn: 'span 1',
    height: '350px',
    borderRadius: '15px',
  },
  header: {
    ...desktopStyles.header,
    fontSize: '32px',
    marginBottom: '0px',
    marginLeft: '15px',
  },
  content: {
    ...desktopStyles.content,
    fontSize: '18px',
    width: '70%',
    marginLeft: '15px',
  },
  iconsContainer: {
    ...desktopStyles.iconsContainer,
    bottom: '0px',
  },
  circlesContainer: {
    ...desktopStyles.circlesContainer,
    width: '60%',
    bottom: '80px',
  },
  scrollContainer: {
    ...desktopStyles.scrollContainer,
    bottom: '90px',
    width: '50%',
  },
  bookContainer: {
    ...desktopStyles.bookContainer,
    width: '40%',
    bottom: '120px',
  },
  starsContainer: {
    ...desktopStyles.starsContainer,
    width: '55%',
    bottom: '80px',
  },
  bookIcon: {
    ...desktopStyles.bookIcon,
    width: '120px',
  },
  armIcon: {
    ...desktopStyles.armIcon,
    width: '90px',
    margin: '0 -8px',
  },
  hatScrollIcon: {
    ...desktopStyles.hatScrollIcon,
    width: '90px',
    margin: '0 40px',
  },
  scrollIcon: {
    ...desktopStyles.scrollIcon,
    width: '60px',
    margin: '0 15px',
  },
  offTrackContainer: {
    ...desktopStyles.offTrackContainer,
    padding: '18px',
  },
  textContent: {
    ...desktopStyles.textContent,
    width: '65%',
  },
  offTrackHeader: {
    ...desktopStyles.offTrackHeader,
    fontSize: '28px',
  },
  offTrackContent: {
    ...desktopStyles.offTrackContent,
    fontSize: '17px',
    maxWidth: '90%',
  },
  illustrationContainer: {
    ...desktopStyles.illustrationContainer,
    right: '-60px',
    bottom: '35px',
    width: '65%',
    height: '65%',
  },
  mightyMindsHeader: {
    ...desktopStyles.mightyMindsHeader,
    fontSize: '40px',
  },
  mightyMindsContent: {
    ...desktopStyles.mightyMindsContent,
    fontSize: '20px',
    maxWidth: '80%',
  },
  mightyMindsIllustration: {
    ...desktopStyles.mightyMindsIllustration,
    width: '68%',
    height: '58%',
  },
  quoteText: {
    ...desktopStyles.quoteText,
    fontSize: '24px',
  },
  quoteMark: {
    ...desktopStyles.quoteMark,
    fontSize: '42px',
  },
  authorName: {
    ...desktopStyles.authorName,
    fontSize: '22px',
  },
  authorTitle: {
    ...desktopStyles.authorTitle,
    fontSize: '17px',
  },
  weDoMoreContainer: {
    ...desktopStyles.weDoMoreContainer,
    padding: '18px',
  },
  weDoMoreTitle: {
    ...desktopStyles.weDoMoreTitle,
  },
  card: {
    ...desktopStyles.card,
    height: '130px',
  },
  cardTitle: {
    ...desktopStyles.cardTitle,
    fontSize: '22px',
  },
};

const mobileStyles = {
  ...desktopStyles,
  container: {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: '10px',
    padding: '10px',
    width: '100%',
    maxWidth: '100%',
    overflow: 'hidden', // Add this to prevent overflow
    boxSizing: 'border-box', // Add this to include padding in width calculation
  },
  bentoBox: {
    gridTemplateColumns: '1fr',
    gridGap: '0.5rem',
    padding: '0.5rem',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    overflow: 'hidden',
  },
  largeItem: {
    gridColumn: '1 / span 1',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    marginLeft: 0,
    marginRight: 0,
    height: 'auto',
    minHeight: '400px', // Set a minimum height if needed
    gridColumn: 'span 2',
    gridRow: 'span 1',
    borderRadius: '15px',
  },
  mediumItem: {
    gridColumn: '1 / span 1',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    marginLeft: 0,
    marginRight: 0,
    height: 'auto',
    minHeight: '350px',
  },
  smallItem: {
    gridColumn: '1 / span 1',
    width: '100%',
    maxWidth: '100%',
    boxSizing: 'border-box',
    marginLeft: 0,
    marginRight: 0,
    height: 'auto',
    minHeight: '350px',
  },
  illustrationContainer: {
    right: '-40px', // Reduce the negative margin
    bottom: '30px',
    width: '60%', // Reduce the width
    height: '60%',
  },
  mightyMindsIllustration: {
    right: '-20px', // Reduce the negative margin
    bottom: '30px',
    width: '60%', // Reduce the width
    height: '50%',
  },
  columnOne: {
    ...desktopStyles.columnOne,
    gridColumn: '1 / span 1',
    textAlign: 'left',
  },
  columnTwo: {
    ...desktopStyles.columnTwo,
    gridColumn: '1 / span 1',
    textAlign: 'center',
  },
  bentoBox: {
    ...desktopStyles.bentoBox,
    gridTemplateColumns: '1fr',
    gridGap: '0.5rem',
    padding: '0.5rem',
  },

  iconsContainer: {
    ...desktopStyles.iconsContainer,
    bottom: 'calc(100% * 0.001)',
  },
  circlesContainer:{
    ...desktopStyles.circlesContainer,
    position: 'absolute',
    width: '90%',
    bottom: '', 
    height: '100%',
  }, 
  hatsScrollsContainer: {
    ...desktopStyles.hatsScrollsContainer,
    position: 'initial',
    bottom: 0,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'end',
    zIndex: 2,
  },
  scrollContainer: {
    ...desktopStyles.scrollContainer,
    bottom: '130px', // Move the scrolls down
    width: '80%',
    height: 'auto',
  },
  bookContainer:{
    ...desktopStyles.bookContainer,
    width: '60%',
  },
  //medium item 
  mediumItem: {
    ...desktopStyles.mediumItem,
    width: '100%',
    height: 'auto',
    gridColumn: '1 / span 1',
  },
  smallItem: {
    ...desktopStyles.smallItem,
    width: '100%',
    height: 'auto',
    gridColumn: '1 / span 1',
  },
  // quote container
  quoteContainer: {
    ...desktopStyles.quoteContainer,
    height: '',
  },
  expandedItem: {
    ...desktopStyles.expandedItem,
    left: '5%',
    marginTop: '5%',
    width: '90%',
    height: '70%',
  },
  offTrackContainer: {
    ...desktopStyles.offTrackContainer,
    padding: '15px',
  },
  textContent: {
    ...desktopStyles.textContent,
    marginBottom: '120px', // Make room for the illustration
    width: '60%'
  },
  offTrackHeader: {
    ...desktopStyles.offTrackHeader,
    fontSize: '24px',
  },
  offTrackContent: {
    ...desktopStyles.offTrackContent,
    fontSize: '16px',
    maxWidth: '100%',
  },
  illustrationContainer: {
    ...desktopStyles.illustrationContainer,
    right: '-80px',
    bottom: '30px',
    width: '75%',
    height: '75%',
  },
  mightyMindsContainer: {
    ...desktopStyles.mightyMindsContainer,
    padding: '15px',
  },
  mightyMindsText: {
    ...desktopStyles.mightyMindsText,
    marginBottom: '140px', // Make room for the illustration
    width: '70%'
  },
  mightyMindsHeader: {
    ...desktopStyles.mightyMindsHeader,
    fontSize: '32px',
  },
  mightyMindsContent: {
    ...desktopStyles.mightyMindsContent,
    fontSize: '18px',
    maxWidth: '100%',
  },
  mightyMindsIllustration: {
    ...desktopStyles.mightyMindsIllustration,
    width: '70%',
    height: '50%',
    right: '-20px',
    bottom: '30px',
  },
  quoteText: {
    ...desktopStyles.quoteText,
    fontSize: '24px',
  },
  quoteMark: {
    ...desktopStyles.quoteMark,
    fontSize: '36px',
  },
  authorName: {
    ...desktopStyles.authorName,
    fontSize: '20px',
  },
  authorTitle: {
    ...desktopStyles.authorTitle,
    fontSize: '16px',
  },
  weDoMoreContainer: {
    ...desktopStyles.weDoMoreContainer,
    padding: '15px',
  },
  weDoMoreTitle: {
    ...desktopStyles.weDoMoreTitle,
    fontSize: '32px',
  },
  card: {
    ...desktopStyles.card,
    height: '120px',
  },
  cardTitle: {
    ...desktopStyles.cardTitle,
    fontSize: '20px',
  },
};

export default BentoBoxComponent;
