import { db } from '../Firebase/firebaseConfig';
import { onValue, ref } from 'firebase/database';

// Calling the EDUCO page routes and pulling down the data
export const fetchPageRoutes = () => {
    return new Promise((resolve, reject) => {
      const dbRef = ref(db, 'pages/');
      
      const snapshotCallback = (snapshot) => {
        if (snapshot.exists()) {
         {/* Calling for db to get route information 
          - route is title and route is key */}
          const routesData = snapshot.val();
          const routesArray = Object.keys(routesData).map((key) => ({
            title: routesData[key].route,
            route: routesData[key].key,
          }));
          resolve(routesArray);
        } else {
          reject(new Error('No data available'));
        }
      };
      
      onValue(dbRef, snapshotCallback, {
        onlyOnce: true,
        error: (error) => reject(error),
      });
    });
  };

export const fetchCourseRoutes = () => {
    const dbRef = ref(db, 'pages/courses/'); 

    return new Promise((resolve) => {
        const snapshotCallback = (snapshot) => {
            if (snapshot.val() !== null) {
                const routesData = snapshot.val();
                const routesArray = Object.keys(routesData).map((key) => ({
                path: routesData[key].key,
                title: routesData[key].route,
                }));
                resolve(routesArray);
            } else {
                resolve([]);
            }
        };
        onValue(dbRef, snapshotCallback);
    });
};

export const fetchCommunitiesRoutes = () => {
    const dbRef = ref(db, 'pages/communities/'); 

    return new Promise((resolve) => {
        const snapshotCallback = (snapshot) => {
            if (snapshot.val() !== null) {
                const routesData = snapshot.val();
                const routesArray = Object.keys(routesData).map((key) => ({
                path: routesData[key].key,
                title: routesData[key].route,
                }));
                resolve(routesArray);
            } else {
                resolve([]);
            }
        };
        onValue(dbRef, snapshotCallback);
    });
};

export const fetchMentorsRoutes = () => {
    const dbRef = ref(db, 'pages/mentors/'); 
    return new Promise((resolve) => {
        const snapshotCallback = (snapshot) => {
            if (snapshot.val() !== null) {
                const routesData = snapshot.val();
                const routesArray = Object.keys(routesData).map((key) => ({
                path: routesData[key].key,
                title: routesData[key].route,
                }));
                resolve(routesArray);
            } else {
                resolve([]);
            }
        };
        onValue(dbRef, snapshotCallback);
    });
};