import React, { useContext } from 'react';
 // Use the ThemeContext
import { ThemeContext } from '../../../../Components/Common/ThemeContext/ThemeContext';

const Settings = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <div>
      <h2>Admin - Website Settings</h2>
      <p>Current Theme: {theme === 'light' ? 'Light Mode' : 'Dark Mode'}</p>
      <button onClick={toggleTheme}>
        Toggle to {theme === 'light' ? 'Dark' : 'Light'} Mode
      </button>
    </div>
  );
};

export default Settings;