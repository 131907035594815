import React, { useState, useEffect } from 'react';
import BlueActionButton from '../../../Components/Common/Buttons/BlueActionButton/BlueActionButton';
import { Link, useNavigate } from 'react-router-dom';
import { images, iconImages } from './Components/SignInWith/images';
import { motion } from 'framer-motion';
import { sendPasswordResetEmail } from "firebase/auth";
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import { auth } from '../../../Firebase/firebaseConfig';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import SignInWithComponent from './Components/SignInWith/SignInWith';
import { CiMail } from "react-icons/ci";

const ForgotPasswordComponent = () => {
  const navigate = useNavigate();
  const [focusedField, setFocusedField] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const images = [
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
  ];
  const meta = pageMetaTags.forgotPassword;
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    

    return () => clearInterval(interval);
  }, [images.length]);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage("");
    setSuccessMessage("");

    if (!email) {
      setErrorMessage("Please enter your email address");
      setIsLoading(false);
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      setSuccessMessage("Password reset email sent! Please check your inbox.");
      setEmail(""); // Clear the email field
    } catch (error) {
      switch (error.code) {
        case 'auth/invalid-email':
          setErrorMessage("Invalid email address format");
          break;
        case 'auth/user-not-found':
          setErrorMessage("No account found with this email address");
          break;
        case 'auth/too-many-requests':
          setErrorMessage("Too many attempts. Please try again later");
          break;
        default:
          setErrorMessage("An error occurred. Please try again");
      }
      //console.error("Password reset error:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const containerStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 1200 ? 'column-reverse' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    margin: '2.5% auto',
    padding: '0 20px',
    maxWidth: '1600px',
  };

  const carouselStyle = {
    width: '100%',
    maxWidth: '1200px',
    height: windowWidth <= 1200 ? '400px' : '800px',
    border: '1px solid rgba(0, 0, 0, 0.05)',  
    borderRadius: '10px',
    backgroundColor: '#fff',
    marginRight: windowWidth <= 1200 ? '0' : '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  };

  const imageStyle = {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
  };

  const formContainerStyle = {
    padding: '2.5%', 
    width: '100%',
    maxWidth: windowWidth <= 1200 ? '480px' : '400px',
    boxShadow: `
      0px 1.8px 2.2px rgba(0, 0, 0, 0.028),
      0px 4.3px 5.3px rgba(0, 0, 0, 0.04),
      0px 8.1px 10px rgba(0, 0, 0, 0.05),
      0px 14.5px 17.9px rgba(0, 0, 0, 0.06),
      0px 65px 80px rgba(0, 0, 0, 0.01)
    `,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: windowWidth <= 1200 ? '0 auto' : '0',
    marginTop: windowWidth <= 1200 ? '50px' : '',
    borderRadius: '15px'
  };

  const labelStyle = {
    marginRight: windowWidth <= 768 ? '0' : '10px',
    width: windowWidth <= 768 ? '100%' : '100px',
    textAlign: windowWidth <= 768 ? 'left' : 'right',
    fontSize: windowWidth <= 768 ? '14px' : '16px',
    fontWeight: '500',
    color: '#1F484C',
    marginBottom: windowWidth <= 768 ? '5px' : '0',
    display: windowWidth <= 768 ? 'block' : 'inline-block',
    transition: 'all 0.3s ease',
  };
  
  const inputContainerStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    alignItems: windowWidth <= 768 ? 'flex-start' : 'center',
    marginBottom: '20px',
    width: '100%',
    position: 'relative',
    gap: windowWidth <= 768 ? '5px' : '10px',
  };
  
  const inputStyle = {
    width: '100%',
    padding: '12px 16px',
    fontSize: '16px',
    border: '2px solid #E5E7EB',
    borderRadius: '8px',
    backgroundColor: '#FFFFFF',
    transition: 'all 0.3s ease',
    outline: 'none',
    color: '#1F2937',
    '&:focus': {
      borderColor: '#54AFB8',
      boxShadow: '0 0 0 3px rgba(84, 175, 184, 0.1)',
    },
    '&:hover': {
      borderColor: '#54AFB8',
    },
    '&:disabled': {
      backgroundColor: '#F3F4F6',
      cursor: 'not-allowed',
      opacity: 0.7,
    },
    '&::placeholder': {
      color: '#9CA3AF',
    },
  };

  const buttonStyle = {
    backgroundColor: '#54AFB8',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '3px',
    cursor: 'pointer',
    marginBottom: '10px',
  };
  const formFooterStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  };

  const socialIconsStyle = {
    display: 'flex',
    gap: '10px',
  };

  const iconImagesStyle ={
    height: '40px', 
  }

  const messageStyle = {
    padding: '10px',
    borderRadius: '4px',
    marginBottom: '15px',
    width: '100%',
    textAlign: 'center',
  };

  const errorStyle = {
    ...messageStyle,
    backgroundColor: '#ffebee',
    color: '#c62828',
    border: '1px solid #ffcdd2',
  };

  const successStyle = {
    ...messageStyle,
    backgroundColor: '#e8f5e9',
    color: '#2e7d32',
    border: '1px solid #c8e6c9',
  };
  const formStyle = {
    width: '100%',
    marginTop: '20px',
  };
  
  const formHeaderStyle = {
    textAlign: 'center',
    marginBottom: '30px',
  };
  
  const formTitleStyle = {
    fontSize: windowWidth <= 768 ? '24px' : '28px',
    fontWeight: '600',
    color: '#1F484C',
    marginBottom: '10px',
  };
  
  const formDescriptionStyle = {
    fontSize: '16px',
    color: '#6B7280',
    lineHeight: '1.5',
    maxWidth: '400px',
    margin: '0 auto',
  };
  const handleClick = () => {
    navigate('/signin')
  }

  const styles = {
    inputGroup: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      position: 'absolute',
      top: '50%', 
      transform: 'translateY(-50%)', 
      left: window.innerWidth < 768 ? '10%' : '5%',
      width: window.innerWidth < 768 ? '16px' : '20px',
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      zIndex: 1,
    },
    input: {
      width: window.innerWidth < 768 ? '75%' : '100%',
      maxWidth: '500px',
      padding: window.innerWidth < 768 
        ? '12px 12px 12px 45px'
        : '12px 16px 12px 40px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: window.innerWidth < 768 ? '14px' : '16px',
      outline: 'none',
      transition: 'border-color 0.2s, box-shadow 0.2s',
    },
    passwordGroup: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    eyeIcon: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: window.innerWidth < 768 ? '15%' : '5%',
      width: window.innerWidth < 768 ? '16px' : '20px',
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      cursor: 'pointer',
      zIndex: 1,
    }
  };
  


  return (
    <div style={containerStyle}>
    <MetaTags {...meta} />
    <div style={carouselStyle}>
      <img src={images[currentIndex]} alt={`Carousel ${currentIndex + 1}`} style={imageStyle} />
    </div>
    <div style={formContainerStyle}>
      <div style={formHeaderStyle}>
        <h2 style={formTitleStyle}>Forgot Password?</h2>
        <p style={formDescriptionStyle}>
          Please enter your email address below to receive a password reset link.
        </p>
      </div>
      
      {errorMessage && <div style={errorStyle}>{errorMessage}</div>}
      {successMessage && <div style={successStyle}>{successMessage}</div>}
      
      <form onSubmit={handleForgotPassword} style={formStyle}>
        <div style={styles.inputGroup}>
          <CiMail style={styles.icon} />
            <input 
              type="email" 
              name="email"
              placeholder="i.e. @gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                ...styles.input,
                boxShadow: focusedField === 'email' ? '0 0 0 1px #1F484C' : 'none',
                borderColor: focusedField === 'email' ? '#1F484C' : '#e5e7eb',
              }}
              onFocus={() => setFocusedField('email')}
              onBlur={() => setFocusedField(null)}
              required
            />
          </div>
        
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonTwo 
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? "SENDING..." : "RESET PASSWORD"}
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
        
        <div style={formFooterStyle}>
          <p>Remember your password?</p>
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonOne 
            onClick={handleClick}>
              Sign in here
            </StandardButtonContainer.ButtonOne>
          </StandardButtonContainer>
        </div>
        
        <div style={formFooterStyle}>
          <p>New to the platform?</p>
          <Link to="/createaccount">Create an account</Link>
        </div>
      </form>
      
      <p>or login with</p>
      <SignInWithComponent />
    </div>
  </div>
  );
};

export default ForgotPasswordComponent;