import { styles } from "../../styles";

const LoadedTranscriptComponent = ({data}) => {
  return (
    <div
      style={styles.scrollableBox}>
      {data.map((items) => (
        <div>
          <h3>{items.sectionHeader}</h3>
          <p>
            {items.sectionText}
          </p>
        </div>
      ))}
    </div>
  );
};

export { LoadedTranscriptComponent };
