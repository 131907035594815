import React, {useContext, useEffect, useRef, useState, useCallback} from 'react';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import MetaTags from "../../../../Components/Common/MetaTags/metaTagsComponent";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';
import { motion } from 'framer-motion';

// Page meta tags
import { pageMetaTags } from "../../../../Components/Common/MetaTags/metaData";

//Components
import { styles, lightTheme, darkTheme } from "../styles";
import { LoadingContext } from '../../../../Roots/LoadingContent';


import QuizPage from '../Components/QuizComponent/QuizPage';
import { emailsHasher } from '../../../../Firebase/educoFunctions';

//functions
import useAuth from '../../../../Firebase/Auth';


const LoadedCourseVideoPage = ({ 
  data, 
  quizInfo}) => {
  const [newVideoData, setNewVideoData] = useState(null);
  const videoRef = useRef();
  const [showQuiz, setShowQuiz] = useState(false);
  const [hasShownQuiz, setHasShownQuiz] = useState(false);
  const [currentQuizIndex, setCurrentQuizIndex] = useState(0);
  const [watchPercentage, setWatchPercentage] = useState(0);
  const [lastTrackedPercentage, setLastTrackedPercentage] = useState(null);

  // hook
  const currentUser = useAuth();
  // pull down data
  const [videoData, setVideoData] = useState({
      key: data['key'],
      videoSource: data['videoSource'],
      poster: data['pagePoster'],
      alt: data['videoAlt'], 
      likes: data['likes'],
      views: data['count'], 
      title: data['key'],
      pagedesc: data['pageDesc'],
      guestSpeaker: data['guestSpeaker'],
      transcript: data['videoTranscript'], 
    });

  const [quizInfoData, setQuizInfoData] = useState({
    key: quizInfo['key'],
    quizTimings: quizInfo['quizTimings']
  });

  const [updatedLikesCount, setUpdatedLikesCount] = useState(videoData.likes || 0); // State to store updated likes
  const quizTimings = [15, 30, 45, 75, 120];
  const quizQuestions = [
    [
      {
        type: 'multipleChoice',
        question: 'What are stars?',
        options: ['amer', 'semer', 'aimer', 'aider'],
        correctAnswer: 2
      },
      {
        type: 'dragAndDrop',
        question: 'Stars are...',
        correctOrder: ['Aimes', 'tu', 'l\'art', '?'],
        options: ['tu', '?', 'l\'art', 'Aimes']
      }
    ],
    [
      {
        type: 'multipleChoice',
        question: 'to give (something); to hand (something); to give as a present',
        options: ['donner', 'servir', 'offrir', 'donner'],
        correctAnswer: 0
      },
      {
        type: 'dragAndDrop',
        question: 'Do you like food?',
        correctOrder: ['Aimes', 'tu', 'la', 'nourriture', '?'],
        options: ['nourriture', '?', 'Aimes', 'tu', 'la']
      }
    ],
    [
      {
        type: 'multipleChoice',
        question: 'to eat; to have a meal',
        options: ['manger', 'boire', 'aimer', 'aider'],
        correctAnswer: 0
      },
      {
        type: 'multipleChoice',
        question: 'to drink',
        options: ['manger', 'boire', 'aimer', 'aider'],
        correctAnswer: 1
      }
    ]
  ];
  const pageName = data.videohref;
  const canonicalUrl = 'https://offthecurriculum.co.uk/' + pageName;
  const meta = pageMetaTags.Loading;

  const trackVideoInteraction = async (interactionData) => {  
    try {
      //console.log('track video interaction');
      const hashedEmail = emailsHasher(currentUser.email);
      const trackUserInteraction = httpsCallable(functions, 'track_user_interaction');
      //console.log('joseph')
      await trackUserInteraction({
        user_id: hashedEmail,
        interaction_type: 'video',
        video_id: data.key,
        topics: [data.courseTag], // Assuming courseTag is the topic
        ...interactionData
      });
    } catch (error) {
      //console.error('Error tracking video interaction:', error);
    }
  };
  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;
  
    if (currentUser) {
      //console.log('currentUser is set:', currentUser);
  
      const handleTimeUpdate = () => {
        const percentage = Math.floor((video.currentTime / video.duration) * 100);
        //console.log(percentage)
  
        // Ensure percentage is exactly 25, 50, 75, or 100 and hasn't been tracked already
        if (
          (percentage === 25 || percentage === 50 || percentage === 75 || percentage === 100) &&
          percentage !== lastTrackedPercentage
        ) {
          //console.log('every 25% interaction');
          trackVideoInteraction({ 
            watch_percentage: percentage,
            liked: false,
            commented: false
           });
          setLastTrackedPercentage(percentage); // Mark this percentage as tracked
        }
      };
  
      video.addEventListener('timeupdate', handleTimeUpdate);
      return () => {
        video.removeEventListener('timeupdate', handleTimeUpdate);
      };
    } else {
      //console.log('currentUser is null or undefined');
    }
  }, [currentUser, lastTrackedPercentage]);
  
  
  const navigate = useNavigate();

  useEffect(() => {
    // Force the video element to load the new source
    if (newVideoData) {
      setVideoData(newVideoData);
      if (videoRef.current) {
        videoRef.current.load();
      }
      setNewVideoData(null); 
    }
  }, [newVideoData]);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = videoRef.current?.currentTime || 0;
      const targetTime = quizTimings[currentQuizIndex];

      if (currentTime >= targetTime && !showQuiz) {
        setShowQuiz(true);
        setHasShownQuiz(true);
        videoRef.current.pause();
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [showQuiz, currentQuizIndex]);

  const handleQuizComplete = () => {
    setShowQuiz(false);
    setHasShownQuiz(false);
    if (currentQuizIndex < quizTimings.length - 1) {
      setCurrentQuizIndex(currentQuizIndex + 1);
    }
    if (videoRef.current) {
      videoRef.current.currentTime = quizTimings[currentQuizIndex];
      videoRef.current.play();
    }
  };
  
  return (
    <>
      <MetaTags 
        title={videoData['key']}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.educoContainer}>
        <div style={styles.secondEduco}>
          <div style={styles.videoContainer}>
            <video
              id="videoPlayer"
              style={styles.videoEduoPlayer}
              controls
              //autoPlay
              controlsList="nodownload"
              poster={data['pagePoster']}
              alt={data['videoAlt']}
              ref={videoRef}
            >
              <source src={videoData['videoSource']} type="video/mp4" />
              <track default kind="captions" srcLang="English" src="" />
            </video>
            {showQuiz && (
                <div style={styles.quizOverlay}>
                  <QuizPage questions={quizQuestions[currentQuizIndex]} onComplete={handleQuizComplete} />
                </div>
              )}
          </div>
        <div style={styles.videoInfoContainer}>
          <div style={styles.videoAnalytics}>

          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap:'10px' }}>
              <p style={{ marginRight: '10px' }}>
                Share via:
              </p>
              <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0 }}
              > 
              <FacebookShareButton url={canonicalUrl}>
                <FacebookIcon size={32} round={true} />
              </FacebookShareButton>
            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0 }}
            >
              <WhatsappShareButton url={canonicalUrl}>
                <WhatsappIcon size={32} round={true} />
              </WhatsappShareButton>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0 }}>
              <TwitterShareButton url={canonicalUrl}>
                <TwitterIcon size={32} round={true} />
              </TwitterShareButton>
            </motion.div>
            <motion.div 
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 1.0 }}>
              <LinkedinShareButton url={canonicalUrl}>
                <LinkedinIcon size={32} round={true} />
              </LinkedinShareButton>
            </motion.div>
              </div>
            </div>
          </div>
          <h2>
            {data['key']}
          </h2>
          <p>
            Date released:
          </p>
          <p>
            {data['pageDesc']}
          </p>
          <hr style={styles.educoSubjectCourse}/>
          <h4>
            Guest Speakers
          </h4>
          <div className="card-course">
            <div className="card-educo-title">
              <img 
              style={styles.courseInfographic} src="" alt="Transcript" />
              <span>
                Transcript
              </span>
            </div>
            <hr 
            style={styles.educoSubjectCourse}
            />
          </div>
        </div>
      </div>
      <div style={styles.firstEduco}>
      <div style={styles.recommendOverview}>
      <h3 style={styles.educoTitle}>
          For you:
        </h3>
        <div style={styles.carouselContainer}>
          <div style={styles.carousel}>
          </div>
        </div>
      </div>
      </div>
      </div>
    </>
  );
};

export default LoadedCourseVideoPage;

  {/*
  useEffect(() => {
    // Timer for rendering circles
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 0.1); 
    return () => clearTimeout(timer);
  }, [setIsContentLoaded]);
 */}