import { color } from 'framer-motion';
import React from 'react';

const WelcomeProfresh = ({ menteeName, projectTitle, startDate, endDate }) => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    header: {
      textAlign: 'center',
      borderBottom: '2px solid #0C111D',
      paddingBottom: '10px',
      marginBottom: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    title: {
      color: '#1F484C',
      borderBottom: '1px solid #0C111D',
      paddingBottom: '5px',
    },
    info: {
      backgroundColor: '#1F484C',
      color: '#fff',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    contactGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
      gap: '15px',
    },
    contactCard: {
      backgroundColor: 'white',
      padding: '15px',
      borderRadius: '5px',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    email: {
      color: '#1F484C',
      textDecoration: 'none',
    },
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1>Welcome to the Pro-fresh Academic Mentoring Programme</h1>
      </header>

      <section style={styles.section}>
        <h2 style={styles.title}>Programme Overview</h2>
        <p>
          The Pro-fresh Academic Mentoring Programme is designed to support and guide students 
          through their academic projects. Our experienced mentors will provide valuable insights, 
          help you develop your skills, and assist you in achieving your project goals.
        </p>
      </section>

      <section style={styles.section}>
        <h2 style={styles.title}>Your Project Details</h2>
        <div style={styles.info}>
          <p><strong>Mentee Name:</strong> {menteeName}</p>
          <p><strong>Project Title:</strong> {projectTitle}</p>
          <p><strong>Start Date:</strong> {startDate}</p>
          <p><strong>End Date:</strong> {endDate}</p>
        </div>
      </section>

      <section style={styles.section}>
        <h2 style={styles.title}>Useful Contacts</h2>
        <div style={styles.contactGrid}>
          <div style={styles.contactCard}>
            <h3>Mentor Programme Coordinators</h3>
            <p>Joseph Levermore</p>
            <p>Tim Siu</p>
            <p>
                
              <a href="mailto:coordinators@profresh.com" style={styles.email}>
                E: coordinators@otc.co.uk
              </a>
            </p>
          </div>
          <div style={styles.contactCard}>
            <h3>Recruitment Team and Human Resources</h3>
            <p>Remy</p>
            <p>Zayn</p>
            <p>
            
              <a href="mailto:hr@profresh.com" style={styles.email}>
                E: volunteering@otc.co.uk
              </a>
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};

export default WelcomeProfresh;