import React from 'react';
import StandardButtonContainer from '../../../../../../Components/Common/Buttons/StandardButtons/StandardButtons';

const MapComponent = ({mapLocation}) => {
  const styles = {
    eventMap: {
      width: '100%',
      height: '400px',
      marginTop: '15px',
      marginBottom: '15px',
      borderRadius: '10px',
    },
    buttonContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: '20px',
    },
    button: {
      padding: '10px 20px',
      backgroundColor: '#e0e0e0',
      border: 'none',
      borderRadius: '5px',
      cursor: 'pointer',
    },
  };

  const openMap = () => {
    const googleMapsUrl = 'https://www.google.com/maps?q=51.51724122406897,-0.1754029640962397';
    const appleMapsUrl = 'https://maps.apple.com/?q=51.51724122406897,-0.1754029640962397';
    if (navigator.platform.indexOf('iPhone') !== -1 || navigator.platform.indexOf('iPad') !== -1 || navigator.platform.indexOf('iPod') !== -1) {
      window.open(appleMapsUrl, '_blank');
    } else {
      window.open(googleMapsUrl, '_blank');
    }
  };

  return (
    <>
      <h2>Event Location</h2>
      <iframe 
        src={mapLocation}  
        allowFullScreen="" 
        loading="lazy" 
        referrerPolicy="no-referrer-when-downgrade"
        style={styles.eventMap}>
      </iframe>
      <div style={styles.buttonContainer}>
        <StandardButtonContainer.ButtonOne onClick={openMap}>
           Open in Maps
        </StandardButtonContainer.ButtonOne>
      </div>
    </>
  );
};

export default MapComponent;
