import React, { useState, useEffect } from 'react';
import { CiHeart } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
import { CiPhone } from "react-icons/ci";
import { FiMessageCircle } from "react-icons/fi";
import { IoSendOutline } from "react-icons/io5";
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

// meta tags
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';

const ContactForm = () => {
  const meta = pageMetaTags.ContactForm;
  const styles = {
    container: {
      width: '90%', // Changed from 100% to prevent edge-to-edge on mobile
      maxWidth: '750px',
      margin: '0 auto',
      marginTop: window.innerWidth < 768 ? '20px' : '50px', // Reduced top margin on mobile
      marginBottom: window.innerWidth < 768 ? '20px' : '50px',
      padding: window.innerWidth < 768 ? '16px' : '24px', // Reduced padding on mobile
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    },
    header: {
      textAlign: 'center',
      marginBottom: window.innerWidth < 768 ? '24px' : '32px',
    },
    heartIcon: {
      width: window.innerWidth < 768 ? '36px' : '48px', // Smaller icon on mobile
      height: window.innerWidth < 768 ? '36px' : '48px',
      color: '#ef4444',
      margin: '0 auto 16px',
    },
    title: {
      fontSize: window.innerWidth < 768 ? '24px' : '30px', // Smaller font on mobile
      fontWeight: 'bold',
      color: '#1f2937',
      marginBottom: '8px',
    },
    subtitle: {
      color: '#4b5563',
      fontSize: window.innerWidth < 768 ? '14px' : '16px',
      padding: window.innerWidth < 768 ? '0 10px' : '0',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: window.innerWidth < 768 ? '16px' : '24px', // Reduced gap on mobile
    },
    inputGroup: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      position: 'absolute',
      top: '50%', // Center vertically
      transform: 'translateY(-50%)', // Center vertically
      left: window.innerWidth < 768 ? '10%' : '5%', // Adjust left position based on screen size
      width: window.innerWidth < 768 ? '16px' : '20px', // Smaller icon on mobile
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      zIndex: 1, // Ensure icon stays above input
    },
    iconMessage:{
      position: 'absolute',
      top: window.innerWidth < 768 ? '17%' : '50%', // Center vertically
      transform: 'translateY(-50%)', // Center vertically
      left: window.innerWidth < 768 ? '10%' : '5%', // Adjust left position based on screen size
      width: window.innerWidth < 768 ? '16px' : '20px', // Smaller icon on mobile
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      zIndex: 1, // Ensure icon stays above input
    }, 
    input: {
      width: window.innerWidth < 768 ? '75%' : '100%',
      maxWidth: '500px',
      padding: window.innerWidth < 768 
        ? '12px 12px 12px 45px' // Increased left padding on mobile
        : '12px 16px 12px 40px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: window.innerWidth < 768 ? '14px' : '16px',
      outline: 'none',
      transition: 'border-color 0.2s, box-shadow 0.2s',
    },
    textarea: {
      width: window.innerWidth < 768 ? '75%' : '100%',
      maxWidth: '500px',
      padding: window.innerWidth < 768 
        ? '12px 12px 12px 45px' // Increased left padding on mobile
        : '12px 16px 12px 40px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: window.innerWidth < 768 ? '14px' : '16px',
      minHeight: window.innerWidth < 768 ? '100px' : '120px',
      outline: 'none',
      transition: 'border-color 0.2s, box-shadow 0.2s',
      resize: 'none',
    },
    footer: {
      marginTop: window.innerWidth < 768 ? '24px' : '32px',
      textAlign: 'center',
      fontSize: window.innerWidth < 768 ? '12px' : '14px',
      color: '#6b7280',
      padding: window.innerWidth < 768 ? '0 10px' : '0',
    },
  };

  useEffect(() => {
    const handleResize = () => {
      // Force re-render on window resize
      setFormData({...formData});
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Handle hover and focus states
  const [buttonHover, setButtonHover] = useState(false);
  const [focusedField, setFocusedField] = useState(null);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    message: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate required fields
    let hasErrors = false;
    if (!formData.name) {
      setErrors((prev) => ({ ...prev, name: true }));
      hasErrors = true;
      alert('please add name')
    } else {
      setErrors((prev) => ({ ...prev, name: false }));
    }

    if (!formData.email) {
      setErrors((prev) => ({ ...prev, email: true }));
      hasErrors = true;
      alert('please user email')

    } else {
      setErrors((prev) => ({ ...prev, email: false }));
    }

    if (!formData.message) {
      setErrors((prev) => ({ ...prev, message: true }));
      hasErrors = true;
      alert('please type your message to OTC')

    } else {
      setErrors((prev) => ({ ...prev, message: false }));
    }

    if (!hasErrors) {
      //console.log('Form submitted:', formData);
    }
  };

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };


  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.header}>
        <CiHeart style={styles.heartIcon} />
        <h2 style={styles.title}>Get in Touch</h2>
        <p style={styles.subtitle}>We'd love to hear from you and help make a difference together</p>
      </div>

      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.inputGroup}>
          <CiMail style={styles.icon} />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            placeholder="Your Email"
            style={{
              ...styles.input,
              boxShadow: focusedField === 'email' ? '0 0 0 2px rgba(37, 99, 235, 0.2)' : 'none',
              borderColor: focusedField === 'email' ? '#2563eb' : '#e5e7eb',
            }}
            onFocus={() => setFocusedField('email')}
            onBlur={() => setFocusedField(null)}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <FiMessageCircle style={styles.icon} />
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            placeholder="Your Name"
            style={{
              ...styles.input,
              boxShadow: focusedField === 'name' ? '0 0 0 2px rgba(37, 99, 235, 0.2)' : 'none',
              borderColor: focusedField === 'name' ? '#2563eb' : '#e5e7eb',
            }}
            onFocus={() => setFocusedField('name')}
            onBlur={() => setFocusedField(null)}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <CiPhone style={styles.icon} />
          <input
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            placeholder="Your Phone (optional)"
            style={{
              ...styles.input,
              boxShadow: focusedField === 'phone' ? '0 0 0 2px rgba(37, 99, 235, 0.2)' : 'none',
              borderColor: focusedField === 'phone' ? '#2563eb' : '#e5e7eb',
            }}
            onFocus={() => setFocusedField('phone')}
            onBlur={() => setFocusedField(null)}
            required
          />
        </div>

        <div style={styles.inputGroup}>
          <FiMessageCircle style={styles.iconMessage} />
          <textarea
            name="message"
            value={formData.message}
            onChange={handleChange}
            placeholder="Your Message"
            style={{
              ...styles.textarea,
              boxShadow: focusedField === 'message' ? '0 0 0 2px rgba(37, 99, 235, 0.2)' : 'none',
              borderColor: focusedField === 'message' ? '#2563eb' : '#e5e7eb',
            }}
            onFocus={() => setFocusedField('message')}
            onBlur={() => setFocusedField(null)}
            required
          />
        </div>
 
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonTwo onClick={handleSubmit}>
            <div style={{ 
              display: 'flex', 
              alignItems: 'center', 
              justifyContent: 'center', 
              gap: '8px' 
            }}>
              <IoSendOutline size={20} />
              <span>Send Message</span>
            </div>
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
      </form>

      <div style={styles.footer}>
        <p>Your message will help us create positive change. We'll respond within 24-48 hours.</p>
      </div>
    </div>
  );
};

export default ContactForm;