import React, { useState, useEffect } from 'react';
import CourseModalPage from './CourseModalPage';
import { useNavigate, useParams } from 'react-router-dom';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth } from 'firebase/auth';
import { emailsHasher } from '../../../Firebase/educoFunctions';
import useAuth from '../../../Firebase/Auth';

const courses = [
  { 
    title: 'AI and Robotics', 
    description: 'Learn how to build AI and Robotic systems.', 
    tags: ["Machine Learning", "Artificial general intelligence", "Brain plasticity"],
    icon: '🤖' },
  { 
    title: 'Astronomy', 
    description: 'Discover the beauty of our cosmos.', 
    icon: '🔭',
    tags: ["Cosmology", "Electromagnetic Spectrum", "Planets and Stars"]
 },
  { 
    title: 'Business', 
    description: 'Uncover your inner Entrepreneur.', 
    icon: '💼', 
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"]
},
  { 
    title: 'Climate Change', 
    description: 'Predict the climate change.', 
    icon: '🌡️',
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"]
 },
  { 
    title: 'African History', 
    description: 'Tap into African history.', 
    icon: '🗿',
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"]
 },
  { 
    title: 'Immunology', 
    description: 'Explore bugs that evade our natural defenses.', 
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"], 
    icon: '🦠' },
  { 
    title: 'Psychology', 
    description: 'Understand the complexities of the human mind.', 
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"], 
    icon: '🧠' },
  { 
    title: 'Neuroscience', 
    description: 'Explore the intricate workings of the brain.', 
    tags: ["Neuroscience", "Brain computer interface", "Brain plasticity"],
    icon: '🧬' }
];

const CourseExplorePage = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [enrolledCourse, setEnrolledCourse] = useState(null);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const functions = getFunctions();
  const currentUser = useAuth();
  const { courseId } = useParams();

  useEffect(() => {
    const checkUserEnrollment = async () => {
      //console.log('Checking user enrollment');
      if (currentUser && currentUser.email) {
        console.log('Current user email:', currentUser.email);
        try {
          const hashedEmail = emailsHasher(currentUser.email);
          const { isEnrolled, courseId } = await checkEnrollment(hashedEmail);
          console.log(hashedEmail)
          console.log('Is user enrolled:', isEnrolled);
          if (isEnrolled) { 
            //console.log('Navigating to enrolled course', courseId);
            navigate(`/course/${courseId}`);
          }
        } catch (error) {
          //console.error('Error checking enrollment:', error);
          setError('Failed to check enrollment status');
        } finally {
          setLoading(false);
        }
      } else {
        //console.log('No current user or email');
        setLoading(false);
      }
    };
  
    checkUserEnrollment();
  }, [currentUser, navigate, functions]);
  
  // In the enrollStudent function
  const enrollStudent = async (courseId) => {
    setLoading(true);
    setError(null);
  
    try {
      if (!currentUser) {
        throw new Error('User not authenticated');
      }
  
      const hashedEmail = emailsHasher(currentUser.email);
      
      // Check if already enrolled
      const { isEnrolled, courseId: enrolledCourseId } = await checkEnrollment(hashedEmail);
      if (isEnrolled) {
        //console.log('User already enrolled in a course');
        navigate(`/course/${enrolledCourseId}`);
        return;  // Exit the function early
      }
  
      //console.log('data sent', hashedEmail, courseId);
      // Enroll the student
      const enrollCourseFunction = httpsCallable(functions, 'enroll_course');
      const result = await enrollCourseFunction({ userId: hashedEmail, courseId });
      //console.log('Enrollment successful:', result.data);
  
      // Navigate to the course page or dashboard
      navigate(`/course/${courseId}`);
    } catch (error) {
      //console.error('Enrollment failed:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const openModal = (course) => {
    //console.log('Opening modal for course:', course);
    setSelectedCourse(course);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedCourse(null);
    setError(null);
  };

  const checkEnrollment = async (hashedEmail) => {
    //console.log('Checking enrollment for:', hashedEmail);
    const checkEnrollmentFunction = httpsCallable(functions, 'check_course_enrollment');
    try {
      const result = await checkEnrollmentFunction({ userId: hashedEmail });
      //console.log('result here', result)
      const lowerCaseCourseId = result.data.courseId.toLowerCase();
      //console.log('lower case',lowerCaseCourseId)
      setEnrolledCourse(lowerCaseCourseId)
      return { isEnrolled: result.data.isEnrolled, courseId: lowerCaseCourseId };
    } catch (error) {
      //console.error('Error checking enrollment:', error);
      throw error;
    }
  };
  

  const gridStyle = {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(250px, 1fr))',
    gap: '20px',
    padding: '20px',
  };

  const cardStyle = {
    backgroundColor: '#f0f0f0',
    borderRadius: '8px',
    padding: '20px',
    textAlign: 'center',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    cursor: 'pointer',
    '@media (min-width: 768px)': {
      textAlign: 'left',
    },
  };

  const iconStyle = {
    fontSize: '48px',
    marginBottom: '10px',
    textAlign: 'center',
    '@media (min-width: 768px)': {
      textAlign: 'left',
      marginBottom: '0',
    },
  };

  const infoStyle = {
    '@media (min-width: 768px)': {
      marginLeft: '20px',
    },
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div>
      <div style={gridStyle}>
        {courses.map((course, index) => (
          <div style={cardStyle} key={index} onClick={() => openModal(course)}>
            <div style={iconStyle}>{course.icon}</div>
            <div style={infoStyle}>
              <h2 style={{ marginBottom: '5px' }}>{course.title}</h2>
              <p>{course.description}</p>
            </div>
          </div>
        ))}
      </div>
      {modalIsOpen && selectedCourse && (
        <CourseModalPage
          course={selectedCourse}
          onClose={closeModal}
          onSave={(courseName) => enrollStudent(courseName)}
          loading={loading}
          error={error}
        />
      )}
    </div>
  );
};
export default CourseExplorePage;