import React, { useState, useContext, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import MetaTags from "../../../../Components/Common/MetaTags/metaTagsComponent";
import { pageMetaTags } from "../../../../Components/Common/MetaTags/metaData";
import { LoadingContext } from '../../../../Roots/LoadingContent';
import { FaLightbulb, FaTimes } from 'react-icons/fa';

const UnLoadedCoursePageContent = ({ courseId, moduleId, lessonId }) => {
  const [showTopTips, setShowTopTips] = useState(false);
  const [currentSection, setCurrentSection] = useState(1);
  const { setIsContentLoaded } = useContext(LoadingContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, 100);
    return () => clearTimeout(timer);
  }, [setIsContentLoaded]);

  const meta = pageMetaTags.Loading;

  const toggleTopTips = () => {
    setShowTopTips(!showTopTips);
  };

  const renderProgressBar = () => {
    const sections = 4;
    return (
      <div style={styles.progressBar}>
        {[...Array(sections)].map((_, index) => (
          <div
            key={index}
            style={{
              ...styles.progressSection,
              backgroundColor: index < currentSection ? '#ffa500' : '#f0f0f0',
            }}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={styles.container}>
        <div style={styles.courseProgress}>
          <span>Section {currentSection} of 4</span>
          {renderProgressBar()}
          <span style={styles.timeRemaining}>Time remaining: 01:45</span>
        </div>

        <div style={styles.contentContainer}>
        <motion.div 
            style={styles.videoContainer}
            animate={{ width: showTopTips ? 'calc(100% - 300px)' : '100%' }}
            transition={{ duration: 0.3 }}
          >
            <div style={styles.videoPlayer}>
              <img src="/path-to-placeholder-image.jpg" alt="Video Placeholder" style={styles.placeholderImage} />
              <button onClick={toggleTopTips} style={styles.topTipsButton}>
                <FaLightbulb /> Top Tips
              </button>
            </div>
            <div style={styles.videoInfo}>
              <h2 style={styles.videoInfoTitle}>Title of Video</h2>
              <p style={styles.videoInfoDescription}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
              </p>
            </div>
          </motion.div>

          <AnimatePresence>
            {showTopTips && (
              <motion.div 
                style={styles.topTipsPanel}
                initial={{ opacity: 0, x: 320 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 320 }}
                transition={{ duration: 0.3 }}
              >
                <div style={styles.topTipsHeader}>
                  <h3 style={styles.topTipsTitle}>What are we learning?</h3>
                  <button onClick={toggleTopTips} style={styles.closeButton}>
                    <FaTimes />
                  </button>
                </div>
                <ol style={styles.topTipsList}>
                  <li>Where is the Corpus Callosum?</li>
                  <li>What is the Corpus Callosum?</li>
                  <li>Myelinated nerves cells explained?</li>
                  <li>What does it do?</li>
                  <li>Stroke and damage to Corpus Callosum</li>
                </ol>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '20px',
  },
  courseProgress: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '20px',
  },
  progressBar: {
    flex: 1,
    display: 'flex',
    height: '8px',
    margin: '0 15px',
  },
  progressSection: {
    flex: 1,
    height: '100%',
    marginRight: '4px',
    borderRadius: '4px',
  },
  timeRemaining: {
    backgroundColor: '#e0f2f1',
    padding: '5px 10px',
    borderRadius: '5px',
  },
  contentContainer: {
    display: 'flex',
    position: 'relative',
    gap: '20px', // This adds space between the video and the top tips section
  },
  videoContainer: {
    flexGrow: 1,
  },
  videoPlayer: {
    position: 'relative',
    width: '100%',
    paddingTop: '56.25%', // 16:9 aspect ratio
    backgroundColor: '#f0f0f0',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  videoInfo: {
    backgroundColor: '#e0f2f1',
    padding: '20px',
    borderRadius: '10px',
    marginTop: '20px',
  },
  videoInfoTitle: {
    marginTop: 0,
  },
  videoInfoDescription: {
    lineHeight: 1.6,
  },
  placeholderVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#888',
    fontSize: '24px',
  },
  topTipsButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: '#ffd700',
    border: 'none',
    borderRadius: '5px',
    padding: '5px 10px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    gap: '5px',
  },
  topTipsPanel: {
    width: '300px',
    backgroundColor: '#fff5e6',
    boxShadow: '-5px 0 15px rgba(0,0,0,0.1)',
    padding: '20px',
    boxSizing: 'border-box',
    overflowY: 'auto',
    borderRadius: '10px',
  },
  topTipsHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  topTipsTitle: {
    margin: 0,
    fontSize: '20px',
  },
  closeButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
  },
  topTipsList: {
    paddingLeft: '20px',
    lineHeight: 1.6,
  },
};

export default UnLoadedCoursePageContent;