const educationPageContent ={
    pageTitle: `Education by Off the Curriculum`, 
    pageIntroduction:`Education by Off the Curriculum is a truly world-leading academic opportunity designed to empower young people in their pursuit of success. By harnessing modern technology and innovative teaching methods, we provide a comprehensive, all-in-one flagship program that integrates one-to-one mentoring, live seminars, and interactive forums. Our educational approach is evolving, shifting from traditional guest speaker-led formats to dynamic, student-focused project-based series.`, 
    headerOne:`Profesh: Academic one-to-one mentoring`, 
    paraOne: `Off the Curriculum (OTC) is proud to facilitate the Extended Project Qualification (EPQ) in partnership with Elm Academy and Harris Purely, offering students an independent research project worth half an A-level. Participants can choose one of three routes: a Dissertation (5,000-word essay), an Investigation (collection of primary data culminating in a 4,000-5,000 word report and a presentation), or an Artefact (a 1,000-word essay paired with a creative artifact). The program pairs students with seminar volunteers, including Ph.D. candidates and Post-Docs, who serve as expert mentors, fostering mutual benefit for both parties. The EPQ is awarded by Elm Academy and Harris Purely, while the effectiveness of the mentorship will be assessed by comparing the performance of OTC participants with previous cohorts. Throughout the program, students will engage in milestone meetings and receive training, ensuring a comprehensive support structure designed to enhance their learning experience. Evaluation metrics will include both qualitative and quantitative assessments to gauge student engagement and overall program success.`, 
    headerTwo: `Seminar Series`, 
    paraTwo: `Our 2024/25 seminar series is transforming from traditional guest speaker-led events into a dynamic, student-focused project-based series. This year, we're thrilled to offer 12 engaging seminars each month, covering a diverse array of topics, including our popular Mighty Minds Wellbeing Seminars, thought-provoking discussions on Architecture, inspiring insights into Business and Innovation, and explorations into the History of Language. For our upcoming events see below:`, 
    headerThree: `EDUCO`, 
    paraThree: `The EDUCO online learning platform serves as a comprehensive hub for Off the Curriculum participants, offering access to seminars, assignment information, hand-in dropboxes, and additional learning resources. Distinct from previous platforms, EDUCO features weekly video tutorials tailored to current seminar topics, along with various educational materials. As a student volunteer-led initiative, content creation is essential for earning the Off the Curriculum Volunteer Award, allowing volunteers to choose focus areas ranging from myth-busting to concept explanations, with oversight from Off the Curriculum officers. This collaboration not only enriches the overall learning experience but also provides volunteers with valuable opportunities to enhance their presentation skills.`, 
    headerFour: `Mighty minds`, 
    paraFour: `The Wellbeing Programme at Off the Curriculum, developed in partnership with Nurture with Education, emphasizes the importance of mental and social wellbeing as integral to a complete definition of health by the World Health Organisation. Led by Joseph Levermore and Sinead Reed-Forester, the programme aims to equip participants with tools to navigate life's stresses, fostering a flourishing educational environment. It features bi-termly seminars on topics selected by students, encouraging active participation in their learning. Evaluation is conducted through a Wellbeing Programme Questionnaire to assess self-worth and its impact on happiness and motivation, ultimately aiming to enhance students' self-esteem and positive self-image. `, 
    headerFive: `Introducing Ask Astro: Harnessing AI and ML for Education`,
    paraFive: `At Off the Curriculum, we are excited to announce the development of Ask Astro, our innovative fine-tuned multi-modal teaching assistant. Designed to create a dynamic educational learning environment, Ask Astro will provide personalised learning experiences tailored to each user’s interests and needs. Key Features of Ask Astro include: Bespoke Quizzes generation, unique course experiences, and a multi-modal learning environment - where educational video content from our course and explore catolouge are incorporated in to the student's unique learning environment. The performance of 'Ask Astro' is reviewed by our Educational Board and EDUCO content officers continually to ensure the highest quality of educational support. Our dedicated AI and Machine Learning team at OT are integrating cutting-edge technology to facilitate interactive learning and problem-solving. With Ask Astro, we aim to empower students by providing a personalised educational assistant that complements our evolving seminar and academic mentoring offerings, where students can explore significant global issues.`
}
export {
    educationPageContent, 
}