import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaEnvelope, FaExternalLinkAlt, FaInfoCircle } from 'react-icons/fa';
import { useState, useEffect } from 'react';

const GrievanceProcedure = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Breakpoints
  const isDesktop = windowWidth > 1024;
  const isTablet = windowWidth <= 1024 && windowWidth > 768;
  const isMobile = windowWidth <= 768;
  const isSmallMobile = windowWidth <= 480;

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isDesktop ? '20px' : isTablet ? '16px' : isMobile ? '12px' : '10px',
      gap: isDesktop ? '40px' : isTablet ? '24px' : isMobile ? '20px' : '16px',
      flexDirection: isMobile ? 'column' : 'row',
    },
    contentSection: {
      flex: '1',
      width: '100%',
      padding: isMobile ? '0 8px' : '0',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: isMobile ? '16px' : '20px',
      fontSize: isSmallMobile ? '0.9em' : '1em',
      padding: isSmallMobile ? '6px 10px' : '8px 12px',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : isTablet ? '2.2em' : isMobile ? '2em' : '1.8em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: isSmallMobile ? '10px' : '15px',
      letterSpacing: '-0.02em',
    },
    introSection: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: isDesktop ? '24px' : isMobile ? '16px' : '12px',
      marginBottom: isDesktop ? '24px' : isMobile ? '20px' : '16px',
    },
    introText: {
      fontSize: isDesktop ? '1.1em' : isMobile ? '1em' : '0.95em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: isDesktop ? '16px' : isMobile ? '12px' : '10px',
    },
    stepsGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '1fr' : 'repeat(2, 1fr)',
      gap: isDesktop ? '24px' : isTablet ? '20px' : '16px',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: isDesktop ? '24px' : isTablet ? '20px' : isMobile ? '16px' : '14px',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      transform: !isMobile && 'translateY(-2px)',
      boxShadow: !isMobile && '0 10px 20px rgba(0,0,0,0.1)',
    },
    cardTitle: {
      fontSize: isDesktop ? '1.4em' : isMobile ? '1.2em' : '1.1em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: isDesktop ? '16px' : isMobile ? '12px' : '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    },
    list: {
      listStyle: 'none',
      padding: 0,
      margin: `0 0 ${isMobile ? '16px' : '20px'} 0`,
    },
    listItem: {
      margin: isSmallMobile ? '6px 0' : '8px 0',
      paddingLeft: isSmallMobile ? '16px' : '20px',
      position: 'relative',
      fontSize: isSmallMobile ? '0.95em' : 'inherit',
      '&:before': {
        content: '"•"',
        position: 'absolute',
        left: 0,
        color: '#1F484C',
      }
    },
    orderedList: {
      paddingLeft: isMobile ? '16px' : '20px',
      margin: `0 0 ${isMobile ? '16px' : '20px'} 0`,
    },
    orderedListItem: {
      margin: isSmallMobile ? '6px 0' : '8px 0',
      color: '#4b5563',
      fontSize: isSmallMobile ? '0.95em' : 'inherit',
    },
    button: {
      display: 'inline-flex',
      alignItems: 'center',
      gap: '8px',
      padding: isMobile ? '10px 16px' : '12px 20px',
      backgroundColor: '#1F484C',
      color: 'white',
      border: 'none',
      borderRadius: '8px',
      cursor: 'pointer',
      fontSize: isMobile ? '0.95em' : '1em',
      fontWeight: '500',
      width: isMobile ? '100%' : 'auto',
      justifyContent: isMobile ? 'center' : 'flex-start',
    },
    contactInfo: {
      marginTop: isMobile ? '12px' : '16px',
      padding: isMobile ? '12px' : '16px',
      backgroundColor: '#f9fafb',
      borderRadius: '8px',
    },
    contactDetail: {
      display: 'flex',
      alignItems: 'center',
      gap: isSmallMobile ? '6px' : '8px',
      color: '#4b5563',
      marginBottom: isSmallMobile ? '6px' : '8px',
      fontSize: isSmallMobile ? '0.95em' : 'inherit',
    }
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentSection}>
        <button 
          onClick={() => navigate('/statements')} 
          style={styles.backButton}
        >
          <FaArrowLeft /> Back to Resources
        </button>

        <h1 style={styles.pageTitle}>Grievance Procedure</h1>

        <div style={styles.introSection}>
          <p style={styles.introText}>Welcome to Off the Curriculum's Grievance Procedure.</p>
          <p style={styles.introText}>We understand that concerns may arise, and we're committed to addressing them promptly and fairly. This procedure outlines the steps to follow when raising a grievance.</p>
          <p style={styles.introText}>Remember, your voice matters to us. Don't hesitate to speak up if you have any concerns.</p>
        </div>

        <div style={styles.stepsGrid}>
          <div style={styles.card}>
            <h2 style={styles.cardTitle}>
              <FaInfoCircle /> Step 1: Informal Resolution
            </h2>
            <p style={styles.introText}>Try to resolve the issue directly or seek guidance from:</p>
            <ul style={styles.list}>
              <li style={styles.listItem}>Young Person and Duty Officer: Nicole Curtis</li>
              <li style={styles.listItem}>Education Team Lead: Jawaria Suleman</li>
              <li style={styles.listItem}>Chair: Joseph Levermore</li>
            </ul>
            <button style={styles.button}>
              Contact a Guide <FaExternalLinkAlt />
            </button>
          </div>

          <div style={styles.card}>
            <h2 style={styles.cardTitle}>
              <FaInfoCircle /> Step 2: Formal Grievance Procedure
            </h2>
            <ol style={styles.orderedList}>
              <li style={styles.orderedListItem}>Submit a written complaint via our incident reporting portal</li>
              <li style={styles.orderedListItem}>Receive acknowledgment within 5 working days</li>
              <li style={styles.orderedListItem}>Investigation conducted</li>
              <li style={styles.orderedListItem}>Receive written response within 10 working days</li>
              <li style={styles.orderedListItem}>Appeal within 45 days if unsatisfied</li>
              <li style={styles.orderedListItem}>Final decision provided within 15 working days</li>
            </ol>
            <button style={styles.button}>
              File a Complaint <FaExternalLinkAlt />
            </button>
          </div>

          <div style={styles.card}>
            <h2 style={styles.cardTitle}>
              <FaInfoCircle /> Important Notes
            </h2>
            <ul style={styles.list}>
              <li style={styles.listItem}>All grievances are treated confidentially</li>
              <li style={styles.listItem}>Support is available throughout the process</li>
              <li style={styles.listItem}>Certain allegations must be reported to the LADO</li>
            </ul>
            <button style={styles.button}>
              Learn More <FaExternalLinkAlt />
            </button>
          </div>

          <div style={styles.card}>
            <h2 style={styles.cardTitle}>
              <FaInfoCircle /> Key Contacts
            </h2>
            <p style={styles.introText}><strong>Check Contact HR to use the reporting portal or email our HR Manager:</strong></p>
            <div style={styles.contactInfo}>
              <div style={styles.contactDetail}>
                <FaEnvelope />
                <strong>Tim Sui</strong>
              </div>
              <div style={styles.contactDetail}>
                <span>timsui@offthecurriculum.co.uk</span>
              </div>
            </div>
            <button style={styles.button}>
              Contact HR <FaExternalLinkAlt />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GrievanceProcedure;