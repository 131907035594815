import React from 'react';
import { FaTwitter, FaLinkedin, FaInstagram } from 'react-icons/fa';

const styles = {
  container: {
    maxWidth: '1200px',
    margin: '0 auto',
    padding: '40px 20px',
  },
  header: {
    marginBottom: '40px',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#1F484C',
    marginBottom: '20px',
  },
  metadata: {
    display: 'flex',
    gap: '20px',
    color: '#666',
    marginBottom: '30px',
  },
  authorSection: {
    display: 'flex',
    gap: '30px',
    padding: '30px',
    backgroundColor: '#f8f9fa',
    borderRadius: '15px',
    marginBottom: '40px',
  },
  authorImage: {
    width: '120px',
    height: '120px',
    borderRadius: '60px',
    objectFit: 'cover',
  },
  authorInfo: {
    flex: 1,
  },
  socialLinks: {
    display: 'flex',
    gap: '15px',
    marginTop: '15px',
  },
  socialIcon: {
    color: '#1F484C',
    fontSize: '20px',
    cursor: 'pointer',
  },
  content: {
    fontSize: '18px',
    lineHeight: '1.8',
    color: '#333',
  },
  relatedArticles: {
    marginTop: '60px',
  },
  relatedGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
    gap: '30px',
    marginTop: '20px',
  },
  articleCard: {
    borderRadius: '10px',
    overflow: 'hidden',
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    transition: 'transform 0.3s ease',
  },
  articleImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
  },
  articleInfo: {
    padding: '20px',
  },
  poem: {
    container: {
      padding: '30px',
      margin: '30px 0',
      backgroundColor: '#f8f9fa',
      borderLeft: '4px solid #1F484C',
      borderRadius: '4px',
      fontFamily: 'Georgia, serif',
    },
    line: {
      marginBottom: '16px',
      lineHeight: '1.8',
      fontSize: '18px',
      fontStyle: 'italic',
      color: '#2a2a2a',
    },
    emphasis: {
      fontWeight: 'bold',
      color: '#1F484C',
      fontSize: '20px',
    },
    question: {
      color: '#1F484C',
      fontWeight: '500',
      paddingLeft: '20px',
    },
    indent: {
      marginLeft: '40px',
    },
  },
  
  // Responsive styles for poem
  '@media (max-width: 768px)': {
    poem: {
      container: {
        padding: '20px',
        margin: '20px 0',
      },
      line: {
        fontSize: '16px',
        marginBottom: '12px',
      },
      emphasis: {
        fontSize: '18px',
      },
      indent: {
        marginLeft: '20px',
      },
    },
  }
};

const ArticleTemplate = ({ article }) => {
  const renderContent = (section, index) => {
    switch(section.type) {
      case 'heading':
        return <h2 key={index}>{section.content}</h2>;
      case 'paragraph':
        return <p key={index}>{section.content}</p>;
      case 'poem':
        return (
          <div key={index} style={section.style.container}>
            {section.content.map((line, lineIndex) => (
              <div 
                key={lineIndex} 
                style={{
                  ...section.style.line,
                  ...(line.includes('Young. Black. Neurodiverse') ? 
                    section.style.emphasis : {}),
                  ...(line.startsWith('Why') ? 
                    section.style.question : {}),
                  ...(line.startsWith(' ') ? 
                    section.style.indent : {})
                }}
              >
                {line}
              </div>
            ))}
          </div>
        );
      case 'image':
        return (
          <figure key={index}>
            <img src={section.url} alt={section.caption} />
            <figcaption>{section.caption}</figcaption>
          </figure>
        );
      default:
        return null;
    }
  };

  return (
    <div style={styles.container}>
      <header style={styles.header}>
        <h1 style={styles.title}>{article.title}</h1>
        
        <div style={styles.metadata}>
          <span>{new Date(article.metadata.date).toLocaleDateString()}</span>
          <span>·</span>
          <span>{article.metadata.readTime}</span>
          <span>·</span>
          <span>{article.metadata.category}</span>
        </div>
      </header>

      <section style={styles.authorSection}>
        <img src={article.author.image} alt={article.author.name} style={styles.authorImage} />
        <div style={styles.authorInfo}>
          <h3>{article.author.name}</h3>
          <p>{article.author.bio}</p>
          <div style={styles.socialLinks}>
            <FaTwitter style={styles.socialIcon} onClick={() => window.open(article.author.social.twitter)} />
            <FaLinkedin style={styles.socialIcon} onClick={() => window.open(article.author.social.linkedin)} />
            <FaInstagram style={styles.socialIcon} onClick={() => window.open(article.author.social.instagram)} />
          </div>
        </div>
      </section>

      <article style={styles.content}>
        {article.content.map((section, index) => renderContent(section, index))}
      </article>

      <section style={styles.relatedArticles}>
        <h2>Related Articles</h2>
        <div style={styles.relatedGrid}>
          {article.relatedArticles.map((related, index) => (
            <div key={index} style={styles.articleCard}>
              <img src={related.image} alt={related.title} style={styles.articleImage} />
              <div style={styles.articleInfo}>
                <h3>{related.title}</h3>
                <p>{related.category}</p>
                <p>{related.author}</p>
              </div>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default ArticleTemplate;