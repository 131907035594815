import React from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
const SideTabRegister = ({eventId, location}) => {
  const styles = {
    tab: {
      position: 'fixed',
      right: '-10px',
      top: '40%',
      transform: 'translateY(-50%) rotate(-90deg)',
      transformOrigin: 'right bottom',
      backgroundColor: '#E79B18',
      color: 'white',
      padding: '15px 30px',
      borderRadius: '10px 10px 0 0',
      cursor: 'pointer',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      zIndex: 1000,
      transition: 'background-color 0.3s ease',
    },
    text: {
      fontWeight: '500',
      fontSize: '1rem',
      letterSpacing: '0.5px',
      whiteSpace: 'nowrap',
    },
    icon: {
      transform: 'rotate(90deg)',
      display: 'inline-block',
      marginLeft: '8px',
    }
  };
  const navigate = useNavigate();

  const handleClick = () => {
    // Add your registration logic here
    navigate(location)
    
  };

  const handleMouseDown = (e) => {
    e.currentTarget.style.backgroundColor = '#163437'; // Darker shade for click effect
  };

  const handleMouseUp = (e) => {
    e.currentTarget.style.backgroundColor = '#1F484C'; // Return to original color
  };

  return (
    <>
    <div 
      style={styles.tab}
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp}
        >
      <span style={styles.text}>Click to Register Today!</span>
      <span style={styles.icon}>→</span>
    </div>
    </>
  );
};

export default SideTabRegister;