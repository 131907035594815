import React, { useState, useEffect} from 'react';

const ThingsYouNeedComponent = ({requirements}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const toggleCollapse = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const styles = {
    container: {
      borderRadius: '5px',
      padding: windowWidth < 768 ? '' : '10px',
      width: '100%',
      margin: '20px auto',
    },
    header: {
      backgroundColor: '#1F484C',
      padding: '10px',
      borderRadius: '5px 5px 0 0',
      cursor: 'pointer',
      color: '#fff',
      fontWeight: 'bold',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
      borderBottomLeftRadius: isOpen ? '0px' : '10px',
      borderBottomRightRadius:  isOpen ? '0px' : '10px',
      transition: 'border-radius 0.3s ease',
    },
    content: {
      padding: '10px',
      display: isOpen ? 'block' : 'none',
      border: '1px solid #ccc',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
      backgroundColor: '#fff',
    },
    qrCode: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '20px',
    },
    toggleButton: {
      background: 'none',
      border: 'none',
      color: '#fff',
      fontSize: '20px',
      cursor: 'pointer',
      
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.header} onClick={toggleCollapse}>
        <span>Things you will need...</span>
        <button style={styles.toggleButton}>{isOpen ? '-' : '+'}</button>
      </div>
      <div style={styles.content}>
        <p>{requirements.description} </p>
        {requirements.listItems && (
            <ul>
              {requirements.listItems.map((link, index) => (
                <li
                  key={index}
                >
                  {link.listItem}
                </li>
              ))}
            </ul>
          )}
        <ul>
          <li>
            Paper and writing materials
          </li>
          <li>
            A <b>plastic water bottle </b>(either a 1L bottle or 500ml bottle)
          </li>
        </ul>
      </div>
    </div>
  );
};

export default ThingsYouNeedComponent;
