import { styles } from "../../styles";

const LoadingGuestSpeakerComponent = () => {
  const outerContainerStyle = {
    display: 'flex',
    gap: '20px',
    width: '100%',
    marginTop: '2.5%',
    marginBottom: '5%'
  };

  const columnGuestStyle = {
    display: 'flex',
    flexDirection: 'column',  
    alignItems: 'center',
  };
  const columnGuestInfoStyle = {
    display: 'flex',
    flexDirection: 'column',  
    alignItems: 'center',
    width: '80%',
    justifyContent: 'center', 
  };

  const circleStyle = {
    width: '250px',
    height: '250px',
    borderRadius: '50%',
    backgroundColor: '#ddd', 
  };

  const lineStyle = {
    width: '80%',
    height: '10%',
    marginBottom: '2.5%',
    backgroundColor: '#ddd', 
    borderRadius: '20px',
    animation: 'pulse 1s ease-in-out infinite',
  };

  return (
    <div style={outerContainerStyle}>
      <div style={columnGuestStyle}> {/* Column for circle */}
        <div style={circleStyle} />
      </div>
      <div style={columnGuestInfoStyle}> {/* Column for lines */}
        <div style={lineStyle} />
        <div style={{ ...lineStyle, animationDelay: '0.2s' }} />
        <div style={{ ...lineStyle, animationDelay: '0.4s' }} />
      </div>
    </div>
  );
};

const LoadedGuestSpeakerComponent = ({data, wWidth}) => {
  const outerContainerStyle = {
    display: 'flex',
    flexDirection: wWidth <= 500 ? 'column' : 'row', // Stack elements vertically if window width is <= 500
    gap: '20px',
    width: wWidth <= 500 ? '100%' : '100%',
    margin: '2.5% auto', // Center the container horizontally
    alignItems: 'center',
  };

  const columnGuestStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  };

  const columnGuestInfoStyle = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: wWidth <= 500 ? 'center' : 'flex-start',
    width: '100%', // Make sure it occupies full width in column layout
    justifyContent: 'center',
    textAlign: wWidth <= 500 ? 'center' : 'left', // Center text for small screens
  };

  const imageStyle = {
    width: wWidth <= 500 ? '100%' : '250px',
    height: wWidth <= 500 ? '' : '',
    objectFit: 'cover',
  };

  // JSX
  return (
    <div>
      {data.map((items, index) => (
        <div key={index} style={outerContainerStyle}>
          <div style={columnGuestStyle}>
            <img
              style={imageStyle}
              src={items.guestSpeakerImage}
              alt={items.guestspeakerAlt}
            />
          </div>
          <div style={columnGuestInfoStyle}>
            <p>{items.guestspeakerBio}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export { LoadingGuestSpeakerComponent, LoadedGuestSpeakerComponent };
