const outreachPageContent ={
    headerOne: `Education by Off the Curriculum`, 
    teamVision: ``,
    pageIntroduction:`Our Seminar and EDUCO volunteers are a fundamental aspect of our charity and play a key role in ensuring we meet our educational standards. As a volunteer at Off the Curriculum you will receive training and development opportunities to ensure you are adequately prepared to support our participants in their educational journey.'. An example structure of our Project Seminar Series can be seen below:`, 
    headerTwo:`What does it mean to volunteer at Off the Curriculum`, 
    paraOne: `Being a volunteer at Off the Curriculum, means you have the opportunity to inspire and mentor future leaders on the subject you have chosen to study for your further education. There are four types of voluntary opportunities at Off the Curriculum: Seminar,EDUCO, Academic (EPQ) and Professional mentoring.`, 
    headerThree: `Testimonials`, 
    paraTwo: `Seminars at Off the Curriculum are changing, evolving from a guest speaker lead affair into a student-focused project based series. Our repeating five-week Project Seminar Series, will consist of five seminars where you will propose solutions to some of your generations biggest questions. Topics will range from 'Assessing COVID-19's impact on human civilisation', 'Navigating a Post-Truth World', and 'Inequality in the AI age'. 
    An example structure of our Project Seminar Series can be seen below:`, 
    headerFour: `Current team members`, 
    paraThree: `Seminars at Off the Curriculum are changing, evolving from a guest speaker lead affair into a student-focused project based series. Our repeating five-week Project Seminar Series, will consist of five seminars where you will propose solutions to some of your generations biggest questions. Topics will range from 'Assessing COVID-19's impact on human civilisation', 'Navigating a Post-Truth World', and 'Inequality in the AI age'. 
    An example structure of our Project Seminar Series can be seen below:`, 
    headerFive: `Join our team`, 
    paraFour: `Seminars at Off the Curriculum are changing, evolving from a guest speaker lead affair into a student-focused project based series. Our repeating five-week Project Seminar Series, will consist of five seminars where you will propose solutions to some of your generations biggest questions. Topics will range from 'Assessing COVID-19's impact on human civilisation', 'Navigating a Post-Truth World', and 'Inequality in the AI age'. 
    An example structure of our Project Seminar Series can be seen below:`, 

}
export {
    outreachPageContent, 
}