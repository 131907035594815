

const Events = [    
  {
    uid: 1,
    title: "Uncovering the Mysterious Life of Worms",
    imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fevents%2Funcoveringthemysteriouslifeofworms%2Funcoveringthemysteriouslifeofworms.webp?alt=media&token=c9ce723b-f161-44c2-975b-8719f1ae9bb9`,
    imageSrcAlt: 'Uncovering the Mysterious Life of Worms - Garden museum',
    date: `NOV`, 
    year: '23', 
    author: `Garden Museum`,
    time: `11:00-12:30`, 
    category: `seminar-series`,
    articleDesc: `
    Students will uncover the hidden world beneath their feet, exploring the influence soil biodiversity and earthworms has on  our planet's health, while creating their own sustainable wormery to continue their discoveries at home. 
        `,
    eventpagehref: `uncoveringthemysteriouslifeofworms`,
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea',
  }, 
]

export {
  Events
}