import React from 'react';
import { FaBrain } from 'react-icons/fa';

const AskAstro = ({ onClick }) => {
  return (
    <div style={styles.container} onClick={onClick}>
      <FaBrain style={styles.icon} />
      <span style={styles.text}>Ask Astro</span>
    </div>
  );
};

const styles = {
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#e6f7ff',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    borderTopRightRadius: '0',
    borderBottomRightRadius: '0',
    padding: '10px 20px 10px 15px',
    boxShadow: '-2px 2px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    '&:hover': {
      backgroundColor: '#bae7ff',
      boxShadow: '-4px 4px 15px rgba(0,0,0,0.15)',
    },
  },
  icon: {
    fontSize: '24px',
    color: '#ff9999',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#333',
  },
};

export default AskAstro;