/**dependencies */
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  deleteDoc,
  doc,
  updateDoc
} from "firebase/firestore";
import { ref, onValue } from "firebase/database";
import { useRef, useState, useEffect } from "react";
import { firestore, auth, db } from "../../../Firebase/firebaseConfig";
import { formatDistanceToNow } from 'date-fns';
import { shortStyles } from "./style";
import { emailsHasher } from "../../../Firebase/educoFunctions";
import ShortCommentBlueActionButton from "../Buttons/CommentButton/ShortCommentButton";

function useHover() {
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  return [hover, onMouseEnter, onMouseLeave];
}


function DisplayMessage({ id, name, timestamp, text, picUrl, currentUser, onDelete, onEdit, theme}) {
  const [editHover, onEditMouseEnter, onEditMouseLeave] = useHover();
  const [deleteHover, onDeleteMouseEnter, onDeleteMouseLeave] = useHover();
  const formattedTimestamp = timestamp ? formatDistanceToNow(timestamp.toDate(), { addSuffix: true }) : '';
  const isCurrentUser = currentUser && currentUser.displayName === name;

  return (
    <div style={shortStyles(theme).commentStyle}>
      <div style={{ display: 'flex' }}>
        <div style={shortStyles(theme).headerStyle}>
          <img style={shortStyles(theme).avatarStyle} src={picUrl} alt={name} />
          <div style={shortStyles(theme).infoStyle}>
            <span style={shortStyles(theme).userInfo}>{name}</span>
            <br />
            <span style={shortStyles(theme).postTime}>{formattedTimestamp}</span>
          </div>
        </div>
        <div style={shortStyles(theme).commentContainerStyle}>
          <div style={shortStyles(theme).paraCommentStyle}>
            <p>{text}</p>
            <span style={shortStyles(theme).arrowStyle}></span>
          </div>
        </div>
      </div>
      {isCurrentUser && (
        <div style={shortStyles(theme).actionsStyle}>
          <button
            style={{ ...shortStyles(theme).editButton, ...(editHover ? shortStyles(theme).editButtonHover : {}) }}
            onMouseEnter={onEditMouseEnter}
            onMouseLeave={onEditMouseLeave}
            onClick={() => onEdit(id, text)}>
            Edit
          </button>
          <button
            style={{ ...shortStyles(theme).deleteButton, ...(deleteHover ? shortStyles(theme).deleteButtonHover : {}) }}
            onMouseEnter={onDeleteMouseEnter}
            onMouseLeave={onDeleteMouseLeave}
            onClick={() => onDelete(id)}>Delete</button>
        </div>
      )}
      <span style={shortStyles.arrowStyle}></span>
    </div>
  );
}

export default function ShortsCommentSection({name=name, theme=theme}) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [editId, setEditId] = useState(null);
  const messageInputRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser) {
      const emailHash = emailsHasher(currentUser.email);
      const dbRef = ref(db, `person/${emailHash}`);
      onValue(dbRef, (snapshot) => {
        const profileData = snapshot.val();
        setStudent(profileData);
        setLoading(false);
      }, { onlyOnce: true });
    }
  }, [currentUser]);

  useEffect(() => {
    if (student && student.token === true) {
      const commentStore = name;
      const recentMessagesQuery = query(
        collection(firestore, "comments", "shortVideos", commentStore),
        orderBy("timestamp")
      );

      const unsubscribe = onSnapshot(recentMessagesQuery, (snapshot) => {
        const newMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(newMessages);
      });

      return unsubscribe;
    }
  }, [student]);

  async function saveMessage(messageText) {
    const commentStore = name;
    if (editId) {
      await updateDoc(doc(firestore, "comments", "shortVideos", commentStore, editId), {
        text: messageText,
        timestamp: serverTimestamp(),
      });
      setEditId(null);
    } else {
      try {
        await addDoc(collection(firestore, "comments", "shortVideos", commentStore), {
          name: auth.currentUser.displayName || 'Anonymous',
          text: messageText,
          profilePicUrl: student.displayPhotoUrl,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        //console.error("Error writing new message to Firebase Database", error);
      }
    }
  }

  async function deleteMessage(id) {
    const commentStore = name;
    //console.log(deleteMessage)
    try {
      await deleteDoc(doc(firestore, "comments", "shortVideos", commentStore, id));
    } catch (error) {
      //console.error("Error deleting message from Firebase Database", error);
    }
  }

  function handleInputChange(event) {
    setMessage(event.target.value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    if (message.trim() === "") return;

    await saveMessage(message);
    setMessage("");
    messageInputRef.current.focus();
  }

  function toggleChat() {
    setIsChatOpen(prevIsChatOpen => !prevIsChatOpen);
  }

  function handleEdit(id, text) {
    setMessage(text);
    setEditId(id);
    messageInputRef.current.focus();
  }
  //console.log('update', theme)
  return (
    <div style={shortStyles(theme).commentContainer}>
    <div style={shortStyles(theme).commentWrapper}>
      {messages.length > 0 ? (
        messages.map((message) => (
          <DisplayMessage
            key={message.id}
            id={message.id}
            name={message.name}
            timestamp={message.timestamp}
            text={message.text}
            picUrl={message.profilePicUrl}
            currentUser={currentUser}
            onDelete={deleteMessage}
            onEdit={handleEdit}
            theme={theme}
          />
        ))
      ) : (
        <div>No comments yet.</div>
      )}
    </div>
    <form style={shortStyles(theme).messageForm} onSubmit={handleFormSubmit}>
      <textarea
        style={shortStyles(theme).inputComment}
        autoComplete="on"
        spellCheck={false}
        placeholder="Add a comment here..."
        value={message}
        onChange={handleInputChange}
        ref={messageInputRef}
      />
      <ShortCommentBlueActionButton>
        {editId ? "Update Post" : "Post"}
      </ShortCommentBlueActionButton>
    </form>
  </div>
);
}