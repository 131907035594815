import React, { useState, useEffect, useMemo } from 'react';
import StandardButtonContainer from "../../../../Components/Common/Buttons/StandardButtons/StandardButtons";
import useAuth from '../../../../Firebase/Auth';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import { useNavigate } from 'react-router-dom';


const Modal = ({ show, onClose, mentorEmail, mentorId, title, description, specialties, languages, methods, imageUrl }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showComingSoon, setShowComingSoon] = useState(false);
  const [isRequestSending, setIsRequestSending] = useState();   
  const [hasRequested, setHasRequested] = useState(false);  
  const navigate = useNavigate(); 

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isMobile = windowWidth <= 550;

  const currentUser = useAuth();

  const handleBookTrialClick = async () => {
    if (!currentUser?.email || isRequestSending || hasRequested) {
      return; // Prevent multiple clicks
    }
  
    try {
      setIsRequestSending(true);
      const hashedEmail = emailsHasher(currentUser.email);
      //const mentorSearch = emailsHasher(mentorEmail);
      
      const sendMentorRequest = httpsCallable(functions, 'send_mentor_request');
      
      const result = await sendMentorRequest({
        userId: hashedEmail, 
        mentorId: mentorId,
        menteeEmail: currentUser.email,
      });
      
      //console.log('here', result)

      if (result.data?.success === true) {
        alert('Mentor request successful! Updating your portal! While you wait why not explore other programmes at OTC!');
        setHasRequested(true);
        // Optional: Show success message
        navigate('/events/uncoveringthemysteriouslifeofworms')
    }  else if (result.error?.status === "error") {
        if (result.data?.message === "You already have a pending mentor request") {
            alert('You have already requested a mentor, please wait for their response');
        }
    }
    } catch (error) {
      //console.error("Error sending mentor request:", error);
      alert('Failed to send request. Please try again later.');
    } finally {
      setIsRequestSending(false);
    }
  };

    {/*else if (result.error?.status === "error") {
    if (result.data?.message === "You already have a pending mentor request") {
      alert('You have already requested a mentor, please wait for their response');
    }
    if (result.error?.message === "You are not enrolled in our Excellence Programme") {
      alert('You are not enrolled on the Excellence Programme. Please re-complete the form and ensure to enrol as a student with OTC.');
      navigate('/createauserprofile');
    }
  }*/}

  const styles = useMemo(() => ({
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      backdropFilter: 'blur(5px)',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 100000,
      padding: isMobile ? '16px' : '24px'
    },
    modalContainer: {
      position: 'relative',
      backgroundColor: 'white',
      borderRadius: '24px',
      padding: 0,
      maxWidth: isMobile ? '100%' : '900px',
      width: '100%',
      maxHeight: '90vh',
      overflow: 'hidden',
      boxShadow: '0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)'
    },
    header: {
      padding: isMobile ? '16px' : '24px',
      borderBottom: '1px solid #FEFBF6',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      backgroundColor: '#FEFBF6'
    },
    content: {
      display: isMobile ? '' : 'flex',
      flexDirection: isMobile ? 'row' : 'row',
      gap: '24px',
      padding: isMobile ? '16px' : '24px',
      overflowY: 'auto',
      maxHeight: 'calc(90vh - 140px)'
    },
    imageContainer: {
      width: isMobile ? '100%' : '350px',
      flexShrink: 0
    },
    image: {
      width: '100%',
      aspectRatio: '1/1',
      objectFit: 'cover',
      borderRadius: '16px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)'
    },
    section: {
      padding: '16px',
      borderRadius: '12px',
      marginBottom: '16px'
    },
    tag: {
      padding: '8px 16px',
      backgroundColor: '#FDF5E8',
      borderRadius: '20px',
      fontSize: isMobile ? '13px' : '14px',
      color: '#E79B18',
      border: '1px solid #E79B18',
      fontWeight: '500',
      display: 'inline-flex',
      alignItems: 'center',
      gap: '4px',
      boxShadow: '0 1px 2px rgba(0, 0, 0, 0.05)'
    },
    comingSoon: {
      position: 'fixed',
      bottom: '24px',
      left: '50%',
      transform: 'translateX(-50%)',
      backgroundColor: '#2D3748',
      color: 'white',
      padding: '12px 24px',
      borderRadius: '8px',
      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1)',
      zIndex: 10000000,
      animation: 'slideUp 0.3s ease'
    },
    // Add a specific style for the about section
    aboutSection: {
      backgroundColor: '#FEFBF6',
      padding: '16px',
      borderRadius: '12px',
      marginBottom: '16px',
      maxHeight: '200px', // Set a fixed height
      overflow: 'hidden' // Hide overflow
    },
    loadingSpinner: {
      display: 'inline-block',
      width: '16px',
      height: '16px',
      marginRight: '8px',
      border: '2px solid rgba(255, 255, 255, 0.3)',
      borderRadius: '50%',
      borderTopColor: '#fff',
      animation: 'spin 1s linear infinite',
    },

    aboutContent: {
      maxHeight: '150px', // Leave space for the heading
      overflowY: 'auto', // Enable vertical scrolling
      padding: '4px', // Add some padding
      scrollbarWidth: 'thin', // For Firefox
      scrollbarColor: '#CBD5E0 transparent', // For Firefox
      '&::-webkit-scrollbar': {
        width: '6px',
      },
      '&::-webkit-scrollbar-track': {
        background: 'transparent',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#CBD5E0',
        borderRadius: '3px',
      }
    }
  }), [isMobile]);

  if (!show) return null;

  return (
    <div style={styles.overlay}>
      <div style={styles.modalContainer}>
        <div style={styles.header}>
          <h2 style={{ 
            margin: 0, 
            fontSize: isMobile ? '20px' : '24px',
            fontWeight: '600',
            color: '#1F484C'
          }}>
            {title}
          </h2>
          <button 
            onClick={onClose}
            style={{
              background: 'none',
              border: 'none',
              fontSize: '24px',
              cursor: 'pointer',
              padding: '8px',
              borderRadius: '50%'
            }}
          >
            ×
          </button>
        </div>

        <div style={styles.content}>
          <div style={styles.imageContainer}>
            <img src={imageUrl} alt={title} style={styles.image} />
          </div>

          <div style={{ flex: 1, minWidth: 0 }}>
            <div style={styles.section}>
              <h4 style={{ marginTop: '0px', marginBottom: '12px' }}>Specialties</h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {specialties.map((specialty, index) => (
                  <div key={index} style={styles.tag}>{specialty}</div>
                ))}
              </div>
            </div>

            <div style={styles.section}>
              <h4 style={{ marginBottom: '12px' }}>Languages</h4>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {languages.map((language, index) => (
                  <div key={index} style={styles.tag}>{language}</div>
                ))}
              </div>
            </div>

            <div style={styles.aboutSection}>
            <h4 style={{ marginTop: '0px', marginBottom: '12px' }}>About</h4>
            <div style={styles.aboutContent}>
              <p style={{ margin: 0, lineHeight: '1.6' }}>
                {description}
              </p>
            </div>
          </div>

            <div style={{ display: 'flex', gap: '12px', marginTop: 'auto' }}>
              <StandardButtonContainer>
                <StandardButtonContainer.ButtonTwo
                    onClick={handleBookTrialClick}
                    disabled={isRequestSending || hasRequested}
                    style={{
                      width: isMobile ? '100%' : 'auto',
                      padding: '12px 24px',
                      fontSize: '16px',
                      opacity: isRequestSending || hasRequested ? 0.7 : 1,
                      cursor: isRequestSending || hasRequested ? 'not-allowed' : 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                  {isRequestSending ? (
                    <>
                      <div style={styles.loadingSpinner} />
                      Sending Request...
                    </>
                  ) : hasRequested ? (
                    <>
                      <span style={{ marginRight: '8px' }}>✓</span>
                      Request Sent
                    </>
                  ) : (
                    "Send Mentor Request"
                  )}
                </StandardButtonContainer.ButtonTwo>
              </StandardButtonContainer>
            </div>
          </div>
        </div>

        {showComingSoon && (
          <div style={styles.comingSoon}>
            ✨ Scheduling feature coming soon! ✨
          </div>
        )}
      </div>

      <style>
        {`
          @keyframes slideUp {
            from { transform: translate(-50%, 100%); opacity: 0; }
            to { transform: translate(-50%, 0); opacity: 1; }
          }
        `}
      </style>
    </div>
  );
};

export default Modal;