import React, { useState } from 'react';
import DragAndDropQuiz from './DragDropComponent/DragDropComponent';
import MultipleChoiceQuizComponent from './MultipleChoiceComponent/MultipleChoiceComponent';
import QuizResults from './ResultsComponent/resultsComponent';
import { styles } from './styles';

const QuizPage = ({ questions, onComplete }) => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userAnswers, setUserAnswers] = useState(
    Array(questions.length).fill(null)
  );
  const [showResults, setShowResults] = useState(false);

  const currentQuestion = questions[currentQuestionIndex];

  const handleAnswer = (answer) => {
    setUserAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[currentQuestionIndex] = answer;
      return newAnswers;
    });
    handleNextQuestion();
  };

  const handleNextQuestion = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowResults(true);
    }
  };

  return (
    <div style={styles.containerStyle}>
      {!showResults ? (
        <>
          <h2>{currentQuestion.question}</h2>
          {currentQuestion.type === 'multipleChoice' && (
            <MultipleChoiceQuizComponent
              {...currentQuestion}
              onAnswer={handleAnswer}
            />
          )}
          {currentQuestion.type === 'dragAndDrop' && (
            <DragAndDropQuiz
              {...currentQuestion}
              onAnswer={handleAnswer}
            />
          )}
          {currentQuestionIndex < questions.length && ( 
            <button onClick={handleNextQuestion}>
              {currentQuestionIndex < questions.length - 1 ? 'Next Question' : 'End Quiz'}
            </button>
          )}
        </>
      ) : (
        <QuizResults 
          questions={questions} 
          userAnswers={userAnswers} 
          onComplete={onComplete} 
        />
      )}
    </div>
  );
};

export default QuizPage;
