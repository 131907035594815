const styles = {
  // Main container
  educoContainer: {
    width: '100%',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
  },
  recommendOverview: {
    width: '85%',
    marginBottom: '50px',
    marginTop: '50px',
  },
  firstEduco: {
    flex: '1 1 20%', // Grow to 20% of the container
    padding: '0px',
    zIndex: '-0',
    paddingLeft: '5px'
  },
  secondEduco: {
    flex: '1 1 75%', // Grow to 80% of the container
  },
  videoInfoContainer:{
    borderRadius: '25px',
    paddingTop: '30px',
    paddingBottom: '30px',
    justifyContent: 'space-between',
    marginRight: '50px'
  },
  videoContainer:{
    width: '100%',
    position: 'relative',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  videoEduoPlayer: {
    marginTop: '5%',
    marginBottom: '2.5%',
    display: 'block',
    position: 'static',
    borderRadius: '12px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
  },
  videoAnalytics: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    gap: '20px',
  },
  cardCourseTitle: {
    backgroundColor: 'white',
    boxShadow: '0 80% 80% 0 rgba(0, 0, 0, 0.2)',
    transition: '0.3s',
    alignIstems: 'center',
    margin: 'auto',
    borderRadius: '0.8rem',
  },
  educoTitle: {
    textAlign: 'left',
    paddingTop: '2.5%',
    paddingBottom: '1.5%',
  },
  educoSubjectCourse: {
    width: '80%',
  },
  // Next videos segment
  recommendedSeminar: {
    minWidth: '17.5rem',
    width: '30%',
    margin: '0.5%',
    contain: 'content',
    height: '15rem',
    overflowY: 'hidden',
    boxShadow: '0px 4px 8px 0 rgb(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
    padding: '5px',
    borderRadius: '2.5%',
  },
  // Likes and views section
  LikesViews: {
    float: 'left',
  },
  LikesViewsSpan: {
    display: 'inline',
    marginRight: '100px',
  },
  // Guest speaker component
  educoRow: {
    margin: 0,
  },
  educoRowAfter: {
    content: '""',
    display: 'table',
    clear: 'both',
  },
  guestSpeakerColumn: {
    flex: '1 1 48%',
    padding: '0 10px',
  },
  guestSpeakerImage: {
    height: '250px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  carouselContainer: {
    overflowX: 'none', /* Enable horizontal scrolling */
    whiteSpace: 'none', /* Prevent wrapping of carousel items */
  },
  carousel: {
    display: '',
    padding: 'none',
  },
  // next up section
  videoInfoStyle: {
    flex: '1',
  },
  videoTitle: {
    marginBottom: '15px', 
    color: '#000',
  },
  videoDetailsStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    color: 'lightgrey',
    fontSize: '0.9em',
  },
  videoItemStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#fff',
    marginBottom: '15px',
    borderRadius: '15px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
    padding: '10px',
    cursor: 'pointer',
  },
  thumbnailStyle:{
    width: '100%',
    height: '150px',
    backgroundColor: '#fff',
    marginBottom: '10px',
    borderRadius: '10px',
  }, 
  thumbnailLoadedStyle:{
    height: '130px',
    marginBottom: '10px',
    borderRadius: '10px',
  },
  courseInfographic:{
    height: '60px',
    verticalAlign: 'middle',
    paddingRight: '2.5%',
  },
  scrollableBox: {
    overflowY: 'auto',
    height: '500px',
    boxShadow: '5px 5px #ecebec',
  },
  quizOverlay:{
    alignItems: 'center',
    background: 'linear-gradient(0deg, #ffffffeb, #ffffffeb), #e89919',
    borderRadius: '12px',
    bottom: '0',
    display: 'flex',
    gap: '1rem',
    justifyContent: 'space-evenly',
    left: '0',
    margin: 'auto',
    maxHeight: '100%',
    width: '100%',
    position: 'absolute',
    right: '0',
    textAlign: 'center',
    top: '0',
    zIndex: 100,   
  },

  popupItemLinkHover: {
    color: '#FEFBF6',
    backgroundColor: '#1F484C',
    border: '2px solid #1F484C',
    transitionDuration: '0.3s',
    boxShadow: 'none',
  },
  
    closePopup: {
      position: 'absolute',
      top: 0,
      right: '5px',
      padding: '0.2rem',
      margin: 0,
      cursor: 'pointer',
      color: 'black',
      fontSize: '0.9rem',
      fontWeight: 700,
      transition: '0.2s',
      opacity: 0.5,
    },
  
    closePopupHover: {
      opacity: 1,
      borderRadius: '50%',
      backgroundColor: 'white',
    },
  
    externalQuizHeading: {
      marginBottom: '1rem',
    },
  
    externalQuizLink: {
      color: '#e89919',
    },
  
    externalQuizLinkHover: {
      textDecoration: 'none',
      color: '#e89919',
      opacity: 1,
    },
}

const lightTheme = {
  thumbnailStyle:{
    width: '100%',
    height: '200px',
    backgroundColor: 'grey',
    marginBottom: '10px',
    borderRadius: '10px',
  }, 
  videoItemStyle: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#f0f0f0',
    marginBottom: '15px',
    borderRadius: '15px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
    padding: '10px',
    cursor: 'pointer',
  },
}

const darkTheme = {
  thumbnailStyle: {
    width: '100%',
    height: '200px',
    backgroundColor: 'grey',
    marginBottom: '10px',
    borderRadius: '10px',
  }, 
  videoItemStyle: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#303030',
    marginBottom: '15px',
    borderRadius: '15px',
    boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
    padding: '10px',
  },
}

if (window.matchMedia('(max-width: 1000px)').matches) {
  Object.assign(styles.secondEduco, {
    width: '100%',
    flex: 'none',
  });
  Object.assign(styles.firstEduco, {
    width: '100%',
    flex: 'none',
    paddingBottom: '5%',
  });
}

if (window.matchMedia('(max-width: 1630px)').matches) {
  Object.assign(styles.videoEduoPlayer, {
    width: '80%',
    marginLeft: 'auto',
    marginRight: 'auto',
  });
  Object.assign(styles.recommendOverview, {
    width: '100%',
    marginBottom: '50px',
    marginTop: '0px',
  });
  Object.assign(styles.carouselContainer, {
    overflowX: 'auto',
    whiteSpace: 'nowrap',
  });
  Object.assign(styles.carousel, {
    display: 'flex', /* Display carousel items in a row */
    padding: '10px' /* Optional: Add padding for spacing between items */
  });
  Object.assign(styles.recommendedSeminar, {
    minWidth: '17.5rem',
    width: '30%',
    margin: '0.5%',
    contain: 'content',
    height: '15rem',
    overflowY: 'hidden',
    boxShadow: '0px 4px 8px 0 rgba(0, 0, 0, 0.2)',
    padding: '5px',
    borderRadius: '2.5%',
    flex: '0 0 auto', /* Prevent flex items from growing */
  });
}
if (window.matchMedia('(max-width: 1610px)').matches) {
  Object.assign(styles.guestSpeakerImage, {
    height: '100px',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto'
  });
}

if (window.matchMedia('(max-width: 991px)').matches) {
  Object.assign(styles.guestSpeakerColumn, {
    width: '100%',
    flex: 'none',
  });
}




export { styles, lightTheme, darkTheme }
