import { initializeApp } from "firebase/app";
import { getDatabase } from "firebase/database";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getFunctions } from "firebase/functions";

import {
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} from "firebase/app-check";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: "off-the-curriculum-8ef2a.firebaseapp.com",
  databaseURL:
    "https://off-the-curriculum-8ef2a-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};
//process.env.REACT_APP_FIREBASE_TOKEN;
let sitekey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
let debugToken = process.env.REACT_APP_FIREBASE_APPCHECK_DEBUG_TOKEN;
const app = initializeApp(firebaseConfig);

const auth = getAuth();

if (
  window.location.host === "localhost:3000" ||
  window.location.host === "localhost:3001"
) {
  // key for debug and prod
  if (process.env.NODE_ENV !== "production") {
    window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  }
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(sitekey, debugToken),
    isTokenAutoRefreshEnabled: true,
  });
} else {
  const appCheck = initializeAppCheck(app, {
    provider: new ReCaptchaEnterpriseProvider(sitekey),
    isTokenAutoRefreshEnabled: true,
  });
}

export { auth };
export { app };
export const fbStorage = getStorage();
export const firestore = getFirestore()

export const db = getDatabase();
export const functions = getFunctions();

/** initialize app-check
/*const appCheck =
  initializeAppCheck,
  ReCaptchaEnterpriseProvider,
} = require("firebase/app-check");
//  initialize app
const app = initializeApp(firebaseConfig);
// check if in debug mode for app-check token to work
{/
}
// Uploading test data to database
  const testData = {
    academicMentoring: {
      schools: {
        "school_001": {
          name: "Elm Academy",
          location: "Lambeth, London",
          teacher: "Mr. Henry Goodwin",
          students: {
            "student_001": {
              name: "John Smith",
              grade: "11",
              project: "Climate Change Analysis",
              mentor: "mentor_001",
              mentorId: "mentor_001",
              mentorBio: "mentor_001",
              stage: "Research",
              feedbackForms: {
                "feedback_001": {
                  student: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Very Engaging",
                    session_relevance: "Highly Relevant",
                    focus_areas: "Research methodology and literature review",
                    action_items: "Complete initial research outline, gather primary sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "The session was very helpful in clarifying my research direction"
                  },
                  mentor: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Highly Engaged",
                    session_relevance: "Very Relevant",
                    focus_areas: "Research methodology, literature review structure, source evaluation",
                    action_items: "Review research outline by next week, provide feedback on sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "Good session with clear objectives met",
                    mentee_progress_rating: "Improved",
                    mentee_progress_feedback: "Showing good understanding of research requirements"
                  }
                }
              }
            },
          },
            "student_002": {
              name: "Sarah Johnson",
              grade: "12",
              project: "Renewable Energy Solutions",
              mentor: "mentor_002",
              mentorId: "mentor_001",
              mentorBio: "mentor_001",
              stage: "Data Collection",
              feedbackForms: {
                "feedback_001": {
                  student: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Very Engaging",
                    session_relevance: "Highly Relevant",
                    focus_areas: "Research methodology and literature review",
                    action_items: "Complete initial research outline, gather primary sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "The session was very helpful in clarifying my research direction"
                  },
                  mentor: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Highly Engaged",
                    session_relevance: "Very Relevant",
                    focus_areas: "Research methodology, literature review structure, source evaluation",
                    action_items: "Review research outline by next week, provide feedback on sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "Good session with clear objectives met",
                    mentee_progress_rating: "Improved",
                    mentee_progress_feedback: "Showing good understanding of research requirements"
                  }
              }
            }
          }
        },
        "school_002": {
          name: "Harris Bermondsey",
          location: "London, United Kingdom",
          teacher: "Dr. Jamie Mcdougall",
          students: {
            "student_003": {
              name: "Michael Chen",
              grade: "10",
              project: "Machine Learning Applications",
              mentor: "mentor_003",
              stage: "Planning",
              feedbackForms: {
                "feedback_001": {
                  student: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Very Engaging",
                    session_relevance: "Highly Relevant",
                    focus_areas: "Research methodology and literature review",
                    action_items: "Complete initial research outline, gather primary sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "The session was very helpful in clarifying my research direction"
                  },
                  mentor: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Highly Engaged",
                    session_relevance: "Very Relevant",
                    focus_areas: "Research methodology, literature review structure, source evaluation",
                    action_items: "Review research outline by next week, provide feedback on sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "Good session with clear objectives met",
                    mentee_progress_rating: "Improved",
                    mentee_progress_feedback: "Showing good understanding of research requirements"
                  }
              }
            }
            },
            "student_004": {
              name: "Emily Rodriguez",
              grade: "11",
              project: "Urban Agriculture",
              mentor: "mentor_004",
              stage: "Implementation",
              feedbackForms: {
                "feedback_001": {
                  student: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Very Engaging",
                    session_relevance: "Highly Relevant",
                    focus_areas: "Research methodology and literature review",
                    action_items: "Complete initial research outline, gather primary sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "The session was very helpful in clarifying my research direction"
                  },
                  mentor: {
                    date: new Date("2024-10-27"),
                    session_engagement: "Highly Engaged",
                    session_relevance: "Very Relevant",
                    focus_areas: "Research methodology, literature review structure, source evaluation",
                    action_items: "Review research outline by next week, provide feedback on sources",
                    additional_catchup_needed: "Yes",
                    other_feedback: "Good session with clear objectives met",
                    mentee_progress_rating: "Improved",
                    mentee_progress_feedback: "Showing good understanding of research requirements"
                  }
              }
            }
            }
          }
        }
      }
    }
  };
  async function uploadTestData(db) {
    try {
      for (const [schoolId, schoolData] of Object.entries(testData.academicMentoring.schools)) {
        // Add school
        await setDoc(doc(db, "academicMentoring", schoolId), {
          name: schoolData.name,
          location: schoolData.location
        });
  
        // Add students
        for (const [studentId, studentData] of Object.entries(schoolData.students)) {
          await setDoc(doc(db, "academicMentoring", schoolId, "students", studentId), {
            name: studentData.name,
            grade: studentData.grade,
            project: studentData.project,
            mentor: studentData.mentor,
            stage: studentData.stage
          });
  
          // Add feedback forms
          for (const [formId, formData] of Object.entries(studentData.feedbackForms)) {
            await setDoc(
              doc(db, "academicMentoring", schoolId, "students", studentId, "feedbackForms", formId),
              {
                ...formData,
                date: new Date()  // Ensure date is a Firestore timestamp
              }
            );
          }
        }
      }
      console.log("Test data uploaded successfully!");
    } catch (error) {
      console.error("Error uploading test data:", error);
    }
  }
  
  // Usage
  uploadTestData(firestore);

*/