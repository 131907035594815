import React from 'react';
import { useParams } from 'react-router-dom';
import ArticleTemplate from './newsArticleTemplate';
import * as articles from './articles/index'

const NewsArticle = () => {
  const { articleId } = useParams();
  const article = articles[articleId];

  if (!article) {
    return <div>Article not found</div>;
  }

  return <ArticleTemplate article={article} />;
};

export default NewsArticle;