import React, { useState, useEffect } from 'react';
import LoadedContentComponent from "./LoadedContentComponent/LoadedContentComponent";
import UnLoadedPageContent from "./UnloadedContentComponent/UnloadedContentComponent";
import { emailsHasher } from '../../../Firebase/educoFunctions';
import { fetchNextVideos, fetchVideoData } from '../../../Firebase/educoFunctions';
import { ref, runTransaction, getDatabase } from 'firebase/database';
import useAuth from '../../../Firebase/Auth';
import { db } from '../../../Firebase/firebaseConfig';

const VideoPage = (props) => {
  const [data, setData] = useState(null);
  const [updatedviews, setUpdatedViews] = useState(null);
  const [nextVideos, setNextVideos] = useState(null);
  const currentUser = useAuth();
  const name = props.name;

  useEffect(() => {
    const fetchData = async () => {
      const result = await fetchVideoData(name);
      setData(result.videoData);
      setUpdatedViews(result.updatedCount);
      
      if (currentUser && result.videoData) {
        try {
          const emailHash = emailsHasher(currentUser.email);
          const userViewsRef = ref(db, `person/${emailHash}/views/${result.videoData.key}`);
          
          await runTransaction(userViewsRef, (currentViewsData) => {
            if (!currentViewsData) {
              return { number: 1, timestamp: Date.now() }; // First view
            } else {
              currentViewsData.number++; // Increment view count
              currentViewsData.timestamp = Date.now(); // Update timestamp
              return currentViewsData;
            }
          });
        } catch (error) {
          //console.error('Error updating user views:', error);
        }
      }
    };
    fetchData();
  }, [name, currentUser]); // Fetch data when name or currentUser changes

  useEffect(() => {
    const fetchRecommendedVideos = async () => {
      if (data && data.courseTag) {
        const courseTag = data.courseTag;
        const recommendedResults = await fetchNextVideos(courseTag);
        setNextVideos(recommendedResults);
      }
    };
    fetchRecommendedVideos();
  }, [data]);

  //console.log('logging', name, data)

  return(
    <React.Fragment>
      {data === null ? <UnLoadedPageContent name={name} /> : <LoadedContentComponent data={data} viewCount={updatedviews} nextVideos={nextVideos} />}
    </React.Fragment>
  );
};

export default VideoPage;
