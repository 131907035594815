import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import EllipsisText from '../../../Components/Common/EllipsisText/ellipsisText';
import { ImageComponent } from '../../../Pages/EDUCO/Explore/Components/VideoImageComponent';
import { CiHeart } from "react-icons/ci";
import { IoEyeOutline } from "react-icons/io5";
const VideoCard = ({ video, theme, linkStyling, windowWid }) => {
  const lightTheme = {
    thumbnailStyle: {
      width: '100%',
      height: '165px',
      backgroundColor: '#fff',
      marginBottom: '10px',
      borderRadius: '10px',
    },
    videoItemStyle: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#fff',
      marginBottom: '15px',
      borderRadius: '15px',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      padding: '10px',
      cursor: 'pointer',
      height: '250px',
    },
    videoDetailsStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: 'darkgrey',
      fontSize: '0.9em',
    },
    videoTitle: {
      marginBottom: '15px', 
      color: '#000',
    },
    videoSpan: {
      color: 'darkgrey',
      fontSize: windowWid <= 400 ? '18px' : '10px'
    },
  };

  const darkTheme = {
    thumbnailStyle: {
      width: '100%',
      height: '165px',
      backgroundColor: '#303030',
      marginBottom: '10px',
      borderRadius: '10px',
    },
    videoItemStyle: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: '#303030',
      marginBottom: '15px',
      borderRadius: '15px',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      padding: '10px',
      cursor: 'pointer',
      height: '250px',
    },
    videoDetailsStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: 'lightgrey',
      fontSize: '0.9em',
    },
    videoTitle: {
      marginBottom: '15px', 
      color: '#fff',
    },
    videoSpan: {
      color: 'lightgrey',
    },
  };

  const styles = theme === 'light' ? lightTheme : darkTheme;

  return (
    <Link
      style={linkStyling}
      to={`/${video.videohref}`}
    >
      <motion.div
        style={styles.videoItemStyle}
        whileHover={{
          scale: 1.1,
        }}
        initial={{
          scale: 1.0,
        }}
      >
        <div style={{width: '100%', height: '100%' }}>
          <div style={styles.thumbnailStyle}>
            <ImageComponent
              imageSrc={video.videoImage}
              imageAlt={video.videoImageAlt}
              videoSrc={video.videoSnippet}
              styles={styles.thumbnailStyle}
            />
          </div>
          <div style={styles.videoInfoStyle}>
            <EllipsisText text={video.key || ''} tag="h3" maxLength={25} style={styles.videoTitle} />
            <div style={styles.videoDetailsStyle}>
              <span style={styles.videoSpan}>
                {video.author}
              </span>
              <span style={styles.videoSpan}>
              <span>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <CiHeart style={{color: '#000'}} size={15} />:
                  </span>
                  <span>{video.likes}</span>
                </div>
                </span>
              </span>
              <span style={styles.videoSpan}>
                <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <IoEyeOutline style={{color: '#000'}} size={15} />:
                  </span>
                  <span>{video.count}</span>
                </div>
              </span>
            </div>
          </div>
        </div>
      </motion.div>
    </Link>
  );
};

export default VideoCard;