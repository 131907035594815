export default {
  id: 'mindfullness',
    title: "Mindfullness",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Dr. Joseph Michael Levermore",
      image: '',
      bio: "I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-05-10",
      readTime: "5 minutes",
      category: "wellness"
    },
    content: [
      {
        type: "heading",
        content: "Introduction"
      },
      {
        type: "paragraph",
        content: `Mindfulness is defined here as the "awareness that arises when paying attention to the present moment in a non-judgmental manner" (Kabat-Zinn, 1990). Practicing mindfulness can come in many different forms, including waking mindfully, a mindful pause, or meditating (Bishop et al., 2006; Baer, 2003). All exercises intend to allow the individual to be present and check-in with one's feelings and emotions (why not take a moment to focus on your breathing?; Pal et al., 2018).`
      },
      {
        type: "paragraph",
        content: `Practicing mindfulness has been associated with multiple health benefits, including enhanced working memory, a longer attention span, and an improved ability to switch between tasks (Slagter et al., 2007; Schuman-Olivier et al., 2020). Mindfulness has been found to improve emotional regulation, i.e., the management of stress and anxiety (Britton et al., 2016), and heighten motivation for learning (Schuman-Olivier et al., 2020).`
      },
      {
        type: "paragraph",
        content: `During this pandemic, we have been working from home far more often than most of us were previously used to, and education has also made a shift to online learning. We wanted to highlight some useful ways you can incorporate mindfulness into your daily routine in order to enhance your online learning experience during this strange time in all of our lives!`
      },
      {
        type: "heading",
        content: "Incorporate a mindful workout into your routine"
      },
      {
        type: "paragraph",
        content: `Add mindfulness to your workout. It doesn't matter if your idea of a workout is dancing to your favourite music or an online Zumba or pilates session- as long as whatever it gets you moving. Insert mindfulness into your workout by setting a clear goal for your workout session. An example goal outlined by Pal et al. (2018) is 'breathe deeply and notice the sensation of the breeze, the sun and the passing scenery' before you start an outdoor jogging session.`
      },
      {
        type: "heading",
        content: "Taking a minute between study sessions to have a 'mindful pause'"
      },
      {
        type: "paragraph",
        content: "After a study session, make sure to stand up, move around a little, and then why not incorporate a 'mindful pause' into your day. Pal et al. (2018) describes this as a moment to focus on the present moment, breathe deeply and pay attention to your emotions."
      },
      {
        type: "heading",
        content: "Meditation focussed on self-awareness"
      },
      {
        type: "paragraph",
        content: "Mindfulness-focussed meditation can be at any time of day, but we find doing it in the morning can help set a positive and open mindset in preparation for the day ahead. To make a meditation mindful, you can focus on the breath (Jacobson, 2021), perform a 'body-scan style of meditation where you take time to focus on each part of the body, from the toes to the head (Mayo Clinic, 2020)."
      },
      {
        type: "heading",
        content: "Add a mindful walk to your day"
      },
      {
        type: "paragraph",
        content: "You can make your walk mindful by staying 'present' throughout, notice your surroundings, and take in any sensory information or bodily sensations (Yorke, 2020). Incorporating exposure to nature also helps one relax, and time spent in nature has long been associated with better mental wellbeing (Bratman et al., 2019). You can also listen to a guided meditation intended for a walking session on apps such as 'Medito' and 'YouTube'. Remember to walk at a comfortable pace (Yorke, 2020)."
      },
      {
        type: "heading",
        content: "Mindful stretching"
      },
      {
        type: "paragraph",
        content: "Spend some time doing a sequence of stretches mindfully. Stretching can release stress and can counteract fatigue (Yorke, 2020). While you stretch, focus on taking deep breaths and observing how your body feels."
      },
      
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };