import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CiCalendar, CiMail } from "react-icons/ci";
import { FaChevronDown, FaArrowLeft } from "react-icons/fa";
import { GoPaperclip } from "react-icons/go";

const GrievanceForm = () => {
  const navigate = useNavigate();
  const [formType, setFormType] = useState('concern');
  const [date, setDate] = useState('');
  const [type, setType] = useState('');
  const [description, setDescription] = useState('');
  const [individuals, setIndividuals] = useState('');
  const [location, setLocation] = useState('');
  const [file, setFile] = useState(null);
  const [contactNumber, setContactNumber] = useState('');
  const [email, setEmail] = useState('');

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      gap: '40px',
    },
    contentSection: {
      flex: '1',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: '20px',
      fontSize: '1em',
    },
    pageTitle: {
      fontSize: '2.5em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '30px',
    },
    formTypeContainer: {
      display: 'flex',
      gap: '12px',
      marginBottom: '30px',
    },
    formTypeButton: {
      padding: '12px 24px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      backgroundColor: 'white',
      cursor: 'pointer',
      fontSize: '1em',
      transition: 'all 0.2s ease',
    },
    formTypeButtonActive: {
      backgroundColor: '#1F484C',
      color: 'white',
      border: '1px solid #1F484C',
    },
    formCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      marginBottom: '24px',
    },
    formGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '20px',
      marginBottom: '20px',
    },
    formField: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      fontSize: '0.95em',
      fontWeight: '500',
      color: '#374151',
      marginBottom: '8px',
    },
    inputWrapper: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
    },
    input: {
      width: '95%',
      padding: '12px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: '1em',
      transition: 'border-color 0.2s ease',
    },
    select: {
      width: '100%',
      padding: '12px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: '1em',
      appearance: 'none',
      backgroundColor: 'white',
    },
    textarea: {
      width: '95%',
      padding: '12px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: '1em',
      minHeight: '120px',
      resize: 'none',
    },
    fileUpload: {
      display: 'flex',
      alignItems: 'center',
      padding: '20px',
      borderRadius: '8px',
      border: '2px dashed #e5e7eb',
      cursor: 'pointer',
      position: 'relative',
      justifyContent: 'center',
      backgroundColor: 'white',
      width: '95%'
    },
    icon: {
      position: 'absolute',
      right: '12px',
      color: '#6b7280',
      pointerEvents: 'none',
    },
    submitButton: {
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '12px 24px',
      backgroundColor: '#1F484C',
      color: 'white',
      borderRadius: '8px',
      border: 'none',
      fontSize: '1em',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      marginTop: '20px',
    },
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    //console.log('Form submitted');
  };

  return (
    <div style={styles.container}>
      <div style={styles.contentSection}>
        <button 
          onClick={() => navigate('/statements')} 
          style={styles.backButton}
        >
          <FaArrowLeft /> Back to Resources
        </button>

        <h1 style={styles.pageTitle}>{formType === 'allegation' ? 'Report an allegation' : 'Submit a Concern'}</h1>
        <p style={styles.pageDescription}>
          Use this form to {formType === 'allegation' ? 'report an allegation' : 'voice a concern'}. All submissions are treated confidentially.
        </p>

        <div style={styles.formTypeContainer}>
          <button 
            style={{
              ...styles.formTypeButton,
              ...(formType === 'concern' ? styles.formTypeButtonActive : {})
            }}
            onClick={() => setFormType('concern')}
          >
            Voice Concern
          </button>
          <button 
            style={{
              ...styles.formTypeButton,
              ...(formType === 'allegation' ? styles.formTypeButtonActive : {})
            }}
            onClick={() => setFormType('allegation')}
          >
            Report Allegation
          </button>
        </div>

        <form onSubmit={handleSubmit}>
          <div style={styles.formCard}>
            <div style={styles.formGrid}>
              <div style={styles.formField}>
                <label style={styles.label}>Date of {formType}:</label>
                <div style={styles.inputWrapper}>
                  <input
                    type="text"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    placeholder="MM/DD/YYYY"
                    style={styles.input}
                  />
                  <CiCalendar size={20} style={styles.icon} />
                </div>
              </div>

              <div style={styles.formField}>
                <label style={styles.label}>{formType} Type:</label>
                <div style={styles.inputWrapper}>
                  <select
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    style={styles.select}
                  >
                    <option value="">Select type</option>
                    {formType === 'concern' ? (
                      <>
                        <option value="Seminar">About a seminar</option>
                        <option value="Video">Educo video content</option>
                        <option value="Course">Educo course</option>
                        <option value="Mentor">A mentor</option>
                        <option value="Website">Website not working</option>
                        <option value="Other">Other</option>
                      </>
                    ) : (
                      <>
                        <option value="Harassment">Harassment</option>
                        <option value="Discrimination">Discrimination</option>
                        <option value="Fraud">Fraud</option>
                        <option value="Other">Other</option>
                      </>
                    )}
                  </select>
                  <FaChevronDown size={16} style={styles.icon} />
                </div>
              </div>
            </div>

            <div style={styles.formField}>
              <label style={styles.label}>Description of {formType}:</label>
              <textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                style={styles.textarea}
                placeholder="Please provide detailed information..."
              />
            </div>

            <div style={styles.formField}>
              <label style={styles.label}>Names of Individuals Involved:</label>
              <textarea
                value={individuals}
                onChange={(e) => setIndividuals(e.target.value)}
                style={styles.textarea}
                placeholder="List all relevant individuals..."
              />
            </div>

            <div style={styles.formField}>
              <label style={styles.label}>Incident Location:</label>
              <input
                type="text"
                value={location}
                onChange={(e) => setLocation(e.target.value)}
                style={styles.input}
                placeholder="Where did this occur?"
              />
            </div>

            <div style={styles.formField}>
              <label style={styles.label}>Supporting Evidence:</label>
              <div style={styles.fileUpload}>
                <GoPaperclip size={20} style={{marginRight: '10px'}} />
                <span>Drag & drop a file or <span style={{textDecoration: 'underline'}}>browse</span></span>
                <input
                  type="file"
                  onChange={(e) => setFile(e.target.files[0])}
                  style={{opacity: 0, position: 'absolute', top: 0, left: 0, width: '100%', height: '100%'}}
                />
              </div>
            </div>

            <div style={styles.formGrid}>
              <div style={styles.formField}>
                <label style={styles.label}>Contact Number:</label>
                <div style={styles.inputWrapper}>
                  <input
                    type="tel"
                    value={contactNumber}
                    onChange={(e) => setContactNumber(e.target.value)}
                    style={styles.input}
                    placeholder="Your contact number"
                  />
                </div>
              </div>

              <div style={styles.formField}>
                <label style={styles.label}>Email:</label>
                <div style={styles.inputWrapper}>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    placeholder="Your email address"
                  />
                  <CiMail size={20} style={styles.icon} />
                </div>
              </div>
            </div>
            <button type="submit" style={styles.submitButton}>
            Submit {formType === 'allegation' ? 'Report' : 'Concern'}
          </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GrievanceForm;