import React, { useState } from 'react';
import { styles } from '../styles';

const MultipleChoiceQuizComponent = ({ question, options, correctAnswer }) => {
  const [selectedOption, setSelectedOption] = useState(null); // No need for <number | null> in JSX
  const [showResult, setShowResult] = useState(false);

  // Event Handler
  const handleOptionClick = (index) => {
    setSelectedOption(index);
    setShowResult(true);
  };
  const progressPercentage = showResult ? 100 : 0; 
  const progressBarStyle = {
    width: `${progressPercentage}%`,
    height: '8px',
    backgroundColor: '#4CAF50',
    borderRadius: '5px',
    marginBottom: '10px'
  }

  return (
    <div>
      <div style={progressBarStyle} /> 
      <div style={styles.questionStyle}>{question}</div>
      <div style={styles.optionsStyle}>
        {options.map((option, index) => (
          <button
            key={index}
            style={{
              ...styles.optionStyle,
              ...(styles.selectedOption === index ? styles.selectedOptionStyle : {}),
              ...(showResult ? (index === correctAnswer ? styles.correctOptionStyle : styles.incorrectOptionStyle) : {})
            }}
            onClick={() => handleOptionClick(index)}
            disabled={showResult}
          >
            {option}
          </button>
        ))}
      </div>

      {showResult && (
        <div style={styles.resultStyle}>
          {selectedOption === correctAnswer ? 'Correct!' : 'Incorrect'}
        </div>
      )}
    </div>
  );
};

export default MultipleChoiceQuizComponent;
