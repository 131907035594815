import React, { useState } from 'react';
import { motion } from 'framer-motion';

const LearningObjectives = ({ objectives }) => {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  const styles = {
    container: {
      marginTop: '3rem',
      marginBottom: '3rem'
    },
    header: {
      fontWeight: 'bold',
      marginBottom: '2rem'
    },
    objectivesList: {
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem'
    },
    objectiveItem: (isHovered) => ({
      display: 'flex',
      gap: '1.5rem',
      padding: '1.5rem',
      backgroundColor: isHovered ? '#fff' : '#fff',
      borderRadius: '8px',
      boxShadow: isHovered 
        ? '0 4px 12px rgba(0, 0, 0, 0.1)' 
        : '0 2px 4px rgba(0, 0, 0, 0.05)',
      cursor: 'default',
      transition: 'all 0.3s ease',
    }),
    objectiveBar: (color, isHovered) => ({
      width: isHovered ? '6px' : '4px',
      backgroundColor: color,
      borderRadius: '2px',
      alignSelf: 'stretch',
      transition: 'all 0.3s ease'
    }),
    objectiveContent: {
      flex: 1
    },
    objectiveTitle: (isHovered) => ({
      fontWeight: '500',
      color: '#000',
      marginBottom: '0.5rem',
      transform: isHovered ? 'translateX(4px)' : 'translateX(0)',
      transition: 'all 0.3s ease'
    }),
    objectiveDescription: (isHovered) => ({
      lineHeight: '1.6',
      color: '#000',
      transform: isHovered ? 'translateX(4px)' : 'translateX(0)',
      transition: 'all 0.3s ease'
    })
  };

  // Function to get different colors for the bars
  const getBarColor = (index) => {
    const colors = ['#e56251', '#4dabb5', '#f3ad1b', '#2C5282'];
    return colors[index % colors.length];
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Learning Objectives</h2>
      <div style={styles.objectivesList}>
        {objectives.map((objective, index) => (
          <motion.div
            key={index}
            style={styles.objectiveItem(hoveredIndex === index)}
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ 
              duration: 0.3, 
              delay: index * 0.1 
            }}
            whileHover={{
              scale: 1.02,
              transition: { duration: 0.2 }
            }}
          >
            <div
              style={styles.objectiveBar(
                getBarColor(index),
                hoveredIndex === index
              )}
            />
            <div style={styles.objectiveContent}>
              <h3 style={styles.objectiveTitle(hoveredIndex === index)}>
                {objective.title}
              </h3>
              <p style={styles.objectiveDescription(hoveredIndex === index)}>
                {objective.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default LearningObjectives;