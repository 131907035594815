import { motion } from 'framer-motion';
import React from 'react';
import ReactDOM from 'react-dom';

const ModalWrapper = ({ children, onClose }) => {
  return ReactDOM.createPortal(
    <div style={wrapperStyles.overlay}>
      <div style={wrapperStyles.modal}>
        {children}
        <motion.button
            style={wrapperStyles.closeButton}
            onClick={onClose}
            whileTap={{ scale: 0.9 }}
            whileHover={{ 
              scale: 1.3,
             }}
             >
              x
             </motion.button>
      </div>
    </div>,
    document.body
  );
};

const wrapperStyles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modal: {
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '10px',
    minWidth: '300px',
    top: '45px', 
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
  },
};

export default ModalWrapper;
