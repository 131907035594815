import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
} from 'react-share';

import { CiShare2 } from "react-icons/ci";

const MobileSocialShare = ({ canonicalUrl }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isOpen, setIsOpen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleClick = () => {
    setIsOpen(!isOpen)
  }
  const yPosition = windowWidth <= 550 ? -105 : -290;
  const shareButtons = [
    { Button: FacebookShareButton, Icon: FacebookIcon, color: "#1877F2" },
    { Button: WhatsappShareButton, Icon: WhatsappIcon, color: "#25D366" },
    { Button: TwitterShareButton, Icon: TwitterIcon, color: "#1DA1F2" },
    { Button: LinkedinShareButton, Icon: LinkedinIcon, color: "#0A66C2" }
  ];

  return (
      <div style={{
        position: 'relative', // Add this
        display: 'inline-block', // Add this
        zIndex: 10,
      }}>
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setIsOpen(!isOpen)}
          style={{
            width: '48px',
            height: '48px',
            borderRadius: '50%',
            backgroundColor: isOpen ? '#fff' : '#1F484C',
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
            transition: 'all 0.3s ease',
            zIndex: 1001,
            position: 'relative' // Add this
          }}
        >
          <CiShare2
            size={24}
            color= {isOpen ? "#1F484C" : '#FEFBF6'}
            style={{
              transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
              transition: 'transform 0.3s ease'
            }}
          />
        </motion.button>
  
        <AnimatePresence>
          {isOpen && (
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{
                opacity: 1,
                scale: 1,
              }}
              exit={{ opacity: 0, scale: 0.95 }}
              transition={{ duration: 0.2 }}
              style={{
                position: 'absolute', // Add this
                bottom: '100%', // Change this from '60px'
                right: '-16px', // Adjust to align with the button
                backgroundColor: '#FFFFFF',
                borderRadius: '16px',
                padding: '16px',
                boxShadow: '0 4px 20px rgba(0,0,0,0.12)',
                display: 'flex',
                gap: '35px',
                zIndex: 1000,
                width: 'max-content',
                transformOrigin: 'bottom right',
                marginBottom: '10px', // Add some space between button and popup
              }}
            >
            {shareButtons.map(({ Button, Icon, color }, index) => (
              <motion.div
                key={index}
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <Button
                  url={canonicalUrl}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}
                >
                  <Icon
                    size={40}
                    round={true}
                    bgStyle={{ fill: 'white' }}
                    iconFillColor={color}
                  />
                </Button>
              </motion.div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default MobileSocialShare;