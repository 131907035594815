import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import useAuth from '../../../../Firebase/Auth';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../Firebase/firebaseConfig';

const styles = {
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  section: {
    marginBottom: '30px',
  },
  fieldGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#000',
  },
  input: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',

  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    backgroundColor: '#fff',
  },
  textarea: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    height: '120px', 
    maxHeight: '120px',
    resize: 'none', 
    overflowY: 'auto', 
    margin: 'auto',
  },
  radioGroup: {
    display: 'flex',
    gap: '15px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkboxGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: '500',
    border: 'none',
    borderRadius: '24px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    minWidth: '120px',
    backgroundColor: '#1F484C',
    color: '#ffffff',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
};

const MentorIncidentForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const currentUser = useAuth();
  const [hashedEmail, setHashedEmail] = useState('');

  useEffect(() => {
    const fetchAuth = async () => {
      if (currentUser) {
        try {
          const hashed = emailsHasher(currentUser.email);
          setHashedEmail(hashed); // Store the hashed email in state
          // Update formData with username
          setFormData(prev => ({
            ...prev,
            username: hashed
          }));
        } catch (error) {
         //console.error('Error hashing email:', error);
        }
      }
    };
    fetchAuth();
  }, [currentUser]);

  const [formData, setFormData] = useState({
    username: '',
    name: '',
    menteeName: '', 
    incidentLocation: '',
    incidentDate: '',
    incidentTime: '',
    incidentDescription: '',
    witnesses: '',
    reported: '',
    reportedTo: '',
    reportedTitle: '',
    dateReported: '',
    notReportedReason: '',
    injuryNature: [],
    injuryDescription: '',
    firstAidProvided: '',
    firstAidProvider: '',
    firstAidDescription: '',
    printName: '',
    signatureDate: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' 
        ? (checked 
          ? [...prevState[name], value]
          : prevState[name].filter(item => item !== value))
        : value
    }));
  };

  const handleSubmitReport = async () => {
    // Check if all required fields are filled
    const requiredFields = {
      name: 'Name',
      menteeName: 'Mentee Name',
      incidentDate: 'Incident Date',
      incidentDescription: 'Incident Description',
      // Add other required fields as needed
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key]) => !formData[key])
      .map(([_, label]) => label);

    if (missingFields.length > 0) {
      alert(`Please fill in the following required fields:\n${missingFields.join('\n')}`);
      return;
    }

    // Ask user for confirmation
    const confirmed = window.confirm(
      'Please confirm that all information provided is accurate and true. Do you want to submit this incident report?'
    );

    if (!confirmed) {
      return;
    }

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      // Call the Firebase function
      const submitIncidentReport = httpsCallable(functions, 'submit_mentor_incident_report');
      const result = await submitIncidentReport(formData);

      if (result.data.success) {
        alert('Incident report submitted successfully!');
        // Clear form data
        setFormData({
          username: hashedEmail,
          name: '',
          menteeName: '', 
          incidentLocation: '',
          incidentDate: '',
          incidentTime: '',
          incidentDescription: '',
          witnesses: '',
          reported: '',
          reportedTo: '',
          reportedTitle: '',
          dateReported: '',
          notReportedReason: '',
          injuryNature: [],
          injuryDescription: '',
          firstAidProvided: '',
          firstAidProvider: '',
          firstAidDescription: '',
          printName: '',
          signatureDate: '',
        });
      } else {
        throw new Error(result.data.error || 'Failed to submit report');
      }
    } catch (error) {
      //console.error('Error submitting report:', error);
      setSubmitError(error.message);
      alert(`Error submitting report: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
      <h1>Incident / Accident Report</h1>

      <div style={styles.section}>
        <h2>Personal Information</h2>
        <div style={styles.fieldGroup}>
          <label htmlFor="name" style={styles.label}>Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
      </div>

      <div style={styles.section}>
        <h3 style={styles.heading}>
          Incident Description
        </h3>
        <div style={styles.fieldGroup}>
          <label htmlFor="name" style={styles.label}>Mentee name:</label>
          <input
            type="text"
            id="menteeName"
            name="menteeName"
            value={formData.menteeName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>
        <div style={styles.fieldGroup}>
          <label htmlFor="incidentLocation" style={styles.label}>Location:</label>
          <input
            type="text"
            id="incidentLocation"
            name="incidentLocation"
            value={formData.incidentLocation}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentDate" style={styles.label}>Date of Incident:</label>
          <input
            type="date"
            id="incidentDate"
            name="incidentDate"
            value={formData.incidentDate}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentTime" style={styles.label}>Time of Incident:</label>
          <input
            type="time"
            id="incidentTime"
            name="incidentTime"
            value={formData.incidentTime}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentDescription" style={styles.label}>Incident Description:</label>
          <textarea
            id="incidentDescription"
            name="incidentDescription"
            value={formData.incidentDescription}
            onChange={handleChange}
            style={styles.textarea}
            placeholder="In as much detail as possible, describe what caused the incident / accident / injury, what you were doing just before the incident, and what you did after the incident. Name any objects or substances involved."
          ></textarea>
        </div>

        <div style={styles.fieldGroup}>
          <label style={styles.label}>
            Did anyone see you get hurt?
          </label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="witnesses"
                value="yes"
                checked={formData.witnesses === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="witnesses"
                value="no"
                checked={formData.witnesses === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>

        {formData.witnesses === 'yes' && (
          <div style={styles.fieldGroup}>
            <label htmlFor="witnessList" style={styles.label}>If YES, list all witnesses:</label>
            <textarea
              id="witnessList"
              name="witnessList"
              value={formData.witnessList}
              onChange={handleChange}
              style={styles.textarea}
            ></textarea>
          </div>
        )}

        <div style={styles.fieldGroup}>
          <label style={styles.label}>
            Did you report this incident to anyone?
          </label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="reported"
                value="yes"
                checked={formData.reported === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="reported"
                value="no"
                checked={formData.reported === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>

        {formData.reported === 'yes' && (
          <>
            <div style={styles.fieldGroup}>
              <label htmlFor="reportedTo" style={styles.label}>Reported to Name:</label>
              <input
                type="text"
                id="reportedTo"
                name="reportedTo"
                value={formData.reportedTo}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.fieldGroup}>
              <label htmlFor="reportedTitle" style={styles.label}>Title:</label>
              <input
                type="text"
                id="reportedTitle"
                name="reportedTitle"
                value={formData.reportedTitle}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.fieldGroup}>
              <label htmlFor="dateReported" style={styles.label}>Date Reported:</label>
              <input
                type="date"
                id="dateReported"
                name="dateReported"
                value={formData.dateReported}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
          </>
        )}

        {formData.reported === 'no' && (
          <div style={styles.fieldGroup}>
            <label htmlFor="notReportedReason" style={styles.label}>If NO, explain why you chose not to report:</label>
            <textarea
              id="notReportedReason"
              name="notReportedReason"
              value={formData.notReportedReason}
              onChange={handleChange}
              style={styles.textarea}
            ></textarea>
          </div>
        )}
      </div>

      <div style={styles.section}>
        <h3 style={styles.heading}>Injury Description</h3>
        <div style={styles.fieldGroup}>
          <label style={styles.label}>Nature of Injury (select all that apply):</label>
          <div style={styles.checkboxGroup}>
            {['Abrasion, scrapes', 'Broken Bone', 'Bruise', 'Burn (heat)', 'Burn (chemical)', 'Concussion', 'Cut, laceration, puncture', 'Illness', 'Mental health concerns', 'Neglect', 'Safeguarding Concern', 'Sprain, strain', 'Other'].map(injury => (
              <label key={injury} style={styles.radioLabel}>
                <input
                  type="checkbox"
                  name="injuryNature"
                  value={injury}
                  checked={formData.injuryNature.includes(injury)}
                  onChange={handleChange}
                /> {injury}
              </label>
            ))}
          </div>
        </div>

        {formData.injuryNature.includes('Other') && (
          <div style={styles.fieldGroup}>
            <label htmlFor="otherInjuryDescription" style={styles.label}>Other injury description:</label>
            <input
              type="text"
              id="otherInjuryDescription"
              name="otherInjuryDescription"
              value={formData.otherInjuryDescription}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        )}

        <div style={styles.fieldGroup}>
          <label htmlFor="injuryDescription" style={styles.label}>Description of Injury:</label>
          <textarea
            id="injuryDescription"
            name="injuryDescription"
            value={formData.injuryDescription}
            onChange={handleChange}
            style={styles.textarea}
          ></textarea>
        </div>

        <div style={styles.fieldGroup}>
          <label style={styles.label}>
            Was first aid provided at the scene?
          </label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="firstAidProvided"
                value="yes"
                checked={formData.firstAidProvided === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="firstAidProvided"
                value="no"
                checked={formData.firstAidProvided === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>

        {formData.firstAidProvided === 'yes' && (
          <>
            <div style={styles.fieldGroup}>
              <label htmlFor="firstAidProvider" style={styles.label}>Who administered first aid?</label>
              <input
                type="text"
                id="firstAidProvider"
                name="firstAidProvider"
                value={formData.firstAidProvider}
                onChange={handleChange}
                style={styles.input}
              />
            </div>
            <div style={styles.fieldGroup}>
              <label htmlFor="firstAidDescription" style={styles.label}>Please describe the first aid administered:</label>
              <textarea
                id="firstAidDescription"
                name="firstAidDescription"
                value={formData.firstAidDescription}
                onChange={handleChange}
                style={styles.textarea}
              ></textarea>
            </div>
          </>
        )}

        <div style={styles.fieldGroup}>
          <label style={styles.label}>
            Was medical treatment necessary?
          </label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="medicalTreatment"
                value="yes"
                checked={formData.medicalTreatment === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="medicalTreatment"
                value="no"
                checked={formData.medicalTreatment === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>
      </div>

      <div style={styles.section}>
        <h3 style={styles.heading}>Medical Release</h3>
        <p>
          I hereby agree that Off the Curriculum, or its designated representative, will manage the requirements related to the incident report. Furthermore, I authorise any individual(s) who may possess relevant information to provide further details to Off the Curriculum in support of our investigation into the incident.
        </p>
        <div style={styles.fieldGroup}>
          <label htmlFor="printName" style={styles.label}>Print Your Name:</label>
          <input
            type="text"
            id="printName"
            name="printName"
            value={formData.printName}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div style={styles.fieldGroup}>
          <label htmlFor="signatureDate" style={styles.label}>Date:</label>
          <input
            type="date"
            id="signatureDate"
            name="signatureDate"
            value={formData.signatureDate}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
      </div>
      <motion.button
        onClick={handleSubmitReport}
        disabled={isSubmitting}
        style={{ ...styles.button }}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.98 }}
      >
        {isSubmitting ? 'Submitting...' : 'Submit Report'}
      </motion.button>

      {submitError && (
        <div style={{ color: 'red', marginTop: '10px' }}>
          {submitError}
        </div>
      )}
    </form>
  );
};

export default MentorIncidentForm;