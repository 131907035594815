import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';

// Action button imports
import BlueActionButton from '../../../Components/Common/Buttons/BlueActionButton/BlueActionButton';
import  MainCreateAccountButton, { SecondaryCreateAnAccountButton } from '../../../Components/Common/Buttons/CreateAnAccount/CreateAnAccountButtons';

// sign in with email and password function 
import { handleEmailAndPasswordSignIn } from '../../../Firebase/signInFunctions';

// Component 
import SignInWithComponent from './Components/SignInWith/SignInWith';
import { images, iconImages } from './Components/SignInWith/images';
import { motion } from 'framer-motion'; 

// Loading metaTag information
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

// icons
import { CiMail } from "react-icons/ci";
import { TbPassword } from "react-icons/tb";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";



const SignInComponent = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  const meta = pageMetaTags.signIn;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!email || !password) {
      alert('Please enter both email and password');
      return;
    }
    setIsSubmitting(true);
    try {
      await handleEmailAndPasswordSignIn(email, password, navigate);
    } catch (error) {
      //console.error('Sign in error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleButtonClick = async () => {
    if (!email || !password) {
      alert('Please enter both email and password');
      return;
    }
    setIsSubmitting(true);
    try {
      await handleEmailAndPasswordSignIn(email, password, navigate);
    } catch (error) {
      //console.error('Sign in error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClick = () => {
    navigate('/createaccount')
  }

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const containerStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 1200 ? 'column-reverse' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    margin: '2.5% auto',
    padding: '0 20px',
    maxWidth: '1600px',
  };

  const carouselStyle = {
    width: '100%',
    maxWidth: '1200px',
    height: windowWidth <= 1200 ? '400px' : '800px',
    border: '1px solid rgba(0, 0, 0, 0.05)',  
    borderRadius: '10px',
    backgroundColor: '#fff',
    marginRight: windowWidth <= 1200 ? '0' : '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  };

  const imageStyle = {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
  };

  const formContainerStyle = {
    padding: '2.5%', 
    width: '100%',
    maxWidth: windowWidth <= 1200 ? '480px' : '400px',
    boxShadow: `
      0px 1.8px 2.2px rgba(0, 0, 0, 0.028),
      0px 4.3px 5.3px rgba(0, 0, 0, 0.04),
      0px 8.1px 10px rgba(0, 0, 0, 0.05),
      0px 14.5px 17.9px rgba(0, 0, 0, 0.06),
      0px 65px 80px rgba(0, 0, 0, 0.01)
    `,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: windowWidth <= 1200 ? '0 auto' : '0',
    marginTop: windowWidth <= 1200 ? '50px' : '',
    borderRadius: '15px'
  };
  const inputContainerStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    alignItems: windowWidth <= 768 ? 'flex-start' : 'center',
    marginBottom: '20px',
    width: '100%',
    gap: '10px',
  };

  const labelStyle = {
    marginRight: windowWidth <= 768 ? '0' : '10px',
    width: windowWidth <= 768 ? '100%' : '100px',
    textAlign: windowWidth <= 768 ? 'left' : 'right',
  };

  const inputStyle = {
    width: '100%',
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '3px',
    flex: 1,
  };

  const buttonStyle = {
    backgroundColor: '#54AFB8',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '3px',
    cursor: 'pointer',
    marginBottom: '10px',
  };

  const formFooterStyle = {
    marginTop: '30px',
    display: 'flex',
    flexDirection: windowWidth <= 480 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth <= 480 ? 'flex-start' : 'center',
    width: '100%',
    gap: '10px',
  };

  const socialIconsStyle = {
    display: 'flex',
    gap: '10px',
  };
  
  const accountInfoStyle = {
    display: 'flex',
    flexDirection: windowWidth <= 480 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth <= 480 ? 'flex-start' : 'center',
    width: '100%',
    marginTop: '20px',
    gap: '10px',
  };

  const textStyle = {
    flex: 1,
    textAlign: 'left',
  };

  const buttonContainerStyle = {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  };
  const iconImagesStyle ={
    height: '40px', 
  }
  const styles = {
    inputGroup: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    icon: {
      position: 'absolute',
      top: '50%', 
      transform: 'translateY(-50%)', 
      left: window.innerWidth < 768 ? '10%' : '5%',
      width: window.innerWidth < 768 ? '16px' : '20px',
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      zIndex: 1,
    },
    input: {
      width: window.innerWidth < 768 ? '75%' : '100%',
      maxWidth: '500px',
      padding: window.innerWidth < 768 
        ? '12px 12px 12px 45px'
        : '12px 16px 12px 40px',
      borderRadius: '8px',
      border: '1px solid #e5e7eb',
      fontSize: window.innerWidth < 768 ? '14px' : '16px',
      outline: 'none',
      transition: 'border-color 0.2s, box-shadow 0.2s',
    },
    passwordGroup: {
      position: 'relative',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    eyeIcon: {
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      right: window.innerWidth < 768 ? '15%' : '5%',
      width: window.innerWidth < 768 ? '16px' : '20px',
      height: window.innerWidth < 768 ? '16px' : '20px',
      color: '#9ca3af',
      cursor: 'pointer',
      zIndex: 1,
    }
  };
  

  return (
    <div>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <div style={containerStyle}>
        <div style={carouselStyle}>
          <img src={images[currentIndex]} alt={`Caro ${currentIndex + 1}`} style={imageStyle} />
        </div>
        <div style={formContainerStyle}>
          <h2 style={{ fontSize: window.innerWidth <= 768 ? '24px' : '28px', color: '#1F484C'}}>
            Welcome to EDUCO
          </h2>
          <p>Please sign-in to your account to continue your learning journey: </p>
          <form onSubmit={handleSubmit}>
          <div style={styles.inputGroup}>
            <CiMail style={styles.icon} />
            <input 
              type="email" 
              name="email"
              placeholder="i.e. @gmail.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={{
                ...styles.input,
                boxShadow: focusedField === 'email' ? '0 0 0 1px #1F484C' : 'none',
                borderColor: focusedField === 'email' ? '#1F484C' : '#e5e7eb',
              }}
              onFocus={() => setFocusedField('email')}
              onBlur={() => setFocusedField(null)}
              required
            />
          </div>
          <br />
          <div style={styles.passwordGroup}>
            <TbPassword style={styles.icon} />
            <input 
              type={showPassword ? "text" : "password"}
              name="password"
              placeholder="i.e. OTCRules"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              style={{
                ...styles.input,
                boxShadow: focusedField === 'password' ? '0 0 0 1px #1F484C' : 'none',
                borderColor: focusedField === 'password' ? '#1F484C' : '#e5e7eb',
              }}
              onFocus={() => setFocusedField('password')}
              onBlur={() => setFocusedField(null)}
              required
            />
            <div
              onClick={() => setShowPassword(!showPassword)}
              style={styles.eyeIcon}
            >
              {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
            </div>
          </div>
            <div style={formFooterStyle}>
              <label>
                <input type="checkbox" /> Remember Me
              </label>
              <Link to="/ForgotPassword">Forgot Password?</Link>
            </div>
            <StandardButtonContainer>
              <StandardButtonContainer.ButtonTwo 
                type="submit" 
                onClick={handleButtonClick}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'LOGGING IN...' : 'LOGIN'}
              </StandardButtonContainer.ButtonTwo>
            </StandardButtonContainer>
            <br />
            <div style={accountInfoStyle}>
              <p>New to our platform?</p>
              <div style={buttonContainerStyle}>
                <StandardButtonContainer>
                  <StandardButtonContainer.ButtonOne
                  onClick={handleClick}
                  >
                  CREATE AN ACCOUNT
                  </StandardButtonContainer.ButtonOne>
                </StandardButtonContainer>
              </div>
            </div>
          </form>
          <p>or login with</p>
          <SignInWithComponent />
        </div>
      </div>
    </div>
  );
};

export default SignInComponent;