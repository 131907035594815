// Page headers for the site - stored in a scalable fashion
export const defaultMetaTags = {
    title: 'Off the Curriculum',
    description: 'Default description',
    url: 'https://default-url.com',
    image: 'https://default-url.com/default-image.jpg',
    siteName: 'Off the Curriculum',
    twitterCard: 'summary_large_image',
    twitterSite: '@otc_london',
    fbAppId: 'default_fb_app_id',
  };
  
  export const pageMetaTags = {
    home: {
      title: 'Off the Curriculum',
      description: 'Description for the home page',
      url: 'https://offthecurriculum.co.uk/home',
      image: 'https://your-site.com/home-image.jpg',
    },
    about: {
      title: 'About Off the Curriculum',
      description: 'Learn more about us',
      url: 'https://offthecurriculum.co.uk/about',
      image: 'https://your-site.com/about-image.jpg',
    },
    signIn: {
        title: 'Sign to EDUCO with Off the Curriculum',
        description: 'Learn more about us',
        url: 'https://offthecurriculum.co.uk/SignIn',
        image: 'https://your-site.com/about-image.jpg',
      },
    createAUserProfile: {
      title: 'Create A User Profile', 
      description: 'Create your user profile', 
      url: 'https://offthecurriculum.co.uk/CreateAUserProfile',
      image: 'https://your-site.com/about-image.jpg',
    }, 
    forgotPassword: {
      title: 'Forgot Password', 
      description: 'Forgot your user password', 
      url: 'https://offthecurriculum.co.uk/ForgotPassword',
      image: 'https://your-site.com/about-image.jpg',
    },
    Loading: {
      title: 'Loading: Educo page', 
      description: 'Loading: Educo page', 
      url: 'https://offthecurriculum.co.uk/Loading',
      image: 'https://your-site.com/about-image.jpg',
    },
    ExploreEduco: {
      title: 'Explore page', 
      description: 'Educo page', 
      url: 'https://offthecurriculum.co.uk/Explore',
      image: 'https://your-site.com/about-image.jpg',
    },
    ExploreEduco: {
      title: 'Explore page', 
      description: 'Educo page', 
      url: 'https://offthecurriculum.co.uk/Explore',
      image: 'https://your-site.com/about-image.jpg',
    },
    MentorRequestsPage:{
      title: 'Mentor page portal', 
      description: `Review and manage your incoming mentorship requests, visit the mentor 'Find a Mentor Page', and continue along the programme with your current students.`,
      url: 'https://offthecurriculum.co.uk/mentors/mentor-platform',
      image: 'https://your-site.com/about-image.jpg',
    },
    MentorExplorePage:{
      title: 'Mentor explore page', 
      description: `Get ready to dive deep into your favorite subjects with our expert tutors. From STEM fields to Humanities, our mentors are here to guide you.`,
      url: 'https://offthecurriculum.co.uk/mentors',
      image: 'https://your-site.com/about-image.jpg',
    }, 
    MentorProgrammePage:{
      title: 'Mentor explore page', 
      description: `Guide your mentee or mentor through their EPQ journey`,
      url: 'https://offthecurriculum.co.uk/mentors/mentorprogammepage',
      image: 'https://your-site.com/about-image.jpg',
    }, 
    MentorProgrammePage:{
      title: 'Mentor programme page', 
      description: `Guide your mentee or mentor through their EPQ journey`,
      url: 'https://offthecurriculum.co.uk/mentors/mentorprogammepage',
      image: 'https://your-site.com/about-image.jpg',
    },
    MentoringFeedbackForm:{
      title: 'Mentor feedback form page', 
      description: `Thank you for making time for the connect. We hope you found it useful! We want to hear your feedback so we can make them more productive. Please fill this quick survey and let us know your thoughts.`,
      url: 'https://offthecurriculum.co.uk/mentors/sessionfeedbackform',
      image: 'https://your-site.com/about-image.jpg',
    },
    ProfilePage:{
      title: 'Your OTC Profile Page', 
      description: `Your own Off the Curriculum Profile page.`,
      url: 'https://offthecurriculum.co.uk/mentors/sessionfeedbackform',
      image: 'https://your-site.com/about-image.jpg',
    },
    OurTeam:{
      title: 'Our OTC Team', 
      description: `Behind Off the Curriculum stands a remarkable team of changemakers. Our officers come from diverse professional backgrounds but share an unwavering commitment to educational empowerment. From groundbreaking researchers to industry leaders, each member brings unique insights and expertise to our mission.`,
      url: 'https://offthecurriculum.co.uk/ourteam',
      image: 'https://your-site.com/about-image.jpg',
    },
    OurTeam:{
      title: 'Our OTC Team', 
      description: `Behind Off the Curriculum stands a remarkable team of changemakers. Our officers come from diverse professional backgrounds but share an unwavering commitment to educational empowerment. From groundbreaking researchers to industry leaders, each member brings unique insights and expertise to our mission.`,
      url: 'https://offthecurriculum.co.uk/ourteam',
      image: 'https://your-site.com/about-image.jpg',
    },
    OurTrustees:{
      title: 'Our Trustees Team', 
      description: `Our trustees bring diverse expertise and experience to guide Off the Curriculum in its mission to empower young minds through education.`,
      url: 'https://offthecurriculum.co.uk/ourtrustees',
      image: 'https://your-site.com/about-image.jpg',
    },
    ContactForm:{
      title: 'Contact Off the Curriculum', 
      description: `We'd love to hear from you and help make a difference together.`,
      url: 'https://offthecurriculum.co.uk/contactus',
      image: 'https://your-site.com/about-image.jpg',
    },
    DonateCard:{
      title: 'Donate to Off the Curriculum', 
      description: `Your support is vital in helping us make education accessible to everyone. As donations are our primary source of revenue, every contribution directly impacts our ability to provide quality education and resources to those who need it most.`,
      url: 'https://offthecurriculum.co.uk/donate',
      image: 'https://your-site.com/about-image.jpg',
    },
    Education:{
      title: 'Education at Off the Curriculum', 
      description: `Education by Off the Curriculum is a truly world-leading academic opportunity designed to empower young people in their pursuit of success. By harnessing modern technology and innovative teaching methods, we provide a comprehensive, all-in-one flagship program that integrates one-to-one mentoring, live seminars, and interactive forums. Our educational approach is evolving, shifting from traditional guest speaker-led formats to dynamic, student-focused project-based series.`,
      url: 'https://offthecurriculum.co.uk/education',
      image: 'https://your-site.com/about-image.jpg',
    },
    Enrol:{
      title: 'Enrol in the Off the Curriculum programme', 
      description: `Take the first step towards expanding your horisons and joining our community of learners and innovators.`,
      url: 'https://offthecurriculum.co.uk/enrol',
      image: 'https://your-site.com/about-image.jpg',
    },
    EventsPortal:{
      title: 'Events with Off the Curriculum', 
      description: `The 2024/25 seminar series, we're thrilled to offer 12 engaging seminars each month, covering a diverse array of topics, including our popular Mighty Minds Wellbeing Seminars, thought-provoking discussions on Architecture, inspiring insights into Business and Innovation, and explorations into the History of Language.`,
      url: 'https://offthecurriculum.co.uk/events',
      image: 'https://your-site.com/about-image.jpg',
    },
    Outreach:{
      title: 'Outreach at Off the Curriculum', 
      description: `The 2024/25 seminar series, we're thrilled to offer 12 engaging seminars each month, covering a diverse array of topics, including our popular Mighty Minds Wellbeing Seminars, thought-provoking discussions on Architecture, inspiring insights into Business and Innovation, and explorations into the History of Language.`,
      url: 'https://offthecurriculum.co.uk/outreach',
      image: 'https://your-site.com/about-image.jpg',
    },
    Policies:{
      title: 'Policies at Off the Curriculum', 
      description: `Welcome to Off the Curriculum's Policy Center. Here you'll find all our organisational policies that guide our operations, ensure safety, and maintain high standards in all our activities.`,
      url: 'https://offthecurriculum.co.uk/policies',
      image: 'https://your-site.com/about-image.jpg',
    },
    ResourceLibrary:{
      title: 'Resource Library at Off the Curriculum', 
      description: `Welcome to Off the Curriculum's Resource Library. We understand you may need support outside of what we can offer. That's why we've curated a list of trusted external organisations - which are available to support you.`,
      url: 'https://offthecurriculum.co.uk/resourceLibrary',
      image: 'https://your-site.com/about-image.jpg',
    },
    BecomeAnOfficer:{
      title: 'Become an Officer at Off the Curriculum!', 
      description: `Be a part of history and apply to be an Officer at Off the Curriculum today!`,
      url: 'https://offthecurriculum.co.uk/volunteer/becomeanofficer',
      image: 'https://your-site.com/about-image.jpg',
    },
    Volunteer:{
      title: 'Volunteering at Off the Curriculum!', 
      description: `The Volunteering team is in charge of recruiting and managing short and long term volunteers for the Charity, whilst establishing partnerships with Universities across the UK. From their recruitment onwards, the team ensures that all volunteers enjoy their time with us and use their volunteering experience to grow personally and professionally.`,
      url: 'https://offthecurriculum.co.uk/volunteer',
      image: 'https://your-site.com/about-image.jpg',
    },
    AboutPage:{
      title: 'About Off the Curriculum!', 
      description: `We are empowering young people through education, mentoring, and community building.`,
      url: 'https://offthecurriculum.co.uk/about',
      image: 'https://your-site.com/about-image.jpg',
    },
    AdminHomepage:{
      title: 'Admin portal at Off the Curriculum!', 
      description: `We are empowering young people through education, mentoring, and community building.`,
      url: 'https://offthecurriculum.co.uk/about',
      image: 'https://your-site.com/about-image.jpg',
    },
    News:{
      title: 'Recent News at Off the Curriculum', 
      description: `Recent News at Off the Curriculum`,
      url: 'https://offthecurriculum.co.uk/about',
      image: 'https://your-site.com/about-image.jpg',
    },

      // Add page headers data below.... 
  };
  