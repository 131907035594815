// GuestSpeakerComponent.jsx
import React from 'react';
import { useState, useEffect } from 'react';
const GuestSpeaker = ({ speaker }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const styles = {
    container: {
      marginTop: '2rem',
      marginBottom: '2rem'
    },
    header: {
      fontWeight: 'bold',
      marginBottom: '1.5rem'
    },
    flexContainer: {
      display: 'flex',
      flexDirection: windowWidth < 768 ? 'column' : 'row',
      gap: '2rem'
    },
    imageColumn: {
      width: windowWidth < 768 ? '100%' : '33.333%'
    },
    image: {
        width: '100%',
        height: '300px',
        borderRadius: '0.5rem',
        objectFit: 'cover',
        objectPosition: 'center top', // This ensures the top of the image is always visible
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
    },
    bioColumn: {
      width: windowWidth < 768 ? '100%' : '66.666%'
    },
    speakerName: {
      fontWeight: '600',
      marginBottom: '0.5rem'
    },
    speakerTitle: {
      marginBottom: '1rem'
    },
    speakerBio: {
      lineHeight: '1.6',
      marginBottom: '1rem'
    },
    socialLinks: {
      display: 'flex',
      gap: '1rem'
    },
    socialLink: {
      color: '#1F484C',
      cursor: 'pointer',
      transition: 'color 0.3s ease',
      textDecoration: 'none'
    }
  };

  // Add hover effect for social links
  const handleLinkHover = (e) => {
    e.target.style.color = '#E79B18';
  };

  const handleLinkLeave = (e) => {
    e.target.style.color = '#1F484C';
  };

  // Add window resize listener for responsive behavior

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div style={styles.container}>
      <h2 style={styles.header}>Guest Speaker</h2>
      <div style={styles.flexContainer}>
        <div style={styles.imageColumn}>
          <img 
            src={speaker.image} 
            alt={speaker.name}
            style={styles.image}
          />
        </div>
        
        <div style={styles.bioColumn}>
          <h3 style={styles.speakerName}>{speaker.name}</h3>
          <p style={styles.speakerTitle}>{speaker.title}</p>
          <p style={styles.speakerBio}>{speaker.bio}</p>
          
          {speaker.socialLinks && (
            <div style={styles.socialLinks}>
              {speaker.socialLinks.map((link, index) => (
                <a
                  key={index}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={styles.socialLink}
                  onMouseEnter={handleLinkHover}
                  onMouseLeave={handleLinkLeave}
                >
                  {link.platform}
                </a>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default GuestSpeaker;