import React, { useState, useRef } from 'react';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { emailsHasher, publicUserHasher} from '../../../../Firebase/educoFunctions';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { ref } from 'firebase/storage';
import { fbStorage } from '../../../../Firebase/firebaseConfig';
import { uploadString, getDownloadURL } from 'firebase/storage';
import useAuth from '../../../../Firebase/Auth';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';

const colors = ['#f9b6a3', '#e56251', '#ed1f06', '#e79b18', '#52aeb8'];

const departments = [
  'Senior Charity Officers',
  'Education Officers',
  'Expert Panelists',
  'Recruitment Officers',
  'Profresh-Academic Mentoring',
  'Web-development Officers',
  'Policy Officers',
  'Multimedia Officers',
  'Admin Team',
];

const cropperOptions = {
  viewMode: 1,
  aspectRatio: 1,
  minCropBoxWidth: 750,
  minCropBoxHeight: 750,
  cropBoxResizable: false,
  data: { 
    width: 750,
    height: 750
  }
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '20px',
  },
  section: {
    marginBottom: '20px',
  },
  input: {
    width: '100%',
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  },
  textArea: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    height: '120px', 
    maxHeight: '120px',
    resize: 'none', 
    overflowY: 'auto', 
    margin: 'auto',
  },
  button: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  disclaimer: {
    fontSize: '14px',
    color: '#666',
    marginTop: '20px',
    padding: '10px',
    backgroundColor: '#f8f8f8',
    borderRadius: '4px',
  },
  fileInputContainer: {
    display: 'inline-flex',
    alignItems: 'center',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: '#fff',
  },
  fileInputText: {
    marginRight: '10px',
    color: '#666',
  },
  fileInputButton: {
    backgroundColor: '#f0f0f0',
    border: '1px solid #ccc',
    borderRadius: '4px',
    padding: '6px 12px',
    fontSize: '14px',
    cursor: 'pointer',
  },
  hiddenFileInput: {
    display: 'none',
  },
  // Preview styles
  previewContainer: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  previewContent: {
    maxWidth: '800px',
    margin: '0 auto',
  },
  profileHeader: {
    display: 'flex',
    gap: '20px',
    marginBottom: '30px',
  },
  previewImage: {
    width: '250px',
    height: '250px',
    borderRadius: '10px',
    objectFit: 'cover',
  },
  profileInfo: {
    flex: 1,
  },
  previewSection: {
    marginBottom: '20px',
  },
  previewActions: {
    display: 'flex',
    gap: '15px',
    justifyContent: 'center',
    marginTop: '30px',
  },
  editButton: {
    padding: '10px 20px',
    backgroundColor: '#666',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  submitButton: {
    padding: '10px 20px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  nameContainer: {
    display: 'flex',
    gap: '20px',
    width: '100%',
  },
  nameInput: {
    flex: 1,
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
  }
};
const additionalStyles = {
  imageWrapper: {
    position: 'relative',
    width: '200px',  // Slightly wider to accommodate the shift
    height: '200px',
    margin: '0 auto',
  },
  circleBackground: {
    position: 'absolute',
    right: '0',
    top: '0',
    width: '250px',
    height: '250px',
    borderRadius: '50%',
  },
  croppedImageCircle: {
    position: 'absolute',
    left: '0',
    top: '0',
    width: '250px',
    height: '250px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  colorPicker: {
    display: 'flex',
    justifyContent: 'center',
    gap: '10px',
    marginTop: '15px',
  },
  colorOption: {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    cursor: 'pointer',
    transition: 'transform 0.2s',
    marginLeft: '5px',
    marginRight: '5px',
  }
};

const CreateCharityProfile = () => {
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [borderColor, setBorderColor] = useState(colors[0]);
  const [aboutMe, setAboutMe] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [email, setEmail] = useState('');
  const [specialties, setSpecialties] = useState('');
  const [quote, setQuote] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [roleTitle, setRoleTitle] = useState('');
  const [department, setDepartment] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const currentUser = useAuth();
  const [isCropped, setIsCropped] = useState(false);

  const handleCropClick = () => {
    getCropData();
    setIsCropped(true);
  };


  // Cropper options for square profile photo
  const cropperOptions = {
    viewMode: 1,
    aspectRatio: 1,
    minCropBoxWidth: 250,
    minCropBoxHeight: 250,
    cropBoxResizable: false,
    data: { 
      width: 250,
      height: 250
    }
  };

  const handleImageChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  const getCropData = () => {
  if (typeof cropperRef.current?.cropper !== "undefined") {
    const cropper = cropperRef.current?.cropper;
    
    // Get the cropped canvas
    const croppedCanvas = cropper.getCroppedCanvas({
      width: 750,  // Match the preview dimensions
      height: 750
    });

    // Create a new canvas for the final image with background
    const finalCanvas = document.createElement('canvas');
    finalCanvas.width = 800;  // Increased canvas size
    finalCanvas.height = 800; // Increased canvas size
    const ctx = finalCanvas.getContext('2d');

    // Draw background circle (shifted right)
    ctx.beginPath();
    ctx.arc(775/2 + 20, 775/2, 775/2, 0, 2 * Math.PI);  // Offset the circle
    ctx.fillStyle = borderColor;
    ctx.fill();

    // Draw the image circle (shifted left)
    ctx.save();
    ctx.beginPath();
    ctx.arc(750/2, 750/2, 750/2, 0, 2 * Math.PI);
    ctx.clip();
    ctx.drawImage(croppedCanvas, 0, 0, 750, 750);
    ctx.restore();

    // Convert to base64
    const finalImage = finalCanvas.toDataURL('image/webp', 1.00);
    setCroppedImage(finalImage);
  }
};

  const handleAboutMeChange = (e) => {
    const text = e.target.value;
    if (text.split(/\s+/).length <= 150) {
      setAboutMe(text);
    }
  };
  const handleQuoteChange = (e) => {
    const text = e.target.value;
    if (text.split(/\s+/).length <= 100) { 
      setQuote(text);
    }
  };
  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const validateForm = () => {
    const isValid = 
      croppedImage &&
      firstName.trim() &&
      lastName.trim() &&
      aboutMe.trim() &&
      quote.trim() &&
      roleTitle.trim() &&
      department.trim() &&
      email.trim();
    
    setIsFormValid(isValid);
    return isValid;
  };

  const handlePreview = (e) => {
    e.preventDefault();
    if (validateForm()) {
      setShowPreview(true);
    } else {
      alert('Please fill in all required fields and crop your profile photo.');
    }
  };

  const handleSubmit = async () => {
    try {
      const userId = emailsHasher(currentUser?.email)
      const publicUserId = publicUserHasher(currentUser?.email)

      // First upload the image to Firebase Storage
      const imageRef = ref(fbStorage, `website/meetTheTeam/officers/${publicUserId}_profile.webp`);
      // Remove the data:image/jpeg;base64 prefix before uploading
      const imageData = croppedImage.split(',')[1];
      
      // Upload the image
      await uploadString(imageRef, imageData, 'base64');
      
      // Get the download URL
      const imageUrl = await getDownloadURL(imageRef);

      // Prepare the data for the Firebase Function
      const officerData = {
        publicUserId: publicUserId, 
        username: userId,
        firstName: firstName,
        lastName: lastName,
        fullName: `${firstName}_${lastName}`,
        quote: quote,
        department: department,
        roleTitle: roleTitle,
        linkedin: linkedin,
        email: email,
        profileImage: imageUrl
      };

      // Call the Firebase Function
      const submitProfile = httpsCallable(functions, 'submit_charity_profile_information');
      const result = await submitProfile(officerData);
      //console.log('logging result', result)
      if (result.data.success) {
        alert('Profile submitted successfully!');
        // Optionally redirect or clear form
      } else {
        throw new Error(result.data.error || 'Failed to submit profile');
      }
    } catch (error) {
      //console.error('Error submitting profile:', error);
      alert(`Error submitting profile: ${error.message}`);
    }
  };

  const ProfilePreview = () => (
    
    <div style={styles.previewContainer}>
      <h2>Preview Your Profile</h2>
      <div style={styles.previewContent}>
        <div style={styles.profileHeader}>
        <div style={additionalStyles.imageWrapper}>
          <img 
            src={croppedImage} 
            alt="Profile" 
            style={{
              position: 'absolute',
              left: '0',
              top: '0',
              width: '160px',
              height: '160px',
              objectFit: 'cover'
            }}
          />
        </div>
          <div style={styles.profileInfo}>
            <h3>{firstName} {lastName}</h3>
            <p>{aboutMe}</p>
          </div>
        </div>

        <div style={styles.previewSection}>
          <h3>Quote</h3>
          <p>{quote}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Role Title</h3>
          <p>{roleTitle}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Department</h3>
          <p>{department}</p>
        </div>

        <div style={styles.previewSection}>
          <h3>Contact Information</h3>
          <p>Email: {email}</p>
          {linkedin && <p>LinkedIn: {linkedin}</p>}
        </div>
        <div style={styles.previewActions}>
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonOne
              onClick={() => setShowPreview(false)}  
              >
                Edit Profile
            </StandardButtonContainer.ButtonOne>
            <StandardButtonContainer.ButtonTwo
              onClick={handleSubmit}
              >
                Submit Profile
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
        </div>
      </div>
    </div>
  );

  if (showPreview) {
    return <ProfilePreview />;
  }

  return (
    <div style={styles.container}>
      <h1>Create Charity Officer Profile</h1>
      <div style={styles.disclaimer}>
        <strong>Disclaimer:</strong> The information you provide, including your profile photo and about me section, will be posted on the mentor section of our website (See <Link to={'/meettheteam'}>here</Link>). Please ensure that you are comfortable with this information being shared before submitting your profile. You can edit your profile information at any time after submission.
      </div>
      <form onSubmit={handlePreview} style={styles.form}>
        <div style={styles.section}>
          <h2>Profile Photo</h2>
          <div style={styles.fileInputContainer} onClick={triggerFileInput}>
            <span style={styles.fileInputText}>
              {image ? '1 file selected' : 'No attached files'}
            </span>
            <span style={styles.fileInputButton}>Attach file</span>
            <input 
              type="file" 
              ref={fileInputRef}
              onChange={handleImageChange} 
              accept="image/*" 
              style={styles.hiddenFileInput}
            />
          </div>
          {image && (
            <>
              <Cropper
                ref={cropperRef}
                style={{ height: 400, width: '100%', marginTop: '10px' }}
                zoomTo={0.5}
                src={image}
                {...cropperOptions}
              />
              {!isCropped ? (
                <>
                <StandardButtonContainer>
                <StandardButtonContainer.ButtonTwo
                  onClick={handleCropClick} 
                  type="button" 
                  style={{marginTop: '10px'}}
                >
                  Crop Image
                </StandardButtonContainer.ButtonTwo>
                </StandardButtonContainer>
                </>
              ) : (
                <div style={{ marginTop: '10px', textAlign: 'center' }}>
                  <p style={{ marginBottom: '10px', color: '#666' }}>Choose a background color</p>
                  <StandardButtonContainer>
                  <StandardButtonContainer.ButtonOne
                    onClick={() => {
                      getCropData();
                    }} 
                    type="button" 
                  >
                    Update Color
                  </StandardButtonContainer.ButtonOne>
                  </StandardButtonContainer>
                </div>
              )}
            </>
          )}
          {croppedImage && (
            <div style={{marginTop: '20px'}}>
              <h3>Profile Image Preview</h3>
              <div style={additionalStyles.imageWrapper}>
                <img 
                  src={croppedImage} 
                  alt="Cropped" 
                  style={{
                    position: 'absolute',
                    left: '0',
                    top: '0',
                    width: '200px',
                    height: '200px',
                    objectFit: 'cover'
                  }}
                />
              </div>
              <div style={additionalStyles.colorPicker}>
                {colors.map(color => (
                  <motion.div
                    key={color}
                    style={{
                      ...additionalStyles.colorOption,
                      backgroundColor: color,
                      border: color === borderColor ? '2px solid black' : 'none',
                      boxShadow: color === borderColor ? '0 0 0 2px white, 0 0 0 4px ' + color : 'none'
                    }}
                    onClick={() => {
                      setBorderColor(color);
                    }}
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  />
                ))}
              </div>
            </div>
          )}
        </div>

        <div style={styles.section}>
          <h2>Name</h2>
          <div style={styles.nameContainer}>
            <input 
              type="text" 
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              placeholder="First Name"
              style={styles.nameInput}
              required
            />
            <input 
              type="text" 
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              placeholder="Last Name"
              style={styles.nameInput}
              required
            />
          </div>
        </div>

        <div style={styles.section}>
          <h2>About Me</h2>
          <textarea 
            value={aboutMe} 
            onChange={handleAboutMeChange} 
            placeholder="Tell us about yourself (max 150 words)"
            style={styles.textArea}
          />
          <p>Word count: {aboutMe.split(/\s+/).filter(Boolean).length}/150</p>
        </div>

        <div style={styles.section}>
          <h2>Inspirational Quote</h2>
          <textarea 
            value={quote} 
            onChange={handleQuoteChange} 
            placeholder="List your areas of expertise (e.g., Physics, Toxicology, Biology)"
            style={styles.textArea}
          />
          <p>Word count: {quote.split(/\s+/).filter(Boolean).length}/100</p>  
        </div>

        <div style={styles.section}>
          <h2>Department</h2>
          <select
            value={department}
            onChange={(e) => setDepartment(e.target.value)}
            style={{
              ...styles.input,
              height: '40px',
              cursor: 'pointer'
            }}
          >
            <option value="">Select Department</option>
            {departments.map((dept) => (
              <option key={dept} value={dept}>
                {dept}
              </option>
            ))}
          </select>
        </div>

        <div style={styles.section}>
          <h2>Role Title</h2>
          <input 
            type="text" 
            value={roleTitle} 
            onChange={(e) => setRoleTitle(e.target.value)} 
            placeholder="Outline your role title, i.e., Recruitment Manager"
            style={styles.input}
          />
        </div>

        <div style={styles.section}>
          <h2>Social Links</h2>
          <input 
            type="text" 
            value={linkedin} 
            onChange={(e) => setLinkedin(e.target.value)} 
            placeholder="LinkedIn URL"
            style={{...styles.input, marginTop: '10px'}}
          />
          <input 
            type="email" 
            value={email} 
            onChange={(e) => setEmail(e.target.value)} 
            placeholder="Email Address"
            style={{...styles.input, marginTop: '10px'}}
          />
        </div>
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonTwo
            onClick={handlePreview}     
            >
              Preview Profile
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
      </form>

    </div>
  );
};

export default CreateCharityProfile
