// src/hooks/useSetIsContentLoaded.js

import { useEffect, useContext } from 'react';
import { LoadingContext } from '../../../Roots/LoadingContent';

const useSetIsContentLoaded = (delay) => {
  const { setIsContentLoaded } = useContext(LoadingContext);

  useEffect(() => {
    // Timer for rendering circles
    const timer = setTimeout(() => {
      setIsContentLoaded(true);
    }, delay);

    return () => clearTimeout(timer);
  }, [setIsContentLoaded, delay]);
};

export default useSetIsContentLoaded;
