const volunteerPageContent = {
    headerOne: "Volunteering with Off the Curriculum",
    teamVision: "Empowering the next generation through mentorship, knowledge-sharing, and hands-on educational experiences.",
    pageIntroduction: "We believe every student deserves access to quality education and professional guidance, regardless of their background. Join our dynamic community of educators, professionals, and change-makers who are reshaping educational possibilities for young minds. As a volunteer, you'll receive comprehensive training and development opportunities while making a lasting impact on students' lives.",
    headerTwo: "What does it mean to volunteer at Off the Curriculum?",
    paraOne: "Make a difference by sharing your expertise and passion with aspiring young leaders. Whether you're leading interactive seminars, creating educational content, mentoring EPQ students, or providing professional guidance, you'll help bridge the gap between academic knowledge and real-world success. Choose from four impactful roles: Seminar Volunteer, EDUCO Content Creator, Academic Mentor, or Professional Mentor.",
    headerThree: "Why Volunteer With Us?",
    paraTwo: "Be part of an innovative educational movement that tackles real-world challenges. Our Project Seminar Series addresses crucial topics like 'COVID-19's impact on society', 'Navigating a Post-Truth World', and 'Inequality in the AI age'. You'll guide students through critical thinking, problem-solving, and creative solutions while developing your own leadership and communication skills.",
    headerFour: "Our Impact",
    paraThree: "Through our volunteer network, we're creating transformative educational experiences that open doors to new opportunities. Our hands-on approach combines academic excellence with practical skills, preparing students for future success while building meaningful connections between professionals and aspiring learners.",
    headerFive: "Join Our Mission",
    paraFour: "Whether you can spare a few hours or commit to long-term mentoring, your expertise can help shape the future of education. We offer flexible volunteering options, comprehensive training, and the chance to be part of a community dedicated to educational empowerment. Together, we can create pathways to success for every student, regardless of their circumstances.",
  };
  
  export {
    volunteerPageContent,
  };