import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

const Statements = () => {
  const navigate = useNavigate();
  const { statementId } = useParams();
  const [selectedCategory, setSelectedCategory] = useState('all');
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > 1200;
  const isTablet = windowWidth <= 1200 && windowWidth >= 768;
  const isMobile = windowWidth < 768;

  const handleStatementClick = (statement) => {
    navigate(`/statements/${statement.id}`, {
      state: {
        statementDetails: statement
      }
    });
  };

  const statements = [
    {
      id: 'resourcelibrary/charityhelplines',
      title: 'Charity Helplines Directory',
      description: 'A comprehensive list of helplines and support services available through Off the Curriculum and our partner organisations for young people in need.',
      category: 'Support Services',
      dateUpdated: '2024-10-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'resourcelibrary/grievanceprocedure',
      title: 'Grievance Process Guide',
      description: 'Step-by-step guidance on how to raise and resolve concerns or grievances within Off the Curriculum, ensuring fair and transparent resolution.',
      category: 'Process Guides',
      dateUpdated: '2024-09-20',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'resourcelibrary/grievanceform',
      title: 'Grievance Reporting Form',
      description: 'Step-by-step form to either voice a concern or report an allegation.',
      category: 'Process Guides',
      dateUpdated: '2024-09-20',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'resourceLibrary',
      title: 'Resource Library',
      description: `Welcome to Off the Curriculum's Resource Library. We understand you may need support outside of what we can offer. That's why we've curated a list of trusted external organisations - which are available to support you`,
      category: 'Resources',
      dateUpdated: '2024-10-01',
      approvedBy: 'Tim Siu'
    },
    {
      id: 'resourcelibrary/whistleblowingpolicyguide',
      title: 'Whistleblowing Policy Guide',
      description: 'Off the Curriculum is committed to maintaining an open and accountable workplace. If you have concerns about wrongdoing within our organization...',
      category: 'Process Guides',
      dateUpdated: '2024-09-15',
      approvedBy: 'Joseph Levermore'
    },
    {
      id: 'resourceLibrary/edistatement',
      title: 'Equality, Diversity and Inclusion Commitment',
      description: 'Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. We want everyone to feel like they belong.',
      category: 'Resources',
      dateUpdated: '2024-10-10',
      approvedBy: 'Jawaria Suleman'
    },
    {
      id: 'resourceLibrary/diversitystatement',
      title: 'Promoting Anti-Discriminatory Practice',
      description: 'At Off the Curriculum, we are dedicated to fostering an inclusive and welcoming environment for all young people. Our commitment to anti-discriminatory practice is central to our mission and operations.',
      category: 'Resources',
      dateUpdated: '2024-09-30',
      approvedBy: 'Tim Siu'
    }
  ];

  const categories = ['all', ...new Set(statements.map(statement => statement.category))];

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      gap: '24px',
      flexDirection: isDesktop ? 'row' : 'column',
      width: '100%',
      boxSizing: 'border-box',
    },
    leftSection: {
      flex: isDesktop ? '0 0 300px' : 'auto',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginBottom: isDesktop ? '0' : '24px',  // Simplified
      width: isDesktop ? 'auto' : '100%',
    },
    rightSection: {
      flex: '1',
      width: '100%',
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : '2em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '24px',
    },
    filterContainer: {
      display: 'flex',
      flexDirection: isDesktop ? 'column' : 'row',  // Simplified
      gap: '8px',
      overflowX: isDesktop ? 'visible' : 'auto',  // Simplified
      paddingBottom: isDesktop ? '0' : '16px',  // Simplified
      WebkitOverflowScrolling: 'touch',
    },
    filterButton: {
      padding: '12px 16px',
      border: '1px solid #e5e7eb',
      borderRadius: '8px',
      cursor: 'pointer',
      textAlign: 'left',
      transition: 'all 0.2s ease',
      fontSize: '0.95em',
      fontWeight: '500',
      whiteSpace: isDesktop ? 'normal' : 'nowrap',  // Simplified
      minWidth: isDesktop ? '100%' : 'auto',  // Simplified
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: isDesktop ? 'repeat(2, 1fr)' : '1fr',  // Simplified
      gap: '20px',
      width: '100%',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      height: '100%',
      minHeight: '200px',
    },
    contentContainer: {
      padding: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      justifyContent: 'space-between',
    },
    itemTitle: {
      fontSize: '1.2em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '12px',
      lineHeight: '1.3',
    },
    categoryTag: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#f3f4f6',
      color: '#111827',
      borderRadius: '6px',
      fontSize: '0.875em',
      fontWeight: '500',
      marginBottom: '12px',
      alignSelf: 'flex-start',
    },
    itemDescription: {
      fontSize: '0.95em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '16px',
      flex: 1,
    },
    metadataContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
      paddingTop: '16px',
      borderTop: '1px solid #e5e7eb',
      marginTop: 'auto',
    },
    metadataItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#6b7280',
      fontSize: '0.825em',
    },
    filterHeader: {
      fontSize: '1.1em',
      fontWeight: '600',
      marginBottom: '12px',
      color: '#111827',
      display: isDesktop ? 'block' : 'none',  // Simplified
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '20px',
      height: '20px',
    },
    activeFilter: {
      backgroundColor: '#1F484C',
      color: 'white',
      border: '1px solid #1F484C',
    },
    inactiveFilter: {
      backgroundColor: 'white',
      color: '#374151',
    },
  };

  const filteredStatements = selectedCategory === 'all'
    ? statements
    : statements.filter(statement => statement.category === selectedCategory);

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return new Date(dateString).toLocaleDateString('en-GB', options);
  };

  return (
    <div style={styles.container}>
      <div style={styles.leftSection}>
        <h1 style={styles.pageTitle}>Statements & Resources</h1>
        <p style={styles.pageDescription}>
          Access Off the Curriculum's comprehensive collection of statements, guides, and resources. These materials support our commitment to transparency, education, and support for all participants.
        </p>
        
        <div style={styles.filterContainer}>
          <h2 style={styles.filterHeader}>
            Filter by Category
          </h2>
          {categories.map(category => (
            <button
              key={category}
              style={{
                ...styles.filterButton,
                ...(selectedCategory === category ? styles.activeFilter : styles.inactiveFilter)
              }}
              onClick={() => setSelectedCategory(category)}
            >
              {category.charAt(0).toUpperCase() + category.slice(1)}
            </button>
          ))}
        </div>
      </div>

      <div style={styles.rightSection}>
        <div style={styles.grid}>
          {filteredStatements.map((item, index) => (
            <div 
              key={index}
              style={styles.gridItem}
              onClick={() => handleStatementClick(item)}
            >
              <div style={styles.contentContainer}>
                <h2 style={styles.itemTitle}>{item.title}</h2>
                <div style={styles.categoryTag}>
                  {item.category}
                </div>
                <p style={styles.itemDescription}>{item.description}</p>
                <div style={styles.metadataContainer}>
                  <div style={styles.metadataItem}>
                    <span role="img" aria-label="calendar" style={styles.icon}>📅</span>
                    <span style={styles.dateLabel}>Updated:</span> {formatDate(item.dateUpdated)}
                  </div>
                  <div style={styles.metadataItem}>
                    <span role="img" aria-label="approved" style={styles.icon}>✓</span>
                    <span style={styles.approvedLabel}>Approved by:</span> {item.approvedBy}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Statements;