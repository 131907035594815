import React from 'react';
import { styles } from '../styles';

const QuizResults = ({ questions, userAnswers, onComplete }) => {
  return (
    <div style={styles.resultsContainer}>
      <h2>Quiz Results</h2>
      {questions.map((q, index) => (
        <div key={index} style={styles.resultItem}>
          {userAnswers[index] === q.correctAnswer ? (
            <>
              <span style={styles.correctIcon}>&#10004;</span> Correct answer
            </>
          ) : (
            <>
              <span style={styles.incorrectIcon}>&#x2718;</span> Incorrect answer
            </>
          )}
          <div>{index + 1}.</div>
        </div>
      ))}
      <button onClick={onComplete}>Close</button>
    </div>
  );
};

export default QuizResults;
