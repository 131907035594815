import { useEffect, useRef } from "react";
import { motion, useAnimation } from "framer-motion";
import { Link } from "react-router-dom";

function StarButton({ children, to }) {
  const controls = useAnimation();
  const buttonRef = useRef(null);

  useEffect(() => {
    const runAnimations = async () => {
      const animations = Array.from({ length: 20 }).map(() => ({
        opacity: 1,
        x: randomNumberBetween(-20, 20),
        y: randomNumberBetween(-20, 20),
        scale: randomNumberBetween(1.5, 2.5),
      }));

      await controls.start(animations, { duration: 0.4, at: "<" });
      await controls.start({ opacity: 0, scale: 0 }, { duration: 0.3, at: "<" });
    };

    if (controls) {
      runAnimations();
    }
  }, [controls]);

  const randomNumberBetween = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };

  return (
    <>
      <Link to={to}>
        <motion.div
        animate={{
          scale: [1.25, 1.5, 1.25],
        }}
        style={{
          paddingLeft: '20px'
        }}
        >
        <motion.button
          whileHover={{ scale: 1.1, 
            backgroundColor: '#52aeb8', color:'#FFF',
            boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)' 
          }}
          whileTap={{ scale: 0.9 }}
          animate={{
            backgroundColor: ['#FFF', '#f9b6a3', '#ed1f06', '#e79b18', '#52aeb8', '#FFF'], 
            color:['#000', '#FFF', '#FFF', '#FFF', '#FFF', '#000']
          }}
          className="StarButton"
          onClick={() => controls.start()} 
          // Start animation on button click
          ref={buttonRef}
          style={{
            backgroundColor: "#FFFFFF",
            color: "#1c1c1e",
            borderRadius: "999px",
            border: "1px solid #d1d5db",
            padding: "8px 16px",
            fontSize: "16px",
            fontWeight: "600",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {children}
          <span className="arrow">→</span>
          <span
            aria-hidden
            style={{
              pointerEvents: "none",
              position: "absolute",
              inset: "0",
              zIndex: "0",
            }}
          >
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
              {Array.from({ length: 20 }).map((_, index) => (
                <motion.svg
                  key={index}
                  style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    opacity: 0,
                  }}
                  className={`sparkle-${index}`}
                  viewBox="0 0 122 117"
                  width="10"
                  height="10"
                >
                  <motion.path
                    style={{ fill: "#3182ce" }}
                    d="M64.39,2,80.11,38.76,120,42.33a3.2,3.2,0,0,1,1.83,5.59h0L91.64,74.25l8.92,39a3.2,3.2,0,0,1-4.87,3.4L61.44,96.19,27.09,116.73a3.2,3.2,0,0,1-4.76-3.46h0l8.92-39L1.09,47.92A3.2,3.2,0,0,1,3,42.32l39.74-3.56L58.49,2a3.2,3.2,0,0,1,5.9,0Z"
                  />
                </motion.svg>
              ))}
            </motion.div>
          </span>
        </motion.button>
        </motion.div>
      </Link>
    </>
  );
}

export default StarButton;
