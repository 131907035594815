import React from 'react';
import './BlackButton.css';
import { Link } from 'react-router-dom';

const BlackButton = ({ children, to, onClick}) => {
  return (
    <Link to={to} className='black-button-link'>
        <button className="black-button" onClick={onClick}>
        {children}
        <span className="arrow">→</span>
        </button>
    </Link>
  );
};

export default BlackButton;