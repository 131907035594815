import React from 'react';
import { RiTwitterXLine } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { CiMail } from "react-icons/ci";
// meta tags 
import MetaTags from '../../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../../Components/Common/MetaTags/metaData';

const OurTrustees = () => {
  const meta = pageMetaTags.OurTrustees;
  const styles = {
    mainContainer: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#1a1a1a'
    },
    introduction: {
      fontSize: '1.1rem',
      lineHeight: '1.6',
      textAlign: 'center',
      marginBottom: '3rem',
      color: '#444444'
    },
    trusteesGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '2rem',
      padding: '1rem'
    },
    trusteeCard: {
      backgroundColor: '#ffffff',
      borderRadius: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
      overflow: 'hidden',
      transition: 'transform 0.3s ease',
      cursor: 'pointer'
    },
    imageContainer: {
      width: '100%',
      paddingTop: '100%',
      position: 'relative',
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    },
    image: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)', // Centers the image
      width: '90%',
      height: '90%',
      objectFit: 'cover'
    },
    cardContent: {
      padding: '1.5rem'
    },
    trusteeTitle: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginBottom: '0.5rem',
      color: '#1a1a1a'
    },
    trusteePosition: {
      fontSize: '1rem',
      color: '#666666',
      marginBottom: '0.25rem'
    },
    trusteeInstitution: {
      fontSize: '0.9rem',
      color: '#888888',
      fontStyle: 'italic',
      marginBottom: '1rem'
    },
    socialLinks: {
      display: 'flex',
      gap: '1rem',
      marginTop: '1rem'
    },
    socialIcon: {
      width: '24px',
      height: '24px',
      opacity: '0.7',
      transition: 'opacity 0.2s ease',
      cursor: 'pointer'
    },
    description: {
      fontSize: '1rem',
      lineHeight: '1.5',
      color: '#555555'
    },
    // Hover states
    cardHover: {
      transform: 'translateY(-5px)'
    },
    iconHover: {
      opacity: '1'
    }
  };

  const mailProtector = () => {
    const email = 'trustees@offthecurriculum.co.uk';
    window.location.href = `mailto:${email}`;
  };

  const handleTwitter = () => {
    const twitter = 'https://twitter.com/otc_london';
    window.open(twitter, '_blank', 'noopener,noreferrer');
  };

  const handleLinkedIn = () => {
    const linkedIn = 'https://www.linkedin.com/company/offthecurriculum-otc/';
    window.open(linkedIn, '_blank', 'noopener,noreferrer');
  };

  const trustees = [
    {
      name: 'Dr. Ian Mudway',
      position: 'Senior Lecturer in Immunology',
      institution: 'Imperial College London',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FianMudway.webp?alt=media&token=c7cb9f15-41b4-497f-a2dc-9b80e65eeead',
      description: `Senior Lecturer in the School of Public Health at Imperial College London with over 25 years of experience. Specialises in science communication and works with artists and educators to enhance public understanding of environmental health risks.`
    },
    {
      name: 'Dr. Rashada Harry (LLB, LLM)',
      position: 'Enterprize Technologist',
      institution: 'Amazon Web Services',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FrashadaHarry.webp?alt=media&token=28186f56-db41-4e64-bdc0-ad38f21e6cbe',
       description: `Enterprise Technology Leader at AWS with an LLM from LSE. Board Trustee at Talawa and EngineeringUK, Advisory board member for Amazon Black Employee Network UK and Women@AWS, and Founding Member of UKBlackTech.`
    },
    {
      name: 'Dr. Sweta Raghavan',
      position: 'Research and Development',
      institution: `Chairman's Core Team`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FswetaRaghavan.webp?alt=media&token=c8b45e2f-7a9b-440b-b0a1-c1658d4915fb',
       description: `King's College London Ph.D. graduate specializing in respiratory illness research. Distinguished mentor to over 3,000 students across UK & India, recognized as Most Influential Life Science Expert 2020 by Global Health Pharma News Magazine.`
    },
    {
      name: 'Dr. Stephanie Wright',
      position: 'Lecturer in Environmental Toxicology',
      institution: 'Imperial College London',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FstephanieWright.webp?alt=media&token=045e1fa7-9e19-49c6-8c71-4d8dda3ef12c',
      description: `Lecturer at the MRC Centre for Environment and Health, School of Public Health at Imperial College London. Specializes in airborne microplastics and human health research, with a Ph.D. from the University of Exeter.`
    },
    {
      name: 'Mr. Mushfiqur Chowdhury',
      position: 'Client Service Manager',
      institution: 'Global Fund Analytics',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FmushfiqurChowdhury.webp?alt=media&token=ddf4ef89-9cfa-4b4f-b913-f8555694621d',
      description: `First Class Honours MSc graduate in Management with Strategic Management. Experienced in financial services, healthcare, and EU operations. Dedicated volunteer with Original Volunteers Charity, focusing on education and support initiatives in Morocco.`
    },
    {
      name: 'Mr. Shaun Behan',
      position: 'Head of Information Systems',
      institution: 'Competition Commission',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Ftrustees%2FshaunBeham.webp?alt=media&token=6b92f663-8ab3-4fc3-bc89-050074822c10',
      description: `awaiting`
    },
  ];

  return (
    <>      
      <div style={styles.mainContainer}>
        <MetaTags 
          title={meta.title}
          description={meta.description}
          url={meta.url}
          image={meta.image}
          siteName={meta.siteName}
          twitterCard={meta.twitterCard}
          twitterSite={meta.twitterSite}
          fbAppId={meta.fbAppId}
        />
        <h1 style={styles.header}>Our Trustees</h1>
        <p style={styles.introduction}>
          Our trustees bring diverse expertise and experience to guide Off the Curriculum
          in its mission to empower young minds through education.
        </p>

        <div style={styles.trusteesGrid}>
          {trustees.map((trustee, index) => (
            <div key={index} style={styles.trusteeCard}>
              <div style={styles.imageContainer}>
                <img
                  style={styles.image}
                  src={trustee.image}
                  alt={`${trustee.name} - Trustee at Off the Curriculum`}
                />
              </div>
              <div style={styles.cardContent}>
                <h3 style={styles.trusteeTitle}>{trustee.name}</h3>
                <h4 style={styles.trusteePosition}>{trustee.position}</h4>
                <h4 style={styles.trusteeInstitution}>{trustee.institution}</h4>
                <p style={styles.description}>{trustee.description}</p>
                <div style={styles.socialLinks}>
                  <CiMail 
                    style={styles.socialIcon}
                    onClick={mailProtector}
                  />
                  <RiTwitterXLine 
                  onClick={handleTwitter}
                  style={styles.socialIcon} />
                  <CiLinkedin 
                    style={styles.socialIcon}
                    onClick={handleLinkedIn}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default OurTrustees;