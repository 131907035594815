import React, { useState, useRef, useEffect } from "react";
import { color, motion } from "framer-motion";
import { httpsCallable } from "firebase/functions";

//icons 
import { CiHeart } from "react-icons/ci";
import { FiMessageCircle } from "react-icons/fi";
import { IoEyeOutline } from "react-icons/io5";

import { functions } from "../../../../../Firebase/firebaseConfig";
import ShortsCommentSection from "../../../../../Components/Common/Comments/shortComments";

const VideoCarousel = ({ videos, theme }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [showComments, setShowComments] = useState(false); 
  const [updatedViews, setUpdatedViews] = useState([]); 
  const [updatedLikes, setUpdatedLikes] = useState([]); 

  const videoRefs = useRef([]);

  useEffect(() => {
    videoRefs.current.forEach((video) => {
      video.currentTime = 2;
      video.play();
    });
  }, []);
  //console.log(videos)
  const openModal = async (index) => {
    setCurrentVideoIndex(index);
    setModalOpen(true);
    // Update view count when modal is opened
    try {
      const updateVideoViewCountFn = httpsCallable(functions, 'update_video_view_count'); // Create callable function reference
      // Call the function with the videoId
      //console.log('hello', videos[index].key)
      const result = await updateVideoViewCountFn({ videoId: videos[index].key }); 
      setUpdatedViews(result.data)
    } catch (error) {
      //console.error("Error updating view count: ", error);
    }
  };

  const handleLikes = async (index) => {  // Pass index as a parameter
    try {
      const updateVideoLikeCountFn = httpsCallable(functions, 'update_video_like_count');
      //console.log(index);
      const result = await updateVideoLikeCountFn({ videoId: index });
      setUpdatedLikes(result.data);
    } catch (error) { // Correct error handling
      //console.error("Error updating like count:", error); 
    }
  };
  //console.log(updatedLikes)
  const closeModal = () => {
    setModalOpen(false);
    setShowComments(false); 
  };

  const isActive = (index) => index === currentVideoIndex;

  const toggleComments = () => {
    setShowComments(!showComments);
  };

  const generalStyles = {
    videoContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '10px',
      overflowX: 'auto',
    },
    videoItem: {
      position: 'relative',
      cursor: 'pointer',
    },
    videoItemActive: {
      zIndex: 1,
    },
    shortsStyle: {
      display: 'flex',
      gap: '10px',
      marginBottom: '20px',
      flexWrap: 'wrap',
    },
    videoDetailsStyle: {
      display: 'flex',
      justifyContent: 'space-between',
      color: 'lightgrey',
      fontSize: '0.9em',
    },
    videoOverlay: {
      position: 'absolute',
      bottom: '0px',
      background: 'rgba(0, 0, 0, 0.7)', // Darker background with opacity for better readability
      color: '#fff',
      maxHeight: '150px', // Fixed height for scrolling
      overflowY: 'auto',  // Enable vertical scrolling
      padding: '10px',
      borderRadius: '8px', // Rounded corners for the overlay
      wordWrap: 'break-word',
      boxSizing: 'border-box',
      width: showComments ? '56.5%' : '100%', 
    },
    videoInfoRow: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center', // Align items vertically in the center
      marginBottom: '10px',
    },
  };
  

  const darkTheme = {
    modalShorts: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(0, 0, 0, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalShortsContent: {
      position: 'relative',
      display: 'flex', // Changed to flex
      height: '80%',
      maxWidth: '800px', // Increased max-width to accommodate comments
      maxHeight: '700px',
      backgroundColor: showComments ? 'rgb(51, 51, 51)' : 'rgba(0, 0, 0, 0.8)', 
      borderRadius: '15px',
      overflow: 'hidden',
    },
    buttonPanel: {
      position: 'absolute',
      right: showComments ? '300px' : '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: showComments ? 'right 0.6s ease' : 'none',
      zIndex: '100'
    },
    button: {
      margin: '10px',
      backgroundColor: 'white',
      padding: '10px',
      borderRadius: '50%',
    },
    shortItemStyle: {
      flex: '1 1 calc(20% - 10px)',
      backgroundColor: '#202020',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      borderRadius: '15px',
    },
    shortThumbnailStyle: {
      height: '400px',
      marginBottom: '10px',
      borderRadius: '10px',
    },
    shortInfoStyle: {
      textAlign: 'left',
      color: '#fff',
      fontSize: '0.9em',
    },
    shortTextDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.8em',
      color: 'lightgrey',
    },
    commentSection: {
      backgroundColor: '#333',
      color: '#fff',
      width: '300px',
      height: '100%',
      overflowY: 'auto',
      padding: '20px',
      boxSizing: 'border-box',
      borderRadius: '15px',
    },
  };
  
  const lightTheme = {
    modalShorts: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalShortsContent: {
      position: 'relative',
      display: 'flex', // Changed to flex
      height: '80%',
      maxWidth: '800px', // Increased max-width to accommodate comments
      maxHeight: '700px',
      backgroundColor: showComments ? 'rgb(245, 245, 245)' : 'rgba(255, 255, 255, 0.8)', 
      borderRadius: '15px',
      overflow: 'hidden',
    },
    buttonPanel: {
      position: 'absolute',
      right: showComments ? '300px' : '10px',
      top: '50%',
      transform: 'translateY(-50%)',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      transition: showComments ? 'right 0.6s ease' : 'none',
      zIndex: '100'
    },
    button: {
      margin: '10px',
      backgroundColor: 'black',
      padding: '10px',
      borderRadius: '50%',
    },
    shortItemStyle: {
      flex: '1 1 calc(20% - 10px)',
      backgroundColor: '#fff',
      padding: '10px',
      textAlign: 'center',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.1)',
      borderRadius: '15px',
    },
    shortThumbnailStyle: {
      height: '400px',
      marginBottom: '10px',
      borderRadius: '10px',
    },
    shortInfoStyle: {
      textAlign: 'left',
      color: '#000',
      fontSize: '0.9em',
    },
    shortTextDetails: {
      display: 'flex',
      justifyContent: 'space-between',
      fontSize: '0.8em',
      color: 'darkgrey',
    },
    commentSection: {
      backgroundColor: '#fff',
      color: '#000',
      width: '300px',
      height: '100%',
      overflowY: 'auto',
      padding: '20px',
      boxSizing: 'border-box',
      borderRadius: '15px',
    },
  };
  
  const buttonVariants = {
    rest: { scale: 1, boxShadow: '0 4px 8px 0 #0003' },
    hover: { scale: 1.15, backgroundColor: '#ff8a65', boxShadow: '0 4px 8px 0 #0003' },
    tap: { scale: 0.95 },
  };
  const styles = theme === 'light' ? lightTheme : darkTheme;
  return (
    <>
      <div style={generalStyles.videoContainer}>
        {videos.map((video, index) => (
          <div
            key={video.id}
            style={{
              ...generalStyles.videoItem,
              ...(isActive(index) && generalStyles.videoItemActive),
            }}
            onClick={() => openModal(index)}
          >
            <div style={generalStyles.shortsStyle}>
              <motion.div
                style={styles.shortItemStyle}
                whileHover={{
                  scale: 1.0,
                }}
                initial={{
                  scale: 0.9,
                }}
              >
                <img src={video.videoSnippet} alt="Video thumbnail" style={styles.shortThumbnailStyle} />
                <div style={styles.shortInfoStyle}>
                  <h4>{video.key}</h4>
                  <div style={styles.shortTextDetails}>
                    <span>{video.author}</span>
                    <span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <CiHeart style={{color: '#000'}} size={15} />:
                      </span>
                      <span>{video.likes}</span>
                    </div>
                    </span>
                    <span>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <span style={{ display: 'flex', alignItems: 'center' }}>
                        <IoEyeOutline style={{color: '#000'}} size={15} />:
                      </span>
                      <span>{video.views}</span>
                    </div>
                    </span>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        ))}
      </div>
      {modalOpen && (
        <div style={styles.modalShorts} onClick={closeModal}>
          <div
            style={styles.modalShortsContent}
            onClick={(e) => e.stopPropagation()}
          >
            <video
              style={{ 
                maxHeight: '700px', 
                maxWidth: '100%', 
                borderRadius: '15px'
              }}
              src={videos[currentVideoIndex].videoSource}
              autoPlay
              controls
            />
            <div style={generalStyles.videoOverlay}>
              <h3>{videos[currentVideoIndex].key}</h3>
              <div style={generalStyles.videoInfoRow}>
                <p>{videos[currentVideoIndex].author}</p>
                <p>
                  {updatedLikes > 0 ? 
                    `${updatedLikes} likes` 
                    : 
                    <span style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
                      <CiHeart style={{color: '#fff'}} size={20} />
                      {videos[currentVideoIndex].likes}
                    </span>
                  }
                </p>
                <p>{updatedViews} views</p>
              </div>
              <p>{videos[currentVideoIndex].videoDesc}</p>
            </div>
            <div style={styles.buttonPanel}>
            <motion.button
                style={styles.button}
                onClick={() => handleLikes(videos[currentVideoIndex].key)}
                variants={buttonVariants} // Apply the animation variants
                whileHover="hover" // Animate on hover
                whileTap="tap"    // Animate on tap (click)
              >
              <CiHeart style={{color: '#fff'}} size={20} />
              </motion.button>
              <motion.button
              style={styles.button}
              onClick={() => toggleComments()}
              variants={buttonVariants} // Apply the animation variants
              whileHover="hover" // Animate on hover
              whileTap="tap"    // Animate on tap (click)
            >
              <FiMessageCircle style={{color: '#fff'}} size={20} />
              </motion.button>
              
              <button style={styles.button}>↗️</button>
            </div>
            {showComments && (
              <div style={styles.commentSection}>
                <h3>Comments</h3>
                <ShortsCommentSection name={videos[currentVideoIndex].key} theme={theme} />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}  

export default VideoCarousel;
