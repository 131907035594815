import React from 'react';
import { motion } from 'framer-motion';

const CommentBlueActionButton = ({ children, onClick }) => {
    const blueActionButtonStyle = {
    backgroundColor: '#1F484C',
    color: '#ffffff',
    border: 'none',
    marginLeft: '20px',
    marginTop: '20px',
    padding: '10px 20px',
    borderRadius: '15px',
    cursor: 'pointer',
    marginBottom: '15px',
    width: '10%',
  };
  const buttonVariants = {
    rest: {
      scale: 1,
      boxShadow: '0 4px 8px 0 #0003',
    },
    hover: {
      scale: 1.15,
      backgroundColor: '#e79b18',
      boxShadow: '0 4px 8px 0 #0003',
    },
    tap: { scale: 0.95 },
  };
  return (
    <motion.button
      variants={buttonVariants}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      onClick={onClick}
      style={blueActionButtonStyle}
    >
      {children}
      <span className="arrow">→</span>
    </motion.button>
  );
};

export default CommentBlueActionButton;