export default {
  id: 'trusteeatotc',
    title: "Our board of trustees ",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Dr. Joseph Michael Levermore",
      image: '',
      bio: "I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-05-10",
      readTime: "5 minutes",
      category: "charity"
    },
    content: [
      {
        type: "heading",
        content: "Introduction"
      },
      {
        type: "paragraph",
        content: `For most of us, having access to inspiring and successful individuals is a rarity. Let alone having five of them accept our offer to be members of our Trustees Board here at OTC! Our main goal is to provide you with access to incredible individuals who will encourage and motivate you to pursue your dreams. People who started right where you are now and with hard work and tenacity, became the admirable people they are today. So without further ado, please let me introduce you to the five members of Off the Curriculum's Trustee Board:`
      },
      {
        type: "heading",
        content: "Dr. Sweta Raghavan"
      },
      {
        type: "paragraph",
        content: `Dr. Raghavan has dedicated her life to science, education, and activism. She received her Ph.D. from King's College London for characterising the biological role of a novel protein, which has implications in unearthing treatment for inflammation arising from respiratory illnesses.`
      },
      {
        type: "paragraph",
        content: `At present, she is devoting her efforts to developing academic research and fostering international collaborations for the Jio Institute (promoted by Reliance Industries Limited, India). She has previously served as the Senior Science & Innovation Advisor to the British Government's Foreign & Commonwealth Office, advising and supporting UK's bilateral relationships with India in the health and life sciences sectors and shaping science policy.`
      },
      {
        type: "paragraph",
        content: `She is also the founder of Scientists & Co, a social initiative that promotes science education among students from underprivileged backgrounds. Having mentored over 3000 students across the UK & India, 85% of whom are now studying at prestigious universities. Among many accolades, she is recognised as the Most Influential Life Science Expert 2020 by the Global Health Pharma News Magazine.`
      },
      {
        type: "heading",
        content: "Dr. Rashada Harry"
      },
      {
        type: "paragraph",
        content: `Having received an LLM from the London School of Economics, Dr. Rashada Harry currently serves as an Enterprise Technologist Leader at Amazon Web Services (AWS). Dr. Harry has an impressive track record in strategic enterprise opportunities, business development, and account management.`
      },
      {
        type: "paragraph",
        content: `Passionate about STEAM, inclusion, and diversity, she has dedicated the last 15 years to providing access to opportunities in various leadership and ambassador roles. She is the co-founder of Your Future, Your Ambition which educates and inspires young people to pursue careers in STEAM, and presently leads this initiative in a pro bono capacity. She is also on the Board Trustee at Talawa, EngineeringUK, serves as an Advisory board member at Amazon Black Employee Network UK, Women@AWS, and is a Founding Member of UKBlackTech.`
      },
      {
        type: "paragraph",
        content: `Dr. Harry holds several additional accolades, including being recognised as one of the top 100 Ethnic Minority Leaders by INvolve (2020), one of the Most Influential Women in UK Tech Longlist by Computer Weekly (2020), named as one of the Top 100 Ethnic Minority Leaders at EMpower and Yahoo, respectively (2019), one of the Top 50 most inspiring and Influential Black Voice in UK Tech by TechNation (2019), and ranked #13 in the Top 50 Future Leaders by the Financial Times (2016), among many other notable accolades.`
      },
      {
        type: "heading",
        content: "Dr. Ian Mudway'"
      },
      {
        type: "paragraph",
        content: "With over 25 years of experience in academia and research, Dr. Ian Mudway is a Senior Lecturer in the School of Public Health at Imperial College London. His research focuses on the impacts of air pollution on human health and the development of assays to quantify its toxicity. Dr. Mudway has published over 100 research papers, reports, and book chapters on such topics. His findings and knowledge in this area have allowed him to play a role in science policy development for local, national, and international governments. Dr. Mudway is a member of the Medical Research Council's Centre for Environment and Health at Imperial College London; MRC & Asthma UK Centre in Allergic Mechanisms of Asthma and the NIHR-PHE Health Protection Research Units in Environmental Exposures and Health and Chemical and Radiation Threats and Hazards; and the World Health Organisation's review panel: Pollution Effects on Asthmatic Children in Europe project (REVIHAAP)."
      },
      {
        type: "paragraph",
        content: "His current work focuses on improving our understanding of how air pollution impacts the health of children and young people living in urban environments."
      },
      {
        type: "paragraph",
        content: "Passionate about science communication, he has worked extensively with artists and educationalists to promote the public's understanding of the risks associated with environmental pollutants."
      },
      {
        type: "heading",
        content: "Dr. Stephanie Wright"
      },
      {
        type: "paragraph",
        content: "Dr. Stephanie Wright is a Lecturer at the Medical Research Council (MRC) Centre for Environment and Health, School of Public Health at Imperial College London. Commencing with a Ph.D. from the University of Exeter, she joined ICL as an early career researcher, specialising in airborne microplastics and human health."
      },
      {
        type: "paragraph",
        content: "She now holds a UK Research and Innovation (UKRI) Rutherford Fellowship and leads the Microplastics team at Imperial College London. Her interdisciplinary research addresses: detection, characterisation, and quantification of airborne microplastics; technique optimisation for their detection in biological matrices; and their toxicological assessment."
      },
      {
        type: "paragraph",
        content: "She has been on national television (BBC and ITV) and radio as a scientific expert, participated in the European Commission's 'Microplastics Working Group', and contributed towards science policy development."
      },
      {
        type: "heading",
        content: "Mr. Mushfiqur Chowdhury"
      },
      {
        type: "paragraph",
        content: "Graduating with First Class Honours in MSc Management with Strategic Management, Mr. Mushfiqur Chowdhury has a decorated history working in financial services, the healthcare industry, and European Union (EU). His work primarily focuses on client servicing, strategy and project management, and digital platform services."
      },
      {
        type: "paragraph",
        content: "During his time at the European Union, serving as an Analyst in the Cabinet of UK Members, he assisted on performance audits for EU policy implementation for sustainable use of natural resources, including the EU action on energy and climate change (Landscape Review). He also managed and organised the EU action on Energy and Climate Change conference. He has served as an Analyst and Consultant for the multinational industry-leading firm Deloitte and currently serves as a Client Service Advisor for GFA S.A. in Luxembourg."
      },
      {
        type: "paragraph",
        content: "He has experience volunteering, having spent time in Morocco aiding the Original Volunteers Charity (OVC). OVC focuses on aiding the homeless in Morocco by providing education, creating team projects, and providing food and shelter."
      },
      {
        type: "heading",
        content: "Conclusion"
      },      
      {
        type: "paragraph",
        content: "These talented individuals have come together to help Off the Curriculum achieve its mission."
      },
      {
        type: "paragraph",
        content: "Each Trustee will fulfill a different role, bringing their own experience and expertise to the table. They will work with our departments and officers to ensure our runnings are smooth and our message is clear."
      },
      
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };