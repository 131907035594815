import React, { useState, useEffect } from 'react';
import LoadedCourseVideoPage from './LoadedCourseVideoPage';
import UnLoadedCoursePageContent from './UnloadedCourseVideoPage';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import useAuth from '../../../../Firebase/Auth';

const CourseVideoPage = (props) => {
  const [data, setData] = useState(null);
  const [quizInfo, setquizInfo] = useState(null);
  const [updatedviews, setUpdatedViews] = useState(null);
  const [nextVideos, setNextVideos] = useState(null);
  const currentUser = useAuth();
  const name = props.name;

  useEffect(() => {
    const fetchData = async () => {
    };
    fetchData();
  }, [name, currentUser]);

  useEffect(() => {
    const fetchRecommendedVideos = async () => {
    };
    fetchRecommendedVideos();
  }, [data]);

  //console.log('logging')

  return(
    <React.Fragment>
      {data === null ? 
      <UnLoadedCoursePageContent name={name} /> : 
      <LoadedCourseVideoPage data={data} quizInfo={quizInfo} />
      }
    </React.Fragment>
  );
};

export default CourseVideoPage;
