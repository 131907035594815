import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  // Inline styles using JS objects
  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      textAlign: 'center',
      backgroundColor: '#f4f4f4',
      padding: '20px',
    },
    content: {
      maxWidth: '600px',
      backgroundColor: 'white',
      padding: '40px',
      borderRadius: '8px',
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    },
    heading: {
      fontSize: '72px',
      color: '#ed1f06',
      marginBottom: '10px',
    },
    subheading: {
      fontSize: '24px',
      color: '#333',
      marginBottom: '20px',
    },
    description: {
      fontSize: '16px',
      color: '#666',
      marginBottom: '30px',
    },
    homeLink: {
      display: 'inline-block',
      backgroundColor: '#28b05f',
      color: 'white',
      padding: '12px 24px',
      borderRadius: '4px',
      textDecoration: 'none',
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '40px',
      transition: 'background-color 0.3s ease',
    },
    homeLinkHover: {
      backgroundColor: '#1f904b',
    },
    suggestions: {
      marginTop: '20px',
    },
    linksHeading: {
      fontSize: '20px',
      color: '#333',
      marginBottom: '20px',
    },
    linksContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'center',
      gap: '15px',
    },
    link: {
      display: 'inline-block',
      color: '#007bff',
      textDecoration: 'none',
      fontSize: '16px',
      transition: 'color 0.3s ease',
    },
    linkHover: {
      color: '#0056b3',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.content}>
        <h1 style={styles.heading}>404</h1>
        <h2 style={styles.subheading}>Page Not Found</h2>
        <p style={styles.description}>
          Oops! The page you’re looking for doesn’t exist. It might have been removed, or the link is broken.
        </p>
        <Link
          to="/"
          style={styles.homeLink}
          onMouseOver={(e) => (e.target.style.backgroundColor = styles.homeLinkHover.backgroundColor)}
          onMouseOut={(e) => (e.target.style.backgroundColor = styles.homeLink.backgroundColor)}
        >
          Return to Homepage
        </Link>

        <div style={styles.suggestions}>
          <h3 style={styles.linksHeading}>Here are some helpful links:</h3>
          <div style={styles.linksContainer}>
            <Link
              to="/Ourmotto"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Our Motto
            </Link>
            <Link
              to="/Theteam"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              The Team
            </Link>
            <Link
              to="/Ourtrustees"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Our Trustees
            </Link>
            <Link
              to="/News"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              News
            </Link>
            <Link
              to="/Education"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Education
            </Link>
            <Link
              to="/explore"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Educo
            </Link>
            <Link
              to="/Collaborations"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Collaborations
            </Link>
            <Link
              to="/Enrol"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Enrol
            </Link>
            <Link
              to="/Volunteering"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Volunteering
            </Link>
            <Link
              to="/Careers"
              style={styles.link}
              onMouseOver={(e) => (e.target.style.color = styles.linkHover.color)}
              onMouseOut={(e) => (e.target.style.color = styles.link.color)}
            >
              Become an Officer
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
