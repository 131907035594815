import React from 'react';
import { FaUsers, FaBookReader, FaYoutube, FaExternalLinkAlt, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FaInstagram, FaTiktok } from 'react-icons/fa6';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { useNavigate } from 'react-router-dom';

const styles = {
  container: {
    maxWidth: '1024px',
    margin: '0 auto',
    padding: '24px',
  },
  mainSection: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    padding: '24px',
    marginBottom: '32px',
  },
  title: {
    fontWeight: 'bold',
    color: '#1F484C',
    marginBottom: '16px',
  },
  description: {
    color: '#4b5563',
    marginBottom: '16px',
  },
  quickLinks: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '16px',
    marginBottom: '24px',
  },
  link: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    padding: '8px 16px',
    backgroundColor: '#E3F4F3',
    color: '#1F484C',
    borderRadius: '6px',
    textDecoration: 'none',
    transition: 'background-color 0.2s',
    cursor: 'pointer',
  },
  highlightsContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
    gap: '24px',
  },
  highlightCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.2s',
  },
  iconContainer: {
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    backgroundColor: '#E3F4F3',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '16px',
  },
  icon: {
    fontSize: '24px',
    color: '#1F484C',
  },
  cardTitle: {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#1f2937',
    marginBottom: '8px',
  },
  cardDescription: {
    color: '#4b5563',
  },
  contactSection: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  contactTitle: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#1f2937',
    marginBottom: '16px',
  },
  button: {
    padding: '12px 24px',
    backgroundColor: '#2563eb',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  eventSection: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
  },
  eventTitle: {
    fontSize: '1.5rem',
    fontWeight: '600',
    color: '#1f2937',
    marginBottom: '16px',
  },
  eventDetails: {
    color: '#4b5563',
    marginBottom: '12px',
  },
  eventHighlight: {
    backgroundColor: '#f3f4f6',
    padding: '16px',
    borderRadius: '6px',
    marginBottom: '16px',
  },
  dateTime: {
    color: '#1F484C',
    fontWeight: '600',
    marginBottom: '8px',
  },
  location: {
    color: '#4b5563',
    marginBottom: '8px',
  },
  registerButton: {
    padding: '12px 24px',
    backgroundColor: '#2563eb',
    color: 'white',
    border: 'none',
    borderRadius: '6px',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
    marginTop: '16px',
  },
  highlightCard: {
    backgroundColor: 'white',
    borderRadius: '8px',
    padding: '24px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: '32px'
  },
  cardContent: {
    flex: 1,
  },
  cardFeatures: {
    marginTop: '16px',
    paddingTop: '16px',
    borderTop: '1px solid #e5e7eb',
  },
  featureList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
  },
  featureItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
    color: '#4b5563',
    fontSize: '0.9rem',
  },
  learnMore: {
    marginTop: '16px',
    color: '#1F484C',
    fontWeight: '600',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
  },
};

const highlights = [
  {
    title: "Communities",
    description: "Connect, learn, and grow with like-minded individuals in our thriving community platform.",
    Icon: FaUsers,
    path: "/communities",
    features: [
      "Create and join study groups",
      "Discuss topics with peers",
      "Share resources and insights",
      "Organize virtual meetups",
      "Build your academic network"
    ]
  },
  {
    title: "Mentoring",
    description: "Get guided by industry experts and leading academics in your field of interest.",
    Icon: FaBookReader,
    path: "/mentors",
    features: [
      "1-on-1 mentoring sessions",
      "Expert guidance",
      "Career development advice",
      "Academic support",
      "Regular feedback sessions"
    ]
  },
  {
    title: "Explore",
    description: "Dive into a world of knowledge with our curated video content from experts across various fields.",
    Icon: FaYoutube,
    path: "/explore",
    features: [
      "Climate change insights",
      "Law and politics",
      "Science and technology",
      "Arts and humanities",
      "Interactive workshops"
    ]
  }
];



const CharityInfo = () => {
  const navigate = useNavigate();
  // Hover effect handlers
  const handleMouseEnter = (e) => {
    e.currentTarget.style.boxShadow = '0 10px 15px rgba(0, 0, 0, 0.1)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
  };

  const handleLinkHover = (e) => {
    e.currentTarget.style.backgroundColor = '#bfdbfe';
  };

  const handleLinkLeave = (e) => {
    e.currentTarget.style.backgroundColor = '#dbeafe';
  };

  const handleButtonClick = (e) => {
    alert('Coming soon!');
  };
  const handleCardClick = (path) => {
    navigate(path);
  };
  
  

  return (
    <div style={styles.container}>
      {/* Main Info Section */}
      <div style={styles.mainSection}>
        <h1 style={styles.title}>Off the Curriculum</h1>
        <p style={styles.description}>
          Empowering young people through education, mentoring, and community building.
        </p>
        
        {/* Quick Links */}
        <div style={styles.quickLinks}>
          <a 
            href="https://register-of-charities.charitycommission.gov.uk/en/about-the-register-of-charities/-/charity-details/5189608/full-print" 
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
          >
            <FaExternalLinkAlt /> Charity Register
          </a>
          
          <a 
            href="https://x.com/otc_london" 
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
          >
            <FaTwitter /> Twitter
          </a>
          
          <a 
            href="https://www.linkedin.com/company/offthecurriculum-otc/" 
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
          >
            <FaLinkedin /> LinkedIn
          </a>
          <a 
            href="https://www.instagram.com/offthecurriculumlondon/" 
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
          >
            <FaInstagram/> Instagram
          </a>
          <a 
            href="https://www.tiktok.com/@offthecurriculum/" 
            target="_blank"
            rel="noopener noreferrer"
            style={styles.link}
            onMouseEnter={handleLinkHover}
            onMouseLeave={handleLinkLeave}
          >
            <FaTiktok/> tiktok
          </a>
        </div>
      </div>

      {/* Highlights Section */}
      <div style={styles.highlightsContainer}>
        {highlights.map((highlight, index) => (
          <div 
            key={index}
            style={styles.highlightCard}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            onClick={() => handleCardClick(highlight.path)}
          >
            <div style={styles.cardContent}>
              <div style={styles.iconContainer}>
                <highlight.Icon style={styles.icon} />
              </div>
              <h3 style={styles.cardTitle}>{highlight.title}</h3>
              <p style={styles.cardDescription}>{highlight.description}</p>
              
              <div style={styles.cardFeatures}>
                <ul style={styles.featureList}>
                  {highlight.features.map((feature, idx) => (
                    <li key={idx} style={styles.featureItem}>
                      <span style={{color: '#1F484C'}}>•</span> {feature}
                    </li>
                  ))}
                </ul>
              </div>
              
              <div style={styles.learnMore}>
                Learn more <span>→</span>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Contact Section */}
      <div style={styles.eventSection}>
        <h2 style={styles.eventTitle}>Upcoming Events</h2>
        
        <div style={styles.eventHighlight}>
          <h3 style={styles.eventDetails}>
            Climate change and biodiversity: The role of our worms
          </h3>
          
          <p style={styles.dateTime}>
            <strong>Date & Time:</strong> November 23rd, 11:00 - 12:30
          </p>
          
          <p style={styles.location}>
            <strong>Location:</strong> Garden Museum
            <br />
            5 Lambeth Palace Rd, London SE1 7LB
          </p>
          
          <p style={styles.description}>
            Join us for an fascinating exploration into the world of worms and soil health. 
            This educational event will cover:
            • The anatomy and behavior of worms
            • Their crucial role in maintaining soil health
            • How healthy soil contributes to climate change mitigation
            • Practical tips for promoting soil biodiversity
          </p>
          
          <p style={styles.description}>
            More detailed information about the speakers and full agenda will be released soon. 
            Stay tuned for updates!
          </p>

          <StandardButtonContainer>
            <StandardButtonContainer.ButtonTwo
              onClick={handleButtonClick}
            >
              Register for Event
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
        </div>
      </div>
    </div>
  );
};

export default CharityInfo;