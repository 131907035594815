import React, {useEffect} from 'react';
import { motion } from 'framer-motion';
import { GoogleAuthProvider, signInWithPopup, getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { handleSignIn, signRedirect } from '../../../../../Firebase/signInFunctions';
import { getRedirectResult } from 'firebase/auth';
import { handleRedirectResult } from '../../../../../Firebase/signInFunctions';


const SignInWithComponent = () => {
  const auth = getAuth();
  const navigate = useNavigate();

  // Handle redirect result when component mounts
  const handleSignInClick = async () => {
    //console.log('hello')
    try {
      const provider = new GoogleAuthProvider();
      await handleSignIn(auth, provider, navigate);
      // The page will redirect to Google here
    } catch (error) {
      //console.error('Sign in failed:', error);
    }
  };

  const styles = { 
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '12px',
      backgroundColor: 'white',
      border: '1px solid #e0e0e0',
      borderRadius: '8px',
      padding: '12px 24px',
      cursor: 'pointer',
      width: 'fit-content',
      boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.05)',
    },
    icon: {
      height: '24px',
      width: '24px',
    },
    text: {
      color: '#333',
      fontSize: '16px',
      fontWeight: '500',
      margin: 0,
    }
  };

  return (
    <motion.div
      style={styles.button}
      whileHover={{
        scale: 1.02,
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
      }}
      whileTap={{ scale: 0.98 }}
      onClick={handleSignInClick}
    >
      <img 
        src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FgoogleIcon.png?alt=media&token=ca574a33-7823-4157-859e-457762c77e39"
        alt="Google Icon"
        style={styles.icon}
      />
      <span style={styles.text}>Sign in with Google</span>
    </motion.div>
  );
};

export default SignInWithComponent;


{/*import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { GoogleAuthProvider, FacebookAuthProvider, TwitterAuthProvider, signInWithPopup, getAuth } from "firebase/auth"; // Import Firebase auth
import { useNavigate } from 'react-router-dom'; 
const iconImages = [
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FappleIcon.png?alt=media&token=59c81968-db93-44d4-bedf-c7c0c1963098',
    provider: 'apple',
    alt: 'Sign In with Apple'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FfacebookIcon.png?alt=media&token=1cc64fde-a0c6-49de-8e96-1f252345d57b',
    provider: 'facebook',
    alt: 'Sign In with Facebook'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FgoogleIcon.png?alt=media&token=ca574a33-7823-4157-859e-457762c77e39',
    provider: 'google',
    alt: 'Sign In with Google'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FtwitterIcon.png?alt=media&token=2589312b-e83e-43ca-87e2-a333b1bac0af',
    provider: 'twitter',
    alt: 'Sign In with Twitter'
  },
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FwindowsIcon.png?alt=media&token=f484bf05-55e2-4052-9ac8-b57f02649bfa',
    provider: 'microsoft',
    alt: 'Sign In with Microsoft'
  },
];

const images = [
  'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
  'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
  'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
];

const SignInWithComponent = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const auth = getAuth(); 
  const navigate = useNavigate();

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, [images.length]);

  const socialIconsStyle = {
    display: 'flex',
    gap: '10px',
  };

  const iconImagesStyle = {
    height: '40px',
    cursor: 'pointer'
  };

  const handleSignInClick = async (providerName) => {
    let provider;
    switch (providerName) {
      case 'google':
        provider = new GoogleAuthProvider();
        break;
      case 'facebook':
        provider = new FacebookAuthProvider();
        break;
      case 'twitter':
        provider = new TwitterAuthProvider();
        break;
      // Add other providers like Apple, Microsoft, etc.
      default:
        console.error('Unsupported provider:', providerName);
        return;
    }

    // Use signInWithPopup for authentication
    try {
      const result = await signInWithPopup(auth, provider);
      // console.log('User signed in:', result.user);
      navigate('/Explore');
      // You can use this to show user info or handle authentication
    } catch (error) {
      // console.error('Sign in failed:', error.message); // Handle errors here
    }
  };

  return (
    <div style={socialIconsStyle}>
      {iconImages.map((icon, index) => (
        <motion.div
          key={index}
          whileHover={{
            scale: 1.1,
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.1)',
          }}
          whileTap={{ scale: 0.9 }}
          onClick={() => handleSignInClick(icon.provider)}
        >
          <img src={icon.image} alt={icon.alt} style={iconImagesStyle} />
        </motion.div>
      ))}
    </div>
  );
};

export default SignInWithComponent;
*/}