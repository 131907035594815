import React, { useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { getStorage, ref, uploadString, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../../Firebase/firebaseConfig';

const UploadingProfile = () => {
  const navigate = useNavigate();

  const uploadImageToFirebase = async (imageData, hashedEmail, imageType) => {
    if (!imageData) return null;
    
    try {
      const storage = getStorage();
      const imageRef = ref(storage, `person/${hashedEmail}/${imageType}`);
      
      await uploadString(imageRef, imageData, 'data_url');
      const downloadURL = await getDownloadURL(imageRef);
      //console.log('url download', downloadURL)
      return downloadURL;
    } catch (error) {
      //console.error(`Error uploading ${imageType}:`, error);
      throw error;
    }
  };

  useEffect(() => {
    const uploadData = async () => {
      try {
        // Get stored data
        const formData = JSON.parse(sessionStorage.getItem('formData'));
        const currentUser = JSON.parse(sessionStorage.getItem('currentUser'));

        if (!formData || !currentUser) {
          throw new Error('Missing form data or user data');
        }

        // Hash the email
        const hashEmail = httpsCallable(functions, 'hashEmail');
        const { data: { hash: hashedEmail } } = await hashEmail({ 
          email: currentUser.email 
        });

        // Upload images and get URLs
        const [displayPhotoUrl, coverPhotoUrl] = await Promise.all([
          uploadImageToFirebase(formData.displayPhoto, hashedEmail, 'displayPhoto'),
          uploadImageToFirebase(formData.coverPhoto, hashedEmail, 'coverPhoto')
        ]);

        // Prepare the final user data object
        const userDataToStore = {
          ...formData,
          displayPhotoUrl,
          coverPhotoUrl,
          email: currentUser.email,
          createdAt: new Date().toISOString(),
          // Remove base64 image data to reduce payload
          displayPhoto: undefined,
          coverPhoto: undefined
        };
        //console.log(userDataToStore)

        // Upload to Firebase using Python function
        const uploadPersonData = httpsCallable(functions, 'store_user_data');
        const result = await uploadPersonData({
          userData: userDataToStore
        });
        //console.log(result)
        
        //console.log(result)

        if (result.data.success) {
          // Clear session storage
          sessionStorage.removeItem('formData');
          sessionStorage.removeItem('currentUser');
          navigate('/explore');
        } else {
          throw new Error(result.data.error || 'Failed to store user data');
        }

      } catch (error) {
        //console.error('Error uploading data:', error);
        alert('An error occurred while uploading data. Please try again.');
        navigate('/error');
      }
    };

    uploadData();
  }, [navigate]);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100vh',
      padding: '20px',
      backgroundColor: '#f5f5f5'
    }}>
      <div style={{
        backgroundColor: 'white',
        padding: '2rem',
        borderRadius: '8px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        maxWidth: '400px',
        width: '100%'
      }}>
        <h2 style={{
          color: '#1F484C',
          marginBottom: '1rem',
          textAlign: 'center'
        }}>Creating Your Profile</h2>
        
        <div style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '1rem'
        }}>
          <div style={{
            width: '40px',
            height: '40px',
            border: '3px solid #1F484C',
            borderTopColor: 'transparent',
            borderRadius: '50%',
            animation: 'spin 1s linear infinite'
          }} />
        </div>
        
        <p style={{
          color: '#666',
          textAlign: 'center',
          marginBottom: '0.5rem'
        }}>
          Please wait while we process your information...
        </p>
      </div>

      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }
        `}
      </style>
    </div>
  );
};

export default UploadingProfile;