import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { FaExternalLinkAlt, FaTwitter, FaLinkedin } from 'react-icons/fa';
import { FaInstagram, FaTiktok, FaGraduationCap, FaHandHoldingHeart } from 'react-icons/fa6';
import { GoHistory } from "react-icons/go";
import { Link } from 'react-router-dom';
import { FaUsers, FaBookReader, FaYoutube, } from 'react-icons/fa';

const EventRegistrationForm = ({ eventDetails }) => {
const navigate = useNavigate();
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showAgeAlert, setShowAgeAlert] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [showTooltip, setShowTooltip] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    confirmEmail: '', // Add this field
    name: '',
    isBiologyStudent: '',
    isAYoungPerson: '',
    isEducoUser: '',
    attendanceFormat: 'in-person',
    dietaryRequirements: '',
    eventTitle: eventDetails.title,
    registrationDate: new Date().toISOString(),
  });

  const handleMouseEnter = (e) => {
    e.currentTarget.style.boxShadow = '0 10px 15px rgba(0, 0, 0, 0.1)';
  };

  const handleMouseLeave = (e) => {
    e.currentTarget.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
  };
  const handleCardClick = (path) => {
    navigate(path);
  };
  const validateEmails = () => {
    if (formData.email !== formData.confirmEmail) {
      setEmailError('Email addresses do not match');
      return false;
    }
    setEmailError('');
    return true;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateEmails()) {
      return;
    }
    
    if (formData.isAYoungPerson === 'No') {
      setShowAgeAlert(true);
      return;
    }
  
    setIsSubmitting(true);

    try {
      const registerForEvent = httpsCallable(functions, 'register_for_event');
      
      const submitData = {
        ...formData,
        userId: emailsHasher(formData.email),
        eventId: eventDetails.id,
        timestamp: new Date().toISOString(),
      };
      const result = await registerForEvent(submitData);
      //console.log('results', result)
      if (result.data.success) {
        // Show success message and redirect
        alert('Registration successful! You will receive a confirmation email shortly.');
        navigate(`/events/${eventDetails.id}`);
      } else {
        throw new Error(result.data.message || 'Registration failed');
      }
    } catch (error) {
        switch(true) {
        case error.message.includes('User already registered for this event'):
            alert('You have already registered for this event');
            break;
        case error.message.includes('Event is at full capacity'):
            alert('Sorry, this event is now full');
            break;
        default:
            alert('Error submitting registration. Please try again.');
    }
} finally {
    setIsSubmitting(false);
}
  }

  const redirectToEduco = () => {
    navigate('/explore');
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  
    if (name === 'isEducoUser' && value === 'No') {
      setShowLoginPrompt(true);
    } else if (name === 'isEducoUser' && value === 'Yes') {
      setShowLoginPrompt(false);
    }
  
    // Add this new condition
    if (name === 'isAYoungPerson') {
      setShowAgeAlert(value === 'No');
    }
  };

  const preventCopyPaste = (e) => {
    e.preventDefault();
    alert('Please type your email address to confirm it');
    return false;
  };
  
  // Add these styles to your styles object
  
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    formGroup: {
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',
    },
    label: {
      fontWeight: 'bold',
      color: '#333',
    },
    input: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '16px',
    },
    select: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      fontSize: '16px',
    },
    textarea: {
      padding: '10px',
      borderRadius: '4px',
      border: '1px solid #ddd',
      minHeight: '100px',
      fontSize: '16px',
      resize: 'vertical',
    },
    button: {
      padding: '12px 24px',
      backgroundColor: '#1F484C',
      color: 'white',
      border: 'none',
      borderRadius: '24px',
      cursor: 'pointer',
      fontSize: '16px',
      fontWeight: '500',
    },
    title: {
      marginBottom: '20px',
    },
    description: {
      marginBottom: '30px',
    },
    required: {
      color: 'red',
      marginLeft: '4px',
    },
    educoPrompt: {
      backgroundColor: '#FEF9F2',
      border: '1px solid #e9ecef',
      borderRadius: '8px',
      padding: '20px',
      marginTop: '20px',
      marginBottom: '20px',
    },
    educoPromptTitle: {
      fontWeight: 'bold',
      color: '#1F484C',
      marginBottom: '10px',
    },
    educoPromptText: {
      marginBottom: '15px',
      lineHeight: '1.5',
    },
    educoButton: {
      padding: '10px 20px',
      backgroundColor: '#2C5282',
      color: 'white',
      border: 'none',
      borderRadius: '20px',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '500',
      marginTop: '10px',
    },
    benefitsList: {
      listStyle: 'none',
      padding: '0',
      margin: '15px 0',
    },
    benefitItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginBottom: '8px',
    },
    checkIcon: {
      color: '#E79B18',
      fontSize: '16px',
    }
  };
  const additionalStyles = {
    ...styles,
    alertBanner: {
      backgroundColor: '#FEE2E2', // Light red background
      border: '1px solid #FCA5A5', // Slightly darker red border
      borderRadius: '8px',
      padding: '16px 20px',
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
    },
    alertIcon: {
      color: '#DC2626', // Red color for the icon
      fontSize: '24px',
    },
    alertText: {
      color: '#991B1B', // Dark red text
      fontSize: '16px',
      fontWeight: '500',
      flex: 1,
    },
    alertTitle: {
      fontSize: '18px',
      fontWeight: '600',
      marginBottom: '4px',
      color: '#991B1B',
    },
    errorText: {
      color: '#DC2626',
      fontSize: '14px',
      marginTop: '4px',
    },
    errorInput: {
      border: '1px solid #DC2626',
      backgroundColor: '#FEF2F2',
    }, 
    helperText: {
      fontSize: '12px',
      color: '#666',
      marginTop: '4px',
      fontStyle: 'italic'
    },
    tooltip: {
      position: 'absolute',
      backgroundColor: '#1F484C',
      color: 'white',
      padding: '8px 12px',
      borderRadius: '4px',
      fontSize: '12px',
      maxWidth: '200px',
      zIndex: 1000,
      transform: 'translateY(-100%)',
      marginBottom: '8px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.2)'
    },
    highlightCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '24px',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      transition: 'all 0.3s ease',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'column',
      marginBottom: '32px'
    },
    cardContent: {
      flex: 1,
    },
    cardFeatures: {
      marginTop: '16px',
      paddingTop: '16px',
      borderTop: '1px solid #e5e7eb',
    },
    featureList: {
      listStyle: 'none',
      padding: 0,
      margin: 0,
    },
    featureItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      marginBottom: '8px',
      color: '#4b5563',
      fontSize: '0.9rem',
    },
    learnMore: {
      marginTop: '16px',
      color: '#1F484C',
      fontWeight: '600',
      display: 'flex',
      alignItems: 'center',
      gap: '4px',
    },
    highlightsContainer: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(280px, 1fr))',
      gap: '24px',
    },
  };

  const highlights = [
    {
      title: "Pro-Fresh Mentoring",
      Icon: FaUsers,
      path: "/mentors",
      features: [
        "6-month personalised research project",
        "1:1 mentoring with global experts",
        "Choose your path: scientific study or creative project",
      ]
    },
    {
      title: "Interactive Learning Serie",
      Icon: FaBookReader,
      path: "/mentors",
      features: [
        `"OFF THE TRACK" monthly interactive seminars`,
        `"Mighty Minds" wellbeing programme`,
        "Student-led topics and discussions",
      ]
    },
    {
      title: "Online learning with EDUCO",
      Icon: FaYoutube,
      path: "/explore",
      features: [
        "Weekly interest-matched video content",
        "MASTERCLASS short courses with certification",
        `"Ask Astro" our 24/7 AI study tutor `,
      ]
    }
  ];


  const yesNoOptions = ['Yes', 'No'];
  const attendanceOptions = ['In-person'];

  return (
    <>
    <form onSubmit={handleSubmit} style={styles.form}>
      <h1 style={styles.title}>Event Registration</h1>
      {showAgeAlert && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          style={additionalStyles.alertBanner}
        >
          <div style={{ flex: 1 }}>
            <div style={additionalStyles.alertTitle}>
              Age Requirement Not Met
            </div>
            <div style={additionalStyles.alertText}>
              Unfortunately this event is exclusively for students aged 16-18 who are currently enrolled in Sixth Form, College, or an Apprenticeship program.
            </div>
          </div>
        </motion.div>
      )}
      <p style={styles.description}>
        Please fill out the form below to register for the event. 
        All fields marked with an asterisk (*) are required.
      </p>
        <div style={styles.formGroup}>
          <label style={styles.label}>
            Have you joined our Young Leaders Excellence programme?
            <span style={styles.required}>*</span>
          </label>
          <select
            name="isEducoUser"
            value={formData.isEducoUser}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {yesNoOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        {showLoginPrompt && (
          <div style={styles.educoPrompt}>
            <h3 style={styles.educoPromptTitle}>
              <b>
                If not, are you ready to take the leap and transform your learning journey with OTC's complete educational experience?
              </b>
            </h3>
            <p style={styles.educoPromptText}>
              We are proud to empower and bring opportunity to the future global leaders. Join our innovative <strong>FREE</strong> programme that goes beyond traditional education, <b>where your interests drive your learning adventure.</b>
            </p>
            <div style={additionalStyles.highlightsContainer}>
              {highlights.map((highlight, index) => (
                <div 
                  key={index}
                  style={additionalStyles.highlightCard}
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  onClick={() => handleCardClick(highlight.path)}
                >
                  <div style={additionalStyles.cardContent}>
                    <div style={additionalStyles.iconContainer}>
                      <highlight.Icon style={styles.icon} />
                    </div>
                    <h3 style={additionalStyles.cardTitle}>{highlight.title}</h3>
                    <p style={additionalStyles.cardDescription}>{highlight.description}</p>
                    
                    <div style={additionalStyles.cardFeatures}>
                      <ul style={additionalStyles.featureList}>
                        {highlight.features.map((feature, idx) => (
                          <li key={idx} style={additionalStyles.featureItem}>
                            <span style={{color: '#1F484C'}}>•</span> {feature}
                          </li>
                        ))}
                      </ul>
                    </div>
                    
                    <div style={additionalStyles.learnMore}>
                      Learn more <span>→</span>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <p style={{
              ...styles.educoPromptText,
              fontStyle: 'italic',
              marginTop: '20px',
              textAlign: 'center',
            }}>
              From research projects to wellbeing support, we've got your complete academic journey covered! Enrol now by clicking the button below or complete the form and head to the 'Explore' page to sign in and enrol. 
            </p>

            <div>
              <StandardButtonContainer>
                <StandardButtonContainer.ButtonTwo                 
                onClick={redirectToEduco}>
                  Begin Your OTC Journey Today!
                </StandardButtonContainer.ButtonTwo>
              </StandardButtonContainer>
              <p style={{
                fontSize: '0.9rem',
                textAlign: 'center',
                marginTop: '10px'
              }}>
                Join students already expanding their horizons!
              </p>
            </div>
          </div>
        )}

      <div style={styles.formGroup}>
        <label style={styles.label}>
          Email Address
          <span style={styles.required}>*</span>
        </label>
        <input
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
          style={{
            ...styles.input,
            ...(emailError ? additionalStyles.errorInput : {})
          }}
          required
          placeholder="Enter your email address"
        />
      </div>
      <div style={styles.formGroup}>
        <label style={styles.label}>
          Confirm Email Address
          <span style={styles.required}>*</span>
        </label>
        <div style={{ position: 'relative' }}>
          {showTooltip && (
            <motion.div
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0 }}
              style={styles.tooltip}
            >
              Please type your email address to confirm it
            </motion.div>
          )}
          <input
            type="email"
            name="confirmEmail"
            value={formData.confirmEmail}
            onChange={handleChange}
            style={{
              width: '98%',
              ...styles.input,
              ...(emailError ? additionalStyles.errorInput : {})
            }}
            required
            placeholder="Confirm your email address"
            onPaste={preventCopyPaste}
            onCopy={preventCopyPaste}
            onCut={preventCopyPaste}
            onDrop={preventCopyPaste}
            onContextMenu={(e) => e.preventDefault()}
            onDragStart={(e) => e.preventDefault()}
          />
        </div>
        {emailError && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            style={additionalStyles.errorText}
          >
            {emailError}
          </motion.div>
        )}
        <span style={styles.helperText}>
          Please type your email address again to confirm it
        </span>
      </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Full Name
            <span style={styles.required}>*</span>
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={styles.input}
            required
            placeholder="Enter your full name"
          />
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Are you a young-person aged between 16 to 18 years old enrolled at a Sixth Form or in a apprenticeship programme?
            <span style={styles.required}>*</span>
          </label>
          <select
            name="isAYoungPerson"
            value={formData.isAYoungPerson}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {yesNoOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Are you a Biology student?
            <span style={styles.required}>*</span>
          </label>
          <select
            name="isBiologyStudent"
            value={formData.isBiologyStudent}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Answer...</option>
            {yesNoOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Format of Attendance
            <span style={styles.required}>*</span>
          </label>
          <select
            name="attendanceFormat"
            value={formData.attendanceFormat}
            onChange={handleChange}
            style={styles.select}
            required
          >
            <option value="">Select Format...</option>
            {attendanceOptions.map(option => (
              <option key={option} value={option}>{option}</option>
            ))}
          </select>
        </div>

        <div style={styles.formGroup}>
          <label style={styles.label}>
            Dietary Requirements
          </label>
          <textarea
            name="dietaryRequirements"
            value={formData.dietaryRequirements}
            onChange={handleChange}
            style={styles.textarea}
            placeholder="Please specify any dietary requirements or restrictions"
          />
        </div>

        <motion.button
        type="submit"
        style={{
          ...styles.button,
          opacity: isSubmitting ? 0.7 : 1,
          cursor: isSubmitting ? 'not-allowed' : 'pointer',
        }}
        whileHover={{ scale: isSubmitting ? 1 : 1.05 }}
        whileTap={{ scale: isSubmitting ? 1 : 0.95 }}
        disabled={isSubmitting}
      >
        {isSubmitting ? 'Submitting...' : 'Register Now'}
      </motion.button>
    </form>
    </>
  );
};


export default EventRegistrationForm;