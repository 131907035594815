import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CiClock1 } from "react-icons/ci";
import { motion } from 'framer-motion';
import { Events } from './eventPageContent';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

// meta data
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
const EventsPortal = () => {
  const meta = pageMetaTags.EventsPortal;

  const navigate = useNavigate();
  const [activeFilter, setActiveFilter] = useState('all');

  const eventCategories = [
    { id: 'all', label: 'All Events' },
    { id: 'mighty-minds', label: 'Mighty Minds' },
    { id: 'seminar-series', label: 'Seminar Series' },
    { id: 'workshops', label: 'Workshops' },
    { id: 'social', label: 'Social Events' }
  ];

  const styles = {
    container: {
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '2rem',
    },
    header: {
      fontSize: '2.5rem',
      fontWeight: 'bold',
      textAlign: 'center',
      marginBottom: '2rem',
      color: '#1a1a1a'
    },
    description: {
      fontSize: '1.125rem',
      color: '#4a4a4a',
      marginBottom: '2rem',
      lineHeight: '1.6'
    },
    filterContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      gap: '1rem',
      marginBottom: '2rem'
    },
    filterButton: (isActive) => ({
      padding: '0.75rem 1.5rem',
      borderRadius: '9999px',
      border: 'none',
      cursor: 'pointer',
      transition: 'all 0.3s ease',
      backgroundColor: isActive ? '#E79B18' : '#FDF5E8',
      color: isActive ? '#ffffff' : '#4b5563',
      fontWeight: '500',
      ':hover': {
        backgroundColor: isActive ? '#E79B18' : '#e5e7eb'
      }
    }),
    eventsGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
      gap: '2rem',
      width: '100%'
    },
    eventCard: {
      backgroundColor: '#ffffff',
      borderRadius: '0.75rem',
      overflow: 'hidden',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      cursor: 'pointer',
      position: 'relative'
    },
    comingSoonCard: {
      backgroundColor: '#ffffff',
      borderRadius: '0.75rem',
      padding: '2rem',
      textAlign: 'center',
      boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
      gridColumn: '1 / -1',
      minHeight: '300px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '1.5rem'
    },
    comingSoonTitle: {
      fontSize: '1.75rem',
      fontWeight: 'bold',
      color: '#1a1a1a',
      marginBottom: '1rem'
    },
    comingSoonText: {
      fontSize: '1.125rem',
      color: '#4a4a4a',
      marginBottom: '1.5rem',
      maxWidth: '500px'
    },
    enrollButton: {
      backgroundColor: '#E79B18',
      color: '#ffffff',
      padding: '0.75rem 2rem',
      borderRadius: '9999px',
      border: 'none',
      cursor: 'pointer',
      fontWeight: '500',
      transition: 'all 0.3s ease',
      ':hover': {
        backgroundColor: '#d38a16'
      }
    },
    dateContainer: {
      position: 'absolute',
      top: '10px',
      left: '10px',
      backgroundColor: 'rgba(0, 0, 0, 0.6)',
      color: '#fff',
      padding: '5px 10px',
      borderRadius: '5px',
      textAlign: 'center',
      zIndex: 2,
    },
    eventDate: {
      fontSize: '14px',
      zIndex: 1000,
    },
    eventYear: {
      fontSize: '20px',
      fontWeight: 'bold',
    },
    imageContainer: {
      position: 'relative',
      overflow: 'hidden',
      height: '200px'
    },
    eventImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    categoryTag: (category) => ({
      position: 'absolute',
      bottom: '1rem',
      left: '1rem',
      padding: '0.5rem 1rem',
      borderRadius: '0.5rem',
      ...getTabColor(category)
    }),
    contentContainer: {
      padding: '1.5rem'
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: '600',
      marginBottom: '1rem',
      color: '#1a1a1a'
    },
    timeContainer: {
      display: 'flex',
      alignItems: 'center',
      color: '#666666',
      gap: '0.5rem'
    }
  };

  const handleNewsArticle = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  const handleEnrollClick = () => {
    navigate('/education/enrol'); // Or whatever your registration path is
  };

  const getTabColor = (category) => {
    switch (category) {
      case 'mighty-minds':
        return { backgroundColor: '#EEF7F8', color: '#4dabb5' };
      case 'seminar-series':
        return { backgroundColor: '#FCEFEE', color: '#e56251ff' };
      case 'workshops':
        return { backgroundColor: '#FDF5E8', color: '#f3ad1b' };
      case 'social':
        return { backgroundColor: '#F0F7F0', color: '#4CAF50' };
      default:
        return { backgroundColor: '#F5F5F5', color: '#666666' };
    }
  };

  const filteredEvents = Events.filter(event => 
    activeFilter === 'all' || event.category === activeFilter
  );

  return (
    <div style={styles.container}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <h1 style={styles.header}>Events with Off the Curriculum</h1>
      
      <p style={styles.description}>
        The 2024/25 seminar series, we're thrilled to offer 12 engaging seminars each month, 
        covering a diverse array of topics, including our popular Mighty Minds Wellbeing Seminars, 
        thought-provoking discussions on Architecture, inspiring insights into Business and Innovation, 
        and explorations into the History of Language.
      </p>

      <div style={styles.filterContainer}>
        {eventCategories.map(category => (
          <button
            key={category.id}
            onClick={() => setActiveFilter(category.id)}
            style={styles.filterButton(activeFilter === category.id)}
          >
            {category.label}
          </button>
        ))}
      </div>

      <div style={styles.eventsGrid}>
        {filteredEvents.length > 0 ? (
          filteredEvents.map((event, index) => (
            <motion.div
              key={index}
              style={styles.eventCard}
              whileHover={{ 
                y: -5,
                boxShadow: '0 8px 30px rgba(0,0,0,0.12)'
              }}
              onClick={() => handleNewsArticle(event.eventpagehref)}
            >
              <div style={styles.dateContainer}>
                <span style={styles.eventDate}>
                  {event.date}
                </span>
                <br />
                <span style={styles.eventYear}>
                  {event.year}
                </span>
              </div>

              <div style={styles.imageContainer}>
                <motion.img
                  src={event.imageSrc}
                  alt={event.imageSrcAlt}
                  style={styles.eventImage}
                  whileHover={{ scale: 1.1 }}
                  transition={{ duration: 0.3 }}
                />
                <div style={styles.categoryTag(event.category)}>
                  {event.category}
                </div>
              </div>

              <div style={styles.contentContainer}>
                <h3 style={styles.title}>{event.title}</h3>
                <div style={styles.timeContainer}>
                  <CiClock1 />
                  <span>{event.time}</span>
                </div>
              </div>
            </motion.div>
          ))
        ) : (
          <motion.div 
            style={styles.comingSoonCard}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 style={styles.comingSoonTitle}>Coming Soon!</h2>
            <p style={styles.comingSoonText}>
              Events for this category will be announced soon! Enroll as a participant to stay up to date on upcoming events and be the first to know when new events are announced.
            </p>
            <StandardButtonContainer.ButtonTwo 
              onClick={handleEnrollClick}>

              Enroll Today!
            </StandardButtonContainer.ButtonTwo>
          </motion.div>
        )}
      </div>
    </div>
  );
};

export default EventsPortal;