import React, { useState } from 'react';
import ModalWrapper from '../Profile/Components/EditProfileModal/ModalWrapper';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const CourseModalPage = ({ course, onClose, onSave, loading, error }) => {
  const [courseData, setCourseData] = useState({
    name: course.title,
    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    tags: course.tags,
    image: "https://example.com/placeholder-image.jpg" // placeholder image URL
  });

  const handleEnroll = () => {
    onSave(courseData.name);
  };

  return (
    <ModalWrapper onClose={onClose}>
      <div style={styles.container}>
        <h1 style={styles.title}>{courseData.name}</h1>
        <div style={styles.tagsContainer}>
          {courseData.tags.map((tag, index) => (
            <span key={index} style={styles.tag}>{tag}</span>
          ))}
        </div>
        <div style={styles.content}>
          <div style={styles.descriptionContainer}>
            <p style={styles.description}>{courseData.description}</p>
            <div style={styles.buttonContainer}>
              <motion.button
                style={styles.messageButton}
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
              >
                Try first lesson
              </motion.button>
              <motion.button
                style={styles.bookButton}
                whileTap={{ scale: 0.95 }}
                whileHover={{ scale: 1.05 }}
                onClick={handleEnroll}
                disabled={loading}
              >
                {loading ? 'Enrolling...' : 'Enroll'}
              </motion.button>
            </div>
            {error && <p style={styles.error}>{error}</p>}
          </div>
          <div style={styles.imageContainer}>
            <img src={courseData.image} alt={courseData.name} style={styles.image} />
          </div>
        </div>
      </div>
    </ModalWrapper>
    );
  };

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '10px',
    width: '800px',
    padding: '20px',
  },
  title: {
    fontSize: '32px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  tagsContainer: {
    display: 'flex',
    gap: '10px',
    marginBottom: '20px',
  },
  tag: {
    padding: '5px 10px',
    backgroundColor: '#f0f0f0',
    borderRadius: '20px',
    fontSize: '14px',
  },
  content: {
    display: 'flex',
    gap: '20px',
  },
  descriptionContainer: {
    flex: 1,
  },
  description: {
    fontSize: '16px',
    lineHeight: '1.5',
    marginBottom: '20px',
  },
  buttonContainer: {
    display: 'flex',
    gap: '10px',
  },
  messageButton: {
    padding: '10px 20px',
    fontSize: '14px',
    borderRadius: '20px',
    border: '1px solid black',
    backgroundColor: 'white',
    color: 'black',
    cursor: 'pointer',
  },
  bookButton: {
    padding: '10px 20px',
    fontSize: '14px',
    borderRadius: '20px',
    border: 'none',
    backgroundColor: 'black',
    color: 'white',
    cursor: 'pointer',
  },
  imageContainer: {
    flex: 1,
  },
  image: {
    width: '100%',
    height: '300px',
    objectFit: 'cover',
    borderRadius: '10px',
  },
};

export default CourseModalPage;