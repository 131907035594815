import React from 'react';
// Meta tag component
import { pageMetaTags } from '../../Components/Common/MetaTags/metaData';
import MetaTags from '../../Components/Common/MetaTags/metaTagsComponent';
// Page components
import LandingPageBanner from './HomePageComponents/LandingPageBanner';
import ActionComponent from './HomePageComponents/ActionComponent';
import EventsComponent from './HomePageComponents/EventsComponent';
import BentoBoxComponent from './HomePageComponents/BentoBoxComponent';
import SchoolLinkComponent from './HomePageComponents/SchoolLinkComponent';
import NewsComponent from './HomePageComponents/NewsComponent';
import SupportUs from './HomePageComponents/SupportUsComponent';
import MembershipPlans from './HomePageComponents/OTCMembershipplans';

//import BackgroundCircles from '../../Components/Common/BackgroundCircles/BackgroundCircles';

const Home = () => {
  const meta = pageMetaTags.home;

  return (
    <div>
      <MetaTags
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <main>
        <LandingPageBanner />
        <ActionComponent />
        <EventsComponent />
        <BentoBoxComponent />
        {/*<SchoolLinkComponent />*/}
        <NewsComponent />
        <SupportUs />
      </main>
    </div>
  );
};

export default Home;