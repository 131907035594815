import React from 'react';
import { Routes, Route } from 'react-router-dom';

// Non-logged in content
import Home from '../Pages/General/Home';
import About from '../Pages/General/About';
import Education from '../Pages/General/Education/Education';
import Volunteering from '../Pages/General/Volunteering/Volunteer';
import BecomeAnOfficer from '../Pages/General/Volunteering/becomeAVolunteer';
import Outreach from '../Pages/General/Outreach/Outreach';
import ContactForm from '../Pages/General/ContactUsForm/ContactUsForm';
import ResourceLibrary from '../Pages/General/ResourceLibrary/ResourceLibrary';
// SignInComponent and Registration
import CreateCommunity from '../Pages/EDUCO/Communities/CreateCommunities';
import SignInComponent from '../Pages/General/Sign-In-Registration/SignIn';
import ForgotPasswordComponent from '../Pages/General/Sign-In-Registration/ForgotPassword';
import CreateAccountComponent from '../Pages/General/Sign-In-Registration/CreateAccount';
import CreateAUserProfile from '../Pages/EDUCO/CreateAUserProfile/createAUserProfile';
import Donate from '../Pages/General/Donate/Donate';

import CreateAUserProfileComponent from '../Pages/EDUCO/CreateAUserProfile/createAUserProfile';
import DiversityStatement from '../Pages/General/About/Statements/diversityInclusionStatement';
// NotFound
import NotFound from '../Components/Common/NotFound/NotFound';
import GrievanceProcedure from '../Pages/General/ResourceLibrary/GrievanceProcess';
import GrievanceForm from '../Pages/General/ResourceLibrary/GrievanceForm';
import Enrol from '../Pages/General/Education/Enrol';
import NewsArticle from '../Pages/General/News/newsArticle';
import OurTrustees from '../Pages/General/About/MeetTheTrustees/meetTheTrustees';
import OurTeam from '../Pages/General/About/MeetTheTeam/meetTheTeam';
import News from '../Pages/General/News/newspage';
import Policies from '../Pages/General/Policies/PolicyListPage';
import Statements from '../Pages/General/ResourceLibrary/Statements';
import EDICommitmentComponent from '../Pages/General/About/Statements/EDICommitmentComponent';
import MentalHealthCharitiesGuide from '../Pages/General/ResourceLibrary/CharityHelplines';
import WhistleblowingPolicyGuide from '../Pages/General/ResourceLibrary/WhistleblowingPolicyGuide';
import ExploreComponent from '../Pages/EDUCO/Explore/Explore';
// events 
import EventsPortal from '../Pages/General/Events/eventsMainPage';
import EventsPage from '../Pages/General/Events/eventsTemplatePage';
import EventRegistrationPage from '../Pages/General/Events/EventRegistrationPage';
// mentors



const RoutePages = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
        {/*
        Main site pages:
        - About, -Education, -Volunteering
        */}
      <Route path="/donate" element={<Donate />} />
      <Route path="/about" element={<About />} />
      <Route path="/meettheteam" element={<About />} />
      <Route path="/ourteam" element={<OurTeam />} />
      
      <Route path="/education" element={<Education />} />
      <Route path="/education/enrol" element={<Enrol />} />
      <Route path="/volunteer" element={<Volunteering />} />
      <Route path="/volunteer/becomeanofficer" element={<BecomeAnOfficer />} />
      <Route path="/ourtrustees" element={<OurTrustees />} />
      <Route path="/ourteam" element={<OurTeam />} />
      <Route path="/policies" element={<Policies />} />
      <Route path="/policies/:policyId" element={<Policies />} />
      <Route path="/contactus" element={<ContactForm />} />
      <Route path="/news" element={<News />} />
      <Route path="/news/:articleId" element={<NewsArticle />} />
      <Route path="/events" element={<EventsPortal />}/>
      <Route path="/events/:eventId" element={<EventsPage />} />
      <Route path="/events/register/:eventId" element={<EventRegistrationPage />}/>
 

      {/*
        Sign and Registration Pages
        */}
      <Route path="/signin" element={<SignInComponent />} />
      <Route path="/forgotpassword" element={<ForgotPasswordComponent />} />
      <Route path="/createaccount" element={<CreateAccountComponent />} />
      <Route path="/createauserprofile" element={<SignInComponent />} />
      <Route path="/createauseraccount" element={<SignInComponent />} />

      <Route path="/statements" element={<Statements />} />
      <Route path="/statements/resourceLibrary/diversitystatement" element={<DiversityStatement />} />
      <Route path="/statements/resourceLibrary/edistatement" element={<EDICommitmentComponent />} />
      <Route path="/statements/resourceLibrary" element={<ResourceLibrary />} />
      <Route path="/statements/resourcelibrary/grievanceprocedure" element={<GrievanceProcedure />} />
      <Route path="/statements/resourcelibrary/grievanceform" element={<GrievanceForm />} />
      <Route path="/statements/resourcelibrary/charityhelplines" element={<MentalHealthCharitiesGuide />} />
      <Route path="/statements/resourcelibrary/whistleblowingpolicyguide" element={<WhistleblowingPolicyGuide />} />

      <Route path="/educo" element={<SignInComponent />} />
      <Route path="/explore" element={<SignInComponent />} />
      <Route path="/profile/*" element={<SignInComponent />} />
      <Route path="/courses" element={<SignInComponent />} />
      <Route path="/course/:courseId" element={<SignInComponent />} />
      <Route path="/course/:courseId/:moduleId/:lessonId" element={<SignInComponent />} />
      <Route path="/mentors" element={<SignInComponent />} />
      <Route path="/mentors/mentor-request" element={<SignInComponent />} />
      <Route path="/mentors/mentorprogammepage" element={<SignInComponent />} />
      <Route path="/mentors/mentoringprogramme/:menteeId" element={<SignInComponent />} />
      <Route path="/mentors/mentorprogammepage/sessionfeedbackform" element={<SignInComponent />} />
      <Route path="/mentors/mentor-request" element={<SignInComponent />} />
      <Route path="/mentors/mentor-platform" element={<SignInComponent />} />
      <Route path="/admin" element={<SignInComponent />} />
      <Route path="/communities" element={<SignInComponent />} />
      <Route
          path="/createcommunity"
          element={<CreateCommunity />}
        />
      <Route path="/communities/c/:communityName" element={<SignInComponent />} />
      <Route path="/communities/c/:communityName/post/:postId" element={<SignInComponent />} />


      {/*<Route path="/about" element={<About />} />*/}
      <Route path="/Contact" element={<ContactForm />} />
      <Route path="*" element={<NotFound />} /> 

    </Routes>
  );
};

export default RoutePages;