const styles = {
  quizContainer:{
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#fff'
  },
  containerStyle: {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '20px',
    marginBottom: '20px',
    backgroundColor: '#fff',
    width: '600px', 
    height: '300px',
    overflowY: 'auto',
  }, 
  questionStyle: {
    fontSize: '1.2rem',
    marginBottom: '15px'
  }, 
  optionsStyle:{
    display: 'flex',
    flexDirection: 'column', 
    gap: '10px'
  }, 
  optionsStyle:{
    padding: '10px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    cursor: 'pointer',
    backgroundColor: '#f0f0f0'
  },
  selectedOptionStyle:{
    backgroundColor: '#e0e0e0'
  }, 
  correctOptionStyle: {
    backgroundColor: '#c0ffc0'
  }, 
  incorrectOptionStyle: {
    backgroundColor: '#ffc0c0'
  }, 
  resultStyle:{
    marginTop: '15px',
    fontSize: '1.1rem'
  }, 
  sentenceContainer: {
    display: 'flex',
    gap: '0.5rem',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  wordPlaceholder: {
    width: '80px',
    height: '30px',
    border: '1px dashed gray',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer', 
  },

}

export {styles}