export default {
  id: 'preparingforexaminations',
    title: "How to support your teen in preparation for their examinations.",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Dr. Joseph Michael Levermore",
      image: '',
      bio: "I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-05-10",
      readTime: "5 minutes",
      category: "educative"
    },
    content: [
      {
        type: "paragraph",
        content: "As a parent, you want the best for your adolescent child. This includes helping them prepare for their exams. Exams can be a stressful time for both the child and the parent, but with the right support, your child can succeed. Here are some tips to help you support your adolescent in preparing for exams."
      },
      {
        type: "heading",
        content: "Create a study schedule"
      },
      {
        type: "paragraph",
        content: "Encourage your adolescent to create a study schedule that breaks down the material they need to cover and sets goals for what they want to achieve each day. This will help them to manage their time effectively and avoid cramming at the last minute."
      },
      {
        type: "heading",
        content: "Provide a quiet study environment"
      },
      {
        type: "paragraph",
        content: "Make sure your child has a quiet and comfortable place to study, free from distractions such as television, video games, or loud music. This will help them to focus on their studies and retain more information."
      },
      {
        type: "heading",
        content: "Help them with study materials"
      },
      {
        type: "paragraph",
        content: "Make sure your adolescent has all the study materials they need, such as textbooks, notes, and past papers. You can also help them by quizzing them on the material or discussing difficult concepts with them."
      },
      {
        type: "heading",
        content: "Encourage healthy habits"
      },
      {
        type: "paragraph",
        content: "Encourage your child to eat healthily, get enough sleep, and exercise regularly. These habits will help them to stay focused and energized during their studies."
      },
      {
        type: "heading",
        content: "Provide emotional support"
      },
      {
        type: "paragraph",
        content: "It's important to remember that your child may be feeling stressed or anxious about their exams. Provide emotional support by listening to their concerns, offering words of encouragement, and reminding them that they have your support."
      },
      {
        type: "heading",
        content: "Avoid adding to the pressure"
      },
      {
        type: "paragraph",
        content: "It's important to avoid adding to the pressure your adolescent may be feeling. Avoid making constant demands about their studies, or putting too much emphasis on exam results. Instead, focus on the effort they are putting in and encourage them to do their best."
      },
      {
        type: "heading",
        content: "Celebrate their successes"
      },
      {
        type: "paragraph",
        content: "Celebrate your child's successes, no matter how small they may be. This will help to boost their confidence and motivate them to keep working hard."
      },
      {
        type: "paragraph",
        content: "In conclusion, preparing for exams can be a challenging time for adolescents, but with the right support, they can succeed. By following these tips, you can help your child to manage their time effectively, stay focused, and feel confident about their abilities. Remember to provide emotional support and celebrate their successes, and your child will be well on their way to success."
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };