import React, { useState, useEffect } from 'react';
import { FaDownload, FaHistory, FaPlus } from 'react-icons/fa';
import StandardButtonContainer from '../../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
import { functions } from '../../../../../Firebase/firebaseConfig';
import BottomLoader from '../../../../../Components/Common/Loading/BottomLoaderComponent';
import { httpsCallable } from 'firebase/functions';


const ActivityLog = ({ userType, userData }) => {
  const [newActivity, setNewActivity] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [allActivities, setAllActivities] = useState([]);
  const [error, setError] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Use windowWidth to determine layout
  const isMobile = windowWidth <= 640;
  const isTablet = windowWidth <= 768 && windowWidth > 640;

  useEffect(() => {
    //console.log('fetch')
    fetchInitialActivities();
  }, []);



  const fetchInitialActivities = async () => {
    try {
      setIsLoading(true);
      const getActivityLogs = httpsCallable(functions, 'get_activity_logs');
      const result = await getActivityLogs({
          menteeId: userData.menteeId
      });

      //console.log('result', result)

      if (result.data.success) {
        setAllActivities(result.data.activities || []);
      } else {
        setError(result.data.error || 'Failed to fetch activities');
      }
    } catch (error) {
      //console.error('Error fetching activities:', error);
      setError('Failed to fetch activities');
    } finally {
      setIsLoading(false);
    }
  };

  
  const styles = {
    container: {
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      boxShadow: '0 1px 3px 0 rgba(0, 0, 0, 0.1)',
      padding: isMobile ? '1rem' : '1.5rem',
      marginTop: '2rem',
    },
    logGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile 
        ? '1fr' 
        : 'minmax(90px, 100px) minmax(90px, 100px) 1fr',
      gap: isMobile ? '0.5rem' : '1rem',
      borderBottom: '1px solid #E5E7EB',
      padding: '0.75rem 0',
      fontSize: '0.875rem',
      alignItems: 'center',
    },
    headerRow: {
      fontWeight: '600',
      color: '#4B5563',
      borderBottom: '2px solid #E5E7EB',
      display: isMobile ? 'grid' : 'grid',
    },
    mobileLabel: {
      display: isMobile ? 'block' : 'none',
      color: '#6B7280',
      fontWeight: '500',
      fontSize: '0.75rem',
      marginBottom: '0.25rem',
    },
    addActivityRow: {
      display: 'grid',
      gridTemplateColumns: isMobile 
        ? '1fr' 
        : 'minmax(90px, 100px) minmax(90px, 100px) 1fr auto',
      gap: isMobile ? '0.75rem' : '1rem',
      padding: '0.75rem 0',
      alignItems: 'center',
      borderBottom: '1px solid #E5E7EB',
    },
    textarea: {
      width: isMobile ? '92.5%': '80%',
      padding: '12px',
      borderRadius: '10px',
      border: '1px solid #dcdcdc',
      fontSize: '16px',
      height: isMobile ? '80px' : '60px',
      marginBottom: '0',
      resize: 'none',
    },
    dateTimeContainer: {
      display: 'flex',
      gap: '0.5rem',
      flexDirection: isMobile ? 'row' : 'row',
    },
    buttonContainer: {
      width: isMobile ? '100%' : 'auto',
    },
    title: {
      fontSize: isMobile ? '1.125rem' : '1.25rem',
      fontWeight: '600',
      color: '#1f2937',
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '1.5rem',
    },
    headerTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
    },
    logGrid: {
      display: 'grid',
      gridTemplateColumns: isMobile ? '80px 35px 1fr': '100px 100px 1fr',
      gap: '1rem',
      borderBottom: '1px solid #E5E7EB',
      padding: '0.75rem 0',
      fontSize: '0.875rem',
      alignItems: 'center',
    },
    logDate: {
      color: '#6B7280',
    },
    logTime: {
      color: '#6B7280',
    },
    logContent: {
      color: '#1F2937',
    },
    noActivities: {
      textAlign: 'center',
      color: '#6B7280',
      padding: '2rem 0',
    },
    dateTime: {
      backgroundColor: '#F3F4F6',
      padding: '0.5rem',
      borderRadius: '0.375rem',
      color: '#6B7280',
      fontSize: '0.875rem',
    }
  };

  const getCurrentDateTime = () => {
    const now = new Date();
    const date = now.toLocaleDateString();
    const time = now.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return { date, time };
  };

  const handleDownload = () => {
    let docContent = 'EPQ Activity Log\n\n';
    allActivities.forEach(activity => {
      docContent += `Date: ${activity.date}\n`;
      docContent += `Time: ${activity.time}\n`;
      docContent += `Activity: ${activity.content}\n\n`;
    });

    const blob = new Blob([docContent], { type: 'application/msword' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'EPQ_Activity_Log.doc';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const handleSubmit = async () => {
    if (newActivity.trim() && !isSubmitting) {
      try {
        setIsSubmitting(true);
        const { date, time } = getCurrentDateTime();
        
        const addActivityLog = httpsCallable(functions, 'add_activity_log');
        const result = await addActivityLog({
            menteeId: userData?.menteeId,
            content: newActivity.trim(),
            date: date,
            time: time
        });

        if (result.data.success) {
          // Add to local state only
          setAllActivities(prev => [{
            date,
            time,
            content: newActivity.trim(),
            id: Date.now() // temporary id for local state
          }, ...prev]);
          setNewActivity('');
        } else {
          //console.error('Failed to add activity:', result.data.error);
          alert('Failed to add activity. Please try again.');
        }
      } catch (error) {
        //console.error('Error adding activity:', error);
        alert('Error adding activity. Please try again.');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const { date, time } = getCurrentDateTime();

  return (
    <div style={styles.container}>
    <div style={styles.header}>
      <div style={styles.headerTitle}>
        <FaHistory style={{ color: '#1F484C' }} />
        <h2 style={styles.title}>Activity Log</h2>
      </div>
      {allActivities.length > 0 && (
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonTwo onClick={handleDownload}>
            <FaDownload style={{ marginRight: '0.5rem' }} />
            Download Log
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
      )}
    </div>

    {isLoading ? (
      <BottomLoader 
        isLoading={true}
        message="Loading your activity log..."
        backgroundColor={"rgba(255, 255, 255, 0.95)"}
        textColor={"#FFFFFF"}
      />
    ) : (
      <div>
        <div style={{...styles.logGrid, ...styles.headerRow}}>
          <div>Date</div>
          <div>Time</div>
          <div>Activity</div>
        </div>

        {allActivities.length > 0 ? (
          allActivities.map((activity) => (
            <div key={activity.id || `${activity.date}-${activity.time}`} style={styles.logGrid}>
              <div style={styles.logDate}>{activity.date}</div>
              <div style={styles.logTime}>{activity.time}</div>
              <div style={styles.logContent}>{activity.content}</div>
            </div>
          ))
        ) : (
          <div style={styles.noActivities}>
            No activities logged yet
          </div>
        )}

        {userType === 'mentee' && (
          <div style={styles.addActivityRow}>
            <div style={styles.dateTime}>{getCurrentDateTime().date}</div>
            <div style={styles.dateTime}>{getCurrentDateTime().time}</div>
            <textarea
              style={styles.textarea}
              value={newActivity}
              onChange={(e) => setNewActivity(e.target.value)}
              placeholder="Add your EPQ activity here..."
              disabled={isSubmitting}
            />
            <StandardButtonContainer.ButtonOne
              onClick={handleSubmit}
              disabled={!newActivity.trim() || isSubmitting}
            >
              <FaPlus style={{ marginRight: '0.5rem' }} />
              {isSubmitting ? 'Adding...' : 'Add'}
            </StandardButtonContainer.ButtonOne>
          </div>
        )}
      </div>
    )}
  </div>
);
};

export default ActivityLog;