import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../Firebase/firebaseConfig';
import { CiLock, CiUnlock } from "react-icons/ci";
import { IoInformationCircleOutline } from "react-icons/io5";
import { Link } from 'react-router-dom';
import { FaPlay, FaThumbsUp } from 'react-icons/fa';
import AskAstro from './Components/Astro';

const CourseHeader = ({ courseTitle, courseObjective }) => (
  <div style={styles.headerContent}>
    <h1 style={styles.title}>{courseTitle}</h1>
    <div style={styles.objective}>
      <IoInformationCircleOutline style={styles.icon} />
      <div>
        <h2 style={styles.objectiveTitle}>Course Objective</h2>
        <p style={styles.objectiveText}>{courseObjective}</p>
      </div>
    </div>
  </div>
);

const ModuleSidebar = ({ modules }) => {
  return (
    <div style={styles.sidebarContainer}>
      <div style={styles.sidebar}>
        <h2 style={styles.sidebarTitle}>Course Structure</h2>
        {modules.map((module, index) => (
          <div key={index} style={{
            ...styles.moduleItem,
            padding: '20px',
            borderRadius: '15px', 
            backgroundColor: module.completed ? '#e8fff3' : (index === 1 ? '#ffffff' : '#f8f9fa'),
          }}>
            <span style={styles.weekLabel}>Week {module.number}</span>
            <div style={styles.moduleContent}>
              <span style={styles.moduleTitle}>{module.title}</span>
              {module.completed ? (
                <div style={styles.checkmark}>✓</div>
              ) : index === 1 ? (
                <CiUnlock style={styles.unlockIcon} />
              ) : (
                <CiLock style={styles.lockIcon} />
              )}
            </div>
          </div>
        ))}
      </div>
      <div style={styles.verticalLine}></div>
    </div>
  );
};


const VideoCard = ({ video }) => {
  const lessonUrl = video.lessonUrl ? `/course${video.lessonUrl}` : '#';
  return (
    <Link to={lessonUrl} style={styles.cardLink}>
      <div style={styles.videoCard}>
        <div style={styles.thumbnailContainer}>
          {video.desktopVideoUrl ? (
            <img src={video.videoThumbnail} alt={video.key} style={styles.videoThumbnail} />
          ) : (
            <div style={styles.placeholderThumbnail}>No Video Available</div>
          )}
          {video.desktopVideoUrl && <FaPlay style={styles.playIcon} />}
        </div>
        <div style={styles.cardContent}>
          <h3 style={styles.videoTitle}>{video.key}</h3>
          <p style={styles.videoAuthor}>by {video.author}</p>
          <div style={styles.statsContainer}>
            <span style={styles.statItem}>
              <FaThumbsUp style={styles.icon} /> {video.likes || 0}
            </span>
            <span style={styles.statItem}>Views: {video.count || 0}</span>
          </div>
        </div>
      </div>
    </Link>
  );
};

const EnrolledCoursePage = () => {
  //const { courseId } = useParams('psychology');
  const courseId = 'psychology'
  const [theme, setTheme] = useState('light');
  const [currentModule, setCurrentModule] = useState(1);
  const [error, setError] = useState(null);
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const styles = theme === 'light' ? 'lightTheme' : 'darkTheme'; // Define these themes
  const courseTitle = "Neuroscience: The Science of the brain";
  const linkStyling = {
    width: 'calc(33.333% - 70px)',
    textDecoration: 'none',
  };
  const courseObjective = "Get ready to dive deep into the mysteries of the world with a qualified tutor. From art to philosophy, our export tutors will break it all down for you.";
  const handleAskAstro = () => {
    // Handle the click event, e.g., open a chat modal
    //console.log('Ask Astro clicked');
  };

  const fetchModuleData = useCallback(async () => {
    setLoading(true);
    //console.log('Fetching module data for courseId:', courseId, 'and moduleNumber:', currentModule);
    const fetchCourseModuleData = httpsCallable(functions, 'fetch_course_data');  
    
    try {
      //console.log('Calling fetch_course_data function');
      const result = await fetchCourseModuleData({ courseId: courseId, moduleNumber: currentModule });
      //console.log('Function call successful. Result:', result);
      
      if (result.data && result.data.lessons) {
        setLessons(result.data.lessons);
      } else {
        //console.warn('Unexpected response structure:', result);
        // You might want to handle this case, perhaps setting an error state
      }
    } catch (error) {
      //console.error("Error fetching module data:", error.code, error.message, error.details);
      // Handle error (e.g., show error message to user)
      if (error.code === 'functions/internal-error') {
        //console.error("This might be a server-side issue. Check your Cloud Function logs.");
      }
      // Set an error state or display a user-friendly error message
      setError("Failed to fetch module data. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [courseId, currentModule]);
  
  useEffect(() => {
    fetchModuleData();
  }, [fetchModuleData]);

  const modules = [
    { number: 1, title: "Lorem Ipsum Dolor", completed: true },
    { number: 2, title: "Lorem Ipsum Dolor", completed: false },
    { number: 3, title: "Lorem Ipsum Dolor", completed: false },
    { number: 4, title: "Lorem Ipsum Dolor", completed: false },
    { number: 5, title: "Lorem Ipsum Dolor", completed: false },
  ];

  useEffect(() => {
    const fetchVideos = async () => {

    }
  })
  const videoListStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '20px',
    justifyContent: 'center', 
    marginBottom: '20px'
  };
  const videoContainer = {
    flex: 1, // This will make the video container take up remaining space
    display: 'flex',
    flexDirection: 'column',
  };
  const  content= {
    display: 'flex',
    gap: '20px',
    alignItems: 'flex-start', // This will align items to the top
  }

  
//console.log('lessons', lessons)

  return (
    <div style={styles.container}>
      <CourseHeader 
        courseTitle={courseTitle}
        courseObjective={courseObjective}
      />
      <div style={content}>
        <ModuleSidebar modules={modules} />
        <div style={videoContainer}>
        <div style={videoListStyle}>
          {lessons.map((lesson, index) => (
            <React.Fragment key={index}>
              <VideoCard 
                video={lesson}
                key={index}
              />
            </React.Fragment>
          ))}
        </div>
        <AskAstro onClick={handleAskAstro} />
      </div>
      </div>
    </div>
  );
};

const styles = {
  headerContent:{
    borderBottom: '1px solid #e0e0e0',
    marginLeft: '80px',
  },
  title: {
    fontSize: '48px',
    fontWeight: 'bold',
    color: '#1a1a1a',
    marginBottom: '24px',
  },
  objective: {
    display: 'flex',
    alignItems: 'flex-start',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
    padding: '16px',
    marginBottom: '32px',
  },
  icon: {
    fontSize: '24px',
    color: '#6c757d',
    marginRight: '16px',
    marginTop: '2px',
  },
  videoThumbnail:{
    width: '563px',
    height: '416px'

  },
  objectiveTitle: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#1a1a1a',
    marginBottom: '8px',
  },
  objectiveText: {
    fontSize: '16px',
    color: '#495057',
    lineHeight: '1.5',
    margin: 0,
  },
  sidebarContainer: {
    display: 'flex',
    position: 'relative',
  },
  sidebar: {
    width: '300px',
    padding: '20px',
    backgroundColor: '#ffffff',
    borderRadius: '10px',
    boxShadow: '0 2px 10px rgba(0,0,0,0.1)',
  },
  sidebarTitle: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#1a1a1a',
  },
  moduleItem: {
    borderRadius: '10px',
    padding: '15px',
    marginBottom: '10px',
    transition: 'all 0.3s ease',

  },
  weekLabel: {
    fontSize: '16px',
    color: '#6c757d',
    display: 'block',
    marginBottom: '5px',
  },
  moduleContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  moduleTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: '#1a1a1a',
  },
  checkmark: {
    width: '24px',
    height: '24px',
    borderRadius: '50%',
    backgroundColor: '#28a745',
    color: '#ffffff',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '14px',
  },
  lockIcon: {
    fontSize: '24px',
    color: '#6c757d',
  },
  unlockIcon: {
    fontSize: '24px',
    color: '#28a745',
  },
  container: {
    padding: '20px',
  },
  title: {
    fontSize: '24px',
    marginBottom: '20px',
  },
  objective: {
    marginBottom: '20px',
  },
  verticalLine: {
    position: 'absolute',
    right: 0,
    width: '1px',
    height: 'calc(100%)', // Adjust this value to match the top offset
    backgroundColor: '#e0e0e0',
  },
  sidebar: {
    width: '300px',
    borderRadius: '10px',
    padding: '20px',
    position: 'relative',
  },
  progressBar: {
    width: '10px',
    height: '100%',
    position: 'absolute',
    left: '20px',
    top: '0',
    display: 'flex',
    flexDirection: 'column',
  },
  progressSegment: {
    flex: 1,
    width: '100%',
  },
  moduleList: {
    marginLeft: '30px',
  },
  moduleItem: {
    marginBottom: '15px',
  },
  moduleBorder: {
    border: '2px solid',
    borderRadius: '20px',
    padding: '10px 15px',
    fontSize: '14px',
    backgroundColor: 'white',
  },
  brainIcon: {
    position: 'absolute',
    bottom: '10px',
    left: '15px',
    fontSize: '24px',
    backgroundColor: '#D3D3D3',
    borderRadius: '50%',
    width: '40px',
    height: '40px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  lessonGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gap: '20px',
    flex: 1,
  },
  lessonCard: {
    backgroundColor: '#f8d7da',
    borderRadius: '10px',
    overflow: 'hidden',
  },
  videoPlaceholder: {
    height: '120px',
    backgroundColor: '#f8d7da',
  },
  lessonInfo: {
    padding: '10px',
    fontSize: '14px',
  },
};

export default EnrolledCoursePage;