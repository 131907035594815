import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { motion } from 'framer-motion';

const Tooltip = ({ children, text }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({});
  const containerRef = useRef(null);

  useEffect(() => {
    //console.log('Tooltip component mounted');
    //return () => console.log('Tooltip component unmounted');
  }, []);

  const handleMouseEnter = () => {
    //console.log('Mouse entered');
    setIsVisible(true);
    if (containerRef.current) {
      const rect = containerRef.current.getBoundingClientRect();
      setTooltipPosition({
        top: rect.top - 32, // 10px above the icon
        left: rect.left + rect.width / 2 - 80,
      });
    }
  };

  const handleMouseLeave = () => {
    //console.log('Mouse left');
    setIsVisible(false);
  };

  const tooltipStyle = {
    position: 'fixed', // Use fixed positioning to ensure it isn't affected by parent elements
    top: tooltipPosition.top + 'px',
    left: tooltipPosition.left + 'px',
    transform: 'translateX(-50%)',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    color: 'white',
    padding: '5px 10px',
    borderRadius: '4px',
    fontSize: '14px',
    zIndex: 1000,
    whiteSpace: 'nowrap',
  };

  const containerStyle = {
    display: 'inline-block',
  };

  return (
    <div
      ref={containerRef}
      style={containerStyle}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {isVisible &&
        createPortal(
          <motion.div
            style={tooltipStyle}
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: isVisible ? 1 : 0, scale: isVisible ? 1 : 0.8 }}
            transition={{ duration: 0.2 }}
          >
            {text}
          </motion.div>,
          document.body // This renders the tooltip at the root level of the DOM
        )}
    </div>
  );
};

export default Tooltip;
