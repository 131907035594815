import { GoogleAuthProvider } from "firebase/auth";


const iconImages = [
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FappleIcon.png?alt=media&token=59c81968-db93-44d4-bedf-c7c0c1963098',
    provider: 'new AppleAuthProvider()', 
    alt: 'Sign In with Apple'
  }, 
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FfacebookIcon.png?alt=media&token=1cc64fde-a0c6-49de-8e96-1f252345d57b',
    provider: 'new FacebookAuthProvider()', 
    alt: 'Sign In with Facebook'
  }, 
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FgoogleIcon.png?alt=media&token=ca574a33-7823-4157-859e-457762c77e39',
    provider: new GoogleAuthProvider(), 
    alt: 'Sign In with Google'
  }, 
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FtwitterIcon.png?alt=media&token=2589312b-e83e-43ca-87e2-a333b1bac0af',
    provider: 'new TwitterAuthProvider()', 
    alt: 'Sign In with Twitter'
  }, 
  {
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FwindowsIcon.png?alt=media&token=f484bf05-55e2-4052-9ac8-b57f02649bfa',
    provider: 'new WindowsAuthProvider()', 
    alt: 'Sign In with Windows'
  }, 
  ];

  const images = [
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
  ];

  export {iconImages, images}