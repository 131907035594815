import React from "react";
import { motion } from "framer-motion";

const styles = {
    button: {
        base: {
            padding: "10px 20px",
            fontSize: "16px",
            fontWeight: "500",
            border: "none",
            borderRadius: "24px",
            cursor: "pointer",
            transition: "all 0.3s",
            minWidth: "120px",
        },
        buttonOne: {
            backgroundColor: '#FEFBF6',
            color: '#4a4a4a',
            border: '1px solid #e0e0e0',
            cursor: 'pointer',
            marginLeft: '20px', 
            marginBottom: '20px'
        },
    },
};


export const DocumentDownload = ({ documentUrl, fileName, text }) => {

    const handleDownload = async () => {
        try {
            const response = await fetch(documentUrl);
            if (!response.ok) {
                throw new Error("Network response was not ok");
            }
            const blob = await response.blob();
            const link = document.createElement('a');
            link.href = URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(link.href); // Clean up the URL object
        } catch (error) {
            //console.error("Download failed:", error);
        }
    };

    return (
        <motion.button
            style={{ ...styles.button.base, ...styles.button.buttonOne }}
            whileHover={{ scale: 1.03 }}
            whileTap={{ scale: 0.98 }}
            onClick={handleDownload}
        >
            Download {text}
        </motion.button>
    );
};
