import React from 'react';
import { Link } from 'react-router-dom';
import './../../../index.css'
import { color } from 'framer-motion';

const Footer = () => {
  return (
    <footer className='footer'>
      <div style={styles.container}>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Quick Links</h3>
          <ul style={styles.linkList}>
            <li>
              <Link to='/'
              className='standardLink'
              >
                Home
              </Link>
            </li>
            <li>
              <Link to='/about'
              className='standardLink'
              >
                About Us
              </Link>
            </li>
            <li>
              <Link to='/ourteam'
              className='standardLink'
              >
                Our Team
              </Link>
            </li>
            <li>
              <Link to='/ourtrustees'
              className='standardLink'
              >
                Our Trustees
              </Link>
            </li>
            <li>
              <Link to='/news'
              className='standardLink'
              >
                News
              </Link>
            </li>
            <li>
              <Link to='/events'
              className='standardLink'
              >
                Events
              </Link>
            </li>
            <li>
              <Link to='/contactus'
              className='standardLink'
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Our Services</h3>
          <ul style={styles.linkList}>
            <li>
              <Link to='/explore'
              className='standardLink'
              >
                EDUCO
              </Link>
            </li>
            <li>
              <Link to='/education'
              className='standardLink'
              >
                Education
              </Link>
            </li>
            <li>
              <Link to='/education/enrol'
              className='standardLink'
              >
                Enrol
              </Link>
            </li>
            <li>
              <Link to='/statements/resourceLibrary'
              className='standardLink'
              >
                Resource Library
              </Link>
            </li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Legal Stuff</h3>
          <ul style={styles.linkList}>
            <li>
              <Link to='/policies/v2_4_OTC_Safeguarding_Policy_10_Oct'
               className='standardLink'>
                Safeguarding Policy
              </Link>
            </li>
            <li>
              <Link to='/policies/e_safety_policy'
              className='standardLink'>
                GDPR Policy
              </Link>
              </li>
            <li>
              <Link to='/policies'
              className='standardLink'
              >
                Policy Centre
              </Link>
              </li>
            <li>
              <Link to='/statements'
              className='standardLink'
              >
                Statements by OTC
              </Link>
            </li>
          </ul>
        </div>
        <div style={styles.section}>
          <h3 style={styles.sectionTitle}>Powered By:</h3>
          <div style={styles.logoContainer}>
            <img 
              src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2FLYMember.webp?alt=media&token=cac96dc2-f885-4173-855c-f8c58e9c3cff'} 
              alt="London Youth Logo" 
              style={styles.logo} 
            />
            <img 
              src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FFooter%2Flondon_youth_qm_bronze_accredited.png?alt=media&token=ef06a583-fa41-4157-843b-5aa40e57d3d8'} 
              alt="Quality Mark Logo" 
              style={styles.logo} 
            />
            <img 
              src={'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FFooter%2FPurple_Badge_PolicyBee.webp?alt=media&token=eed7901b-7ce4-4cf2-8caa-65339ce55b61'} 
              alt="Policy Bee Logo" 
              style={styles.logo} 
            />
          </div>

          <div style={styles.contactInfo}>
            <div style={styles.charityNumber}>
              Charity number: <b>1196701</b>
            </div>
            
            <div style={styles.address}>
              Lambeth, London, United Kingdom
            </div>
            
            <a 
              href='mailto:enquiries@offthecurriculum.co.uk'
              style={styles.email}
            >
              enquiries@offthecurriculum.co.uk
            </a>
          </div>
        </div>
      </div>
      <div style={styles.copyright}>
        © Copyright 2023 All rights reserved Off The Curriculum
      </div>
    </footer>
  );
};

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'space-around',
    maxWidth: '1200px',
    margin: '0 auto',
    flexWrap: 'wrap',
  },
  section: {
    flex: '1 1 200px',
    margin: '1rem',
  },
  linkList: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    color: '#000',
    textAlign: 'left'
  },
  logo: {
    maxWidth: '50px',
    margin: '0.5rem',
  },
  copyright: {
    textAlign: 'center',
    marginTop: '2rem',
  },
  sectionTitle: {
    fontSize: '1.25rem',
    fontWeight: '600',
    color: '#1F484C',
    marginBottom: '16px',
    textAlign: 'left',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '32px',
    marginBottom: '24px',
  },
  contactInfo: {
    display: 'flex',
    flexDirection: 'column',
  },
  charityNumber: {
    marginBottom: '8px',
  },
  address: {
    marginBottom: '8px',
    color: '#4b5563',
  },
  email: {
    color: '#1F484C',
    textDecoration: 'none',
    transition: 'color 0.2s',
    '&:hover': {
      color: '#2563eb',
    },
    linkStyle: {
      textDecoration: 'none', // Removes underline
      color: 'inherit', // Uses the color of its parent element
    }
  },
};

export default Footer;