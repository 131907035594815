import React from 'react';
import { motion } from 'framer-motion';

const LoadingComponent = ({ 
  isLoading, 
  message = "Loading...",
  backgroundColor = "rgba(255, 255, 255, 0.95)", // Lighter background for better visibility
  spinnerColor = "#54AFB8", // Your charity's blue color
  textColor = "#333333", // Matching your text color scheme
}) => {
  if (!isLoading) return null;

  const styles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: backgroundColor,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 1000,
      padding: '20px',
    },
    container: {
      backgroundColor: '#FFFFFF',
      borderRadius: '15px',
      padding: '30px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      boxShadow: `
        0px 1.8px 2.2px rgba(0, 0, 0, 0.028),
        0px 4.3px 5.3px rgba(0, 0, 0, 0.04),
        0px 8.1px 10px rgba(0, 0, 0, 0.05),
        0px 14.5px 17.9px rgba(0, 0, 0, 0.06),
        0px 65px 80px rgba(0, 0, 0, 0.01)
      `,
      border: '1px solid rgba(0, 0, 0, 0.05)',
      maxWidth: '400px',
      width: '100%',
    },
    spinnerContainer: {
      marginBottom: '20px',
      position: 'relative',
    },
    spinner: {
      width: '60px',
      height: '60px',
      border: '4px solid rgba(84, 175, 184, 0.1)', // Light version of your blue
      borderTop: `4px solid ${spinnerColor}`,
      borderRadius: '50%',
    },
    text: {
      color: textColor,
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'center',
      lineHeight: 1.5,
      margin: 0,
    },
    subText: {
      color: '#666666',
      fontSize: '14px',
      textAlign: 'center',
      marginTop: '8px',
      lineHeight: 1.4,
    },
  };

  return (
    <motion.div 
      style={styles.overlay}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <div style={styles.container}>
        <div style={styles.spinnerContainer}>
          <motion.div
            style={styles.spinner}
            animate={{ rotate: 360 }}
            transition={{ 
              duration: 1, 
              repeat: Infinity, 
              ease: "linear",
            }}
          />
        </div>
        <p style={styles.text}>{message}</p>
        <p style={styles.subText}>Please wait while we process your request</p>
      </div>
    </motion.div>
  );
};

export default LoadingComponent;