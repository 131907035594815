import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaExclamationTriangle, FaShieldAlt, FaUserSecret, FaEnvelope } from 'react-icons/fa';

const WhistleblowingPolicyGuide = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > 1024;
  const isTablet = windowWidth <= 1024 && windowWidth > 768;
  const isMobile = windowWidth <= 768;

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isDesktop ? '20px' : isTablet ? '16px' : '12px',
      gap: isDesktop ? '10px' : '24px',
      flexDirection: 'column',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: '20px',
      fontSize: '1em',
      padding: '8px 12px',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : '2em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '30px',
    },
    stepsGrid: {
      display: 'grid',
      gridTemplateColumns: isDesktop ? 'repeat(2, 1fr)' : '1fr',
      gap: '24px',
      marginBottom: '32px',
    },
    stepCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
      },
    },
    icon: {
      fontSize: '2em',
      color: '#1F484C',
      marginBottom: '16px',
    },
    stepTitle: {
      fontSize: '1.25em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '12px',
    },
    stepDescription: {
      fontSize: '1em',
      color: '#4b5563',
      lineHeight: '1.6',
    },
    additionalInfoCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      marginBottom: '32px',
    },
    additionalInfoTitle: {
      fontSize: '1.5em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '16px',
    },
    list: {
      paddingLeft: '20px',
      marginBottom: '20px',
    },
    listItem: {
      color: '#4b5563',
      marginBottom: '8px',
      lineHeight: '1.6',
    },
    footer: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      textAlign: 'center',
      color: '#1F484C',
      fontSize: '1.1em',
      lineHeight: '1.6',
    },
  };

  const WhistleblowingStep = ({ icon, title, description }) => (
    <div style={styles.stepCard}>
      <div style={styles.icon}>{icon}</div>
      <h3 style={styles.stepTitle}>{title}</h3>
      <p style={styles.stepDescription}>{description}</p>
    </div>
  );

  return (
    <div style={styles.container}>
      <button 
        onClick={() => navigate('/statements')} 
        style={styles.backButton}
      >
        <FaArrowLeft /> Back to Statements
      </button>

      <h1 style={styles.pageTitle}>Whistleblowing Policy Guide</h1>
      <p style={styles.pageDescription}>
        Off the Curriculum is committed to maintaining an open and accountable workplace. 
        If you have concerns about wrongdoing within our organisation, here's how you can raise them:
      </p>

      <div style={styles.stepsGrid}>
        <WhistleblowingStep 
          icon={<FaExclamationTriangle />}
          title="Identify a Concern"
          description="Recognise issues such as criminal offences, legal obligations not being met, risks to health and safety, or other serious concerns."
        />
        <WhistleblowingStep 
          icon={<FaShieldAlt />}
          title="Report to Line Manager"
          description="In most cases, first report your concern to your line manager. They will escalate the matter if necessary."
        />
        <WhistleblowingStep 
          icon={<FaUserSecret />}
          title="Contact Whistleblowing Officer"
          description="If you can't report to your manager, or if the concern is very serious, contact our Whistleblowing Officer, Joseph M Levermore."
        />
        <WhistleblowingStep 
          icon={<FaEnvelope />}
          title="Concerns About the Whistleblowing Officer"
          description="If your concern involves the Whistleblowing Officer, contact the Trustees directly at trustees@offthecurriculum.co.uk."
        />
      </div>

      <div style={styles.additionalInfoCard}>
        <h2 style={styles.additionalInfoTitle}>Important Notes:</h2>
        <ul style={styles.list}>
          <li style={styles.listItem}>You can raise concerns in writing or verbally.</li>
          <li style={styles.listItem}>You don't need to prove the wrongdoing, but you should have a reasonable belief that it's occurring.</li>
          <li style={styles.listItem}>Your confidentiality will be protected if requested.</li>
          <li style={styles.listItem}>Off the Curriculum prohibits retaliation against whistleblowers.</li>
          <li style={styles.listItem}>For independent advice, contact Public Concern at Work's helpline: 020 7404 6609.</li>
          <li style={styles.listItem}>Remember, if your concern is about the Whistleblowing Officer, you should contact the Trustees directly.</li>
        </ul>
      </div>

      <div style={styles.footer}>
        <p>Off the Curriculum values your courage in speaking up. Your concerns help us maintain our integrity and improve our organisation.</p>
      </div>
    </div>
  );
};

export default WhistleblowingPolicyGuide;