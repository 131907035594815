export default {
  id: 'learningtolearn',
    title: "Learning to Learn",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Dr. Joseph Michael Levermore",
      image: '',
      bio: "I am the founder of Off the Curriculum and a researcher at Imperial College London, with a keen interest in educational empowerment as a vehicle for social mobility. Upon realising the limited opportunities for educational activities provided to young people in Lambeth and Southwark London, we decided to take action. I am passionate about sports, science (physics and chemistry), and philosophy.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-05-10",
      readTime: "5 minutes",
      category: "educative"
    },
    content: [
      {
        type: "heading",
        content: "Tips and Advice on how to bolster productivity, while working from home."
      },
      {
        type: "paragraph",
        content: "According to experts in education, our 'learning style' can influence how 'successful' we are throughout higher education (Romanelli et al., 2009).  What success means in this context is relative to you, but being aware of the various learning styles could be helpful knowledge in one's journey in education."
      },
      {
        type: "paragraph",
        content: `A learning style is defined here as a group of 'characteristic cognitive, effective, and psychosocial behaviors that serve as relatively stable indicators of how learners perceive, interact with and respond to the learning environment' (Curry, 1981). While there is some debate about exactly which learning style models are most accurate for categorising people - learning style theory remains a helpful tool for educators and students (Romanelli et al., 2009).`
      },
      {
        type: "heading",
        content: "The Seven Learning Styles"
      },
      {
        type: "paragraph",
        content: `This article will touch upon the most widely used model for learning styles. Let's get into the Seven Learning Styles model, the most commonly used (Meyers, 2017):`
      },
      {
        type: "paragraph",
        content: "The visual (spatial) learner: those that are primarily visual learners prefer to absorb information in a visual form;"
      },
      {
        type: "paragraph",
        content: "The auditory (aural) learner: auditory learners prefer to learn through sound and music;"
      },
      {
        type: "paragraph",
        content: "The verbal (linguistic) learner: learns best through reading or listening to speech;"
      },
      {
        type: "paragraph",
        content: "Physical (Kinaesthetic): Learns best through tasks involving touch or movement;"
      },
      {
        type: "paragraph",
        content: "Logical (mathematical): Learners who learn best using reason, logic, and numbers;"
      },
      {
        type: "paragraph",
        content: "Social (Interpersonal): Are skilled in forming interpersonal relationships, and learn best when in a social situation such as a discussion or debate; and"
      },
      {
        type: "paragraph",
        content: "Solitary (Intrapersonal): Learn best when able to process information alone, when they can introspect and reflect.."
      },
      {
        type: "heading",
        content: "Conclusion"
      },
      {
        type: "paragraph",
        content: `Which learner are you? Are you a combination of two or more? Happy learning everybody!`
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };