import React, { useState, useEffect } from 'react';
import { getDatabase, ref, get, update, onValue } from 'firebase/database';
import StandardButtonContainer from "../../../Components/Common/Buttons/StandardButtons/StandardButtons";
import EllipsisText from "../../../Components/Common/EllipsisText/ellipsisText";
import BottomLoader from "../../../Components/Common/Loading/BottomLoaderComponent";
import _ from 'lodash';
import { emailsHasher } from '../../../Firebase/educoFunctions';
import { functions } from '../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import useAuth from '../../../Firebase/Auth';
import { useNavigate } from 'react-router-dom';
//meta tags
import { pageMetaTags } from '../../../Components/Common/MetaTags/metaData';
import MetaTags from '../../../Components/Common/MetaTags/metaTagsComponent';

const MentorRequestsPage = () => {
  const meta = pageMetaTags.MentorRequestsPage;
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeStudents, setActiveStudents] = useState([]);
  const [showActiveStudents, setShowActiveStudents] = useState(false);  // Add this line
  const [selectedView, setSelectedView] = useState('pending'); // 'pending', 'active', or null
  const currentUser = useAuth();  
  const navigate = useNavigate();

  const [mentorStats, setMentorStats] = useState({
    activeStudents: 0,
    totalStudents: 0,
    pendingRequests: 0
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  // Fetch mentor requests
  useEffect(() => {
    const fetchMentorData = async () => {
      if (!currentUser?.email) return;
      
      try {
        setLoading(true);
        const hashedEmail = emailsHasher(currentUser.email);
        
        // Fetch requests
        const getMentorRequests = httpsCallable(functions, "get_mentor_requests");
        const requestsResult = await getMentorRequests({ mentorId: hashedEmail });

        if (requestsResult.data?.success) {
          // Filter out accepted requests

          const pendingRequests = requestsResult.data.requests.filter(
            req => req.status !== 'accepted'
          );
          const acceptedStudents = requestsResult.data.requests.filter(
            req => req.status === 'accepted'
          );
          
          setRequests(pendingRequests);
          setActiveStudents(acceptedStudents);
          setMentorStats({
            activeStudents: acceptedStudents.length,
            totalStudents: acceptedStudents.length,
            pendingRequests: pendingRequests.length
          });
        }
      } catch (error) {
        //console.error("Error fetching mentor data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchMentorData();
  }, [currentUser?.email]);

  const handleRequestResponse = async (requestId, accept) => {
    try {
      setLoading(true);
      const hashedEmail = emailsHasher(currentUser.email);
      const handleMentorRequestResponse = httpsCallable(functions, "handle_mentor_request_response");
      
      const result = await handleMentorRequestResponse({
        requestId: requestId,
        mentorId: hashedEmail,
        accept: accept
      });

      if (result.data.success) {
        if (accept) {
          // Update the local state for accepted requests
          const acceptedRequest = requests.find(req => req.requestId === requestId);
          if (acceptedRequest) {
            setActiveStudents(prev => [...prev, {...acceptedRequest, status: 'accepted'}]);
          }
          setRequests(prev => prev.filter(req => req.requestId !== requestId));
        } else {
          // Remove rejected requests from the local state
          setRequests(prev => prev.filter(req => req.requestId !== requestId));
        }

        // Update stats
        setMentorStats(prev => ({
          ...prev,
          activeStudents: accept ? prev.activeStudents + 1 : prev.activeStudents,
          pendingRequests: prev.pendingRequests - 1
        }));
      }
    } catch (error) {
      //console.error('Error handling mentor request:', error);
    } finally {
      setLoading(false);
    }
  };

  //console.log('active students', activeStudents)




  const activeStudentsDropdownStyle = {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    borderRadius: '0 0 20px 20px',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    border: '1px solid #f0f0f0',
    padding: '16px',
    zIndex: 10,
    maxHeight: '400px',
    overflowY: 'auto'
  };

  const activeStudentCardStyle = {
    padding: '16px',
    borderBottom: '1px solid #f0f0f0',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease',
    '&:hover': {
      backgroundColor: '#f7fafc'
    }
  };

  // Styling
  const containerStyle = {
    width: "100%",
    maxWidth: "1200px",
    margin: "0 auto",
    padding: windowWidth <= 768 ? "16px" : "32px",
    boxSizing: "border-box"
  };

  const headerStyle = {
    marginBottom: "32px",
    textAlign: windowWidth <= 768 ? "center" : "left"
  };

  const titleStyle = {
    fontSize: "32px",
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: "12px"
  };

  const subtitleStyle = {
    fontSize: "16px",
    color: "#718096",
    marginBottom: "24px"
  };

  const requestsContainerStyle = {
    display: "grid",
    gap: "24px",
    gridTemplateColumns: windowWidth <= 768 ? "1fr" : "repeat(auto-fill, minmax(600px, 1fr))"
  };

  const requestCardStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
    border: "1px solid #f0f0f0",
    padding: "24px",
    transition: "transform 0.2s ease, box-shadow 0.2s ease"
  };

  const cardHeaderStyle = {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "20px",
    flexDirection: windowWidth <= 500 ? "column" : "row",
    gap: windowWidth <= 500 ? "16px" : "0"
  };

  const studentInfoStyle = {
    flex: 1
  };

  const studentNameStyle = {
    fontSize: "24px",
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: "8px"
  };

  const statusStyle = (status) => ({
    padding: "6px 12px",
    borderRadius: "20px",
    fontSize: "14px",
    fontWeight: "500",
    backgroundColor: status === 'pending' ? '#FDF5E8' : 
                    status === 'accepted' ? '#F0FFF4' : 
                    '#FFF5F5',
    color: status === 'pending' ? '#E79B18' : 
           status === 'accepted' ? '#48BB78' : 
           '#E53E3E',
    border: `1px solid ${
      status === 'pending' ? '#E79B18' : 
      status === 'accepted' ? '#48BB78' : 
      '#E53E3E'
    }`
  });

  const sectionStyle = {
    marginBottom: "20px"
  };

  const sectionTitleStyle = {
    fontSize: "16px",
    fontWeight: "600",
    color: "#4A5568",
    marginBottom: "8px"
  };

  const contentStyle = {
    fontSize: "15px",
    lineHeight: "1.6",
    color: "#718096"
  };

  const timestampStyle = {
    fontSize: "14px",
    color: "#A0AEC0",
    marginTop: "16px"
  };

  const buttonContainerStyle = {
    display: "flex",
    gap: "12px",
    marginTop: "20px",
    justifyContent: windowWidth <= 500 ? "center" : "flex-start"
  };

  const emptyStateStyle = {
    textAlign: "center",
    padding: "48px",
    backgroundColor: "#fff",
    borderRadius: "20px",
    border: "1px solid #f0f0f0"
  };
  const photoContainerStyle = {
    display: "flex",
    alignItems: "flex-start",
    gap: "20px",
    marginBottom: "16px"
  };

  const photoStyle = {
    width: "100px",
    height: "100px",
    borderRadius: "50%",
    objectFit: "cover",
    border: "3px solid #fff",
    boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
  };

  const detailsContainerStyle = {
    flex: 1
  };

  const buttonGroupStyle = {
    display: "flex",
    gap: "12px",
    marginTop: "16px"
  };

  const statusBadgeStyle = {
    display: "inline-flex",
    alignItems: "center",
    padding: "6px 12px",
    borderRadius: "20px",
    fontSize: "14px",
    fontWeight: "500",
    marginLeft: windowWidth <= 500 ? "0" : "auto"
  };

  // Status styling function
  const getStatusStyle = (status) => {
    const statusMap = {
      pending: {
        bg: '#FDF5E8',
        color: '#E79B18',
        border: '#E79B18'
      },
      accepted: {
        bg: '#F0FFF4',
        color: '#48BB78',
        border: '#48BB78'
      },
      rejected: {
        bg: '#FFF5F5',
        color: '#E53E3E',
        border: '#E53E3E'
      }
    };

    const style = statusMap[status] || statusMap.pending;
    
    return {
      ...statusBadgeStyle,
      backgroundColor: style.bg,
      color: style.color,
      border: `1px solid ${style.border}`
    };
  };
  const dashboardStyle = {
    display: "grid",
    gridTemplateColumns: windowWidth <= 768 ? "1fr" : "repeat(3, 1fr)",
    gap: "24px",
    marginBottom: "40px"
  };

  const dashboardCardStyle = {
    backgroundColor: "#fff",
    borderRadius: "20px",
    padding: "24px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.05)",
    border: "1px solid #f0f0f0",
    cursor: "pointer",
    transition: "transform 0.2s ease, box-shadow 0.2s ease"
  };

  const cardTitleStyle = {
    fontSize: "18px",
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: "16px"
  };

  const cardStatStyle = {
    fontSize: "32px",
    fontWeight: "700",
    color: "#1F484C",
    marginBottom: "8px"
  };

  const cardDescriptionStyle = {
    fontSize: "14px",
    color: "#718096"
  };

  const sectionHeaderStyle = {
    fontSize: "24px",
    fontWeight: "600",
    color: "#2D3748",
    marginBottom: "24px",
    marginTop: "40px"
  };

  const ActiveStudentsCard = () => (
    <div style={{ position: 'relative' }}>
      <div 
        style={dashboardCardStyle}
        onClick={() => setShowActiveStudents(!showActiveStudents)}
        onMouseEnter={(e) => {
          if (windowWidth > 768) {
            e.currentTarget.style.transform = "translateY(-4px)";
            e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
          }
        }}
        onMouseLeave={(e) => {
          if (windowWidth > 768) {
            e.currentTarget.style.transform = "translateY(0)";
            e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
          }
        }}
      >
        <h3 style={cardTitleStyle}>Active Students</h3>
        <p style={cardStatStyle}>{activeStudents.length}</p>
        <p style={cardDescriptionStyle}>Click to view your current mentees</p>
      </div>

      {showActiveStudents && (
        <div style={activeStudentsDropdownStyle}>
          {activeStudents.length === 0 ? (
            <p style={contentStyle}>No active students yet</p>
          ) : (
            activeStudents.map((student) => (
              <div 
                key={student.requestId}
                style={activeStudentCardStyle}
                onClick={() => navigate(`/mentors/mentoringprogramme/${student.menteeId}`)}
              >
                <div style={photoContainerStyle}>
                  <img
                    src={student.menteeDetails.displayPhotoUrl || '/default-profile.png'}
                    alt={student.menteeDetails.name}
                    style={{ ...photoStyle, width: '50px', height: '50px' }}
                  />
                  <div>
                    <h4 style={{ ...studentNameStyle, fontSize: '18px' }}>
                      {student.menteeDetails.name}
                    </h4>
                    <p style={{ ...contentStyle, fontSize: '14px' }}>
                      {student.menteeDetails.title || 'No project title'}
                    </p>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      )}
    </div>
  );

  return (
    <div style={containerStyle}>
      <MetaTags 
        title={meta.title}
        description={meta.description}
        url={meta.url}
        image={meta.image}
        siteName={meta.siteName}
        twitterCard={meta.twitterCard}
        twitterSite={meta.twitterSite}
        fbAppId={meta.fbAppId}
      />
      <header style={headerStyle}>
        <h1 style={titleStyle}>Your Mentor Mentoring Programme Portal</h1>
        <p style={subtitleStyle}>
          Review and manage your incoming mentorship requests, visit the mentor 'Find a Mentor Page', and continue along the programme with your current students. 
          You will also receive an email regarding any mentorship requests. Please do ensure sure to respond to pending requests within 48 hours. Thank you again!
        </p>
      </header>

      <div style={dashboardStyle}>
        {/* Active Students Card */}
        <div 
          style={{
            ...dashboardCardStyle,
            border: selectedView === 'active' ? '2px solid #48BB78' : '1px solid #f0f0f0',
            backgroundColor: selectedView === 'active' ? '#F0FFF4' : '#fff'
          }}
          onClick={() => setSelectedView('active')}
          onMouseEnter={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
            }
          }}
          onMouseLeave={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
            }
          }}
        >
          <h3 style={cardTitleStyle}>Active Students</h3>
          <p style={cardStatStyle}>{activeStudents.length}</p>
          <p style={cardDescriptionStyle}>View your current mentees</p>
        </div>

        {/* Pending Requests Card */}
        <div 
          style={{
            ...dashboardCardStyle,
            border: selectedView === 'pending' ? '2px solid #E79B18' : '1px solid #f0f0f0',
            backgroundColor: selectedView === 'pending' ? '#FDF5E8' : '#fff'
          }}
          onClick={() => setSelectedView('pending')}
          onMouseEnter={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
            }
          }}
          onMouseLeave={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
            }
          }}
        >
          <h3 style={cardTitleStyle}>Pending Requests</h3>
          <p style={cardStatStyle}>{requests.length}</p>
          <p style={cardDescriptionStyle}>Review new mentorship requests</p>
        </div>

        {/* Find Mentees Card */}
        <div 
          style={dashboardCardStyle}
          onClick={() => navigate('/mentors')}
          onMouseEnter={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(-4px)";
              e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
            }
          }}
          onMouseLeave={(e) => {
            if (windowWidth > 768) {
              e.currentTarget.style.transform = "translateY(0)";
              e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
            }
          }}
        >
          <h3 style={cardTitleStyle}>Find Mentees</h3>
          <p style={cardStatStyle}>Explore</p>
          <p style={cardDescriptionStyle}>Browse potential mentees</p>
        </div>
      </div>

      {/* Main Content Container */}
      {loading ? (
        <BottomLoader 
          isLoading={true}
          message="Loading your mentor content..."
          backgroundColor={"rgba(255, 255, 255, 0.95)"}
          textColor={"#FFFFFF"}
        />
      ) : (
        <>
          {selectedView === 'active' ? (
            // Active Students View
            <div>
              <h2 style={sectionHeaderStyle}>Active Students</h2>
              {activeStudents.length === 0 ? (
                <div style={emptyStateStyle}>
                  <h3 style={{...studentNameStyle, marginBottom: "16px"}}>No Active Students</h3>
                  <p style={contentStyle}>You haven't accepted any mentorship requests yet.</p>
                  <div style={{ marginTop: "24px" }}>
                    <StandardButtonContainer>
                      <StandardButtonContainer.ButtonOne
                        onClick={() => setSelectedView('pending')}
                      >
                        View Pending Requests
                      </StandardButtonContainer.ButtonOne>
                    </StandardButtonContainer>
                  </div>
                </div>
              ) : (
                <div style={requestsContainerStyle}>
                  {activeStudents.map((student) => (
                    <div
                      key={student.menteeId}
                      style={{...requestCardStyle, cursor: 'pointer'}}
                      onClick={() => {
                        if (student?.menteeId) {
                          navigate(`/mentors/mentoringprogramme/${student.menteeId}`);
                        } else {
                          //console.error('No menteeId found:', student);
                        }
                      }}
                      onMouseEnter={(e) => {
                        if (windowWidth > 768) {
                          e.currentTarget.style.transform = "translateY(-4px)";
                          e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
                        }
                      }}
                      onMouseLeave={(e) => {
                        if (windowWidth > 768) {
                          e.currentTarget.style.transform = "translateY(0)";
                          e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
                        }
                      }}
                    >
                      <div style={photoContainerStyle}>
                        <img
                          src={student.menteeDetails.displayPhotoUrl || '/default-profile.png'}
                          alt={student.menteeDetails.name}
                          style={photoStyle}
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = '/default-profile.png';
                          }}
                        />
                        <div style={detailsContainerStyle}>
                          <div style={cardHeaderStyle}>
                            <div style={studentInfoStyle}>
                              <h3 style={studentNameStyle}>{student.menteeDetails.name}</h3>
                              <p style={{...contentStyle, marginBottom: "8px"}}>
                                Project: {student.menteeDetails.title || 'No project title'}
                              </p>
                            </div>
                            <div style={getStatusStyle('accepted')}>
                              Active Student
                            </div>
                          </div>
                        </div>
                      </div>
                      <div style={sectionStyle}>
                        <h4 style={sectionTitleStyle}>About the Student</h4>
                        <EllipsisText 
                          text={student.menteeDetails.bio || 'No bio provided'} 
                          maxLength={150}
                          style={contentStyle}
                        />
                      </div>
                      <p style={{...contentStyle, marginTop: "16px", color: "#48BB78"}}>
                        Click to view full profile and progress
                      </p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          ) : selectedView === 'pending' ? (
            // Pending Requests View
            <div>
              <h2 style={sectionHeaderStyle}>Pending Requests</h2>
              {requests.length === 0 ? (
                <div style={emptyStateStyle}>
                  <h3 style={{...studentNameStyle, marginBottom: "16px"}}>No Pending Requests</h3>
                  <p style={contentStyle}>You currently don't have any mentor requests to review.</p>
                  {activeStudents.length > 0 && (
                    <div style={{ marginTop: "24px" }}>
                      <StandardButtonContainer>
                        <StandardButtonContainer.ButtonOne
                          onClick={() => setSelectedView('active')}
                        >
                          View Current Mentees
                        </StandardButtonContainer.ButtonOne>
                      </StandardButtonContainer>
                    </div>
                  )}
                </div>
              ) : (
                <div style={requestsContainerStyle}>
              {requests.map((request) => (
                <div
                  key={request.requestId}
                  style={requestCardStyle}
                  onMouseEnter={(e) => {
                    if (windowWidth > 768) {
                      e.currentTarget.style.transform = "translateY(-4px)";
                      e.currentTarget.style.boxShadow = "0 12px 20px rgba(0, 0, 0, 0.1)";
                    }
                  }}
                  onMouseLeave={(e) => {
                    if (windowWidth > 768) {
                      e.currentTarget.style.transform = "translateY(0)";
                      e.currentTarget.style.boxShadow = "0 4px 6px rgba(0, 0, 0, 0.05)";
                    }
                  }}
                >
                  <div style={photoContainerStyle}>
                    <img
                      src={request.menteeDetails.displayPhotoUrl || '/default-profile.png'}
                      alt={`${request.menteeDetails.name}'s profile`}
                      style={photoStyle}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = '/default-profile.png';
                      }}
                    />
                    <div style={detailsContainerStyle}>
                      <div style={cardHeaderStyle}>
                        <div style={studentInfoStyle}>
                          <h3 style={studentNameStyle}>{request.menteeDetails.name}</h3>
                          <p style={{...contentStyle, marginBottom: "8px"}}>
                            Project Title: {request.menteeDetails.title || 'Not specified'}
                          </p>
                        </div>
                        <div style={getStatusStyle(request.status)}>
                          {_.capitalize(request.status)}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div style={sectionStyle}>
                    <h4 style={sectionTitleStyle}>Student's Message</h4>
                    <EllipsisText 
                      text={request.message || 'No message provided'} 
                      maxLength={150}
                      style={contentStyle}
                    />
                  </div>

                  <div style={sectionStyle}>
                    <h4 style={sectionTitleStyle}>About the Student</h4>
                    <EllipsisText 
                      text={request.menteeDetails.bio || 'No bio provided'} 
                      maxLength={150}
                      style={contentStyle}
                    />
                  </div>

                  <p style={timestampStyle}>
                    Requested on {new Date(request.createdAt).toLocaleDateString()} at{' '}
                    {new Date(request.createdAt).toLocaleTimeString()}
                  </p>
                  {request.type === 'mentorship_request' && (
                    <div style={buttonGroupStyle}>
                      <StandardButtonContainer>
                        <StandardButtonContainer.ButtonTwo
                          onClick={() => handleRequestResponse(request.requestId, true)}
                        >
                          Accept Request
                        </StandardButtonContainer.ButtonTwo>
                        <StandardButtonContainer.ButtonOne
                          onClick={() => handleRequestResponse(request.requestId, false)}
                        >
                          Decline Request
                        </StandardButtonContainer.ButtonOne>
                      </StandardButtonContainer>
                    </div>
                  )}
                </div>
              ))}
        </div>
      )}
    </div>
  ) : null}
</>
)}
</div>
);
};

export default MentorRequestsPage;