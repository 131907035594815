import React from 'react';
import { motion } from 'framer-motion';

const styles = {
  actionButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '12px',
    padding: '20px 0',
  },
  button: {
    base: {
      padding: '10px 20px',
      fontSize: '16px',
      fontWeight: '500',
      border: 'none',
      borderRadius: '24px',
      cursor: 'pointer',
      transition: 'all 0.3s',
      minWidth: '120px',
    },
    buttonOne: {
      backgroundColor: '#FEFBF6',
      color: '#4a4a4a',
      border: '1px solid #e0e0e0',
      cursor: 'pointer',
    },
    buttonTwo: {
      backgroundColor: '#1F484C',
      color: '#ffffff',
    },
    buttonThree: {
      color: '#ffffff',
    },
  },
};

const AnimatedButton = ({ children, style, onClick }) => (
  <motion.button
    style={{ ...styles.button.base, ...style }}
    whileHover={{ scale: 1.03 }}
    whileTap={{ scale: 0.98 }}
    onClick={onClick}
  >
    {children}
  </motion.button>
);

const StandardButtonContainer = ({ children }) => {
  return (
    <div style={styles.actionButtons}>
      {children}
    </div>
  );
};

StandardButtonContainer.ButtonOne = ({ children, onClick, style }) => (
  <AnimatedButton
    style={{ ...styles.button.buttonOne, ...style }}
    onClick={onClick}
  >
    {children}
  </AnimatedButton>
);

StandardButtonContainer.ButtonTwo = ({ children, onClick, style }) => (  // Added style prop
  <AnimatedButton
    style={{ ...styles.button.buttonTwo, ...style }}  // Merge base style with custom style
    onClick={onClick}
  >
    {children}
  </AnimatedButton>
);

StandardButtonContainer.ButtonThree = ({ children, onClick, backgroundColor, style }) => (  // Added style prop for consistency
  <AnimatedButton
    style={{ 
      ...styles.button.buttonThree, 
      backgroundColor: backgroundColor,
      ...style 
    }}
    onClick={onClick}
  >
    {children}
  </AnimatedButton>
);

export default StandardButtonContainer;