import React, { useState, useEffect } from 'react';
import { functions } from '../../../../Firebase/firebaseConfig';
import { httpsCallable } from 'firebase/functions';
import useAuth from '../../../../Firebase/Auth';
import { emailsHasher, publicUserHasher } from '../../../../Firebase/educoFunctions';

const EmailFooterGenerator = () => {
  const currentUser = useAuth();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('Loading');
  const [instructions, setShowInstructions] = useState(false);

  // Loading animation effect
  useEffect(() => {
    if (loading) {
      const interval = setInterval(() => {
        setLoadingText((prev) => {
          if (prev === 'Loading...') return 'Loading';
          return prev + '.';
        });
      }, 500);
      return () => clearInterval(interval);
    }
  }, [loading]);

  useEffect(() => {
    const fetchProfileData = async () => {
      if (currentUser?.email) {
        try {
          setLoading(true);
          const publicUserId = publicUserHasher(currentUser.email);
          //console.log( 'public key', publicUserId)
          // Call Firebase function
          const getFooterData = httpsCallable(functions, 'charity_footer_creator');
          const result = await getFooterData({ publicUserId: publicUserId });
          
          //console.log('Footer data from function:', result.data);
          
          if (result.data && result.data.success) {
            setProfileData(result.data.footer_data);
          } else {
            throw new Error('Profile not found');
          }
        } catch (error) {
          //console.error('Error fetching profile:', error);
          alert('Error loading profile data. Please ensure you have created a profile first.');
        } finally {
          setLoading(false);
        }
      }
    };

    fetchProfileData();
  }, [currentUser]);

  if (loading) {
    return (
      <div style={styles.loadingContainer}>
        <p style={styles.loadingText}>{loadingText}</p>
      </div>
    );
  }

  const generateFooter = () => {
    if (!profileData) return;

    const footerTemplate = `
      <META content="text/html; charset=utf-8" http-equiv="Content-Type">
      <link href="https://fonts.googleapis.com/css?family=montserrat" rel="stylesheet"> 
      </HEAD>
      <BODY style="font-size: 10pt; font-family: montserrat, sans-serif">
      
      <table style="width: 500px; font-size: 10pt; font-family:montserrat, sans-serif;" cellpadding="0" cellspacing="0" border="0">
      <tbody>
       <tr>
        <td width="200" align="center" style="font-size: 10pt; font-family: montserrat, sans-serif; width: 200px; text-align:center; vertical-align: top;" valign="top" rowspan="6">
              <p>
                  <img src="${profileData.profileImage}" alt="photograph" width="160" border="0" style="border:0; height:auto; width:160px; border-radius:50%"/>
              </p>
        </td>
        
        <td width="5" style="width:5px">&nbsp;</td>  
        
       <td>
        <table cellpadding="0" cellspacing="0">
        <tbody>
          <tr>
            <td style="font-size: 10pt; color:#4DABB5; font-family: montserrat, sans-serif; width: 305px; padding-bottom: 15px; vertical-align: top; line-height:1.2;" valign="top">
              <strong><span style="font-size: 14pt; font-family: montserrat, sans-serif; color:#000000;">${profileData.firstName} ${profileData.lastName}<br></span></strong>
              <em style="font-family: montserrat, sans-serif; font-size:10pt; color:#000000;">${profileData.roleTitle}</em>       
              <span style="font-family: montserrat, sans-serif; font-size:9pt; color:#000000;" ><br>Off the Curriculum</span>   
            </td>
          </tr> 
          <tr>
            <td style="border-top:2px solid #4DABB5; font-size: 9pt; color:#444444; font-family: montserrat, sans-serif; padding-top: 15px; vertical-align: top; line-height:1.2" valign="top">
              <span style="color: #4DABB5;">E: </span><span style="font-size: 9pt; font-family: montserrat, sans-serif; color:#000000;">${profileData.email}</span>
            </td>
          </tr>
          <tr>
            <td style="font-size: 10pt; font-family: montserrat, sans-serif; padding-bottom: 5px; padding-top: 5px; vertical-align: top;" valign="top">
              <a href="https://offthecurriculum.co.uk/" target="_blank" rel="noopener"><img border="0" width="20" src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Fotc_logo.png?alt=media&token=61c7601c-934b-4f54-83d0-21e152ba6344" alt="otc icon" style="border:0; height:20px; width:20px"></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://twitter.com/otc_london" target="_blank" rel="noopener"><img border="0" width="20" src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Ftwitter.png?alt=media&token=be0ac32b-366e-452c-b4e1-0f9fdc6dc718" alt="twitter icon" style="border:0; height:20px; width:20px"></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.linkedin.com/company/offthecurriculum-otc/" target="_blank" rel="noopener"><img border="0" width="20" src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Flinkedin.png?alt=media&token=92ec8d5b-194f-471b-975e-364ff98b6aef" alt="linkedin icon" style="border:0; height:20px; width:20px"></a>&nbsp;&nbsp;&nbsp;&nbsp;<a href="https://www.instagram.com/offthecurriculumlondon/" target="_blank" rel="noopener"><img border="0" width="20" src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Finstagram.png?alt=media&token=d4a0f1df-155d-4e09-9677-32921b1da2b1" alt="instagram icon" style="border:0; height:20px; width:20px"></a>
            </td>
           </tr>
          </tbody>
        </table>
       </td>
       </tr> 
      </tbody>
      </table>
      </BODY>
      </HTML>
    `;

    const blob = new Blob([footerTemplate], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = `${profileData.firstName}_${profileData.lastName}_email_signature.html`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    setShowInstructions(true);
  };

  if (loading) {
    return <div>Loading profile data...</div>;
  }

  return (
    <div style={styles.container}>
      <h2>Email Signature Generator</h2>
      
      {profileData ? (
        <div>
            <div style={styles.preview}>
              <h3>Preview of your charity footer</h3>
              <table style={styles.previewTable}>
                <tbody>
                  <tr>
                    <td style={styles.imageCell}>
                      <img 
                        src={profileData.profileImage} 
                        alt="Profile" 
                        style={styles.profileImage} 
                      />
                    </td>
                    <td style={styles.spacerCell}></td>
                    <td style={styles.contentCell}>
                      <div style={styles.nameTitle}>
                        <strong style={styles.name}>{profileData.firstName} {profileData.lastName}</strong>
                        <em style={styles.title}>{profileData.roleTitle}</em>
                        <span style={styles.company}>Off the Curriculum</span>
                      </div>
                      <div style={styles.divider}></div>
                      <div style={styles.contactInfo}>
                        <span style={styles.emailLabel}>E: </span>
                        <span style={styles.emailText}>{profileData.email}</span>
                      </div>
                      <div style={styles.socialLinks}>
                        <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Fotc_logo.png?alt=media&token=61c7601c-934b-4f54-83d0-21e152ba6344" alt="OTC" style={styles.socialIcon} />
                        <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Ftwitter.png?alt=media&token=be0ac32b-366e-452c-b4e1-0f9fdc6dc718" alt="Twitter" style={styles.socialIcon} />
                        <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Flinkedin.png?alt=media&token=92ec8d5b-194f-471b-975e-364ff98b6aef" alt="LinkedIn" style={styles.socialIcon} />
                        <img src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2Femail-branding%2Finstagram.png?alt=media&token=d4a0f1df-155d-4e09-9677-32921b1da2b1" alt="Instagram" style={styles.socialIcon} />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          
          <button onClick={generateFooter} style={styles.button}>
            Generate Email Signature
          </button>

          {instructions && (
            <div style={styles.instructions}>
              <h3>Installation Instructions:</h3>
              <ol>
                <li>Download and open the .html file in your browser</li>
                <li>Press Control + A (Windows) or Cmd + A (Mac) to select all</li>
                <li>Copy the contents (Control/Cmd + C)</li>
                <li>In Gmail, click the settings cog</li>
                <li>Click "See all settings"</li>
                <li>Scroll to "Signature"</li>
                <li>Click "Create new", name it "OTC"</li>
                <li>Paste the signature</li>
                <li>Set as default for new emails and replies</li>
                <li>Enable "Insert signature before quoted text"</li>
                <li>Save changes</li>
              </ol>
            </div>
          )}
        </div>
      ) : (
        <p>No profile data found. Please create your profile first.</p>
      )}
    </div>
  );
};

const styles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
  },
  preview: {
    border: '1px solid #ddd',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
  },
  previewContent: {
    display: 'flex',
    gap: '20px',
    alignItems: 'start',
  },
  profileImage: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  profileInfo: {
    flex: 1,
  },
  button: {
    backgroundColor: '#1F484C',
    color: 'white',
    padding: '10px 20px',
    border: 'none',
    borderRadius: '8px',
    cursor: 'pointer',
    fontSize: '16px',
    marginBottom: '20px',
  },
  instructions: {
    backgroundColor: '#f5f5f5',
    padding: '20px',
    borderRadius: '8px',
    marginTop: '20px',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '200px',
  },
  loadingText: {
    fontSize: '18px',
    color: '#666',
    fontFamily: 'monospace',
  },
  previewTable: {
    width: '500px',
    fontFamily: 'montserrat, sans-serif',
    fontSize: '10pt',
    borderCollapse: 'collapse',
    margin: '20px auto',
  },
  imageCell: {
    width: '200px',
    textAlign: 'center',
    verticalAlign: 'top',
    padding: '10px',
  },
  spacerCell: {
    width: '5px',
  },
  contentCell: {
    verticalAlign: 'top',
    padding: '10px',
  },
  profileImage: {
    width: '160px',
    height: '160px',
    borderRadius: '50%',
    objectFit: 'cover',
  },
  nameTitle: {
    marginBottom: '15px',
    lineHeight: 1.2,
  },
  name: {
    display: 'block',
    fontSize: '14pt',
    color: '#000000',
    marginBottom: '5px',
  },
  title: {
    display: 'block',
    fontSize: '10pt',
    color: '#000000',
    fontStyle: 'italic',
    marginBottom: '5px',
  },
  company: {
    display: 'block',
    fontSize: '9pt',
    color: '#000000',
  },
  divider: {
    borderTop: '2px solid #4DABB5',
    marginBottom: '15px',
    paddingTop: '15px',
  },
  contactInfo: {
    fontSize: '9pt',
    color: '#444444',
    marginBottom: '15px',
  },
  emailLabel: {
    color: '#4DABB5',
  },
  emailText: {
    color: '#000000',
  },
  socialLinks: {
    display: 'flex',
    gap: '15px',
    alignItems: 'center',
  },
  socialIcon: {
    width: '20px',
    height: '20px',
    border: 0,
  },
  preview: {
    border: '1px solid #ddd',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    backgroundColor: '#fff',
  },
}

export default EmailFooterGenerator;