import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import useAuth from '../../../../../Firebase/Auth';
import { emailsHasher } from '../../../../../Firebase/educoFunctions';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../../Firebase/firebaseConfig';
import { PreviewSection } from './PreviewIncidentForm';
export const MenteeIncidentForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitError, setSubmitError] = useState(null);
  const currentUser = useAuth();
  const [hashedEmail, setHashedEmail] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  
  useEffect(() => {
    if (currentUser?.email) {
      const hashed = emailsHasher(currentUser.email);
      setHashedEmail(hashed);
      setFormData(prev => ({
        ...prev,
        username: hashed
      }));
    }
  }, [currentUser]);

  const [formData, setFormData] = useState({
    username: hashedEmail,
    name: '',                 
    mentorName: '',
    schoolName: '',
    incidentDate: '',
    incidentLocation: '',
    incidentDescription: '',
    witnesses: '',              
    witnessList: '',           
    reportedToSchool: '',      
    schoolContactName: '',     
    dateReportedToSchool: '', 
    notReportedReason: '',    
    concernType: [],         
    otherConcernType: '', 
    emotionalImpact: '',
    supportNeeded: '',
    preferredContactMethod: '',
    parentInformed: '',
    additionalInfo: '',
    confirmationName: '',
    confirmationDate: '',
  });
  const resetForm = () => {
    setFormData({
      username: hashedEmail,
      name: '',
      mentorName: '',
      schoolName: '',
      incidentDate: '',
      incidentLocation: '',
      incidentDescription: '',
      witnesses: '',
      witnessList: '',
      reportedToSchool: '',
      schoolContactName: '',
      dateReportedToSchool: '',
      notReportedReason: '',
      concernType: [],
      otherConcernType: '',
      emotionalImpact: '',
      supportNeeded: '',
      preferredContactMethod: '',
      parentInformed: '',
      additionalInfo: '',
      confirmationName: '',
      confirmationDate: '',
    });
  };
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: type === 'checkbox' 
        ? (checked 
          ? [...prevState[name], value]
          : prevState[name].filter(item => item !== value))
        : value
    }));
  };

  const handleSubmitReport = async () => {

    //console.log(formData.name)
    const requiredFields = {
      name: formData.name, 
      mentorName: formData.mentorName,
      incidentDate: formData.incidentDate,
      incidentDescription: formData.incidentDescription,
      confirmationName: formData.confirmationName,
      confirmationDate: formData.confirmationDate
    };

    const missingFields = Object.entries(requiredFields)
      .filter(([key]) => !formData[key])
      .map(([_, label]) => label);

    if (missingFields.length > 0) {
      alert(`Please fill in the following required fields:\n${missingFields.join('\n')}`);
      return;
    }

    const confirmed = window.confirm(
      'Are you sure you want to submit this report? Your safety and wellbeing are important to us, and this report will be treated confidentially.'
    );

    if (!confirmed) {
      return;
    }

    setIsSubmitting(true);
    setSubmitError(null);

    try {
      const submitIncidentReport = httpsCallable(functions, 'submit_student_incident_report');
      const result = await submitIncidentReport(formData);

      if (result.data.success) {

        
        setShowPreview(false)
        resetForm();
        alert('Your report has been submitted successfully. A member of our safeguarding team will contact you soon.');
      } else {
        throw new Error(result.data.error || 'Failed to submit report');
      }
    } catch (error) {
     //console.error('Error submitting report:', error);
      setSubmitError(error.message);
      alert(`Error submitting report: ${error.message}`);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handlePreview = () => {
    // Check required fields first
    const requiredFields = {
      name: formData.name, 
      mentorName: formData.mentorName,
      incidentDate: formData.incidentDate,
      incidentDescription: formData.incidentDescription,
      confirmationName: formData.confirmationName,
      confirmationDate: formData.confirmationDate
    };
  
    const missingFields = Object.entries(requiredFields)
      .filter(([key]) => !formData[key])
      .map(([_, label]) => label);
  
    if (missingFields.length > 0) {
      alert(`Please fill in the following required fields:\n${missingFields.join('\n')}`);
      return;
    }
  
    setShowPreview(true);
  };
  

  return (
    <form style={styles.form} onSubmit={(e) => e.preventDefault()}>
      <h1>Student Incident Report Form</h1>
      <p style={styles.introText}>
        Your safety and wellbeing are our top priority. If you've experienced any concerning behavior or incidents with a mentor, 
        please use this form to report it. All reports are treated confidentially and will be reviewed by our safeguarding team.
      </p>

      <div style={styles.section}>
        <h2>Your Information</h2>
        <div style={styles.fieldGroup}>
          <label htmlFor="name" style={styles.label}>Your Name:</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="schoolName" style={styles.label}>School Name:</label>
          <input
            type="text"
            id="schoolName"
            name="schoolName"
            value={formData.schoolName}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
      </div>

      <div style={styles.section}>
        <h2>Incident Details</h2>
        <div style={styles.fieldGroup}>
          <label htmlFor="mentorName" style={styles.label}>Mentor's Name:</label>
          <input
            type="text"
            id="mentorName"
            name="mentorName"
            value={formData.mentorName}
            onChange={handleChange}
            required
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentDate" style={styles.label}>When did this happen?</label>
          <input
            type="date"
            id="incidentDate"
            name="incidentDate"
            value={formData.incidentDate}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentLocation" style={styles.label}>Where did this happen?</label>
          <input
            type="text"
            id="incidentLocation"
            name="incidentLocation"
            value={formData.incidentLocation}
            onChange={handleChange}
            style={styles.input}
            placeholder="e.g., Online meeting, School library, etc."
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="incidentDescription" style={styles.label}>What happened?</label>
          <textarea
            id="incidentDescription"
            name="incidentDescription"
            value={formData.incidentDescription}
            onChange={handleChange}
            style={styles.textarea}
            placeholder="Please describe what happened in as much detail as you feel comfortable sharing. Include any specific words or actions that concerned you."
          />
        </div>
        {/* After the incident description field */}
        <div style={styles.fieldGroup}>
          <label style={styles.label}>Were there any witnesses?</label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="witnesses"
                value="yes"
                checked={formData.witnesses === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="witnesses"
                value="no"
                checked={formData.witnesses === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>

        {formData.witnesses === 'yes' && (
          <div style={styles.fieldGroup}>
            <label htmlFor="witnessList" style={styles.label}>
              Please provide witness details (names and their role if known):
            </label>
            <textarea
              id="witnessList"
              name="witnessList"
              value={formData.witnessList}
              onChange={handleChange}
              style={styles.textarea}
              placeholder="Example: John Smith (Another student), Ms. Thompson (Teacher), etc."
            />
          </div>
        )}

        <div style={styles.fieldGroup}>
          <label style={styles.label}>Type of Concern:</label>
          <div style={styles.checkboxGroup}>
            {[
              'Inappropriate behavior',
              'Uncomfortable comments',
              'Unprofessional conduct',
              'Session boundaries',
              'Communication issues',
              'Other concerns'
            ].map(concern => (
              <label key={concern} style={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="concernType"
                  value={concern}
                  checked={formData.concernType.includes(concern)}
                  onChange={handleChange}
                /> {concern}
              </label>
            ))}
          </div>
        </div>

        {formData.concernType.includes('Other concerns') && (
          <div style={styles.fieldGroup}>
            <label htmlFor="otherConcernType" style={styles.label}>Please specify your other concerns:</label>
            <textarea
              id="otherConcernType"
              name="otherConcernType"
              value={formData.otherConcernType}
              onChange={handleChange}
              style={styles.textarea}
            />
          </div>
        )}
      </div>

      <div style={styles.section}>
        <h2>Support and Follow-up</h2>
        <div style={styles.fieldGroup}>
          <label htmlFor="emotionalImpact" style={styles.label}>How has this affected you?</label>
          <textarea
            id="emotionalImpact"
            name="emotionalImpact"
            value={formData.emotionalImpact}
            onChange={handleChange}
            style={styles.textarea}
            placeholder="How are you feeling about what happened? This helps us provide appropriate support."
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="supportNeeded" style={styles.label}>What support would be helpful for you right now?</label>
          <textarea
            id="supportNeeded"
            name="supportNeeded"
            value={formData.supportNeeded}
            onChange={handleChange}
            style={styles.textarea}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="preferredContactMethod" style={styles.label}>How would you prefer for us to follow this up?</label>
          <select
            id="preferredContactMethod"
            name="preferredContactMethod"
            value={formData.preferredContactMethod}
            onChange={handleChange}
            style={styles.select}
          >
            <option value="">Please select</option>
            <option value="email">With you directly</option>
            <option value="phone">With your school teacher</option>
            <option value="schoolStaff">Through our mentor</option>
          </select>
        </div>
        <div style={styles.fieldGroup}>
        <label style={styles.label}>Have you reported this to anyone at your school?</label>
        <div style={styles.radioGroup}>
          <label style={styles.radioLabel}>
            <input
              type="radio"
              name="reportedToSchool"
              value="yes"
              checked={formData.reportedToSchool === 'yes'}
              onChange={handleChange}
            /> Yes
          </label>
          <label style={styles.radioLabel}>
            <input
              type="radio"
              name="reportedToSchool"
              value="no"
              checked={formData.reportedToSchool === 'no'}
              onChange={handleChange}
            /> No
          </label>
        </div>
      </div>

      {formData.reportedToSchool === 'yes' && (
        <>
          <div style={styles.fieldGroup}>
            <label htmlFor="schoolContactName" style={styles.label}>
              Who did you report it to at school?
            </label>
            <input
              type="text"
              id="schoolContactName"
              name="schoolContactName"
              value={formData.schoolContactName}
              onChange={handleChange}
              style={styles.input}
              placeholder="Name and role (e.g., Ms. Smith - Head of Year)"
            />
          </div>
          <div style={styles.fieldGroup}>
            <label htmlFor="dateReportedToSchool" style={styles.label}>
              When did you report it?
            </label>
            <input
              type="date"
              id="dateReportedToSchool"
              name="dateReportedToSchool"
              value={formData.dateReportedToSchool}
              onChange={handleChange}
              style={styles.input}
            />
          </div>
        </>
      )}

      {formData.reportedToSchool === 'no' && (
        <div style={styles.fieldGroup}>
          <label htmlFor="notReportedReason" style={styles.label}>
            Could you tell us why you haven't reported it to school?
          </label>
          <textarea
            id="notReportedReason"
            name="notReportedReason"
            value={formData.notReportedReason}
            onChange={handleChange}
            style={styles.textarea}
            placeholder="This helps us understand how to better support you"
          />
        </div>
      )}
      </div>

      <div style={styles.section}>
        <h2>Final Steps</h2>
        <div style={styles.fieldGroup}>
          <label style={styles.label}>
            Have you told a parent/guardian about this?
          </label>
          <div style={styles.radioGroup}>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="parentInformed"
                value="yes"
                checked={formData.parentInformed === 'yes'}
                onChange={handleChange}
              /> Yes
            </label>
            <label style={styles.radioLabel}>
              <input
                type="radio"
                name="parentInformed"
                value="no"
                checked={formData.parentInformed === 'no'}
                onChange={handleChange}
              /> No
            </label>
          </div>
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="confirmationName" style={styles.label}>Please type your name to confirm this report:</label>
          <input
            type="text"
            id="confirmationName"
            name="confirmationName"
            value={formData.confirmationName}
            onChange={handleChange}
            style={styles.input}
          />
        </div>

        <div style={styles.fieldGroup}>
          <label htmlFor="confirmationDate" style={styles.label}>Today's Date:</label>
          <input
            type="date"
            id="confirmationDate"
            name="confirmationDate"
            value={formData.confirmationDate}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
      </div>

      <motion.button
        onClick={handlePreview}
        disabled={isSubmitting}
        style={{
          ...styles.submitButton,
          opacity: isSubmitting ? 0.7 : 1,
          cursor: isSubmitting ? 'not-allowed' : 'pointer'
        }}
        whileHover={{ scale: isSubmitting ? 1 : 1.03 }}
        whileTap={{ scale: isSubmitting ? 1 : 0.98 }}
      >
        Review Report
      </motion.button>

      {/* Add the preview section */}
      {showPreview && (
        <PreviewSection 
          formData={formData} 
          onClose={() => setShowPreview(false)}
          onSubmit={handleSubmitReport}
          isSubmitting={isSubmitting}
        />
      )}

      {submitError && (
        <div style={styles.errorMessage}>
          {submitError}
        </div>
      )}
    </form>
  );
};

const styles = {
  form: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  section: {
    marginBottom: '30px',
  },
  fieldGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#000',
  },
  input: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',

  },
  select: {
    width: '100%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    backgroundColor: '#fff',
  },
  textarea: {
    width: '95%',
    padding: '8px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    fontSize: '16px',
    height: '120px', 
    maxHeight: '120px',
    resize: 'none', 
    overflowY: 'auto', 
    margin: 'auto',
  },
  radioGroup: {
    display: 'flex',
    gap: '15px',
  },
  radioLabel: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  checkboxGroup: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
    gap: '10px',
  },
  button: {
    padding: '10px 20px',
    fontSize: '16px',
    fontWeight: '500',
    border: 'none',
    borderRadius: '24px',
    cursor: 'pointer',
    transition: 'all 0.3s',
    minWidth: '120px',
    backgroundColor: '#1F484C',
    color: '#ffffff',
    border: '1px solid #e0e0e0',
    cursor: 'pointer',
  },
  introText: {
    marginBottom: '20px',
    color: '#666',
    lineHeight: '1.5',
    fontSize: '16px',
  },
  submitButton: {
    padding: '12px 24px',
    fontSize: '16px',
    fontWeight: '500',
    border: 'none',
    borderRadius: '24px',
    cursor: 'pointer',
    backgroundColor: '#1F484C',
    color: '#ffffff',
    marginTop: '20px',
    width: '100%',
    maxWidth: '200px',
  },
  errorMessage: {
    color: '#dc3545',
    marginTop: '10px',
    padding: '10px',
    borderRadius: '4px',
    backgroundColor: '#fff',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginBottom: '8px',
  },
  spinner: {
  width: '20px',
  height: '20px',
  border: '2px solid #ffffff',
  borderTop: '2px solid transparent',
  borderRadius: '50%',
  animation: 'spin 1s linear infinite',
},
};

