import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FaArrowLeft, FaUsers, FaGraduationCap, FaClipboardList, FaHandsHelping, FaCommunity } from 'react-icons/fa';
import { GoPeople } from "react-icons/go";

const diversityData = {
  title: "Promoting Anti-Discriminatory Practice at Off the Curriculum",
  introduction: "At Off the Curriculum, we are dedicated to fostering an inclusive and welcoming environment for all young people. Our commitment to anti-discriminatory practice is central to our mission and operations. Here's how we actively promote equality and respect for diversity:",
  practices: [
    {
      title: "Inclusive Programs and Activities",
      points: [
        "We design our programs to be accessible and inclusive, ensuring that every young person, regardless of their background, has the opportunity to participate and benefit.",
        "Activities and materials are created to reflect and celebrate diversity, promoting understanding and appreciation of different cultures, identities, and perspectives."
      ]
    },
    {
      title: "Education and Training",
      points: [
        "We provide regular training for our staff and volunteers on anti-discriminatory practices and cultural competency, ensuring they understand and can effectively support diverse needs.",
        "Young people in our programs receive education on the importance of respect, inclusion, and the negative impacts of discrimination."
      ]
    },
    {
      title: "Clear Policies and Procedures",
      points: [
        "Our anti-discriminatory policy outlines our zero-tolerance stance on any form of discrimination, harassment, or bullying.",
        "We have clear procedures for reporting and addressing incidents of discrimination, ensuring that any issues are dealt with promptly and effectively."
      ]
    },
    {
      title: "Supportive Environment",
      points: [
        "We create a safe space where young people can express themselves freely and feel valued for who they are.",
        "Mentoring and support services are tailored to meet the individual needs of each young person, acknowledging and respecting their unique identities."
      ]
    },
    {
      title: "Community Engagement",
      points: [
        "We actively engage with diverse communities to ensure our programs are relevant and supportive to all young people.",
        "Feedback from participants and their families is regularly sought and incorporated to continuously improve our practices and ensure inclusivity."
      ]
    }
  ],
  conclusion: "By embedding these principles into every aspect of our work, Off the Curriculum strives to be a place where all young people feel respected, supported, and empowered to achieve their potential."
};

const DiversityStatement = () => {
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const isDesktop = windowWidth > 1024;
  const isTablet = windowWidth <= 1024 && windowWidth > 768;
  const isMobile = windowWidth <= 768;

  const getIcon = (title) => {
    switch (title) {
      case "Inclusive Programs and Activities":
        return <FaUsers />;
      case "Education and Training":
        return <FaGraduationCap />;
      case "Clear Policies and Procedures":
        return <FaClipboardList />;
      case "Supportive Environment":
        return <FaHandsHelping />;
      case "Community Engagement":
        return <GoPeople />;
      default:
        return null;
    }
  };

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: isDesktop ? '20px' : isTablet ? '16px' : '12px',
      gap: isDesktop ? '20px' : '24px',
      flexDirection: 'column',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: '20px',
      fontSize: '1em',
      padding: '8px 12px',
      borderRadius: '4px',
      transition: 'background-color 0.2s ease',
    },
    pageTitle: {
      fontSize: isDesktop ? '2.5em' : '2em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    introSection: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      marginBottom: '24px',
    },
    introText: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
    },
    practicesGrid: {
      display: 'grid',
      gridTemplateColumns: isDesktop ? 'repeat(2, 1fr)' : '1fr',
      gap: '24px',
      marginBottom: '24px',
    },
    practiceCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      transition: 'transform 0.2s ease, box-shadow 0.2s ease',
      '&:hover': {
        transform: 'translateY(-2px)',
        boxShadow: '0 10px 20px rgba(0,0,0,0.1)',
      },
    },
    cardHeader: {
      display: 'flex',
      alignItems: 'center',
      gap: '12px',
      marginBottom: '16px',
    },
    icon: {
      fontSize: '1.5em',
      color: '#1F484C',
    },
    practiceTitle: {
      fontSize: '1.25em',
      fontWeight: '600',
      color: '#111827',
    },
    pointsList: {
      listStyle: 'none',
      padding: '0',
      margin: '0',
    },
    pointItem: {
      color: '#4b5563',
      fontSize: '1em',
      lineHeight: '1.6',
      marginBottom: '12px',
      paddingLeft: '20px',
      position: 'relative',
      '&:before': {
        content: '"•"',
        position: 'absolute',
        left: '0',
        color: '#1F484C',
      },
    },
    conclusionCard: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      padding: '24px',
      textAlign: 'center',
    },
    conclusionText: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
    },
    emphasis: {
      color: '#1F484C',
      fontWeight: '600',
    },
  };

  return (
    <div style={styles.container}>
      <button 
        onClick={() => navigate('/statements')} 
        style={styles.backButton}
      >
        <FaArrowLeft /> Back to Statements
      </button>

      <h1 style={styles.pageTitle}>Promoting Anti-Discriminatory Practice</h1>

      <div style={styles.introSection}>
        <p style={styles.introText}>
          At Off the Curriculum, we are dedicated to fostering an inclusive and welcoming environment for all young people. 
          Our commitment to anti-discriminatory practice is central to our mission and operations. Here's how we actively promote equality and respect for diversity:
        </p>
      </div>

      <div style={styles.practicesGrid}>
        {diversityData.practices.map((practice, index) => (
          <div key={index} style={styles.practiceCard}>
            <div style={styles.cardHeader}>
              <span style={styles.icon}>{getIcon(practice.title)}</span>
              <h3 style={styles.practiceTitle}>{practice.title}</h3>
            </div>
            <ul style={styles.pointsList}>
              {practice.points.map((point, pointIndex) => (
                <li key={pointIndex} style={styles.pointItem}>{point}</li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      <div style={styles.conclusionCard}>
        <p style={styles.conclusionText}>
          By embedding these principles into every aspect of our work, 
          <span style={styles.emphasis}> Off the Curriculum </span> 
          strives to be a place where all young people feel respected, supported, and empowered to achieve their potential.
        </p>
      </div>
    </div>
  );
};

export default DiversityStatement;