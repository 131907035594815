import StandardButtonContainer from "../../../../../Components/Common/Buttons/StandardButtons/StandardButtons";

export const PreviewSection = ({ formData, onClose, onSubmit }) => {
  return (
    <div style={styles.previewOverlay}>
      <div style={styles.previewContent}>
        <h2>Review Your Report</h2>
        <div style={styles.previewSection}>
          <h3>Your Information</h3>
          <PreviewField label="Your Name" value={formData.name} />
          <PreviewField label="School Name" value={formData.schoolName} />
        </div>

        <div style={styles.previewSection}>
          <h3>Incident Details</h3>
          <PreviewField label="Mentor's Name" value={formData.mentorName} />
          <PreviewField label="Date of Incident" value={formData.incidentDate} />
          <PreviewField label="Location" value={formData.incidentLocation} />
          <PreviewField 
            label="Description" 
            value={formData.incidentDescription} 
            type="longText"
          />
          <PreviewField 
            label="Witnesses" 
            value={formData.witnesses === 'yes' ? 'Yes' : 'No'} 
          />
          {formData.witnesses === 'yes' && (
            <PreviewField 
              label="Witness Details" 
              value={formData.witnessList} 
              type="longText"
            />
          )}
          <PreviewField 
            label="Types of Concern" 
            value={formData.concernType.join(', ')} 
          />
          {formData.concernType.includes('Other concerns') && (
            <PreviewField 
              label="Other Concerns" 
              value={formData.otherConcernType} 
              type="longText"
            />
          )}
        </div>

        <div style={styles.previewSection}>
          <h3>Support Information</h3>
          <PreviewField 
            label="Impact" 
            value={formData.emotionalImpact} 
            type="longText"
          />
          <PreviewField 
            label="Support Needed" 
            value={formData.supportNeeded} 
            type="longText"
          />
          <PreviewField 
            label="Preferred Contact Method" 
            value={formData.preferredContactMethod} 
          />
        </div>

        <div style={styles.previewSection}>
          <h3>School Reporting</h3>
          <PreviewField 
            label="Reported to School" 
            value={formData.reportedToSchool === 'yes' ? 'Yes' : 'No'} 
          />
          {formData.reportedToSchool === 'yes' && (
            <>
              <PreviewField 
                label="Reported To" 
                value={formData.schoolContactName} 
              />
              <PreviewField 
                label="Date Reported" 
                value={formData.dateReportedToSchool} 
              />
            </>
          )}
          {formData.reportedToSchool === 'no' && (
            <PreviewField 
              label="Reason Not Reported" 
              value={formData.notReportedReason} 
              type="longText"
            />
          )}
        </div>

        <div style={styles.previewSection}>
          <h3>Final Details</h3>
          <PreviewField 
            label="Parent/Guardian Informed" 
            value={formData.parentInformed === 'yes' ? 'Yes' : 'No'} 
          />
          <PreviewField 
            label="Confirmation Name" 
            value={formData.confirmationName} 
          />
          <PreviewField 
            label="Confirmation Date" 
            value={formData.confirmationDate} 
          />
        </div>

        <StandardButtonContainer>
          <StandardButtonContainer.ButtonOne
            onClick={onClose} 
            >
            Edit
         </StandardButtonContainer.ButtonOne>
          <StandardButtonContainer.ButtonTwo
          onClick={onSubmit} 
           >
          Submit
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
      </div>
    </div>
  );
};

const PreviewField = ({ label, value, type = 'text' }) => (
  <div style={styles.previewField}>
    <span style={styles.previewLabel}>{label}:</span>
    {type === 'longText' ? (
      <p style={styles.previewLongText}>{value}</p>
    ) : (
      <span style={styles.previewValue}>{value || 'Not provided'}</span>
    )}
  </div>
);

const styles = {
  previewOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    padding: '20px',
    overflowY: 'auto',
    zIndex: 1000,
  },
  previewContent: {
    backgroundColor: 'white',
    padding: '30px',
    borderRadius: '10px',
    maxWidth: '800px',
    width: '100%',
    maxHeight: '90vh',
    overflowY: 'auto',
    position: 'relative',
  },
  previewSection: {
    marginBottom: '25px',
    padding: '15px',
    backgroundColor: '#f8f9fa',
    borderRadius: '8px',
  },
  previewField: {
    marginBottom: '12px',
    padding: '8px 0',
    borderBottom: '1px solid #eee',
  },
  previewLabel: {
    fontWeight: 'bold',
    color: '#495057',
    marginRight: '10px',
  },
  previewValue: {
    color: '#212529',
  },
  previewLongText: {
    color: '#212529',
    marginTop: '5px',
    whiteSpace: 'pre-wrap',
  },
  previewButtons: {
    display: 'flex',
    justifyContent: 'center',
    gap: '15px',
    marginTop: '20px',
  },
  editButton: {
    padding: '10px 20px',
    backgroundColor: '#1F484C',
    color: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    fontSize: '16px',
  },
};