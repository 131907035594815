import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getDatabase, ref, child, get, update } from 'firebase/database';
import useAuth from '../../../../Firebase/Auth';
import { emailsHasher } from '../../../../Firebase/educoFunctions';
import { db } from '../../../../Firebase/firebaseConfig';
import { CiHeart } from "react-icons/ci";

export const buttonVariants = {
  rest: { 
    scale: 1,
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: { duration: 0.2 }
  },
  hover: { 
    scale: 1.05,
    backgroundColor: '#ff8a65',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.15)',
    transition: { duration: 0.2 }
  },
  tap: { 
    scale: 0.98,
    boxShadow: '0 1px 2px rgba(0, 0, 0, 0.1)',
    transition: { duration: 0.1 }
  }
};

const LikeButton = ({ name, initialLikes, onUpdateLikes }) => {
  const [videoname, setVideoName] = useState(name);
  const [likes, setLikes] = useState(initialLikes);
  const [isActive, setIsActive] = useState(false);
  const currentUser = useAuth();

  const buttonVariants = {
    rest: { scale: 1, boxShadow: '0 4px 8px 0 #0003' },
    hover: { scale: 1.15, backgroundColor: '#ff8a65', boxShadow: '0 4px 8px 0 #0003' },
    tap: { scale: 0.95 },
  };

  const handleLikeButtonClick = async () => {
    try {
      const dbRef = ref(getDatabase());
      const videoRef = child(dbRef, `video/name/${name}`);
      const snapshot = await get(videoRef);

      // Ensure we get the current likes from the database before updating
      if (snapshot.exists()) {
        const videoData = snapshot.val();
        const currentLikes = videoData.likes || 0;
        const updatedLikes = currentLikes + 1;

        // Update the likes value in the database
        await update(videoRef, { likes: updatedLikes });
        if (onUpdateLikes) {
          onUpdateLikes(updatedLikes);
        }

        // Update the user's like status
        if (currentUser) {
          const emailHash = emailsHasher(currentUser.email);
          const userRef = child(dbRef, `person/${emailHash}/Likes/${name}`);
          await update(userRef, { Name: name, likes: 'Liked' });
          setIsActive(true); // Set the like status to active
        }
      }
    } catch (error) {
      //console.error("Error updating likes:", error);
    }
  };


  return (
    <motion.button
      variants={buttonVariants}
      initial="rest"
      whileHover="hover"
      whileTap="tap"
      onMouseDown={() => setIsActive(true)}
      onMouseUp={() => setIsActive(false)}
      onClick={handleLikeButtonClick}
      style={{
        backgroundColor: isActive ? '#ffbaab' : '#f44336',
        borderRadius: '50px',
        color: 'white',
        border: 'none',
        outline: 'none',
        padding: '8px 16px',
        cursor: 'pointer',
        width: '100px',
        height: '36px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        fontSize: '14px',
        fontWeight: '500',
        letterSpacing: '0.3px',
        boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
        transition: 'all 0.2s ease',
        WebkitTapHighlightColor: 'transparent',
        userSelect: 'none',
      }}
    >
      <span style={{ 
        display: 'flex', 
        alignItems: 'center',
        lineHeight: '1'
      }}>
        Like
      </span>
      <CiHeart 
        style={{
          color: '#fff',
          marginTop: '1px' // Slight adjustment to visually align with text
        }} 
        size={15} 
      />
    </motion.button>
  );
};

export {LikeButton}
