import React, { useState, useEffect } from 'react';
import MainCreateAccountButton from '../../../Components/Common/Buttons/CreateAnAccount/CreateAnAccountButtons';
import { motion } from 'framer-motion';
import SignInWithComponent from './Components/SignInWith/SignInWith';
import { MdError, MdCheckCircle, MdCancel } from 'react-icons/md';
import {  
  createUserWithEmailAndPassword,
} from 'firebase/auth';
import { auth } from '../../../Firebase/firebaseConfig';
import { useNavigate } from 'react-router-dom';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

import LoadingComponent from '../../../Components/Common/Loading/LoadingComponent';

// icons
import { CiMail } from "react-icons/ci";
import { TbPassword } from "react-icons/tb";
import { IoEyeOutline, IoEyeOffOutline } from "react-icons/io5";


const CreateAccountComponent = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [focusedField, setFocusedField] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });
  const [errors, setErrors] = useState({
    show: false,
    type: 'error', // 'error', 'warning', or 'success'
    message: ''
  });
  const images = [
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7',
  ];
  const iconImages = [
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FappleIcon.png?alt=media&token=59c81968-db93-44d4-bedf-c7c0c1963098',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FfacebookIcon.png?alt=media&token=1cc64fde-a0c6-49de-8e96-1f252345d57b',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FgoogleIcon.png?alt=media&token=ca574a33-7823-4157-859e-457762c77e39',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FtwitterIcon.png?alt=media&token=2589312b-e83e-43ca-87e2-a333b1bac0af',
    'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FPages%2FGeneral%2FIcons%2FwindowsIcon.png?alt=media&token=f484bf05-55e2-4052-9ac8-b57f02649bfa',
  ];
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);

    return () => clearInterval(interval);
  }, [images.length]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleEmailSignUp = async (e) => {
    // Prevent double submission
    if (isSubmitting) return;
  
    if (formData.confirmPassword !== formData.password) {
      alert("Password does not match!");
      return false;
    }
    
    e.preventDefault();
    setIsSubmitting(true); // Set submitting state to true
  
    try {
      if (!validateForm()) {
        setIsSubmitting(false);
        return;
      }
  
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      );
      
      // Success handling
      setErrors({
        show: true,
        type: 'success',
        message: 'Account created successfully! Welcome to OTC.'
      });
      navigate('/createauserprofile');
      
    } catch (error) {
      let errorMessage = 'An error occurred during sign up';
      
      switch (error.code) {
        case 'auth/email-already-in-use':
          errorMessage = 'This email is already registered. Please try logging in.';
          break;
        case 'auth/invalid-email':
          errorMessage = 'Please enter a valid email address.';
          break;
        case 'auth/operation-not-allowed':
          errorMessage = 'Email/Password sign up is not enabled. Please contact support.';
          break;
        case 'auth/weak-password':
          errorMessage = 'Please choose a stronger password (at least 6 characters).';
          break;
        default:
          errorMessage = error.message;
      }
      
      setErrors({
        show: true,
        type: 'error',
        message: errorMessage
      });
      setIsSubmitting(false); // Reset submitting state on error
    }
  };
  

  const styles = {
  containerStyle: {
    display: 'flex',
    flexDirection: windowWidth <= 1200 ? 'column-reverse' : 'row',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '30px',
    margin: '2.5% auto',
    padding: '0 20px',
    maxWidth: '1600px',
  },
  carouselStyle: {
    width: '100%',
    maxWidth: '1200px',
    height: windowWidth <= 1200 ? '400px' : '800px',
    border: '1px solid rgba(0, 0, 0, 0.05)',  
    borderRadius: '10px',
    backgroundColor: '#fff',
    marginRight: windowWidth <= 1200 ? '0' : '50px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  imageStyle: {
    maxWidth: '80%',
    maxHeight: '80%',
    objectFit: 'contain',
  },
  formContainerStyle: {
    padding: '2.5%', 
    width: '100%',
    maxWidth: windowWidth <= 1200 ? '480px' : '400px',
    boxShadow: `
      0px 1.8px 2.2px rgba(0, 0, 0, 0.028),
      0px 4.3px 5.3px rgba(0, 0, 0, 0.04),
      0px 8.1px 10px rgba(0, 0, 0, 0.05),
      0px 14.5px 17.9px rgba(0, 0, 0, 0.06),
      0px 65px 80px rgba(0, 0, 0, 0.01)
    `,
    border: '1px solid rgba(0, 0, 0, 0.05)',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: windowWidth <= 1200 ? '0 auto' : '0',
    marginTop: windowWidth <= 1200 ? '50px' : '',
    borderRadius: '15px'
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: window.innerWidth < 768 ? '16px' : '24px', // Reduced gap on mobile
    width: '100%'
  },

  inputGroup: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  icon: {
    position: 'absolute',
    top: '50%', // Center vertically
    transform: 'translateY(-50%)', // Center vertically
    left: window.innerWidth < 768 ? '10%' : '5%', // Adjust left position based on screen size
    width: window.innerWidth < 768 ? '16px' : '20px', // Smaller icon on mobile
    height: window.innerWidth < 768 ? '16px' : '20px',
    color: '#9ca3af',
    zIndex: 1, // Ensure icon stays above input
  },
  inputContainerStyle: {
    display: 'flex',
    flexDirection: windowWidth <= 768 ? 'column' : 'row',
    alignItems: windowWidth <= 768 ? 'flex-start' : 'center',
    marginBottom: '20px',
    width: '100%',
    gap: '10px',
  }, 
  labelStyle: {
    marginRight: windowWidth <= 768 ? '0' : '10px',
    width: windowWidth <= 768 ? '100%' : '100px',
    textAlign: windowWidth <= 768 ? 'left' : 'right',
  }, 
  inputStyle: {
    width: '100%',
    padding: '8px',
    border: '1px solid #1F484C',
    borderRadius: '3px',
    flex: 1,
  }, 

  input: {
    width: window.innerWidth < 768 ? '75%' : '100%',
    maxWidth: '500px',
    padding: window.innerWidth < 768 
      ? '12px 12px 12px 45px' // Increased left padding on mobile
      : '12px 16px 12px 40px',
    borderRadius: '8px',
    border: '1px solid #e5e7eb',
    fontSize: window.innerWidth < 768 ? '14px' : '16px',
    outline: 'none',
    transition: 'border-color 0.2s, box-shadow 0.2s',
  },
  textarea: {
    width: window.innerWidth < 768 ? '75%' : '100%',
    maxWidth: '500px',
    padding: window.innerWidth < 768 
      ? '12px 12px 12px 45px' // Increased left padding on mobile
      : '12px 16px 12px 40px',
    borderRadius: '8px',
    border: '1px solid #e5e7eb',
    fontSize: window.innerWidth < 768 ? '14px' : '16px',
    minHeight: window.innerWidth < 768 ? '100px' : '120px',
    outline: 'none',
    transition: 'border-color 0.2s, box-shadow 0.2s',
    resize: 'none',
  },

  buttonStyle: {
    backgroundColor: '#54AFB8',
    color: '#fff',
    border: 'none',
    padding: '10px 20px',
    borderRadius: '3px',
    cursor: 'pointer',
    marginBottom: '10px',
  },
  formFooterStyle: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: windowWidth <= 480 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth <= 480 ? 'flex-start' : 'center',
    width: '100%',
    gap: '10px',
  }, 
  socialIconsStyle: {
    display: 'flex',
    gap: '10px',
  }, 
  accountInfoStyle: {
    display: 'flex',
    flexDirection: windowWidth <= 480 ? 'column' : 'row',
    justifyContent: 'space-between',
    alignItems: windowWidth <= 480 ? 'flex-start' : 'center',
    width: '100%',
    marginTop: '20px',
    gap: '10px',
  },
  textStyle: {
    flex: 1,
    textAlign: 'left',
  }, 
  
  buttonContainerStyle: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end',
  }, 

  iconImagesStyle: {
    height: '40px', 
  }, 

  // view password
  passwordGroup: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  eyeIcon: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: window.innerWidth < 768 ? '15%' : '5%',
    width: window.innerWidth < 768 ? '16px' : '20px',
    height: window.innerWidth < 768 ? '16px' : '20px',
    color: '#9ca3af',
    cursor: 'pointer',
    zIndex: 1,
  },



// New Alert Styles
  alert: {
  display: errors.show ? 'flex' : 'none',
  alignItems: 'center',
  width: '100%',
  padding: '12px 16px',
  marginBottom: '20px',
  borderRadius: '8px',
  animation: 'slideIn 0.3s ease-out',
  transition: 'all 0.3s ease',
  gap: '12px',
  zIndex:10000,
}, 
alertError: {
  backgroundColor: '#FEF2F2',
  border: '1px solid #FEE2E2',
  color: '#DC2626',
},
alertWarning: {
  backgroundColor: '#FFFBEB',
  border: '1px solid #FEF3C7',
  color: '#D97706',
},
alertSuccess: {
  backgroundColor: '#F0FDF4',
  border: '1px solid #DCFCE7',
  color: '#16A34A',
},
alertIcon: {
  flexShrink: 0,
},
alertContent: {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
},
alertMessage: {
  fontSize: '14px',
  fontWeight: 500,
  marginBottom: '2px',
},
alertCloseButton: {
  background: 'none',
  border: 'none',
  padding: '4px',
  cursor: 'pointer',
  color: 'inherit',
  opacity: 0.7,
  transition: 'opacity 0.2s',
  ':hover': {
    opacity: 1,
  },
},
'@keyframes slideIn': {
  from: {
    transform: 'translateY(-20px)',
    opacity: 0,
  },
  to: {
    transform: 'translateY(0)',
    opacity: 1,
  },
},
};

const getAlertStyle = () => {
switch (errors.type) {
  case 'warning':
    return { ...styles.alert, ...styles.alertWarning };
  case 'success':
    return { ...styles.alert, ...styles.alertSuccess };
  default:
    return { ...styles.alert, ...styles.alertError };
}
};

const getAlertIcon = () => {
  switch (errors.type) {
    case 'warning':
      return <MdError size={20} style={styles.alertIcon} />;
    case 'success':
      return <MdCheckCircle size={20} style={styles.alertIcon} />;
    default:
      return <MdCancel size={20} style={styles.alertIcon} />;
  }
};


const validateForm = () => {
if (!formData.email || !formData.password || !formData.confirmPassword) {
  setErrors({
    show: true,
    type: 'error',
    message: 'Please fill in all required fields to continue'
  });
  return false;
}

if (!formData.email.includes('@')) {
  setErrors({
    show: true,
    type: 'warning',
    message: 'Please enter a valid email address'
  });
  return false;
}

if (formData.password.length < 6) {
  setErrors({
    show: true,
    type: 'warning',
    message: 'Password must be at least 6 characters long'
  });
  return false;
}

if (formData.password !== formData.confirmPassword) {
  setErrors({
    show: true,
    type: 'error',
    message: 'Passwords do not match. Please try again'
  });
  return false;
}

return true;
};

const handleInputChange = (e) => {
  const { name, value } = e.target;
  setFormData(prev => ({
    ...prev,
    [name]: value
  }));
  // Clear errors when user starts typing
  if (errors.show) {
    setErrors({ show: false, type: 'error', message: '' });
  }
};

const dismissAlert = () => {
setErrors({ show: false, type: 'error', message: '' });
};

return (
<div style={styles.containerStyle}>
  <div style={styles.carouselStyle}>
    <img 
      src={images[currentIndex]} 
      alt={`Carousel ${currentIndex + 1}`} 
      style={{ maxWidth: '80%', maxHeight: '80%', objectFit: 'contain' }} 
    />
  </div>
  <div style={styles.formContainerStyle}>
    {isSubmitting && <LoadingComponent />}
    <h2>Create Account</h2>
    <p>Please create an account and start your learning journey.</p>
    
    {errors.show && (
      <div style={getAlertStyle()}>
        {getAlertIcon()}
        <div style={styles.alertContent}>
          <span style={styles.alertMessage}>{errors.message}</span>
        </div>
        <button 
          onClick={dismissAlert}
          style={styles.alertCloseButton}
          aria-label="Dismiss alert"
        >
          <MdCheckCircle size={16} />
        </button>
      </div>
    )}
    <form onSubmit={handleEmailSignUp} style={styles.form}>
      <div style={styles.inputGroup}>
        <CiMail style={styles.icon} />
        <input            
          type="email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          placeholder="Your Email"
          style={{
              ...styles.input,
              boxShadow: focusedField === 'email' ? '0 0 0 1px #1F484C' : 'none',
              borderColor: focusedField === 'email' ? '#1F484C' : '#e5e7eb',
            }}
          onFocus={() => setFocusedField('email')}
          onBlur={() => setFocusedField(null)}
          required
        />
      </div>
      <div style={styles.inputGroup}>
        <TbPassword style={styles.icon} />
        <input            
          type={showPassword ? "text" : "password"}
          name="password"
          placeholder="i.e. OTCRules"
          value={formData.password}
          onChange={handleInputChange}
          style={{
              ...styles.input,
              boxShadow: focusedField === 'password' ? '0 0 0 1px #1F484C' : 'none',
              borderColor: focusedField === 'password' ? '#1F484C' : '#e5e7eb',
            }}
          onFocus={() => setFocusedField('password')}
          onBlur={() => setFocusedField(null)}
          required
        />
         <div
            onClick={() => setShowPassword(!showPassword)}
            style={styles.eyeIcon}
          >
            {showPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
          </div>
      </div>
      <div style={styles.inputGroup}>
        <TbPassword style={styles.icon} />
        <input            
          type={showConfirmPassword ? "text" : "password"}
          name="confirmPassword"
          placeholder="i.e. OTCRules"
          value={formData.confirmPassword}
          onChange={handleInputChange}
          style={{
            ...styles.input,
            boxShadow: focusedField === 'confirmPassword' ? '0 0 0 1px #1F484C' : 'none',
            borderColor: focusedField === 'confirmPassword' ? '#1F484C' : '#e5e7eb',
          }}
          onFocus={() => setFocusedField('confirmPassword')}
          onBlur={() => setFocusedField(null)}
          required
        />
        <div
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
          style={styles.eyeIcon}
        >
          {showConfirmPassword ? <IoEyeOffOutline /> : <IoEyeOutline />}
        </div>
      </div>
      <StandardButtonContainer>
        <StandardButtonContainer.ButtonTwo 
          type="submit" 
          disabled={isSubmitting}
          style={{
            opacity: isSubmitting ? 0.7 : 1,
            cursor: isSubmitting ? 'not-allowed' : 'pointer'
          }}
        >
          {isSubmitting ? 'CREATING ACCOUNT...' : 'SUBMIT'}
        </StandardButtonContainer.ButtonTwo>
      </StandardButtonContainer>
    </form>
    <p>or login with</p>
    <SignInWithComponent />
  </div>
</div>
);
};

export default CreateAccountComponent;