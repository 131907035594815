import React, { useState, useEffect } from 'react';
import { CiClock1 } from "react-icons/ci";
import { GiSkills } from "react-icons/gi";
import { GrWorkshop } from "react-icons/gr";
import { GiPartyPopper } from "react-icons/gi";
import { motion } from 'framer-motion';
const EventsComponent = () => {
  const [events, setEvents] = useState([
    {
      date: 'Nov',
      year: '4',
      tab: 'Workshops',
      title: 'Pro-fresh launch event',
      time: '4:30pm',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Fevents%2FmentoringPhoto.png?alt=media&token=ed34e0d7-bf50-4087-89f9-727d18dd0a4f',
    },
    {
      date: 'NOV',
      year: '15',
      tab: 'Social event',
      title: 'Website and mobile application launch!',
      time: '10am - 3pm',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Fevents%2Foffthecurriculum_long_logo.png?alt=media&token=f2527f4f-c518-4a95-9686-345203d32fd2',
    },
    {
      date: 'Nov',
      year: '23',
      tab: 'Workshops',
      title: 'Climate change and biodiversity at the Garden Museum',
      time: '11am - 12:30pm',
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Fevents%2FgardenMuseum.png?alt=media&token=6845d188-e32b-4b94-813a-957b3105acf6',
    },
  ]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getTabIcon = (tab) => {
    switch (tab) {
      case 'Workshops':
        return <GrWorkshop style={dynamicStyles.tabIcon} />;
      case 'Skills':
        return <GiSkills style={dynamicStyles.tabIcon} />;
      case 'Social event':
        return <GiPartyPopper style={dynamicStyles.tabIcon} />;
      default:
        return null;
    }
  };
  const getTabColor = (tab) => {
    switch (tab) {
      case 'Workshops':
        return { backgroundColor: '#EEF7F8', color: '#4dabb5' };
      case 'Skills':
        return { backgroundColor: '#FCEFEE', color: '#e56251ff' };
      case 'Social event':
        return { backgroundColor: '#FDF5E8', color: '#f3ad1b' };
      default:
        return {};
    }
  };


  const dynamicStyles = isMobile ? mobileStyles : styles;

  return (
    <div style={dynamicStyles.container}>
      <h2 style={dynamicStyles.heading}>Upcoming Events</h2>
      <div style={dynamicStyles.eventsContainer}>
        {events.map((event, index) => (
          <div key={index} style={dynamicStyles.eventCard}>
            <div style={dynamicStyles.dateContainer}>
              <span style={dynamicStyles.eventDate}>
                {event.date}
              </span>
              <br />
              <span style={dynamicStyles.eventYear}>
                {event.year}
              </span>
            </div>
            <motion.div
            style={{
              overflow: 'hidden',
              ...dynamicStyles.imageContainer // You might need to add this style
            }}
          >
            <motion.img
              src={event.image}
              alt={event.title}
              style={dynamicStyles.eventImage}
              whileHover={{ scale: 1.1 }}
              transition={{ duration: 0.3 }}
            />
            </motion.div>
            <div style={{ ...dynamicStyles.tabInput, ...getTabColor(event.tab) }}>
              {getTabIcon(event.tab)}
              <span style={dynamicStyles.tabText}>{event.tab}</span>
            </div>
            <h3 style={dynamicStyles.eventTitle}>{event.title}</h3>
            <p style={dynamicStyles.eventTime}>
            <CiClock1 />{' '}{event.time}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

const styles = {
  
  container: {
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)',
    marginTop: '15px',
    marginBottom: '15px',
  },
  heading: {
    color: '#333',
    marginBottom: '20px',
  },
  eventsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    cursor: 'pointer'
  },
  eventCard: {
    position: 'relative',
    width: '30%',
    marginBottom: '20px',
  },
  dateContainer: {
    position: 'absolute',
    top: '10px',
    left: '10px',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    color: '#fff',
    padding: '5px 10px',
    borderRadius: '5px',
    textAlign: 'center',
    zIndex: 2,
  },
  eventDate: {
    fontSize: '14px',
    zIndex: 1000,
  },
  eventYear: {
    fontSize: '20px',
    fontWeight: 'bold',
  },
  eventImage: {
    width: '100%',
    height: '200px',
    objectFit: 'cover',
    borderRadius: '15px',
    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px',
  },
  tabInput: {
    position: 'absolute',
    top: '150px',
    left: '10px',
    border: '1px solid #ccc',
    padding: '5px 10px',
    borderRadius: '5px',
    fontSize: '14px',
    display: 'flex',
    alignItems: 'center',
  },
  tabIcon: {
    marginRight: '5px',
  },
  tabText: {
    fontWeight: 'bold',
  },
  eventTitle: {
    color: '#333',
    marginTop: '10px',
  },
  eventTime: {
    color: '#666',
    marginTop: '5px',
    display: 'flex',
    alignItems: 'center',
  },
  clockIcon: {
    marginRight: '5px',
  },

  imageContainer: {
    borderRadius: '15px', 
  }
};

const mobileStyles = {
  ...styles,
  eventCard: {
    ...styles.eventCard,
    width: '100%',
  },
};

export default EventsComponent;
