import React, { useState } from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../../Firebase/firebaseConfig';
import { emailsHasher } from '../../../../../Firebase/educoFunctions';

const VerificationBanner = ({ onVerify, isVerifying, windowWidth, currentUser }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [isSendingCode, setIsSendingCode] = useState(false);
  const [showCodeInput, setShowCodeInput] = useState(false);

  const styles = {
    container: {
      position: 'sticky',
      top: '100px',
      zIndex: 50,
      backgroundColor: '#FFAEAE',
      borderLeft: '4px solid #FF5C5C',
      padding: windowWidth <= 768 ? '12px' : '16px',
      marginBottom: '20px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
      borderRadius: '20px'
    },
    innerContainer: {
      display: 'flex',
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      alignItems: windowWidth <= 768 ? 'stretch' : 'center',
      justifyContent: 'space-between',
      gap: '16px',
    },
    messageContainer: {
      flex: '1',
    },
    message: {
      color: '#1F484C',
      fontSize: windowWidth <= 768 ? '14px' : '16px',
      marginBottom: windowWidth <= 768 ? '8px' : '0',
      fontWeight: '500',
    },
    form: {
      display: 'flex',
      flexDirection: windowWidth <= 768 ? 'column' : 'row',
      gap: '8px',
      width: windowWidth <= 768 ? '100%' : 'auto',
    },
    input: {
      padding: '8px 12px',
      border: '1px solid #E2E8F0',
      borderRadius: '6px',
      fontSize: '14px',
      width: windowWidth <= 768 ? '' : '160px',
      outline: 'none',
      transition: 'border-color 0.2s ease',
    },
    button: {
      padding: '8px 16px',
      backgroundColor: '#1F484C',
      color: '#FFFFFF',
      border: 'none',
      borderRadius: '6px',
      fontSize: '14px',
      fontWeight: '500',
      cursor: 'pointer',
      transition: 'background-color 0.2s ease',
      width: windowWidth <= 768 ? '100%' : 'auto',
    },
    error: {
      color: '#DC2626',
      fontSize: '12px',
      marginTop: '4px',
    }
  };

  const handleSendCode = async () => {
    if (!currentUser?.email || isSendingCode) return;

    setIsSendingCode(true);
    setError('');
    //console.log('running')
    
    try {
      const sendVerification = httpsCallable(functions, 'send_verification_email');
      const hashedEmail = emailsHasher(currentUser.email);

      const result = await sendVerification({
        userId: hashedEmail
      });
      
      // result.data should contain the response from your function
      //console.log('Function response:', result.data);
      
      if (result.data.success) {  // Check for success property
        setShowCodeInput(true);
        alert('Verification code has been sent to your email.');
      } else {
        throw new Error(result.data.error || 'Failed to send verification code');
      }
    } catch (err) {
      setError('Failed to send verification code. Please try again.');
    } finally {
      setIsSendingCode(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (verificationCode.length !== 6) {
      setError('Please enter a valid 6-character code');
      return;
    }
    setError('');
    onVerify(verificationCode);
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <div style={styles.messageContainer}>
          <p style={styles.message}>
            {!showCodeInput 
              ? 'Verify your account to access all features'
              : 'Please enter the verification code sent to your email'
            }
          </p>
        </div>
        {!showCodeInput ? (
          <button
            onClick={handleSendCode}
            disabled={isSendingCode}
            style={{
              ...styles.button,
              backgroundColor: isSendingCode ? '#94A3B8' : '#1F484C',
              cursor: isSendingCode ? 'not-allowed' : 'pointer',
              opacity: isSendingCode ? '0.7' : '1',
            }}
          >
            {isSendingCode ? 'Sending Code...' : 'Send Verification Code'}
          </button>
        ) : (
          <form onSubmit={handleSubmit} style={styles.form}>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value.toUpperCase())}
              placeholder="Enter verification code"
              maxLength={6}
              style={styles.input}
            />
            <button
              type="submit"
              disabled={isVerifying}
              style={{
                ...styles.button,
                backgroundColor: isVerifying ? '#94A3B8' : '#1F484C',
                cursor: isVerifying ? 'not-allowed' : 'pointer',
                opacity: isVerifying ? '0.7' : '1',
              }}
            >
              {isVerifying ? 'Verifying...' : 'Verify'}
            </button>
          </form>
        )}
      </div>
      {error && <p style={styles.error}>{error}</p>}
    </div>
  );
};

export default VerificationBanner;