import React from 'react';
import { CiMail } from "react-icons/ci";

const FirstAidPoster = () => {
  const styles = {
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    header: {
      textAlign: 'center',
      color: '#333',
      fontSize: '24px',
      marginBottom: '20px',
    },
    logoContainer: {
      textAlign: 'center',
      marginBottom: '20px',
    },
    logo: {
      width: '100px',
      height: 'auto',
    },
    firstAidersContainer: {
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
    },
    firstAiderCard: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      marginBottom: '20px',
      width: '45%',
      boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
    },
    image: {
      width: '150px',
      height: '150px',
      borderRadius: '50%',
      objectFit: 'cover',
      margin: '0 auto 15px',
      display: 'block',
    },
    name: {
      textAlign: 'center',
      fontSize: '18px',
      fontWeight: 'bold',
      marginBottom: '10px',
    },
    contactInfo: {
      fontSize: '14px',
      marginBottom: '5px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    footer: {
      textAlign: 'center',
      marginTop: '20px',
      fontSize: '14px',
      color: '#666',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>First Aid Information</h1>
      <div style={styles.firstAidersContainer}>
        <div style={styles.firstAiderCard}>
          <img src="https://www.offthecurriculum.co.uk/static/media/josephLevermore.61068ae8b8a3e03b478b.webp" alt="Joseph Levermore" style={styles.image} />
          <h2 style={styles.name}>Joseph Levermore</h2>
          <p style={styles.contactInfo}>
            <CiMail size={16} style={{marginRight: '5px'}} />
            josephlevermore
          </p>
          <p style={styles.contactInfo}>Phone: +44 75 4006 3973</p>
        </div>
        <div style={styles.firstAiderCard}>
          <img src="https://www.offthecurriculum.co.uk/static/media/chrisSmith.5a986114f72693209a4b.webp" alt="Chris Smith" style={styles.image} />
          <h2 style={styles.name}>Chris Smith</h2>
          <p style={styles.contactInfo}>
            <CiMail size={16} style={{marginRight: '5px'}} />
            chrissmith
          </p>
          <p style={styles.contactInfo}>Phone: +44 79 8080 7211</p>
        </div>
      </div>
      <div style={styles.footer}>
        <p>In case of emergency, please contact the nearest first aider immediately.</p>
        <p>For any questions or concerns, email: firstaid@offthecurriculum.co.uk</p>
      </div>
    </div>
  );
};

export default FirstAidPoster;