import React, { useState } from 'react';

const EDICommitmentComponent = () => {
  const [expandedSection, setExpandedSection] = useState('');

  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  const sectionData = [
    {
      id: 'policy',
      title: 'EDI Policy Overview',
      content: [
        'Applies to colleagues, volunteers, Trustees and participants',
        'Prevents discrimination based on protected characteristics',
        'Commitment to equal opportunities for all',
        'Zero tolerance for any form of discrimination'
      ]
    },
    {
      id: 'training',
      title: 'EDI Training Initiatives',
      content: [
        'Mandatory EDI Induction Training for new staff',
        'Annual EDI Refresher Course for all staff',
        'Role-specific training for recruitment team and line managers',
        'Ongoing learning opportunities through various platforms'
      ]
    },
    {
      id: 'recruitment',
      title: 'Ensuring Equality in Recruitment',
      content: [
        'Inclusive job descriptions',
        'Anonymous application screening',
        'Diverse interview panels',
        'Standardised, skills-based interviews',
        'Outreach to underrepresented groups'
      ]
    },
    {
      id: 'monitoring',
      title: 'Monitoring Organisational Equality',
      content: [
        'Annual anonymous diversity census',
        'Regular analysis of compensation practices',
        'Monitoring of volunteer progression',
        'Evaluation of recruitment diversity',
        'Annual EDI report and action plans'
      ]
    },
    {
      id: 'awareness',
      title: 'Policy Implementation and Awareness',
      content: [
        'Formal introduction during onboarding',
        'Mandatory EDI training for new members',
        'Ongoing education through newsletters',
        'Easy access to EDI policy on volunteer officer resource portal'
      ]
    }
  ];

  return (
    <div className="edi-container">
      <div className="edi-card">
        <h1 className="edi-title">Off the Curriculum: Equality, Diversity and Inclusion Commitment</h1>
        <p className="edi-description">Our dedication to creating an inclusive environment for all</p>
        <p className="edi-content">
          Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. 
          We want everyone to feel like they belong. We value each person as an individual. 
          We will treat everyone with dignity and respect. And we want to recognise all parts of a person's identity.
        </p>
      </div>

      <div className="edi-accordion">
        {sectionData.map((section) => (
          <div key={section.id} className="edi-accordion-item">
            <button 
              onClick={() => toggleSection(section.id)}
              className={`edi-accordion-trigger ${expandedSection === section.id ? 'active' : ''}`}
            >
              {section.title}
              <span className="edi-accordion-icon"></span>
            </button>
            <div className={`edi-accordion-content ${expandedSection === section.id ? 'active' : ''}`}>
              <ul>
                {section.content.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>

      <style jsx>{`
        .edi-container {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
          font-family: Arial, sans-serif;
        }
        .edi-card {
          background-color: white;
          padding: 20px;
          margin-bottom: 20px;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        }
        .edi-title {
          font-size: 24px;
          margin-bottom: 10px;
          color: #333;
        }
        .edi-description {
          font-size: 16px;
          color: #666;
          margin-bottom: 15px;
        }
        .edi-content {
          font-size: 14px;
          line-height: 1.5;
          color: #333;
        }
        .edi-accordion {
          background-color: white;
          border-radius: 8px;
          box-shadow: 0 2px 4px rgba(0,0,0,0.1);
          overflow: hidden;
        }
        .edi-accordion-item {
          border-bottom: 1px solid #e0e0e0;
        }
        .edi-accordion-item:last-child {
          border-bottom: none;
        }
        .edi-accordion-trigger {
            width: 100%;
            padding: 15px 20px;
            background-color: #1F484C; /* This sets the background color to a dark teal (almost black) */
            color: white;
            border: 2px solid transparent;
            text-align: left;
            font-size: 16px;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: all 0.3s ease;
            }
        .edi-accordion-trigger:hover {
          opacity: 0.9;
        }
        .edi-accordion-trigger.active {
          background-color: transparent;
          color: #1F484C;
          border-color: #1F484C;
        }
        .edi-accordion-icon {
          width: 10px;
          height: 10px;
          border-right: 2px solid white;
          border-bottom: 2px solid white;
          transform: rotate(45deg);
          transition: transform 0.3s ease, border-color 0.3s ease;
        }
        .edi-accordion-trigger.active .edi-accordion-icon {
          transform: rotate(-135deg);
          border-color: #1F484C;
        }
        .edi-accordion-content {
          max-height: 0;
          overflow: hidden;
          transition: max-height 0.3s ease;
        }
        .edi-accordion-content.active {
          max-height: 1000px;
        }
        .edi-accordion-content ul {
          list-style-type: disc;
          padding: 0 20px 15px 40px;
        }
        .edi-accordion-content li {
          margin-bottom: 5px;
        }
      `}</style>
    </div>
  );
};

export default EDICommitmentComponent;