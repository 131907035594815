

const Articles = [    
  {
    uid: 1,
    title: "Advice for young people: Unleashing the Power of Self-Gratitude",
    imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Funleashing_the_power_of_self_gratitude%20.webp?alt=media&token=58e48af3-66eb-4ffa-aa3f-dca606d11e3e`,
    imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
    date: `17/05/2023`, 
    author: `Dr. Joseph Levermore`,
    time: `10 minutes read`, 
    articleDesc: `
    It's time to turn up the self-appreciation dial and celebrate the hard work you've put into achieving your goals. Self-gratitude is like giving yourself a high-five, a pat on the back... 
        `,
    articlepagehref: `powerofselfgratitude`,
    image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea',
  }, 
  {
      uid: 2,
      title: "Advice for young people: A guided meditation session before entering an exam",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fguided_meditation.webp?alt=media&token=b2cab257-74ca-4bab-98bd-25cc26ea0271`,
      imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
      date: `16/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Welcome, fearless exam warrior! Before you step into the battleground of knowledge and conquer that exam, let's take a moment to find your inner calm... 
          `,
      articlepagehref: `guidedmeditation`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2FguidedMeditationSessionbeforeExam.webp?alt=media&token=d34fa26b-7dfa-41ed-aa3d-7cf45ccd73ea',
    }, 
    {
      uid: 3,
      title: "Advice for young people: Breath work and meditation for managing stressful times",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fbreath_work_and_meditation_managing_stressful_times.webp?alt=media&token=3b883d95-6d48-4ef4-a914-21e9237fd03c`,
      imageSrcAlt: 'Advice for young people: Breath work and meditation for managing stressful times',
      date: `15/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Exams can be super stressful, right? Your palms get sweaty, your heart starts racing, and your mind goes into overdrive...
          `,
      articlepagehref: `adviceforyoungpeoplebreathworkandmeditation`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    }, 
    {
      uid: 4,
      title: "Advice for young people: Essay writing - tips and tricks",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fessay_writing_tips_and_tricks.webp?alt=media&token=1f26469d-f320-407b-b7df-f818b68d1c65`,
      imageSrcAlt: 'Advice for young people: Essay writing - tips and tricks',
      date: `12/05/2023`, 
      author: `Dr. Joseph Levermore`,
      time: `10 minutes read`, 
      articleDesc: `
      Writing a great A-level essay answer can be a challenging task, but with the right techniques and strategies, it is possible to produce...
          `,
      articlepagehref: `writeagreatalevelessay`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    }, 
    {
        uid: 5,
        title: "Advice for young people: Designing a two-hour study session",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fdesign_your_two_hour_study_session.webp?alt=media&token=9907da13-cab5-4ef9-aefa-2b2e3071828a`,
        imageSrcAlt: 'Advice for young people: Designing a two-hour study period',
        date: `11/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Revising for an exam can be a daunting task, especially if you don't have a clear plan or schedule in place. In this article...
            `,
        articlepagehref: `studyingforatwohourperiod`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
        uid: 6,
        title: "Advice for young people: Best revision techniques",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fbest_revision_techniques.webp?alt=media&token=d8bf63ef-e282-46ef-b139-543dfc7d0af0`,
        imageSrcAlt: 'Advice for young people: How to best prepare for exams Image',
        date: `10/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Revising and memorising difficult content can be a challenging task for students, particularly when faced with a large amount of information to learn...
            `,
        articlepagehref: `adviceforyoungpeoplebestrevisiontechniques`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
        uid: 7,
        title: "Advice for parents: Preparing for an exam",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fpreparing_for_an_exam.webp?alt=media&token=1c24abb1-118f-4e6b-a899-6c103dc805b7`,
        imageSrcAlt: 'Advice for parents: How to support your teen in preparation for their examinations.',
        date: `09/05/2023`, 
        author: `Dr. Joseph Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        As a parent, you want the best for your adolescent child. This includes helping them prepare for their exams. Exams can be a stressful time for both the child and the parent..
            `,
        articlepagehref: `howtobestprepareforexams`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      }, 
    {
      uid: 8,
      title: "Young. Black. And Neurodiverse.",
      imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fyoung_black_neurodiversity.webp?alt=media&token=0f386307-ca11-4788-a46b-5b4b714229fa`,
      imageSrcAlt: 'Young. Black. And Neurodiverse.',
      date: `06/02/2023`, 
      author: `Nicole Curtis`,
      time: `10 minutes read`, 
      articleDesc: `
      Joshua-Tobiloba Olowe, a student attending Off the Curriculum's seminar series wrote a spoke-word about being 'Young Black And Neurodiverse'.
          `,
      articlepagehref: `youngblackandneurodiverse`,
      image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
    },
    {
        uid: 9,
        title: "Off the Curriculum Welcomes Our Newly Recruited Trustee Boards.",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Four_trustee_board.webp?alt=media&token=9ac080fd-9e10-4a64-82ac-31524b25090c`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Curtis`,
        time: `10 minutes read`, 
        articleDesc: `
        We at Off the Curriculum are proud to Introduce our incredible board of trustees.
            `,
        articlepagehref: `trusteeatotc`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 10,
        title: "Teach, Watch and Learn: We are recruiting Educo volunteers!",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Feduco_volunteers_needed.webp?alt=media&token=0224de1d-ffc9-4729-8ba4-089671432a4a`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Curtis`,
        time: `10 minutes read`, 
        articleDesc: `
        We at Off the Curriculum are recruiting volunteers for our Educo platform, find out more here.
            `,
        articlepagehref: `educovolunteers`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 11,
        title: "Learning to learn at home: tips and tricks",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Flearning_to_learn_at_home.webp?alt=media&token=14e5d026-09cf-4f32-8bcf-06fee6c69483`,
        imageSrcAlt: 'Learning to learn at home: tips and tricks image',
        date: `12/06/2021`, 
        author: `Nicole Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        The pandemic has shifted learning to the home, here's how make the most out of it?
            `,
        articlepagehref: `learningtolearn`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
      {
        uid: 12,
        title: "Mindfulness",
        imageSrc: `https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fnews%2Fbreathe.webp?alt=media&token=6422f970-f392-4833-8314-f997e46f62bb`,
        imageSrcAlt: 'Teach, Watch, and Learn Article header',
        date: `12/06/2021`, 
        author: `Nicole Levermore`,
        time: `10 minutes read`, 
        articleDesc: `
        Practicing mindfulness has been associated with multiple health benefits, why not find out how it can help you?
            `,
        articlepagehref: `mindfulness`,
        image: 'https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/educo%2Fastronomy%2FAstronomy%2Ftelescope.webp?alt=media&token=bade55c8-bd3a-445f-aa05-007236f32cd3',
      },
]

export {
    Articles
}