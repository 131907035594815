import React, { useState, useEffect, useRef } from 'react';
import Message from './MessageComponent';
import { FaBrain, FaSearch, FaMicrophone, FaImage, FaSmile, FaLanguage, FaThumbsUp } from 'react-icons/fa';
import { motion, AnimatePresence, color } from 'framer-motion';
import { functions } from '../../../../../Firebase/firebaseConfig';
import { emailsHasher } from '../../../../../Firebase/educoFunctions';
import { httpsCallable } from 'firebase/functions';
import useAuth from '../../../../../Firebase/Auth';
import StandardButtonContainer from '../../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
const ChatWithMentor = ({userType, userData}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  const [mentors, setMentors] = useState('');
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const currentUser = useAuth(); 
  const messagePollingInterval = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Determine if we should show the floating chat button or the side tab
  const showFloatingButton = windowWidth > 1200;

  //console.log('userType, userData', userType, userData)

  useEffect(() => {
    const getUserChats = async () => {
      if (currentUser) {
        try {
          setIsLoading(true);
          const emailHash = emailsHasher(currentUser.email);
          const menteeId = userData.menteeId;
          const mentorId = userData.mentorId;
          const mentorName = userData.mentor;
          const menteeName = userData.name;
          const getUserChats = httpsCallable(functions, 'create_mentor_chats');
          const result = await getUserChats({
            userId: emailHash,
            menteeId: menteeId, 
            mentorId: mentorId,
            mentorName: mentorName,
            menteeName: menteeName,
            userType: userType, 
          });
          //console.log('result here', result)
          if (result.data.success) {
            setMentors(Array.isArray(result.data.userChats) ? result.data.userChats : [result.data.userChats])
            setChats(result.data.userChats);
          } else {
            setError(result.data.error || 'Failed to fetch chats');
          }
        } catch (error) {
          //console.error("Error fetching user chats:", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };

    getUserChats();
  }, [currentUser]);

  useEffect(() => {
    const fetchMessages = async () => {
      if (selectedMentor) {
        //console.log('select', selectedMentor)
        try {
          setIsLoading(true);
          const getChatMessages = httpsCallable(functions, 'get_chat_messages');
          const result = await getChatMessages({ chatId: selectedMentor.id });
          //console.log('messages chat', result)
          if (result.data.success) {
            const fetchedMessages = result?.data?.messages || [];
            setMessages(fetchedMessages);
          } else {
            setError(result.data.error || 'Failed to fetch messages');
          }
        } catch (error) {
          //console.error("Error fetching messages:", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      }
    };
  
    fetchMessages();
  }, [selectedMentor]);

  const fetchLatestMessages = async () => {
    if (selectedMentor) {
      try {
        const getChatMessages = httpsCallable(functions, 'get_chat_messages');
        const result = await getChatMessages({ 
          chatId: selectedMentor.id,
          lastMessageTimestamp: messages.length > 0 
            ? messages[messages.length - 1].timestamp 
            : null 
        });

        if (result.data.success) {
          const fetchedMessages = result?.data?.messages || [];
          // Only update if we have new messages
          if (fetchedMessages.length > messages.length) {
            setMessages(fetchedMessages);
          }
        }
      } catch (error) {
        //console.error("Error fetching latest messages:", error);
      }
    }
  };
  useEffect(() => {
    if (selectedMentor) {
      // Initial messages fetch
      const fetchInitialMessages = async () => {
        try {
          setIsLoading(true);
          const getChatMessages = httpsCallable(functions, 'get_chat_messages');
          const result = await getChatMessages({ chatId: selectedMentor.id });
          
          if (result.data.success) {
            const fetchedMessages = result?.data?.messages || [];
            setMessages(fetchedMessages);
          }
        } catch (error) {
          //console.error("Error fetching initial messages:", error);
          setError(error.message);
        } finally {
          setIsLoading(false);
        }
      };

      fetchInitialMessages();

      // Start polling for new messages
      messagePollingInterval.current = setInterval(fetchLatestMessages, 3000); // Poll every 3 seconds

      // Cleanup function
      return () => {
        if (messagePollingInterval.current) {
          clearInterval(messagePollingInterval.current);
        }
      };
    }
  }, [selectedMentor]);

  const handleSend = async (e) => {
    e.preventDefault();
    
    if (isSending || !message.trim() || !selectedMentor) return;
    
    setIsSending(true);
    
    try {
      const sendMessage = httpsCallable(functions, 'send_chat_message');
      const result = await sendMessage({
        chatId: selectedMentor.id,
        content: message,
        senderId: currentUser.uid,
        timestamp: new Date().toISOString()
      });
      
      if (result.data.success) {
        setMessage('');
        // Immediately add the new message to the state
        setMessages(prevMessages => [...prevMessages, result.data.message]);
        // Trigger an immediate fetch for any other new messages
        fetchLatestMessages();
      }
    } catch (error) {
      //console.error('Error sending message:', error);
    } finally {
      setIsSending(false);
    }
  };
  const formatMessageTime = (timestamp) => {
    if (!timestamp) return '';
    
    const date = new Date(timestamp);
    
    // Format date
    const formattedDate = date.toLocaleDateString('en-GB', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit'
    }).split('/').reverse().join('-');
  
    // Format time
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    
    return `${formattedDate} | ${hours}:${minutes}`;
  };


  const MessagesList = ({ onEdit, onDelete, onReact, isLoading, error, messages = [], currentUserId }) => {
    if (isLoading) return <div>Loading messages...</div>;
    if (error) return <div>Error: {error}</div>;
    
    if (messages.length === 0) {
      return (
        <div style={styles.emptyState}>
          <p style={styles.emptyStateText}>
            No messages yet, send a message to chat with your mentor or mentee
          </p>
        </div>
      );
    }
  
    return (
      <div style={styles.messagesContainer}>
        {messages.map((message) => (
          <Message
            key={message.id}
            message={message}
            isUser={message.senderId === currentUserId}
            onEdit={onEdit}
            onDelete={onDelete}
            onReact={onReact}
          />
        ))}
      </div>
    );
  };

  const handleEditMessage = (messageId, newText) => {
    setMessages(messages.map(msg => 
      msg.id === messageId 
        ? { ...msg, text: newText, edited: true } 
        : msg
    ));
  };
  
  const handleDeleteMessage = (messageId) => {
    setMessages(messages.filter(msg => msg.id !== messageId));
  };
  
  const handleReactToMessage = (messageId, reaction, action = 'add') => {
    setMessages(messages.map(msg => {
      if (msg.id === messageId) {
        let updatedReactions = [...(msg.reactions || [])];
        
        if (action === 'remove') {
          updatedReactions = updatedReactions.filter(r => r !== reaction);
        } else if (!updatedReactions.includes(reaction)) {
          updatedReactions.push(reaction);
        }
        
        return {
          ...msg,
          reactions: updatedReactions
        };
      }
      return msg;
    }));
  };

  return (
      <>
        {/* Side Tab for Mobile */}
        {windowWidth <= 1200 && !isOpen && (
          <div
            style={{
              position: 'fixed',
              right: '0px',
              top: '50%',
              transform: 'translateY(-50%) rotate(-90deg)',
              transformOrigin: 'right bottom',
              backgroundColor: '#1F484C',
              color: 'white',
              padding: '15px 30px',
              borderRadius: '10px 10px 0 0',
              cursor: 'pointer',
              boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
              display: 'flex',
              alignItems: 'center',
              gap: '10px',
              zIndex: 1200,
              transition: 'all 0.3s ease',
            }}
            onClick={() => setIsOpen(true)}
            onMouseDown={(e) => e.currentTarget.style.backgroundColor = '#163437'}
            onMouseUp={(e) => e.currentTarget.style.backgroundColor = '#1F484C'}
            onMouseLeave={(e) => e.currentTarget.style.backgroundColor = '#1F484C'}
          >
            <FaBrain style={{ transform: 'rotate(90deg)', marginRight: '8px', fontSize: '20px', color: '#ff9999' }} />
            <span style={{ 
              fontWeight: '500',
              fontSize: '0.9rem',
              letterSpacing: '0.5px',
              whiteSpace: 'nowrap',
            }}>
              {userType === 'mentor' ? 'Chat with Mentee' : 'Chat with Mentor'}
            </span>
            <span style={{ transform: 'rotate(90deg)', marginLeft: '8px' }}>→</span>
          </div>
        )}
    
        {/* Desktop Chat Button and Chat Window */}
        <div style={styles.wrapper}>
          <AnimatePresence>
            {!isOpen ? (
              windowWidth > 1200 && (
                <motion.div
                  key="chat-button"
                  style={styles.container}
                  onClick={() => setIsOpen(true)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  initial={{ x: 0 }}
                  animate={{ x: 0 }}
                  exit={{ x: 100 }}
                >
                  <FaBrain style={styles.icon} />
                  <span style={styles.text}>
                    {userType === 'mentor' ? 'Chat with Mentee' : 'Chat with Mentor'}
                  </span>
                </motion.div>
              )
            ) : (
              <motion.div
                key="chat-window"
                style={{
                  ...styles.chatContainer,
                  ...(windowWidth <= 1200 ? {
                    position: 'fixed',
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '100vh',
                    borderRadius: 0,
                    maxWidth: 'none',
                  } : {})
                }}
                initial={windowWidth <= 1200 ? 
                  { opacity: 0, y: '100%' } : 
                  { opacity: 0, x: 400 }
                }
                animate={{ opacity: 1, x: 0, y: 0 }}
                exit={windowWidth <= 1200 ? 
                  { opacity: 0, y: '100%' } : 
                  { opacity: 0, x: 400 }
                }
                transition={{ type: "spring", stiffness: 300, damping: 30 }}
              >
                {!selectedMentor ? (
                  // Mentor List View
                  <div style={styles.card}>
                    <div style={styles.header}>
                      <div style={styles.headerTop}>
                        <h2 style={styles.title}>Chats</h2>
                        <div style={styles.controls}>
                          <button style={styles.controlButton}>
                            <span style={styles.controlText}>⋯</span>
                          </button>
                          <button style={styles.controlButton}>
                            <span style={styles.controlText}>⤢</span>
                          </button>
                          <button 
                            style={styles.controlButton}
                            onClick={() => setIsOpen(false)}
                          >
                            <span style={styles.controlText}>✕</span>
                          </button>
                        </div>
                      </div>
                      
                      <div style={styles.searchContainer}>
                        <FaSearch style={styles.searchIcon} />
                        <input
                          type="text"
                          placeholder="Search Mentors"
                          style={styles.searchInput}
                          value={searchQuery}
                          onChange={(e) => setSearchQuery(e.target.value)}
                        />
                      </div>
                      
                      <div style={styles.filterContainer}>
                        <button style={styles.inboxButton}>
                          Inbox
                        </button>
                      </div>
                    </div>
                    <div style={styles.mentorList}>
                      {Array.isArray(mentors) && mentors.length > 0 ? (
                        mentors.map((mentor) => (
                          <motion.div
                            key={mentor.id}
                            whileHover={{ backgroundColor: "#f5f5f5" }}
                            style={styles.mentorItem}
                            onClick={() => setSelectedMentor(mentor)}
                          >
                            <div style={styles.avatarContainer}>
                              <div style={styles.avatar}>
                                <FaBrain style={styles.avatarIcon} />
                              </div>
                              <div style={styles.statusDot}></div>
                            </div>
                            <div style={styles.mentorInfo}>
                              <div style={styles.mentorHeader}>
                                <span style={styles.mentorName}>
                                  {mentor.name}
                                </span>
                                <span style={styles.timeago}>
                                  {formatMessageTime(mentor.lastMessageTimestamp)}
                                </span>
                              </div>
                              <div style={styles.lastMessage}>
                                {mentor.lastMessage}
                              </div>
                            </div>
                          </motion.div>
                        ))
                      ) : (
                        <div>
                          {userType === 'mentor' ? 'No mentee chat available' : 'No mentor chat available'}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  // Chat Page View
                  <div style={styles.card}>
                    <div style={styles.chatHeader}>
                      <div style={styles.chatHeaderContent}>
                        <div style={styles.headerLeft}>
                          <button 
                            style={styles.backButton}
                            onClick={() => setSelectedMentor(null)}
                          >
                            ←
                          </button>
                          <div style={styles.avatarAndInfo}>
                            <div style={styles.avatar}>
                              <FaBrain style={styles.avatarIcon} />
                            </div>
                            <div style={styles.mentorDetails}>
                              <h3 style={styles.chatMentorName}>{selectedMentor.name}</h3>
                            </div>
                          </div>
                        </div>
                        <div style={styles.headerControls}>
                          <button style={styles.iconButton}>-</button>
                          <button 
                            style={styles.iconButton} 
                            onClick={() => {
                              setSelectedMentor(null);
                              setIsOpen(false);
                            }}
                          >
                            ×
                          </button>
                        </div>
                      </div>
                      <div style={styles.statusLine}>
                        <span style={styles.activeStatus}>Active now</span>
                      </div>
                    </div>
                    <div style={styles.messageContainer}>
                      <MessagesList
                        messages={messages}
                        currentUserId={currentUser?.uid}
                        isLoading={isLoading}
                        error={error}
                        onEdit={handleEditMessage}
                        onDelete={handleDeleteMessage}
                        onReact={handleReactToMessage}
                      />
                    </div>
                    <form style={styles.inputArea}>                  
                      <textarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        placeholder="Aa"
                        style={styles.chatInput}
                      />
                      <div style={styles.inputControls}>
                        <StandardButtonContainer.ButtonTwo 
                          onClick={handleSend}
                          disabled={isSending}
                          style={isSending ? { opacity: 0.7, cursor: 'not-allowed' } : {}}
                        >
                          {isSending ? 'Sending...' : 'Send'}
                        </StandardButtonContainer.ButtonTwo>
                      </div>
                    </form>
                  </div>
                )}
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </>
  );
};

const styles = {
  wrapper: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    zIndex: 999,
  },
  container: {
    position: 'fixed',
    bottom: '20px',
    right: '0',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#1F484C',
    color: '#fff',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    padding: '10px 20px 10px 15px',
    boxShadow: '-2px 2px 10px rgba(0,0,0,0.1)',
    cursor: 'pointer',
    transition: 'all 0.3s ease',
    zIndex: 999,
  },
  icon: {
    fontSize: '24px',
    color: '#ff9999',
    marginRight: '10px',
  },
  text: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#fff',
  },
  chatContainer: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    width: '384px',
    backgroundColor: '#fff',
    borderRadius: '0.5rem',
    boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
    overflow: 'hidden',
    zIndex: 999,
  },
  card: {
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    padding: '1rem',
    borderBottom: '1px solid #e5e7eb',
  },
  headerTop: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '1rem',
  },
  title: {
    fontSize: '1.25rem',
    fontWeight: 'bold',
    margin: 0,
  },
  controls: {
    display: 'flex',
    gap: '0.5rem',
  },
  controlButton: {
    padding: '0.5rem',
    backgroundColor: 'transparent',
    border: 'none',
    borderRadius: '9999px',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  controlText: {
    fontSize: '1.25rem',
  },
  searchContainer: {
    position: 'relative',
  },
  searchIcon: {
    position: 'absolute',
    left: '0.75rem',
    top: '50%',
    transform: 'translateY(-50%)',
    color: '#9ca3af',
  },
  searchInput: {
    width: '100%',
    paddingLeft: '2.5rem',
    paddingRight: '1rem',
    paddingTop: '0.5rem',
    paddingBottom: '0.5rem',
    backgroundColor: '#f3f4f6',
    border: 'none',
    borderRadius: '9999px',
    outline: 'none',
  },
  filterContainer: {
    marginTop: '0.5rem',
  },
  inboxButton: {
    padding: '0.25rem 1rem',
    backgroundColor: '#e6f7ff',
    color: '#1890ff',
    border: 'none',
    borderRadius: '9999px',
    fontSize: '0.875rem',
    fontWeight: '500',
    cursor: 'pointer',
  },
  mentorList: {
    overflowY: 'auto',
    flex: 1,
  },
  mentorItem: {
    display: 'flex',
    alignItems: 'center',
    padding: '0.75rem',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  avatarContainer: {
    position: 'relative',
  },
  avatar: {
    width: '48px',
    height: '48px',
    backgroundColor: '#f3f4f6',
    borderRadius: '9999px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  avatarIcon: {
    fontSize: '1.5rem',
    color: '#6b7280',
  },
  statusDot: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '12px',
    height: '12px',
    backgroundColor: '#10b981',
    borderRadius: '9999px',
    border: '2px solid #fff',
  },
  mentorInfo: {
    marginLeft: '0.75rem',
    flex: 1,
  },
  mentorHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  mentorName: {
    fontWeight: '500',
  },
  timeago: {
    fontSize: '0.875rem',
    color: '#6b7280',
  },
  lastMessage: {
    fontSize: '0.875rem',
    color: '#6b7280',
  },

  // chat page 
  chatHeader: {
    padding: '12px 16px',
    borderBottom: '1px solid #e4e6eb',
    backgroundColor: 'white',
  },
  chatHeaderContent: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '4px',
  },
  headerLeft: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  avatarAndInfo: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  backButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '8px',
    color: '#65676B',
  },
  chatMentorName: {
    margin: 0,
    fontSize: '16px',
    fontWeight: '600',
  },
  statusLine: {
    paddingLeft: '44px', // Aligns with the content after back button
  },
  activeStatus: {
    fontSize: '13px',
    color: '#65676B',
  },
  headerControls: {
    display: 'flex',
    gap: '4px',
  },
  iconButton: {
    background: 'none',
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '8px',
    color: '#65676B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  
  mentorDetails: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: '12px',
  },
  subtitle: {
    fontSize: '12px',
    color: '#65676B',
  },
  messageContainer: {
    flex: 1,
    overflowY: 'auto',
    padding: '16px',
    backgroundColor: 'white',
  },
  message: {
    display: 'flex',
    marginBottom: '12px',
  },
  messageText: {
    padding: '12px 16px',
    backgroundColor: '#e4e6eb',
    borderRadius: '18px',
    fontSize: '14px',
    maxWidth: '80%',
  },
  inputArea: {
    padding: '12px 16px',
    borderTop: '1px solid #e4e6eb',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  chatInput: {
    flex: 1,
    padding: '8px 12px',
    borderRadius: '20px',
    border: '1px solid #1F484C',
    fontSize: '14px',
    outline: 'none',
    resize: 'none', // Prevent manual resizing
    minHeight: '20px',
    maxHeight: '150px',
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
    overflowY: 'auto',
    overflowX: 'hidden',
    // Flex properties to vertically center the text
    display: 'flex',
    alignItems: 'center',
    lineHeight: '20px', // Match the line height to the font size for vertical alignment
  },
  inputControls: {
    display: 'flex',
    gap: '8px',
  },
  inputButton: {
    background: 'none',
    border: 'none',
    padding: '8px',
    cursor: 'pointer',
    color: '#1F484C',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  emptyState: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    padding: '20px',
  },
  emptyStateText: {
    color: '#666',
    fontSize: '16px',
    textAlign: 'center',
    fontStyle: 'italic',
  }
};

export default ChatWithMentor;