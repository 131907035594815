{/* Requiring call from database - Likes and Views Components */}

const styles = {
	LikesViews: {
    display: 'flex',
    alignItems: 'center',
		float: 'left',
	}, 
	LikesViewsSpan: {
		display: 'inline', 
		marginRight: '100px', 
	}
}

function Views({views, icon: Icon}) {
    return (
      <div style={styles.LikesViews}>
      {views && (
        <>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{color: '#000'}} size={15} />
              </span>
              <span>: {views}</span>
            </div>
          </div>
        </>
      )}
    </div>
    );
  }

  function Likes({likes, icon: Icon}) {
    return (
      <div style={styles.LikesViews}>
      {likes && (
        <>
          <div>
            <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
              <span style={{ display: 'flex', alignItems: 'center' }}>
                <Icon style={{color: '#000'}} size={15} />
              </span>
              <span>: {likes}</span>
            </div>
          </div>
        </>
      )}
    </div>
    );
  }

  {/* Loading Components */}
  function LoadingViews() {
    return (
      <div style={styles.LikesViews}>
        {
          <>
            <div>
              <span>
								Views:
							</span>
            </div>
          </>
        }
      </div>
    );
  }
  function LoadingLikes() {
    return (
      <div style={styles.LikesViews}>
        {
          <>
            <div>
              <span>
								Likes:
							</span>
            </div>
          </>
        }
      </div>
    );
  }

  export {LoadingLikes, LoadingViews, Likes, Views}