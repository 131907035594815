import React from "react";
import { useState } from "react";
import { styles } from "../PortalHomepage/AdminHomePage";
import RecentUpdates from "../RecentUpdates/RecentUpdates";
import { CharityUpdates } from "../CharityUpdates/CharityUpdates";
import MenteeWelcomeProfresh from "./Components/WelcomeSection/MenteeWelcomeSection";
import EPQMenteeResourceLibrary from "./Components/ResourceLibrary/EPQMenteeResourceLibrary";
import { MenteeIncidentForm } from "./Components/IncidentForm/MenteeIncidentForm";

export const EPQHomePage = ({epqData}) => {
  const [selectedUpdate, setSelectedUpdate] = useState(null);
  const [mentorName, setMentorName] = useState(epqData?.MentorName)
  const [projectTitle, setprojectTitle] = useState(epqData?.topicChosen)
  const [startDate, setstartDate] = useState(epqData?.startDate)
  const [endDate, setendDate] = useState(epqData?.endDate)

  const orienteeringUpdates = [
    { title: 'OTC Welcome pack', status: 'Welcome to Off the Curriculum!', image: '🧭' },
    { title: 'Resource Library', status: 'A compilation of useful links for your EPQ project', image: '🗺️' },
    { title: 'Incident Form', status: 'If you are involved in or witness a incident, please report so immediately via the attached.', image: '🗺️' },
  ];

  const CharityUpdatesList = [
    {
      title: 'Welcome pack',
      assignedBy: 'Orientation Team',
      dueDate: 'Next Monday',
      ongoingProjects: 'New member onboarding',
      urgencyLevel: 'High',
    },
    {
      title: 'Resource Library',
      assignedBy: 'Marketing Team',
      dueDate: 'End of week',
      ongoingProjects: 'Website update',
      urgencyLevel: 'Medium',
    },
    {
      title: 'Incident Form',
      assignedBy: 'Marketing Team',
      dueDate: 'End of week',
      ongoingProjects: 'Website update',
      urgencyLevel: 'Medium',
    },
  ];

  const handleUpdateClick = (index) => {
    setSelectedUpdate(CharityUpdatesList[index]);
  };


  return (
    <div style={styles.columns}>
      <div style={styles.column}>
        <RecentUpdates
          title="EPQ Resource Center"
          updates={orienteeringUpdates}
          onUpdateClick={handleUpdateClick}
        />
      </div>
      <div style={styles.column}>
        {selectedUpdate && selectedUpdate.title === 'Welcome pack' ? (
          <MenteeWelcomeProfresh
          mentorName={mentorName}  
          projectTitle={projectTitle}
          startDate={startDate}
          endDate={endDate}
          
          />
        ) :
        selectedUpdate && selectedUpdate.title === 'Resource Library' ? (
          <EPQMenteeResourceLibrary />
        ) :
        selectedUpdate && selectedUpdate.title === 'Incident Form' ? (
          <MenteeIncidentForm />
        ) :
         (
          <CharityUpdates
            updates={selectedUpdate || CharityUpdatesList[0]}
          />
        )}
      </div>
    </div>
  );
};