import React, { useRef, useState, useEffect } from 'react';
import { motion, useScroll, useTransform, useInView } from 'framer-motion';
import BlackButton from '../../../Components/Common/Buttons/BlackButton/ButtonButton';
import StandardButtonContainer from '../../../Components/Common/Buttons/StandardButtons/StandardButtons';

const ActionComponent = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const ref = useRef(null);
  const isInView = useInView(ref, { once: false, amount: 0.3 });
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start end", "end start"]
  });

  const opacity = useTransform(scrollYProgress, [0, 0.3], [0.4, 1]);
  const scale = useTransform(scrollYProgress, [0.3, 0.6], [0.95, 1]);
  const handleButtonClick = () => {
    window.open('http://eepurl.com/hYXulj', '_blank');
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '20px',
      flexWrap: 'wrap',
      borderRadius: '15px',
      flexDirection: windowWidth <= 768 ? 'column' : 'row'
    },
    columnLeft: {
      flex: windowWidth <= 768 ? '1 1 100%' : '1 1 50%',
      display: 'flex',
      justifyContent: 'center',
      marginBottom: windowWidth <= 768 ? '30px' : '0',
      order: windowWidth <= 768 ? 1 : 'unset'
    },
    columnRight: {
      flex: windowWidth <= 768 ? '1 1 100%' : '1 1 50%',
      marginBottom: '20px',
      order: windowWidth <= 768 ? 2 : 'unset',
      textAlign: windowWidth <= 768 ? 'center' : 'left'
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      maxWidth: windowWidth <= 768 ? '300px' : '400px',
      width: '100%'
    },
    logo: {
      width: '100%',
      height: 'auto'
    },
    charityNumber: {
      marginTop: '10px',
      fontSize: '14px',
      fontWeight: 'bold',
    },
    text: {
      fontSize: '18px',
      lineHeight: '1.5',
      marginBottom: '20px',
    },
    button: {
      backgroundColor: '#333',
      color: '#fff',
      border: 'none',
      padding: '10px 20px',
      borderRadius: '5px',
      cursor: 'pointer',
      fontWeight: 'bold',
    },
  
    '@media (max-width: 768px)': {
      columnLeft: {
        flex: '1 1 100%',
      },
      columnRight: {
        flex: '1 1 100%',
      },
    },
  };

  return (
    <motion.div
      ref={ref}
      style={{
        ...styles.container,
        opacity: isInView ? opacity : 0.4,
        scale: isInView ? scale : 0.95,
      }}
    >
      <div style={styles.columnLeft}>
        <div style={styles.logoContainer}>
          <motion.div
            whileHover={{ scale: 1.05 }}
            transition={{ type: "spring", stiffness: 300 }}
            style={{ width: '100%', display: 'flex', justifyContent: 'center' }} // Add this
          >
            <motion.img
              src="https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/Branding%2FLogos%2Fcolour%2FotcLogoLarge.png?alt=media&token=7cdce30c-54fe-45fa-ad95-c24198a4e2b7"
              alt="Off The Curriculum"
              style={styles.logo}
              whileHover={{}}
            />
          </motion.div>
          <p style={styles.charityNumber}>Charity Number: 1196701</p>
        </div>
      </div>
      <div style={styles.columnRight}>
        <p style={styles.text}>
          A UK charity with a global perspective, Off the Curriculum provides educational services with a common goal - inspiring the next generation to succeed.
        </p>
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonTwo
            onClick={handleButtonClick}
          >
            Stay in touch
          </StandardButtonContainer.ButtonTwo>
        </StandardButtonContainer>
      </div>
    </motion.div>
  );
};



export default ActionComponent;