import React, { useState, useEffect} from 'react';
import { httpsCallable } from 'firebase/functions';
import { functions } from '../../../../Firebase/firebaseConfig';
import { DocumentDownload } from '../../../../Components/Common/Buttons/DownloadButton/DownloadButton';


const ProgrammeDocumentation = () => {
  const [expandedSection, setExpandedSection] = useState('');
  const [pdfUrls, setPdfUrls] = useState('');
  const [loading, setLoading] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchPdfUrls = async () => {
      try {
        const getMentorPdfs = httpsCallable(functions, 'get_mentor_pdfs');
        const result = await getMentorPdfs();
        setPdfUrls(result.data);
        //console.log('results', result)
        setLoading(false);
      } catch (err) {
        //console.error("Error fetching PDF URLs:", err);
        setError("Failed to load PDF documents. Please try again later.");
        setLoading(false);
      }
    };
    fetchPdfUrls();
  }, []);


  const toggleSection = (section) => {
    setExpandedSection(expandedSection === section ? '' : section);
  };

  const sectionData = [
    {
      id: 'job-contract',
      title: 'OTC: Mentor Contract',
      content: [
        'Exciting opportunity for STEM experts to mentor A-Level students in their Extended Project Qualification (EPQ)',
        'Flexible remote volunteering role requiring just 1.5 hours per month for 6 months', 
        `Chance to shape future STEM leaders while developing your own coaching and communication skills`,
        `Impactful way to give back to the community and inspire young people from diverse backgrounds`
      ], 
      pdfKey: 'mentorcontract'
    },
    {
      
      id: 'welcome-pack',
      title: 'Welcome Pack',
      content: [
        'Comprehensive guide for students and mentors participating in the Extended Project Qualification (EPQ) program for 2024/25',
        'Outlines the three EPQ project routes (dissertation, investigation/field study, and artefact), including learning objectives and assessment criteria for each',
        'Provides detailed information on project planning, research methodologies, writing requirements, and presentation guidelines for students',
        'Includes important sections on mentor responsibilities, safeguarding procedures, and malpractice policies for the EPQ program'
      ],
      pdfKey: 'welcomePack'
    },
    {
      id: 'literature-review',
      title: 'Induction Session - Milestone One',
      content: [
        'Step-by-step walkthrough of the literature review process for EPQ students', 
        'Tips for crafting effective project titles and conducting targeted research using academic databases', 
        'Guidance on evaluating scientific literature and identifying gaps in current knowledge',
        `Preparation checklist for students' first mentor meeting, including self-reflection questions and discussion prompts`
      ], 
      pdfKey: 'literatureReview'
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }


  return (
    <div style={programmeDocStyles.container}>
    <h1>Off the Curriculum - Profresh Academic Mentoring Programme Documentation</h1>
    <p>Mentoring with empathy and understanding first, expertise and knowledge second!</p>
    <p>
      In the drop-down sections below, you will find all the relevant documentation for the Profresh Academic Mentoring Programme. Each section contains carefully curated resources, guidelines, and materials designed to support both mentors and mentees throughout the programme. These documents offer insights into the structure of the programme, expectations, and best practices for fostering meaningful mentoring relationships, while emphasising empathy, understanding, and personal development as key components of the experience.
    </p>

    <div style={programmeDocStyles.accordion}>
      {sectionData.map((section) => (
        <div key={section.id} style={programmeDocStyles.accordionItem}>
          <button 
            onClick={() => toggleSection(section.id)}
            style={{
              ...programmeDocStyles.accordionTrigger,
              ...(expandedSection === section.id ? programmeDocStyles.accordionTriggerActive : {})
            }}
          >
            {section.title}
            <span 
              style={{
                ...programmeDocStyles.accordionIcon,
                ...(expandedSection === section.id ? programmeDocStyles.accordionIconActive : {})
              }}
            ></span>
          </button>
          <div style={{
            ...programmeDocStyles.accordionContent,
            ...(expandedSection === section.id ? programmeDocStyles.accordionContentActive : {})
          }}>
            <ul style={programmeDocStyles.accordionContentUl}>
              {section.content.map((item, index) => (
                <li key={index} style={programmeDocStyles.accordionContentLi}>{item}</li>
              ))}
            </ul>
            {section.pdfKey && pdfUrls[section.pdfKey] && (
              <div style={programmeDocStyles.pdfLink}>
                <DocumentDownload 
                documentUrl={pdfUrls[section.pdfKey]} 
                fileName={section.title}
                text={section.title}
                />
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  </div>
  );
};

const programmeDocStyles = {
  container: {
    maxWidth: '800px',
    margin: '0 auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 0 10px rgba(0,0,0,0.1)',
  },
  card: {
    backgroundColor: 'white',
    padding: '20px',
    marginBottom: '20px',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
  },
  content: {
    fontSize: '14px',
    lineHeight: 1.5,
    color: '#333',
  },
  accordion: {
    backgroundColor: 'white',
    borderRadius: '8px',
    boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    overflow: 'hidden',
  },
  accordionItem: {
    borderBottom: '1px solid #e0e0e0',
  },
  accordionItemLast: {
    borderBottom: 'none',
  },
  accordionTrigger: {
    width: '100%',
    padding: '15px 20px',
    backgroundColor: '#1F484C',
    color: 'white',
    border: '2px solid transparent',
    textAlign: 'left',
    fontSize: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    transition: 'all 0.3s ease',
  },
  accordionTriggerHover: {
    opacity: 0.9,
  },
  accordionTriggerActive: {
    backgroundColor: 'transparent',
    color: '#1F484C',
    borderColor: '#1F484C',
  },
  accordionIcon: {
    width: '10px',
    height: '10px',
    borderRight: '2px solid white',
    borderBottom: '2px solid white',
    transform: 'rotate(45deg)',
    transition: 'transform 0.3s ease, border-color 0.3s ease',
  },
  accordionIconActive: {
    transform: 'rotate(-135deg)',
    borderColor: '#1F484C',
  },
  accordionContent: {
    maxHeight: 0,
    overflow: 'hidden',
    transition: 'max-height 0.3s ease',
  },
  accordionContentActive: {
    maxHeight: '1000px',
  },
  accordionContentUl: {
    listStyleType: 'disc',
    padding: '0 20px 15px 40px',
  },
  accordionContentLi: {
    marginBottom: '5px',
  },
};

export default ProgrammeDocumentation;