

import { Link } from "react-router-dom";
import { useEffect, useState, useRef, useCallback } from "react";
import { motion, AnimatePresence } from "framer-motion";
import StarButton from "../Buttons/StarButton/StarButton";
import NavActionButton from "../Buttons/NavButtons/NavButtons";

const NavBar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const lastScrollY = useRef(0);
  const scrollTimer = useRef(null);
  const scrollThreshold = 50; // Reduced threshold for more responsive behavior
  const mobileBreakpoint = 1443;

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
      if (window.innerWidth > mobileBreakpoint) {
        setIsMenuOpen(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleScroll = useCallback(() => {
    const currentScrollY = window.scrollY;
    
    // Always show navbar at the top of the page
    if (currentScrollY === 0) {
      setIsVisible(true);
      setIsSticky(false);
      lastScrollY.current = currentScrollY;
      return;
    }

    // Handle scroll behavior
    const scrollDifference = Math.abs(currentScrollY - lastScrollY.current);

    setIsSticky(currentScrollY > 0);

    if (scrollDifference > scrollThreshold) {
      // Scrolling down - hide navbar
      if (currentScrollY > lastScrollY.current) {
        if (scrollTimer.current !== null) {
          clearTimeout(scrollTimer.current);
        }
        scrollTimer.current = setTimeout(() => {
          setIsVisible(false);
        }, 100);
      } else {
        // Scrolling up - show navbar
        clearTimeout(scrollTimer.current);
        setIsVisible(true);
      }
      lastScrollY.current = currentScrollY;
    }
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => window.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);

  const styles = {
    container: {
      position: 'fixed',
      top: 0,
      left: '50%',
      transform: isVisible 
        ? 'translateX(-50%) translateY(0)' 
        : 'translateX(-50%) translateY(-100%)',
      width: '90%',
      maxWidth: '1800px',
      zIndex: 1000,
      backgroundColor: 'transparent',
      padding: '25px 40px',
      transition: 'transform 0.3s ease-in-out',
      display: windowWidth > mobileBreakpoint ? 'block' : 'none', // Hide on mobile
    },
    navbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 35px',
      height: '75px',
      backgroundColor: 'white',
      borderRadius: '40px',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      margin: '0 auto',
    },
    logo: {
      height: '42px', // Adjusted size
      width: 'auto',
      marginRight: '40px', // Added margin
    },
    navLinks: {
      display: windowWidth > mobileBreakpoint ? 'flex' : 'none',
      alignItems: 'center',
      gap: '50px', // Increased gap
      margin: '0 40px',
      flex: 1, // Allow it to take available space
      justifyContent: 'center', // Center the links
    },
    navLink: {
      color: '#333',
      textDecoration: 'none',
      fontWeight: '600', // Slightly bolder
      fontSize: '15px', // Adjusted size
      letterSpacing: '0.3px', // Added letter spacing
      transition: 'color 0.3s ease',
    },
    educoButton: {
      display: windowWidth > mobileBreakpoint ? 'block' : 'none',
      padding: '12px 32px',
      backgroundColor: '#1F484C',
      color: 'white',
      borderRadius: '30px',
      border: 'none',
      fontWeight: '600',
      fontSize: '15px',
      letterSpacing: '0.3px',
      textDecoration: 'none',
      transition: 'all 0.3s ease',
      marginLeft: '40px', // Added margin
      boxShadow: '0 2px 8px rgba(31, 72, 76, 0.2)', // Added subtle shadow
    },
    hamburger: {
      position: 'fixed',
      bottom: '30px',
      left: '30px',
      zIndex: 1001, // Increased to ensure it stays on top
      backgroundColor: '#1F484C',
      border: 'none',
      borderRadius: '20px',
      width: '45px',
      height: '45px',
      display: windowWidth <= mobileBreakpoint ? 'flex' : 'none',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '24px',
      color: 'white',
      boxShadow: '0 4px 12px rgba(31, 72, 76, 0.2)',
      transition: 'all 0.3s ease',
    },
    mobileMenu: {
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100vh',
      backgroundColor: 'white',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      zIndex: 1002,
      padding: '20px',
      overflowY: 'auto',
    },
    mobileHeader: {
      width: '90%',
      maxWidth: '1800px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      margin: '0 auto',
      padding: '20px 0',
    },
    mobileOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: 1001,
    },
    mobileLinkContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '60px',
      gap: '40px',
    },
  
    mobileLink: {
      padding: '15px 30px',
      color: '#333',
      textDecoration: 'none',
      fontSize: '24px',
      fontWeight: '600',
      letterSpacing: '0.5px',
      textAlign: 'center',
      width: '100%',
      maxWidth: '300px',
      transition: 'all 0.3s ease',
    },
    mobileEducoButton: {
      marginTop: '40px',
      padding: '15px 40px',
      backgroundColor: '#1F484C',
      color: 'white',
      borderRadius: '30px',
      border: 'none',
      fontWeight: '600',
      fontSize: '20px',
      letterSpacing: '0.5px',
      textDecoration: 'none',
      textAlign: 'center',
      boxShadow: '0 4px 12px rgba(31, 72, 76, 0.2)',
      transition: 'all 0.3s ease',
    },
    bottomNav: {
      position: 'fixed',
      top: '20px',
      left: '50%',
      transform: 'translateX(-50%)',
      width: '90%',
      maxWidth: '1800px',
      
      // Flex properties
      display: windowWidth <= mobileBreakpoint ? 'flex' : 'none',
      justifyContent: 'space-between',
      alignItems: 'center',
      
      // Styling
      padding: '0 35px',
      height: '75px',
      backgroundColor: 'white',
      borderRadius: '40px',
      boxShadow: '7.8px 15.5px 15.5px rgba(0, 0, 0, 0.2)',
      margin: '0 auto',
      zIndex: 999,
    },
    bottomNavLogo: {
      height: '42px',
      width: 'auto',
    },
    bottomNavButton: {
      padding: '10px 25px',
      backgroundColor: 'white',
      color: '#333',
      borderRadius: '30px',
      border: 'none',
      fontWeight: '600',
      fontSize: '15px',
      textDecoration: 'none',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
    },
    closeButton: {
      backgroundColor: '#1F484C',
      border: 'none',
      borderRadius: '50%',
      width: '45px',
      height: '45px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      fontSize: '24px',
      color: 'white',
      boxShadow: '0 4px 12px rgba(31, 72, 76, 0.2)',
    },
  };

  return (
    <>
    <div style={{
      ...styles.container,
      transform: isVisible 
        ? 'translateX(-50%) translateY(0)' 
        : 'translateX(-50%) translateY(-100%)',
      transition: 'transform 0.3s ease-in-out',  
    }}>
        <nav style={styles.navbar}>
          <Link to="/">
            <motion.div
              whileHover={{
                scale: 1.1,
              }}
              whileTap={{ scale: 1 }}
              animate={{
                scale: [1.0, 1.1, 1.0],
              }}
              style={{
                paddingLeft: '20px',
                display: 'flex',
                alignItems: 'center',
              }}>
              <img
                src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Foffthecurriculum_long_logo.png?alt=media&token=d1824229-8237-473f-947c-4008f6fd3b80'
                style={styles.logo}
              />
            </motion.div>
          </Link>

          <div style={styles.navLinks}>
            <NavActionButton to="/About">
              ABOUT
            </NavActionButton>
            <NavActionButton to="/education">
              EDUCATION
            </NavActionButton>
            <NavActionButton to="/education/enrol">
              ENROL
            </NavActionButton>
            <NavActionButton to="/volunteer">
              VOLUNTEER
            </NavActionButton>
            <NavActionButton to="/donate">
              DONATE
            </NavActionButton>
          </div>
          <StarButton to='/SignIn'>
            EDUCO
          </StarButton>
        </nav>
      </div>

      {/* Bottom Navigation for Mobile */}
      {windowWidth <= mobileBreakpoint && (
        <>
          <div style={styles.bottomNav}>
            <Link to="/">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Foffthecurriculum_long_logo.png?alt=media&token=d1824229-8237-473f-947c-4008f6fd3b80'
                style={styles.bottomNavLogo}
                alt="Logo"
              />
            </Link>
            <StarButton to='/SignIn'>
              EDUCO
           </StarButton>  
          </div>

          <motion.button
            style={styles.hamburger}
            whileHover={{ scale: 1.1, boxShadow: '0 6px 16px rgba(255, 107, 0, 0.4)' }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {isMenuOpen ? (
              <motion.span
                initial={{ opacity: 0, rotate: 0 }}
                animate={{ opacity: 1, rotate: 45 }}
                exit={{ opacity: 0, rotate: 0 }}
                transition={{ duration: 0.3 }}
              >
                &times;
              </motion.span>
            ) : (
              <span>☰</span>
            )}
          </motion.button>
        </>
      )}

      {/* Hamburger Menu Button */}
      <motion.button
        style={styles.hamburger}
        whileHover={{ scale: 1.1, boxShadow: '0 6px 16px rgba(255, 107, 0, 0.4)' }}
        whileTap={{ scale: 0.9 }}
        onClick={() => setIsMenuOpen(!isMenuOpen)}
      >
        {isMenuOpen ? (
          <motion.span
            initial={{ opacity: 0, rotate: 0 }}
            animate={{ opacity: 1, rotate: 45 }}
            exit={{ opacity: 0, rotate: 0 }}
            transition={{ duration: 0.3 }}
          >
            &times;
          </motion.span>
        ) : (
          <span>☰</span>
        )}
      </motion.button>

      {/* Mobile Menu */}
      <AnimatePresence>
      {isMenuOpen && windowWidth <= mobileBreakpoint && (
        <motion.div
          style={styles.mobileMenu}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.2 }}
        >
          <div style={styles.mobileHeader}>
            <Link to="/">
              <img
                src='https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FLandingpage%2Foffthecurriculum_long_logo.png?alt=media&token=d1824229-8237-473f-947c-4008f6fd3b80'
                style={styles.bottomNavLogo}
                alt="Logo"
              />
            </Link>
            
            <motion.button
              whileHover={{ scale: 1.1, boxShadow: '0 6px 16px rgba(255, 107, 0, 0.4)' }}
              style={styles.closeButton}
              whileTap={{ scale: 0.9 }}
              onClick={() => setIsMenuOpen(false)}
            >
              <motion.span
                initial={{ opacity: 0, rotate: 0 }}
                animate={{ opacity: 1, rotate: 45 }}
                exit={{ opacity: 0, rotate: 0 }}
                transition={{ duration: 0.3 }}
              >
                ×
              </motion.span>
            </motion.button>
          </div>

          <div style={styles.mobileLinkContainer}>
            {[
              { to: "/About", label: "ABOUT" },
              { to: "/education", label: "EDUCATION" },
              { to: "/education/enrol", label: "ENROL" },
              { to: "/volunteer", label: "VOLUNTEER" },
              { to: "/donate", label: "DONATE" },
            ].map((item, index) => (
              <motion.div
                key={item.to}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
              >
                <Link
                  to={item.to}
                  style={styles.mobileLink}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {item.label}
                </Link>
              </motion.div>
            ))}
            <motion.div
              whileHover={{ scale: 1.1, boxShadow: '0 6px 16px rgba(255, 107, 0, 0.4)' }}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
            >
              <Link
                to="/SignIn"
                style={styles.mobileEducoButton}
                onClick={() => setIsMenuOpen(false)}
              >
                EDUCO
              </Link>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
    </>
  );
};

export default NavBar;