import React from 'react';
import { FaArrowLeft, FaExternalLinkAlt, FaPhone, FaCommentAlt, FaInfoCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const MentalHealthSupport = () => {

  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/statements');
  };

  const styles = {
    container: {
      display: 'flex',
      maxWidth: '1200px',
      margin: '0 auto',
      padding: '20px',
      gap: '40px',
    },
    contentSection: {
      flex: '1',
    },
    pageTitle: {
      fontSize: '2.5em',
      fontWeight: '600',
      color: '#1a1a1a',
      marginBottom: '15px',
      letterSpacing: '-0.02em',
    },
    pageDescription: {
      fontSize: '1.1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '30px',
    },
    backButton: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#4b5563',
      border: 'none',
      background: 'none',
      cursor: 'pointer',
      marginBottom: '20px',
      fontSize: '1em',
    },
    grid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
      gap: '24px',
      marginBottom: '40px',
    },
    card: {
      backgroundColor: 'white',
      borderRadius: '12px',
      border: '1px solid #e5e7eb',
      transition: 'all 0.3s ease',
      padding: '24px',
    },
    cardHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '16px',
    },
    cardTitle: {
      fontSize: '1.4em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '8px',
    },
    categoryTag: {
      display: 'inline-flex',
      alignItems: 'center',
      padding: '6px 12px',
      backgroundColor: '#f3f4f6',
      color: '#111827',
      borderRadius: '6px',
      fontSize: '0.875em',
      fontWeight: '500',
    },
    description: {
      fontSize: '1em',
      color: '#4b5563',
      lineHeight: '1.6',
      marginBottom: '16px',
    },
    contactInfo: {
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
    },
    contactItem: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#374151',
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      color: '#1F484C',
      textDecoration: 'none',
      fontSize: '0.95em',
    },
    hours: {
      fontSize: '0.875em',
      color: '#6b7280',
      marginLeft: '24px',
    },
    emergencyNote: {
      backgroundColor: '#f3f4f6',
      borderRadius: '12px',
      padding: '24px',
      marginBottom: '40px',
    },
    emergencyTitle: {
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '1.2em',
      fontWeight: '600',
      color: '#111827',
      marginBottom: '12px',
    },
    footer: {
      textAlign: 'center',
      color: '#6b7280',
      fontSize: '0.875em',
      marginTop: '40px',
    },
  };

  const charities = [
    {
      name: "CALM ",
      description: "CALM (Campaign Against Living Miserably) offers support to anyone who is struggling, through their helpline, webchat, and website.",
      website: "https://www.thecalmzone.net",
      phone: "0800 58 58 58",
      hours: "5pm - midnight, 365 days a year",
      category: "Crisis Support"
    },
    {
      name: "Mind",
      description: "Mind provides advice and support to anyone experiencing a mental health problem.",
      website: "https://www.mind.org.uk",
      phone: "0300 123 3393",
      hours: "9am - 6pm, Monday to Friday",
      category: "Mental Health Support"
    },
    {
      name: "Samaritans",
      description: "Samaritans is available 24/7 to provide emotional support to anyone in emotional distress, struggling to cope, or at risk of suicide.",
      website: "https://www.samaritans.org",
      phone: "116 123",
      hours: "24/7 support available",
      category: "Crisis Support"
    },
    {
      name: "YoungMinds",
      description: "YoungMinds is the UK's leading charity fighting for children and young people's mental health.",
      website: "https://youngminds.org.uk",
      textline: "Text YM to 85258",
      hours: "24/7 text support",
      category: "Youth Support"
    }
  ];

  return (
    <div style={styles.container}>
      <div style={styles.contentSection}>
        <button 
        onClick={handleBack} 
        style={styles.backButton}>
          <FaArrowLeft /> Back to Resources
        </button>

        <h1 style={styles.pageTitle}>Mental Health Support Services</h1>
        <p style={styles.pageDescription}>
          If you're feeling overwhelmed or need support, these organizations are here to help. Remember, seeking help is a sign of strength.
        </p>

        <div style={styles.grid}>
          {charities.map((charity, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardHeader}>
                <h2 style={styles.cardTitle}>{charity.name}</h2>
                <span style={styles.categoryTag}>{charity.category}</span>
              </div>

              <p style={styles.description}>{charity.description}</p>

              <div style={styles.contactInfo}>
                {charity.website && (
                  <a href={charity.website} 
                     target="_blank" 
                     rel="noopener noreferrer" 
                     style={styles.link}>
                    <FaExternalLinkAlt size={15} /> Visit Website
                  </a>
                )}

                {charity.phone && (
                  <div style={styles.contactItem}>
                    <FaPhone size={15}/>
                    <span style={{width: '100%'}}>{charity.phone}</span>
                    <span style={styles.hours}>{charity.hours}</span>
                  </div>
                )}

                {charity.textline && (
                  <div style={styles.contactItem}>
                    <FaCommentAlt size={15} />
                    <span>{charity.textline}</span>
                    <span style={styles.hours}>{charity.hours}</span>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>

        <div style={styles.emergencyNote}>
          <div style={styles.emergencyTitle}>
            <FaInfoCircle />
            Important Note
          </div>
          <p style={styles.description}>
            If you're experiencing an immediate crisis or emergency, please call 999 or visit your nearest A&E department. 
            These services are available 24/7 and are equipped to provide immediate assistance.
          </p>
        </div>

        <div style={styles.footer}>
          <p>This resource list is regularly updated to ensure accuracy. Last updated: October 2024</p>
        </div>
      </div>
    </div>
  );
};

export default MentalHealthSupport;
