/**dependencies */
import {
  collection,
  addDoc,
  query,
  orderBy,
  onSnapshot,
  serverTimestamp,
  deleteDoc,
  doc,
  updateDoc
} from "firebase/firestore";
import { ref, onValue } from "firebase/database";
import { useRef, useState, useEffect } from "react";
import { firestore, auth, db } from "../../../Firebase/firebaseConfig";
import { formatDistanceToNow } from 'date-fns';
import { styles } from "./style";
import { motion } from "framer-motion";
import { emailsHasher } from "../../../Firebase/educoFunctions";
import CommentBlueActionButton from "../Buttons/CommentButton/CommentButton";

function useHover() {
  const [hover, setHover] = useState(false);
  const onMouseEnter = () => setHover(true);
  const onMouseLeave = () => setHover(false);

  return [hover, onMouseEnter, onMouseLeave];
}


function DisplayMessage({ id, name, timestamp, text, picUrl, currentUser, onDelete, onEdit }) {
  const [editHover, onEditMouseEnter, onEditMouseLeave] = useHover();
  const [deleteHover, onDeleteMouseEnter, onDeleteMouseLeave] = useHover();
  const formattedTimestamp = timestamp ? formatDistanceToNow(timestamp.toDate(), { addSuffix: true }) : '';
  const isCurrentUser = currentUser && currentUser.displayName === name;
  //console.log('issues',isCurrentUser)
  return (
    <div style={styles.commentStyle}>
      <div style={{ display: 'flex' }}>
        <div style={styles.headerStyle}>
          <img style={styles.avatarStyle} src={picUrl} alt={name} />
          <div style={styles.infoStyle}>
            <span style={styles.usernameStyle}>{name}</span>
            <span style={styles.timestampStyle}>{formattedTimestamp}</span>
          </div>
        </div>
        <div style={styles.commentContainerStyle}>
          <div style={styles.paraCommentStyle}>
            <p>{text}</p>
            <span style={styles.arrowStyle}></span>
          </div>
        </div>
      </div>
      {isCurrentUser && (
        <div style={styles.actionsStyle}>
          <button
            style={{ ...styles.editButton, ...(editHover ? styles.editButtonHover : {}) }}
            onMouseEnter={onEditMouseEnter}
            onMouseLeave={onEditMouseLeave}
            onClick={() => onEdit(id, text)}>
            Edit
          </button>
          <button
            style={{ ...styles.deleteButton, ...(deleteHover ? styles.deleteButtonHover : {}) }}
            onMouseEnter={onDeleteMouseEnter}
            onMouseLeave={onDeleteMouseLeave}
            onClick={() => onDelete(id)}>Delete</button>
        </div>
      )}
      <span style={styles.arrowStyle}></span>
    </div>
  );
}

export default function CommentSection() {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [editId, setEditId] = useState(null);
  const messageInputRef = useRef(null);
  const [currentUser, setCurrentUser] = useState(null);
  const [student, setStudent] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isChatOpen, setIsChatOpen] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      setCurrentUser(user);
    });
    return unsubscribe;
  }, []);

  useEffect(() => {
    if (currentUser) {
      const emailHash = emailsHasher(currentUser.email);
      //console.log('email', emailHash)
      const dbRef = ref(db, `person/${emailHash}`);
      onValue(dbRef, (snapshot) => {
        const profileData = snapshot.val();
        setStudent(profileData);
        setLoading(false);
      }, { onlyOnce: true });
    }
  }, [currentUser]);

  useEffect(() => {
    if (student && student.token === true) {
      //console.log('student')
      const commentStore = document.title.replaceAll(" ", "");
      //console.log(commentStore)
      const recentMessagesQuery = query(
        collection(firestore, "comments", "videos", commentStore),
        orderBy("timestamp")
      );

      const unsubscribe = onSnapshot(recentMessagesQuery, (snapshot) => {
        const newMessages = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setMessages(newMessages);
      });

      return unsubscribe;
    }
  }, [student]);

  async function saveMessage(messageText) {
    const commentStore = document.title.replaceAll(" ", "");
    if (editId) {
      await updateDoc(doc(firestore, "comments", "videos", commentStore, editId), {
        text: messageText,
        timestamp: serverTimestamp(),
      });
      setEditId(null);
    } else {
      try {
        //console.log(student.displayPhotoUrl)
        await addDoc(collection(firestore, "comments", "videos", commentStore), {
          name: auth.currentUser.displayName || 'Anonymous',
          text: messageText,
          profilePicUrl: student.displayPhotoUrl,
          timestamp: serverTimestamp(),
        });
      } catch (error) {
        //console.error("Error writing new message to Firebase Database", error);
      }
    }
  }

  async function deleteMessage(id) {
    const commentStore = document.title.replaceAll(" ", "");
    
    ////console.log(deleteMessage)
    try {
      await deleteDoc(doc(firestore, "comments", "videos", commentStore, id));
    } catch (error) {
      //console.error("Error deleting message from Firebase Database", error);
    }
  }

  function handleInputChange(event) {
    setMessage(event.target.value);
  }

  async function handleFormSubmit(event) {
    event.preventDefault();
    if (message.trim() === "") return;

    await saveMessage(message);
    setMessage("");
    messageInputRef.current.focus();
  }

  function toggleChat() {
    setIsChatOpen(prevIsChatOpen => !prevIsChatOpen);
  }

  function handleEdit(id, text) {
    setMessage(text);
    setEditId(id);
    messageInputRef.current.focus();
  }

  return (
    <>
      <motion.button
        style={isChatOpen ? styles.collapsibleOpen : styles.collapsible}
        onClick={toggleChat}
        whileTap={{ scale: 1.02 }}
        whileHover={{ scale: 1.02 }}
        transition={{ duration: 0.2 }}
      >
        Comment section {isChatOpen ? '-' : '+'}
      </motion.button>
      {isChatOpen && (
        <main id="mains">
          <div style={styles.commentContainer}>
            <div>
              <div>
                {messages.length > 0 ? (
                  <div>
                    {messages.map((message) => (
                      <DisplayMessage
                        key={message.id}
                        id={message.id}
                        name={message.name}
                        timestamp={message.timestamp}
                        text={message.text}
                        picUrl={message.profilePicUrl}
                        currentUser={currentUser}
                        onDelete={deleteMessage}
                        onEdit={handleEdit}
                      />
                    ))}
                  </div>
                ) : (
                  <div>No comments yet.</div>
                )}
              </div>
            </div>
            <div style={styles.messagesCard}>
              <div style={styles.messages}>
                <form style={styles.messageForm} onSubmit={handleFormSubmit}>
                  <textarea
                    style={styles.inputComment}
                    autoComplete="on"
                    spellCheck={false}
                    placeholder="Add a comment here..."
                    value={message}
                    onChange={handleInputChange}
                    ref={messageInputRef}
                  />
                  <CommentBlueActionButton>
                    {editId ? "Update Post" : "Post"}
                  </CommentBlueActionButton>
                </form>
              </div>
            </div>
          </div>
        </main>
      )}
    </>
  );
}
