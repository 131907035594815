import React, { useState } from 'react';
import { styles } from '../styles';

const DragAndDropQuiz = ({ question, correctOrder, options }) => {
  const [wordOrder, setWordOrder] = useState(Array(correctOrder.length).fill(''));
  const [isCorrect, setIsCorrect] = useState(false);

  const handleDragStart = (e, word) => {
    e.dataTransfer.setData('text/plain', word);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const word = e.dataTransfer.getData('text/plain');
    setWordOrder((prevOrder) => {
      const newOrder = [...prevOrder];
      newOrder[index] = word;
      return newOrder;
    });

    setIsCorrect(JSON.stringify(wordOrder) === JSON.stringify(correctOrder));
  };

  return (
    <div>

      <div style={styles.sentenceContainer}>
        {wordOrder.map((word, index) => (
          <div
            key={index}
            onDragOver={handleDragOver}
            onDrop={(e) => handleDrop(e, index)}
            style={styles.wordPlaceholder}
          >
            {word}
          </div>
        ))}
      </div>

      <div style={styles.wordBank}>
        {options.map((word) => (
          <div
            key={word}
            className="word"
            draggable
            onDragStart={(e) => handleDragStart(e, word)}
            style={styles.draggableWord}
          >
            {word}
          </div>
        ))}
      </div>

      {isCorrect && <div style={styles.resultStyle}>Correct!</div>}
    </div>
  );
};

export default DragAndDropQuiz;
