export default {
  id: 'youngblackandneurodiverse',
    title: "Young. Black. And Neurodiverse. ",
    hero_image: "path_to_hero_image.jpg",
    author: {
      name: "Nicole Curtis",
      image: '',
      bio: "I take care of all Marketing and Communications at Off the Curriculum. I was born in London but moved to Spain as a child, growing up there until university. Currently, I am an MSc student reading Psychology and Neuroscience of Mental Health at King’s College London. I work in Child and Adolescent Mental Health and Addiction Rehabilitation. With a passion for education, I jumped at the opportunity to be a part of OTC. Curious by nature, I am passionate about mental health and wellbeing, the creative and performing arts, food, travel, and culture.",
      social: {
        twitter: "https://twitter.com/otclambeth/",
        linkedin: "https://www.linkedin.com/in/nicole-l-aa60301b5",
        instagram: "https://www.instagram.com/offthecurriculumlondon/"
      }
    },
    metadata: {
      date: "2023-02-06",
      readTime: "5 minutes",
      category: "charity"
    },
    content: [
      {
        type: "heading",
        content: "Introduction"
      },
      {
        type: "paragraph",
        content: "During our Psychology and Neuroscience seminar series last year, one of our enrolled students, Joshua-Tobiloba Olowe, after listening to Dr. Max Davie deliver a lecture on people's reactions to neurodivergence, was motivated to write a spoken word for his Self-Determined Assessment piece. With help from Roger Robson, Nicole Curtis, and Joseph Levermore - Joshua refined his spoken word and submitted for the National Poetry Libraries: Black in White Poetry Competition in 2022. Over to you Joshua..."
      },
      {
        type: "heading",
        content: "Young. Black. And Neurodiverse."
      },
      {
        type: "paragraph",
        content: `During this artistic piece, I plan on taking you on a journey that relies on your emotions, experiences and senses. It will take you through some highs and definitely some deep lows that may scare some and trigger others. This poem has been written to give you a different perspective. Some will ask for the facts and objectivity, some will then demand for statistics and scientific evidence to understand the different interpretations being presented. But I am asking you a big favour as you read; picture, imagine, and listen to this art: “Please use your senses, emotions and experiences to understand why…”Being a young black male with mental health concerns is an uphill battle that we are already losing before we are awakened to the fight. So explain to me, as a young black male, how am I meant to understand what “neurodiversity” is? Let alone how we fit into that wide box with its ever evolving definition; the freezing fog of confusion, the condescending tones, and the hypercritical judgment from the majority.So let me illustrate to you, the struggles of a Young. Black. Neurodiverse. Male. `
      },
      {
        type: "poem",
        content: [
          "Why is it so painful for a young black male to seek help within an area that is gaining so much prominence and attention?",
          "Is it because we present differently?",
          "A prime example of this",
          "One we are all too familiar with",
          "is the young black boy with ADHD;",
          "To some this is a lived experience;",
          "The symptoms that are commonly associated with ADHD are:",
          "Inattention - this has the same bitter, burnt taste of plain old disrespect",
          "While Hyperactivity is displayed as the young black boy's inability to stay in one place",
          "And Impulsivity has a gut wrenching,",
          "decaying stench of misguided aggression",
          "So, why is it white male counterparts symptoms are seen and treated just as phases",
          "Why? I ask you why?",
          "Is it because their behaviours are cultivated and protected, nurtured and guarded",
          "Whilst ours are conceived from a place of confusion, disappointment and frustration?",
          "So here I am, a Young. Black. Neurodiverse male, asking you another question:",
          "Why are young, white males allowed to be vulnerable and open with the symptoms they face?",
          "Without any punishment,",
          "Any retribution",
          "Or any hypocrisy?"
        ],
        style: {
          container: {
            padding: '30px',
            margin: '30px 0',
            backgroundColor: '#f8f9fa',
            borderLeft: '4px solid #1F484C',
            borderRadius: '4px'
          },
          line: {
            marginBottom: '16px',
            lineHeight: '1.8',
            fontSize: '18px',
            fontStyle: 'italic'
          },
          emphasis: {
            fontWeight: 'bold',
            color: '#1F484C'
          }
        }
      },
      {
        type: "paragraph",
        content: "We have now reached the end of this artistic piece, but DEFINITELY not the end of this issue. This will undoubtedly be an ongoing concern that will last for many generations to come; for good or bad, this will be a topic that will live on after us. "
      },
      {
        type: "paragraph",
        content: "But what will our contribution be?"
      },
      {
        type: "paragraph",
        content: "Written By Joshua-Tobiloba Olowe"
      },
      {/*{
        type: "image",
        url: "image_url",
        caption: "Image caption"
      },*/}
      // Add more content sections as needed
    ],
    relatedArticles: [
      {
        title: "Mindfulness",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Fmindfulness.webp?alt=media&token=f8585110-94e6-42ea-8a3b-ad1979e436d4",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Mindfulness"
      },
      {
        title: "Learning to Learn from home",
        image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FNews%2Flearn_to_learn.webp?alt=media&token=fe0049d4-fae6-46f3-9b84-f26ea56098a6",
        category: "Education",
        author: "Nicole T Levermore",
        link: "/Learningtolearn"
      }
    ]
  };