import React, { useState } from 'react';
import { motion } from 'framer-motion';
import BlackButton from '../../../Components/Common/Buttons/BlackButton/ButtonButton';
import Toggle from '../../../Components/Common/Buttons/ToggleButton/ToggleButton';
import MembershipPlansComponent from '../../../Components/Common/MembershipPlansComponent/MembershipPlansComponent';
import SchoolsMembershipPlansComponent from '../../../Components/Common/MembershipPlansComponent/SchoolsMembershipComponent';

// PlanCard component remains the same
function PlanCard({ name, price, students, features }) {
  const styles = {
    card: {
      padding: '20px',
      marginBottom: '20px',
      width: '300px', 
    },
    price: {
      fontSize: '24px',
      fontWeight: 'bold',
    },
    students: {
      fontStyle: 'italic',
    },
    features: {
      listStyle: 'none',
      padding: 0,
    },
  };

  return (
    <div className="plan-card" style={styles.card}>
      <h3>{name}</h3>
      <p className="price">{price}</p>
      <p className="students">For up to {students} students</p>
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>{feature}</li>
        ))}
      </ul>
    </div>
  );
}

function MembershipPlans() {
  const [activePlanType, setActivePlanType] = useState("Individual");

  const individualPlans = [
    { name: "Pro", price: "£50 p.a.", features: ["Feature A", "Feature B", "Feature C"] },
    { name: "Max", price: "£60 p.a.", features: ["Feature A", "Feature B", "Feature C", "Feature D"] },
    { name: "Ultra", price: "£65 p.a.", features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E"] },
  ];

  const educatorPlans = [
    { name: "Pro", price: "£799 p.a.", students: 20, features: ["Feature A", "Feature B", "Feature C"] },
    { name: "Max", price: "£2299 p.a.", students: 50, features: ["Feature A", "Feature B", "Feature C", "Feature D"] },
    { name: "Ultra", price: "£3999 p.a.", students: 100, features: ["Feature A", "Feature B", "Feature C", "Feature D", "Feature E"] },
  ];

  const styles = {
    container: {
      backgroundColor: '#fff',
      borderRadius: '10px',
      padding: '20px',
    },
    toggleOption: {
      width: '50%',
      textAlign: 'center',
      zIndex: 1,
    },
    activeIndicator: {
      position: 'absolute',
      top: '0',
      left: activePlanType === "Individual" ? '0%' : '50%',
      width: '50%',
      height: '100%',
      borderRadius: '30px',
      backgroundColor: '#61A6B2',
    },
    planTypeSection: { 
      display: 'flex',
      justifyContent: 'center', 
      flexWrap: 'wrap', 
      gap: '20px',  
    },
  };

  return (
    <div className="membership-plans" style={styles.container}>
      <h1>OTC Membership Plans</h1>
      <Toggle onToggle={setActivePlanType} />
      {activePlanType === "Individual" && (
        <>
          <p>Plans designed for individual learners looking to expand their knowledge.</p>
          <MembershipPlansComponent/>
        </>
      )}
      {activePlanType === "Educator" && (
        <>
          <p>Plans designed for educators with groups of learners looking to expand their knowledge.</p>
          <SchoolsMembershipPlansComponent />
        </>
      )}
    </div>
  );
}

export default MembershipPlans;
