import React, { useState } from 'react';
import { styles } from '../PortalHomepage/AdminHomePage';

const RecentUpdates = ({ title, updates, onUpdateClick }) => {
    const [selectedIndex, setSelectedIndex] = useState(null);
  
    const handleUpdateClick = (index) => {
      setSelectedIndex(index);
      onUpdateClick(index);
    };
  
    return (
      <div style={styles.recentUpdates}>
        <div style={styles.sectionHeader}>
          <h3 style={styles.sectionTitle}>{title}</h3>
          <span style={styles.ellipsis}>⋯</span>
        </div>
        <div style={styles.updatesList}>
          {updates.map((update, index) => (
            <div
              key={index}
              style={{
                ...styles.updateItem,
                ...(selectedIndex === index ? styles.selectedUpdateItem : {}),
              }}
              onClick={() => handleUpdateClick(index)}
            >
              <input type="checkbox" style={styles.checkbox} />
              <div style={styles.updateContent}>
                <div style={styles.updateTitle}>{update.title}</div>
                <div style={styles.updateStatus}>{update.status}</div>
              </div>
              <span style={styles.updateImage}>{update.image}</span>
            </div>
          ))}
        </div>
      </div>
    );
  };

  export default RecentUpdates