import React, { useState } from 'react';
import StandardButtonContainer from '../../../../Components/Common/Buttons/StandardButtons/StandardButtons';
const EDIIncidentReportForm = () => {
  const [incidentDate, setIncidentDate] = useState('');
  const [incidentTime, setIncidentTime] = useState('');
  const [incidentLocation, setIncidentLocation] = useState('');
  const [incidentDetails, setIncidentDetails] = useState('');
  const [offenderDetails, setOffenderDetails] = useState('');
  const [additionalInfo, setAdditionalInfo] = useState('');
  const [fullName, setFullName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [showPreview, setShowPreview] = useState(false);
  const [validationError, setValidationError] = useState('');
  const [showValidationError, setShowValidationError] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    alert('Your equality, diversity and inclusion report incident form has been submitted')
  };
  const validateForm = () => {
    const requiredFields = {
      'Date of incident': incidentDate,
      'Time of incident': incidentTime,
      'Location of incident': incidentLocation,
      'Incident details': incidentDetails,
      'Full name': fullName,
      'Phone number': phoneNumber,
      'Email address': email
    };
  
    const missingFields = Object.entries(requiredFields)
      .filter(([_, value]) => !value.trim())
      .map(([fieldName]) => fieldName);
  
    if (missingFields.length > 0) {
      setValidationError(`Please complete the following required fields: ${missingFields.join(', ')}`);
      setShowValidationError(true);
      setTimeout(() => setShowValidationError(false), 5000); // Hide error after 5 seconds
      return false;
    }
  
    return true;
  };
  const errorStyles = {
    errorMessage: {
      backgroundColor: '#ffebee',
      color: '#c62828',
      padding: '10px',
      borderRadius: '4px',
      marginBottom: '10px',
      fontSize: '14px',
      position: 'fixed',
      top: '150px',
      left: '50%',
      transform: 'translateX(-50%)',
      zIndex: 2000,
      boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
      maxWidth: '80%',
      textAlign: 'center',
    },
  };
  const handlePreviewClick = () => {
    if (validateForm()) {
      setShowPreview(true);
    }
  };
  const PreviewModal = () => (
    <div style={styles.modalOverlay}>
      <div style={styles.modalContent}>
        <div style={styles.modalHeader}>
          <h2 style={styles.modalTitle}>Equality, Diversity and Inclusion report preview</h2>
          <button 
            onClick={() => setShowPreview(false)}
            style={styles.closeButton}
          >
            ×
          </button>
        </div>
        
        <div style={styles.previewContent}>
          <div style={styles.previewSection}>
            <h3 style={styles.previewSectionTitle}>Section 1: Incident Log</h3>
            <div style={styles.previewGrid}>
              <div>
                <span style={styles.previewLabel}>Date:</span>
                <p>{incidentDate || 'Not provided'}</p>
              </div>
              <div>
                <span style={styles.previewLabel}>Time:</span>
                <p>{incidentTime || 'Not provided'}</p>
              </div>
              <div style={styles.fullWidth}>
                <span style={styles.previewLabel}>Location:</span>
                <p>{incidentLocation || 'Not provided'}</p>
              </div>
            </div>
          </div>

          <div style={styles.previewSection}>
            <h3 style={styles.previewSectionTitle}>Section 2: Incident Description</h3>
            <div style={styles.previewDetails}>
              <div>
                <span style={styles.previewLabel}>Incident Details:</span>
                <p style={styles.previewText}>{incidentDetails || 'Not provided'}</p>
              </div>
              <div>
                <span style={styles.previewLabel}>Offender Details:</span>
                <p style={styles.previewText}>{offenderDetails || 'Not provided'}</p>
              </div>
              <div>
                <span style={styles.previewLabel}>Additional Information:</span>
                <p style={styles.previewText}>{additionalInfo || 'Not provided'}</p>
              </div>
            </div>
          </div>

          <div style={styles.previewSection}>
            <h3 style={styles.previewSectionTitle}>Section 3: Contact Information</h3>
            <div style={styles.previewGrid}>
              <div style={styles.fullWidth}>
                <span style={styles.previewLabel}>Full Name:</span>
                <p>{fullName || 'Not provided'}</p>
              </div>
              <div>
                <span style={styles.previewLabel}>Phone:</span>
                <p>{phoneNumber || 'Not provided'}</p>
              </div>
              <div>
                <span style={styles.previewLabel}>Email:</span>
                <p>{email || 'Not provided'}</p>
              </div>
            </div>
          </div>
          <StandardButtonContainer>
            <StandardButtonContainer.ButtonTwo
              type="submit" 
              onClick={handleSubmit}
            >
              Submit
            </StandardButtonContainer.ButtonTwo>
          </StandardButtonContainer>
        </div>
      </div>
    </div>
  );

  const styles = {
    // ... existing styles ...
    modalOverlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
    },
    modalContent: {
      backgroundColor: 'white',
      borderRadius: '8px',
      padding: '20px',
      maxWidth: '800px',
      width: '90%',
      maxHeight: '90vh',
      overflowY: 'auto',
      position: 'relative',
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
      borderBottom: '1px solid #ddd',
      paddingBottom: '10px',
    },
    modalTitle: {
      margin: 0,
      fontSize: '1.5rem',
      color: '#1F484C',
    },
    closeButton: {
      background: 'none',
      border: 'none',
      fontSize: '24px',
      cursor: 'pointer',
      padding: '5px',
      color: '#666',
    },
    previewContent: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    previewSection: {
      marginBottom: '20px',
    },
    previewSectionTitle: {
      fontSize: '1.2rem',
      color: '#1F484C',
      marginBottom: '10px',
      borderBottom: '1px solid #eee',
      paddingBottom: '5px',
    },
    previewGrid: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gap: '15px',
    },
    fullWidth: {
      gridColumn: '1 / -1',
    },
    previewLabel: {
      fontWeight: 'bold',
      color: '#1F484C',
      display: 'block',
      marginBottom: '5px',
    },
    previewText: {
      whiteSpace: 'pre-wrap',
      backgroundColor: '#f8f8f8',
      padding: '10px',
      borderRadius: '4px',
      margin: '5px 0',
    },
    previewDetails: {
      display: 'flex',
      flexDirection: 'column',
      gap: '15px',
    },
    container: {
      maxWidth: '800px',
      margin: '0 auto',
      padding: '20px',
      backgroundColor: '#fff',
      borderRadius: '10px',
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
    },
    header: {
      textAlign: 'center',
      borderBottom: '2px solid #0C111D',
      paddingBottom: '10px',
      marginBottom: '20px',
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',
    },
    section: {
      marginBottom: '20px',
    },
    inputGroup: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      gap: '10px',
    },
    label: {
      width: '200px', // Fixed width for labels
      textAlign: 'right',
      paddingRight: '10px',
      fontSize: '14px',
      color: '#333',
      fontWeight: '500',
    },
    inputContainer: {
      flex: 1, // Takes remaining space
    },
    input: {
      width: '90%',
      padding: '10px',
      border: '1px solid #ddd',
      borderRadius: '4px',
    },
    required: {
      color: 'red',
      marginLeft: '3px',
    },
    textareaGroup: {
      marginBottom: '20px',
    },
    textareaLabel: {
      display: 'block',
      marginBottom: '10px',
      fontSize: '16px',
      color: '#333',
      fontWeight: '500',
      lineHeight: '1.4',
    },
    textarea: {
      width: '95%',
      padding: '12px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      fontSize: '14px',
      height: '120px',
      maxHeight: '120px',
      resize: 'none',
      overflowY: 'auto',
      margin: '0',
      fontFamily: 'inherit',
    },
    required: {
      color: 'red',
      marginLeft: '3px',
    },
    disclaimer: {
      fontSize: '14px',
      color: '#666',
      marginTop: '20px',
      padding: '10px',
      backgroundColor: '#f8f8f8',
      borderRadius: '4px',
    },
    buttonContainer: {
      display: 'flex',
      gap: '10px',
      justifyContent: 'flex-start',
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.header}>Off the Curriculum Equality, Diversity and Inclusion Report Incident Form</h1>
      <div style={styles.disclaimer}>
        <p>Equality, diversity and inclusion (EDI) is at the heart of everything we do at Off the Curriculum. The purpose of this form is to record any incidents of discrimination during your time with Off the Curriculum. It will be used to collect the incidents in a consistent manner and to report to the appropriate bodies. Off the Curriculum will treat all the information you provide sensitively.</p>
        <p>Please note you also have the option to remain anonymous. If you are unsure about any elements of this form or if you require further assistance completing it, please contact enquiries@offthecurriculum.co.uk.</p>
      </div>
      <form style={styles.form}>
        <div style={styles.section}>
          <h2>Section 1. Incident log</h2>
          <div style={styles.inputGroup}>
          <label style={styles.label}>
            Date of incident:<span style={styles.required}>*</span>
          </label>
          <div style={styles.inputContainer}>
            <input
              type="date"
              value={incidentDate}
              onChange={(e) => setIncidentDate(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          </div>

          <div style={styles.inputGroup}>
          <label style={styles.label}>
            Time of incident:<span style={styles.required}>*</span>
          </label>
          <div style={styles.inputContainer}>
            <input
              type="time"
              value={incidentTime}
              onChange={(e) => setIncidentTime(e.target.value)}
              required
              style={styles.input}
            />
          </div>
          </div>

          <div style={styles.inputGroup}>
          <label style={styles.label}>
            Location of incident:<span style={styles.required}>*</span>
          </label>
          <div style={styles.inputContainer}>
            <input
              type="text"
              value={incidentLocation}
              onChange={(e) => setIncidentLocation(e.target.value)}
              placeholder="Include venue, if applicable. If 'online' state online"
              required
              style={styles.input}
            />
          </div>
        </div>
        </div>

        <div style={styles.section}>
          <h2>Section 2. Incident description</h2>
          
          <div style={styles.textareaGroup}>
            <label style={styles.textareaLabel}>
              Please provide details of the incident. Try to provide as much information as possible. What was said, names of those involved if known. Context of the incident.
              <span style={styles.required}>*</span>
            </label>
            <textarea
              value={incidentDetails}
              onChange={(e) => setIncidentDetails(e.target.value)}
              required
              style={styles.textarea}
            />
          </div>

          <div style={styles.textareaGroup}>
            <label style={styles.textareaLabel}>
              If you are able to identify the offenders, please provide names and/or descriptions
            </label>
            <textarea
              value={offenderDetails}
              onChange={(e) => setOffenderDetails(e.target.value)}
              style={styles.textarea}
            />
          </div>

          <div style={styles.textareaGroup}>
            <label style={styles.textareaLabel}>
              Please add any further information
            </label>
            <textarea
              value={additionalInfo}
              onChange={(e) => setAdditionalInfo(e.target.value)}
              style={styles.textarea}
            />
          </div>
        </div>

        <div style={styles.section}>
        <h2>Section 3. About you</h2>
        
        <div style={styles.inputGroup}>
          <label style={styles.label}>
            Full name<span style={styles.required}>*</span>:
          </label>
          <div style={styles.inputContainer}>
            <input
              type="text"
              value={fullName}
              onChange={(e) => setFullName(e.target.value)} 
              required
              placeholder="Full Name"
              style={styles.input}
            />
          </div>
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label}>
            Phone number<span style={styles.required}>*</span>:
          </label>
          <div style={styles.inputContainer}>
            <input
              type="tel"  
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}  
              required
              placeholder="Phone Number"
              style={styles.input}
            />
          </div>
        </div>

        <div style={styles.inputGroup}>
          <label style={styles.label}>
            Email address<span style={styles.required}>*</span>: 
          </label>
          <div style={styles.inputContainer}>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}  
              required
              placeholder="Email address"
              style={styles.input}
            />
          </div>
        </div>
      </div>
      <div style={styles.buttonContainer}>
        {showValidationError && (
          <div style={errorStyles.errorMessage}>
            {validationError}
          </div>
        )}
        <StandardButtonContainer>
          <StandardButtonContainer.ButtonOne
            type="button" 
            onClick={handlePreviewClick}
          >
            Preview Report
          </StandardButtonContainer.ButtonOne>
        </StandardButtonContainer>
      </div>
      </form>

      {showPreview && <PreviewModal />}
    </div>
  );
};

export default EDIIncidentReportForm;