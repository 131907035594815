import { color } from "framer-motion"

const styles = {
  collapsible: {
    backgroundColor: '#1F484C', 
    border: 'none',
    borderRadius: '12px',
    boxShadow: '0 4px 8px 0 #0003',
    color: '#ffffff',
    cursor: 'pointer',
    display: 'block',
    marginBottom: '2.5%',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'none',
    padding: '18px',
    textAlign: 'left',
    width: '100%',
  },
  collapsibleOpen: {
    backgroundColor: '#FEFBF6',
    border: 'none',
    borderRadius: '12px',
    boxShadow: '0 4px 8px 0 #0003',
    color: '#4a4a4a',
    cursor: 'pointer',
    display: 'block',
    marginBottom: '0%',
    marginLeft: 'auto',
    marginRight: 'auto',
    outline: 'none',
    padding: '18px',
    textAlign: 'left',
    width: '100%',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 20%'
  },
  avatarStyle: {
    width: '40px',
    height: '40px',
    marginLeft: '10px',
    borderRadius: '50%',
    backgroundColor: '#ddd',
    marginRight: '10px',
  },
  infoStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  usernameStyle: {
    fontWeight: 'bold',
  },
  timestampStyle: {
    fontSize: '0.8em',
    color: '#777',
  },
  commentContainer: {
    height: '250px',
    paddingBottom: '0',
    width: '80%',
    overflowY: 'scroll',
    margin: '0 auto',
  },
  actionsStyle: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '15px',
    paddingBottom: '10px'
  },
  commentContainerStyle: {
    display: 'flex',
    alignItems: 'left',
    flex: '1 1 70%',
    justifyContent: 'center',
  },
  commentStyle: {
    borderRadius: '15px',
    backgroundColor: '#f5f5f5',
    position: 'relative',
    marginTop: '7.5px', 
    marginBottom: '7.5px',
    width: '100%',
  },
  paraCommentStyle: {
    borderRadius: '15px',
    position: 'relative',
    marginTop: '7.5px', 
    marginBottom: '7.5px',
    width: '100%',
  },
  arrowStyle: {
    content: '',
    position: 'absolute',
    top: '50%',
    left: '-10px',
    transform: 'translateY(-50%)',
    border: '5px solid transparent',
    borderRight: '5px solid #f5f5f5'
  },
  inputComment: {
    width: '80%',
    minHeight: '40px', 
    padding: '10px',  
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    overflowX: 'hidden', 
    overflowY: 'auto',  
    outline: 'none', 
    fontSize: '1em',   
    resize: 'none',   
    wordWrap: 'break-word', // Allow words to break to the next line
    whiteSpace: 'pre-wrap',
  }, 
  messagesCard:{
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", // Example shadow
    marginBottom: "15px",
  },
  messages:{
    display: "flex",
    flexDirection: "column",
  }, 
  messageForm: {
    display: "flex",
    alignItems: "center",
  }, 
  editButton: {
    backgroundColor: '#383ac6',
    borderRadius: '10px',
    color: '#fff', 
    cursor: 'pointer',
    marginRight: '10px',
    borderColor: '#383ac6',
  }, 
  editButtonHover: {
    backgroundColor: '#28249c', // Darker shade for hover effect
  },
  deleteButton: {
    backgroundColor: '#ed1f06',
    borderRadius: '10px', 
    color: '#fff',
    cursor: 'pointer',
    borderColor: '#ed1f06'
  },
  deleteButtonHover: {
    backgroundColor: '#b81a05', // Darker shade for hover effect
  },
}

export const shortStyles = (theme) => ({
  commentContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: '90%',
    maxHeight: '100%',
  },
  commentWrapper: {
    flexGrow: 1,
    overflowY: 'auto',
    marginBottom: '10px', // Add spacing between comments and form
  },
  messageForm: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '10px',
    borderTop: '1px solid #ccc',
  },
  inputComment: {
    flex: 1,
    padding: '10px',
    border: '1px solid #ddd',
    borderRadius: '4px',
    marginRight: '10px',
  },
  actionsStyle: {
    display: 'flex',
    justifyContent: 'left',
    marginLeft: '15px',
    paddingBottom: '10px'
  },
  commentContainerStyle: {
    display: 'flex',
    alignItems: 'left',
    flex: '1 1 70%',
    justifyContent: 'center',
  },
  avatarStyle: {
    width: '25px',
    height: '25px',
    marginLeft: '10px',
    borderRadius: '50%',
    backgroundColor: '#ddd',
    marginRight: '10px',
  },
  commentStyle: {
    borderRadius: '15px',
    backgroundColor: theme === 'light' ? '#f5f5f5' : '#4d4d4d',
    color: theme === 'light' ? '#000' : '#fff',
    position: 'relative',
    marginTop: '7.5px', 
    marginBottom: '7.5px',
    width: '100%',
  },
  headerStyle: {
    display: 'flex',
    alignItems: 'center',
    flex: '1 1 50%'
  },
  paraCommentStyle: {
    borderRadius: '15px',
    position: 'relative',
    marginTop: '7.5px', 
    marginBottom: '7.5px',
    width: '100%',
  },
  userInfo:{
    fontSize: '6px'
  },
  postTime:{
    fontSize: '6px', 
  },
  arrowStyle: {
    content: '',
    position: 'absolute',
    top: '50%',
    left: '-10px',
    transform: 'translateY(-50%)',
    border: '5px solid transparent',
    borderRight: '5px solid #f5f5f5'
  },
  inputComment: {
    width: '80%',
    minHeight: '40px', 
    borderRadius: '8px',
    border: '1px solid #ccc',
    boxSizing: 'border-box',
    overflowX: 'hidden', 
    overflowY: 'auto',  
    outline: 'none', 
    fontSize: '1em',   
    resize: 'none',   
    wordWrap: 'break-word',
    whiteSpace: 'pre-wrap',
  }, 
  messagesCard:{
    padding: "0px",
    borderRadius: "5px",
    boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)", 
    marginBottom: "15px",
  },
  messages:{
    display: "flex",
    flexDirection: "column",
  }, 
  messageForm: {
    display: "flex",
    alignItems: "center",
  }, 
  editButton: {
    backgroundColor: '#FEFBF6',
    borderRadius: '10px',
    color: '#4a4a4a',
    cursor: 'pointer',
    marginRight: '10px',
    borderColor: '#383ac6',
  }, 
  editButtonHover: {
    backgroundColor: 'orange',
    color: '#fff',
  },
  deleteButton: {
    backgroundColor: '#1F484C',
    borderRadius: '10px', 
    color: '#fff',
    cursor: 'pointer',
    borderColor: '#ed1f06'
  },
  deleteButtonHover: {
    backgroundColor: 'orange',
  },
});


export{styles}