import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';

const PageWrapper = ({ children }) => {
  const location = useLocation();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const smallScreenBreakpoint = 400;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const styles = {
    pageWrapper: {
        paddingTop: '130px',
        width: window.innerWidth < 400 ? '95%' : '90%',
        marginRight: 'auto', 
        marginLeft: 'auto'
    }
}

  return (
    <div style={styles.pageWrapper}>
      {children}
    </div>
  );
};

export default PageWrapper;
