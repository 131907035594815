import { useState } from "react";
export const ImageComponent = ({ imageSrc, imageAlt, videoSrc, styles }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <img
        src={imageSrc}
        alt={imageAlt}
        style={{
          opacity: isHovered ? 0 : 1,
          transition: 'opacity 500ms',
          ...styles
        }}
      />

      {isHovered && (
        <video
          autoPlay
          muted
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            opacity: isHovered ? 1 : 0,
            transition: 'opacity 10ms ease-in-out',
            borderRadius:'10px'
          }}
        >
          <source src={videoSrc} type="video/mp4" />
        </video>
      )}
    </div>
  );
};