import { FaLinkedin, FaTwitter, FaGlobe } from 'react-icons/fa';

const eventData = {
  id: 'uncoveringthemysteriouslifeofworms', // Add this
  title: "Uncovering the Mysterious Life of Worms",
  category: "seminar-series",
  time: "11:00 PM - 13:00 PM",
  location: "Garden Museum, 5 Lambeth Palace Rd, London SE1 7LB",
  ticketNumber: '30',
  registerHere: `Register for the event here!`,
  imageSrc: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fevents%2Funcoveringthemysteriouslifeofworms%2Funcoveringthemysteriouslifeofworms.webp?alt=media&token=c9ce723b-f161-44c2-975b-8719f1ae9bb9",
  description: "Join us at the Garden Museum for the first in our 'Off the Track' 2024/25 workshop series, an engaging and interactive session designed to introduce you to the vital importance of biodiversity and its impact on our climate. This session will immerse students in hands-on scientific exploration of the anatomy of worms, and highlight the complexity and ecological significance of soils through hands-on practical investigations. The sessions will be hosted in the state-of-the-art Clore Learning Space!!",
  mapLocation: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2483.994663742273!2d-0.122766523016731!3d51.4949654116112!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487604c019ce4005%3A0x68b50f18a38b02a1!2sGarden%20Museum!5e0!3m2!1sen!2sfr!4v1731478495598!5m2!1sen!2sfr`,
  speaker: {
    name: "Samia Qureshi",
    title: "Biology Expert and School Science Learning Officer at the Garden Museum",
    image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2Fevents%2Funcoveringthemysteriouslifeofworms%2FguestSpeaker.png?alt=media&token=e91806db-e827-48bd-b9c1-337b378211a8",
    bio: "Samia Qureshi, the Plant Science Educator at the Garden Museum brings over 20 years of experience as a post-harvest scientist in the fresh produce industry and holds qualifications as a biology teacher. With a focus on curriculum-linked sessions for Key Stages 3, 4, and 5, Samia specialises in plant science and related topics.",
    socialLinks: [
      {
        platform: <FaLinkedin size={24} />,
        url: "https://www.linkedin.com/company/the-garden-museum/"
      },
      {
        platform: <FaTwitter size={24} />,
        url: "https://x.com/gardenmuseumldn"
      },
      {
        platform: <FaGlobe size={24} />,
        url: "https://gardenmuseum.org.uk/plant-science-for-secondary-schools-at-the-garden-museum/?srsltid=AfmBOoqfH9Tv05gF0JYz8F_fdDUCD4OaLhxEzsGWvcB4eQb3C9PpBMzr"
      }
    ]
  },
  learningObjectives: [
    {
      title: "Soil Investigations",
      description: "You will conduct a series of short, practical laboratory tests to analyse different soil types, pH levels, and water-holding capacities."
    },
    {
      title: "Understanding the Anatomy and Physiology of Worms",
      description: "You will gain an insights into the anatomy of worms and the crucial ecological role that worms play in soil health."
    },
    {
      title: "Building a Sustainable Wormery",
      description: "You will build your own mini-wormery to take home, complete with a live worm with the intention of fostering a deeper understanding of ecological balance and sustainable practices."
    },
  ],
  requirements: [
    {
    description: 'To participate in this seminar, students should bring the following:', 
    listItems: [ 
      {
      listItem: "Paper and writing materials", 
    },
    {
      listItem: "A <b>plastic water bottle </b>(either a 1L bottle or 1.5L bottle)", 
    },
  ]
  }],
  officers: [
    {
      name: "Joseph Levermore, Ph.D.",
      role: "Founder of Curriculum Officer",
      image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FjosephLevermore.webp?alt=media&token=53a6caf9-8580-47f7-80ed-c2c32bbe535a",
      description: "Researcher at Imperial College London and founder of Off the Curriculum, driven by a vision to enhance educational opportunities in Lambeth and Southwark."
    },
    {
      name: "Tim Siu",
      role: "Recruitment and Deputy Safeguarding Team Lead",
      image: "https://firebasestorage.googleapis.com/v0/b/off-the-curriculum-8ef2a.appspot.com/o/website%2FmeetTheTeam%2Fplaceholder-officers%2FtimSiu.webp?alt=media&token=4669e90a-5bba-4957-9b97-be8aa4d08b2e",
      description: "Executive Talent Acquisition Lead for Tax across EMEIA at EY, with extensive experience in senior-level recruitment."
    },
  ],
  formLink: "https://your-form-link.com"
};

export default eventData;